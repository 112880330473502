<template>
  <div>
    <div style="position: absolute; min-width: 1250px">
      <div class="img_title flex_center">
        <img
          style="width: 100%"
          src="../../src/assets/img/work_center_bg.png"
          alt=""
        />
      </div>

      <div class="course_list">
        <div class="wrapper course_filter flex_between">
          <ul class="tab list_tab" style="text-align: left; margin-top: -8px">
            <li>
              <a
                href="javascript:;"
                @click="good"
                :class="n === 1 ? 'current' : ''"
                >{{ $t("message.excellentwork") }}</a
              >
            </li>
            <li>
              <a
                href="javascript:;"
                @click="my"
                :class="n === 2 ? 'current' : ''"
                >{{ $t("message.mywork") }}</a
              >
            </li>
          </ul>
          <div>
            <el-select
              style="text-align: right; margin-top: -20px; width: 180px"
              v-model="value"
              :placeholder="$t('message.all')"
            >
              <el-option
                v-for="item in module_type"
                :key="item.value"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
            <router-link :to="{ name: 'addwork', params: { work_id: null } }">
              <el-button
                class="click"
                style="margin-left: 10px"
                type="primary"
                >{{ $t("message.newwork") }}</el-button
              ></router-link
            >
          </div>
        </div>
        <div class="clearfix" style="width: 100%" v-if="n === 1 && show1 != 1">
          <div
            class="hc_item box"
            v-for="(item, index) in goodresult"
            :key="index"
            v-if="
              [(currentPage - 1) * pageSize] <= index &&
              index < currentPage * pageSize
            "
            style="
              width: 19.5%;
              height: 210px;
              float: left;
              margin-left: 100px;
              margin-right: -60px;
              margin-bottom: 15px;
            "
          >
            <router-link
              :to="{ name: 'goodworkdetail', params: { id: item.id } }"
            >
              <div class="cover_area">
                <img
                  style="height: 150px"
                  :src="item.cover_path"
                  alt=""
                  class="object_fit cover"
                />
              </div>
            </router-link>
            <div class="border">
              <div
                class="hot_range"
                style="font-size: 11px; padding-left: 10px"
              >
                {{ $t("message.excellent") }}
              </div>
              <div class="flex_between" style="margin: 10px; margin-top: 10px">
                <em
                  v-if="item.name.length <= 3"
                  style="font-size: 16px; color: black"
                  >{{ $t("message.workname") }}：{{ item.name }}</em
                >
                <el-popover
                  placement="top-start"
                  width="20"
                  trigger="hover"
                  :content="item.name"
                >
                  <em
                    v-if="item.name.length > 3"
                    style="
                      font-size: 16px;
                      white-space: nowrap;
                      width: 20%;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                    slot="reference"
                    >{{ $t("message.workname") }}：
                    {{ item.name | ellipsis }}</em
                  >
                </el-popover>

                <em class="tag" style="font-size: 13px">{{
                  item.type_name.slice(5, 15)
                }}</em>
              </div>

              <div class="flex_between" style="margin: 10px; margin-top: -8px">
                <em style="font-size: 13px; color: gray"
                  >{{ $t("message.author") }}：{{ item.author_name }}</em
                >
                <em style="font-size: 13px; color: gray"
                  >{{ item.click_num }}{{ $t("message.check") }}</em
                >
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="n === 1 && show1 == 1"
          style="text-align: center; margin-top: 100px"
        >
          <div>
            <img
              src="../../src/assets/img/nomessage.png"
              style="width: 50px; text-align: center; position: absolute"
            />
            <span
              style="
                height: 100px;
                line-height: 100px;
                left: 60px;
                position: relative;
                top: -20px;
                color: grey;
              "
              >暂无优秀作品</span
            >
          </div>
        </div>
        <div class="clearfix" style="width: 100%" v-if="n === 2 && show2 != 1">
          <div
            class="hc_item box"
            v-for="(item, index) in myresult"
            :key="index"
            v-if="
              [(currentPage1 - 1) * pageSize] <= index &&
              index < currentPage1 * pageSize
            "
            style="
              width: 19.5%;
              height: 210px;
              float: left;
              margin-left: 100px;
              margin-right: -60px;
              margin-bottom: 15px;
            "
          >
            <router-link :to="{ name: 'workdetail', params: { id: item.id } }">
              <div class="cover_area">
                <img
                  style="height: 150px"
                  :src="item.cover_path"
                  alt=""
                  class="object_fit cover"
                />
              </div>
            </router-link>
            <div>
              <el-row style="text-align: right; margin-top: -30px">
                <el-button
                  size="mini"
                  class="edit"
                  type="primary"
                  plain
                  style="height: 30px; width: 80px"
                  v-if="item.status == 0"
                  >{{ $t("message.privatework") }}</el-button
                >
                <el-button
                  size="mini"
                  class="edit"
                  type="primary"
                  plain
                  style="height: 30px; width: 80px"
                  v-if="item.status == 1"
                  >{{ $t("message.excellent") }}</el-button
                >

                <el-button
                  size="mini"
                  class="edit"
                  type="primary"
                  plain
                  style="height: 30px; width: 80px"
                  v-if="item.status == 2"
                  >{{ $t("message.underreview") }}</el-button
                >
              </el-row>
              <div class="flex_between" style="margin: 10px; margin-top: 5px">
                <em v-if="item.name.length <= 3"
                  >{{ $t("message.workname") }}：{{ item.name }}</em
                >
                <el-popover
                  placement="top-start"
                  width="20"
                  trigger="hover"
                  :content="item.name"
                >
                  <em
                    v-if="item.name.length > 3"
                    style="
                      white-space: nowrap;
                      width: 20%;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    "
                    slot="reference"
                    >{{ $t("message.workname") }}：
                    {{ item.name | ellipsis }}</em
                  >
                </el-popover>

                <em class="tag" style="font-size: 13px">{{
                  item.type_name.slice(5, 15)
                }}</em>
              </div>

              <div class="flex_between" style="margin: 10px">
                <em style="font-size: 13px; color: gray"
                  >{{ $t("message.author") }}：{{ item.author_name }}</em
                >
                <em style="font-size: 13px; color: gray"
                  >{{ item.click_num }}{{ $t("message.check") }}</em
                >
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="n === 2 && show2 == 1"
          style="text-align: center; margin-top: 100px"
        >
          <div>
            <img
              src="../../src/assets/img/nomessage.png"
              style="width: 50px; text-align: center; position: absolute"
            />
            <span
              style="
                height: 100px;
                line-height: 100px;
                left: 60px;
                position: relative;
                top: -20px;
                color: grey;
              "
              >暂无我的作品</span
            >
          </div>
        </div>
      </div>
    </div>
    <div style="position: relative; top: 640px" v-if="n === 1">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[8, 16, 24, 32]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="goodresult ? goodresult.length : 0"
      ></el-pagination>
    </div>
    <div style="position: relative; top: 640px" v-if="n === 2">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange1"
        :current-page="currentPage1"
        :page-sizes="[8, 16, 24, 32]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="myresult ? myresult.length : 0"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { excelentpage } from "@/http/api.js"; // 导入接口
import { workspage } from "@/http/api.js"; // 导入接口

import CommonLayout from "@/components/CommonLayout.vue";

import CommonFooter from "@/components/CommonFooter.vue";
import CommonStudent from "@/components/CommonStudent.vue";
import CommonTeacher from "@/components/CommonTeacher.vue";
export default {
  name: "taskcenter",
  data() {
    return {
      n: 1,
      value: "",
      type2: "",
      status: "",
      module_type: [],
      goodworks: [],
      goodresult: [],

      myworks: [],
      myresult: [],
      currentPage: 1,
      currentPage1: 1,
      pageSize: 8,
      show1: null,
      show2: null,
      visible: false,
    };
  },
  filters: {
    ellipsis(value) {
      if (!value) return "";
      if (value.length > 3) {
        return value.slice(0, 3) + "...";
      }
      return value;
    },
  },
  methods: {
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
    },
    handleCurrentChange1(currentPage1) {
      this.currentPage1 = currentPage1;
    },
    findcate(item) {
      console.log("item", item);
      this.goodresult = this.goodworks;
      this.goodresult = this.goodworks.filter(function (num) {
        console.log("num", num);
        return num.type_name === item.name;
      });
      this.myresult = this.myworks;
      this.myresult = this.myworks.filter(function (num) {
        console.log(item.name);
        return num.type_name === item.name;
      });
    },

    good() {
      this.n = 1;
      let token = JSON.parse(localStorage.getItem("Authorization"));
      let verify = localStorage.getItem("verify");
      excelentpage({
        school_id: token.school_id,
        uid: token.user_id,
        schoolid: token.school_id,
        verify: verify,
      }).then((res) => {
        if (res.works != null) {
          this.goodworks = res.works;
        } else {
          this.show1 = 1;
        }
      });
      this.goodresult = this.goodworks;
    },
    my() {
      this.n = 2;
      let token = JSON.parse(localStorage.getItem("Authorization"));
      let verify = localStorage.getItem("verify");
      workspage({
        school_id: token.school_id,
        user_id: token.user_id,
        uid: token.user_id,
        schoolid: token.school_id,
        verify: verify,
      }).then((res) => {
        if (res.works != null) {
          this.myworks = res.works;
        } else {
          this.show2 = 1;
        }
      });
      this.myresult = this.myworks;
    },
  },
  created() {
    let token = JSON.parse(localStorage.getItem("Authorization"));
    let verify = localStorage.getItem("verify");
    excelentpage({
      school_id: token.school_id,
      uid: token.user_id,
      schoolid: token.school_id,
      verify: verify,
    }).then((res) => {
      this.module_type = res.module_type;
      this.goodworks = res.works;
      this.goodresult = this.goodworks;
      if (res.works != null) {
        this.goodworks = res.works;
      } else {
        this.show1 = 1;
      }
      res.module_type.shift();
      this.module_type.unshift({
        name: "全部",
      });
    });
    console.log("token", token);
    workspage({
      school_id: token.school_id,
      user_id: token.user_id,
      uid: token.user_id,
      schoolid: token.school_id,
      verify: verify,
    }).then((res) => {
      // console.log(res);
      this.module_type = res.module_type;
      this.myworks = res.works;
      this.myresult = this.myworks;
    });
  },
  mounted() {
    if (this.$route.params.n === 2) this.n = this.$route.params.n;
  },

  components: {
    CommonLayout,
    CommonFooter,
    CommonStudent,
    CommonTeacher,
  },

  watch: {
    n: {
      handler: function (n, o) {
        console.log("n", n);
      },
    },
    value: {
      handler: function (newValue, oldValue) {
        console.log("newValue", newValue);
        if (newValue !== "全部") {
          if (this.goodresult) {
            this.goodresult = this.goodworks.filter(function (num) {
              return num.type_name === newValue;
            });
          }
          if (this.myresult !== null) {
            this.myresult = this.myworks.filter(function (num) {
              return num.type_name === newValue;
            });
          }
        }
        if (newValue === "全部") {
          this.goodresult = this.goodworks;
          this.myresult = this.myworks;
        }
      },
    },
  },
};
</script>

<style scoped lang="css">
.zi {
  font-family: 微软雅黑;
  line-height: 13px;
  font-size: 13px;
  text-indent: -6px;
  text-align: left;
}

.fot {
  position: fixed;
  bottom: 0;
  width: 100%;
  line-height: var(--footer-height);
  color: #fff;
  margin-bottom: 5px;
}

.edit,
.edit:focus {
  color: rgb(239, 88, 10);
  background-color: rgb(253, 246, 236);
  border-color: rgb(239, 88, 10);
}

.edit:hover {
  background: rgb(239, 88, 10);
  border-color: rgb(239, 88, 10);
  color: white;
}

.click {
  color: #fff;
  background-color: rgb(239, 88, 10);
  border-color: rgb(239, 88, 10);
}

.click:hover,
.click:focus {
  background: rgb(235, 157, 121);
  border-color: rgb(235, 157, 121);
  color: #fff;
}
</style>

<style lang="css" src="../../src/assets/.css/common.css" scoped></style>
<style lang="css" src="../../src/assets/.css/index.css" scoped></style>
<style lang="css" src="../../src/assets/.css/page.css" scoped></style>
<style lang="css" scoped>
* {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: #2c3e50; */
}
</style>
