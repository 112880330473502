<template>
  <div>
    <div>
      <div class="bread" style="padding: 20px 0; text-align: left; margin-left: 5%">
        <a @click="fanhui" style="margin-left: ; font-size: 20px">{{
          $t("message.return")
        }}</a>
        <span style="margin-left: ; font-size: 20px">{{
          $t("message.testdetail")
        }}</span>
      </div>

      <div class="wrapper">
        <h2 class="padding_bottom_20"></h2>
      </div>

      <div v-if="restatus === 0" class="wrapper two_box_container flex_between"
        style="width: 84%; margin-left: 8%; margin-right: 8%">
        <div class="box box_left" style="width: 75%; height: 630px">
          <div class="main_content">
            <div class="answer form_feild" v-for="(item, index) in questions" :key="index" style="width: 100%">
              <div v-if="num === index && item.type === '单项选择题'" style="text-align: left">
                <span v-if="item.is_must === 1" style="color: red">* </span>
                <span>{{ index + 1 }}.{{ item.stem }}</span>
                <div>
                  <img v-if="item.photo != null" style="width: 200px; text-align: left" :src="item.photo" alt="" />
                </div>

                <div :key="index" style="">
                  <div style="">
                    <div>
                      <el-radio v-if="item.content.photoA != null" style="margin-bottom: 5px; text-align: left"
                        v-model="item.student_answer" label="A">A.{{ item.content.itemA }}</el-radio>
                      <el-radio v-if="item.content.photoA == null" style="margin-bottom: 5px; text-align: left"
                        v-model="item.student_answer" label="A">A.{{ item.content.itemA }}</el-radio>
                      <img v-if="item.content.photoA != null" style="width: 200px; height: 60px; text-align: left"
                        :src="item.content.photoA" alt="" />
                    </div>
                    <div>
                      <el-radio v-if="item.content.photoB != null" style="margin-bottom: 5px; text-align: left"
                        v-model="item.student_answer" label="B">B.{{ item.content.itemB }}</el-radio>
                      <el-radio v-if="item.content.photoB == null" style="margin-bottom: 5px; text-align: left"
                        v-model="item.student_answer" label="B">B.{{ item.content.itemB }}</el-radio>
                      <img v-if="item.content.photoB != null" style="width: 200px; height: 60px; text-align: left"
                        :src="item.content.photoB" alt="" />
                    </div>
                    <div>
                      <el-radio v-if="item.content.photoC != null" style="margin-bottom: 5px; text-align: left"
                        v-model="item.student_answer" label="C">C.{{ item.content.itemC }}</el-radio>
                      <el-radio v-if="item.content.photoC == null" style="margin-bottom: 5px; text-align: left"
                        v-model="item.student_answer" label="C">C.{{ item.content.itemC }}</el-radio>
                      <img v-if="item.content.photoC != null" style="width: 200px; height: 60px; text-align: left"
                        :src="item.content.photoC" alt="" />
                    </div>
                    <div>
                      <el-radio v-if="item.content.photoD != null" style="margin-bottom: 5px; text-align: left"
                        v-model="item.student_answer" label="D">D.{{ item.content.itemD }}
                      </el-radio>
                      <el-radio v-if="item.content.photoD == null" style="margin-bottom: 5px; text-align: left"
                        v-model="item.student_answer" label="D">D.{{ item.content.itemD }}
                      </el-radio>
                      <img v-if="item.content.photoD != null" style="width: 200px; height: 60px; text-align: left"
                        :src="item.content.photoD" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="num === index && item.type === '文字问答题'" style="text-align: left">
                <span v-if="item.is_must === 1" style="color: red">* </span>
                <span>{{ index + 1 }}.{{ item.stem }}</span>
                <div>
                  <img v-if="item.photo != null" style="width: 200px; text-align: left" :src="item.photo" alt="" />
                </div>
                <el-input style="text-align: left; margin-top: 30px" :autosize="{ minRows: 10, maxRows: 10 }"
                  type="textarea" :rows="2" placeholder="请输入内容" v-model="item.student_answer">
                </el-input>
              </div>
              <!-- 自动测评 -->
              <div v-if="num === index && item.type === '自动测评编程题'" style="display: flex; flex-direction:column">
                <div style="display: flex; justify-content: space-between; align-items: center;">
                  <div>
                    <span v-if="item.is_must === 1" style="color: red">* </span>
                    <span>{{ index + 1 }}.{{ item.stem }}
                      <i style="
                          margin-left: 5px;
                          font-size: 10px;
                          color: rgb(239, 88, 10);
                          font-weight: bold;
                        ">点击提交按钮，答案方可提交</i></span>
                  </div>
                  <div>
                    <img src="../../src/assets/img/big.png" style="width: 20px" alt="" @click="dianji" />
                  </div>
                </div>
                <iframe :src="iframeSrc" allow="fullscreen" scrolling="no"
                  style="width: 100%; height: 460px; margin-bottom: 20px" frameborder="0" id="iframeId"></iframe>
              </div>
            </div>
          </div>
          <div>
            <div class="btn_field">
              <!-- <input type="hidden" name="step" id="step" value="" /> -->
              <a href="javascript:;" :class="n === 1 ? 'btn home_btn' : 'btn home_btn btn_light'" @click="prex()"
                :disabled="preDisabled">
                {{ $t("message.previousquestion") }}</a>
              <a href="javascript:;" :class="n === 2 ? 'btn home_btn' : 'btn home_btn btn_light'" @click="next()"
                :disabled="nextDisabled">{{ $t("message.nextquestion") }}</a>
            </div>
          </div>
        </div>

        <div class="box box_right" style="width: 23%; height: 630px">
          <div class="top_info">
            <div class="flex_between">
              <img src="../../src/assets/img/clock.png" alt="" />
              <div class="release_time">
                <span>{{ $t("message.timeremaining") }}</span>
                <em id="sectotime">{{ countDown }}</em>
              </div>
            </div>
          </div>
          <div class="question_list_container" style="margin-top: 20px">
            <div class="question_title flex_between">
              <span v-for="(item, index) in questions" :key="index" v-if="num === index">{{ $t("message.grade") }}：{{
                item.score }}</span>

              <span>{{ $t("message.tishu") }}：{{ length }} </span>
            </div>
            <div class="question_list clearfix">
              <div v-for="(item, index) in questions" :key="index">
                <!-- <a :class="item.student_answer !== null ? 'q_item done' : 'q_item'
                  " @click="pick(index)">{{ index + 1 }}</a> -->
                <a :class="'q_item'" @click="pick(index)">{{ index + 1 }}</a>
              </div>
            </div>
          </div>
          <div class="bottom_btn">
            <input type="hidden" name="is_submit" id="is_submit" value="0" />
            <input type="button" value="交卷" style="width: 100%; margin: 0" class="btn home_btn" @click="submit" />
          </div>
        </div>
      </div>
      <div v-if="restatus === 1" class="wrapper two_box_container flex_between"
        style="width: 84%; margin-left: 8%; margin-right: 8%">
        <div class="box box_left" style="width: 75%; height: 705px">
          <div class="main_content" style="height: 70px">
            <div class="answer form_feild" v-for="(item, index) in questions" :key="index" style="width: 100%">
              <div v-if="num === index && item.type === '单项选择题'" style="text-align: left">
                <span v-if="item.is_must === 1" style="color: red">* </span>
                <span>{{ index + 1 }}.{{ item.stem }}</span>
                <div>
                  <img v-if="item.photo != null" style="width: 200px; text-align: left" :src="item.photo" alt="" />
                </div>
                <div :key="index">
                  <div>
                    <el-radio disabled v-if="item.content.photoA != null" style="margin-bottom: 5px; text-align: left ;"
                      v-model="item.student_answer" label="A"><span :class="item.answer !== 'A' && item.student_answer === 'A'
                        ? 'red'
                        : item.answer === 'A' && item.student_answer === 'A'
                          ? 'green'
                          : ''
                        ">A：{{ item.content.itemA }}</span></el-radio>
                    <el-radio disabled v-if="item.content.photoA == null" style="margin-bottom: 5px; text-align: left"
                      v-model="item.student_answer" label="A"><span :class="item.answer !== 'A' && item.student_answer === 'A'
                        ? 'red'
                        : item.answer === 'A' && item.student_answer === 'A'
                          ? 'green'
                          : ''
                        ">A：{{ item.content.itemA }}</span></el-radio>
                    <img v-if="item.content.photoA != null" style="width: 200px; height: 60px; text-align: left"
                      :src="item.content.photoA" alt="" />
                  </div>
                  <div>
                    <el-radio disabled v-if="item.content.photoB != null" style="margin-bottom: 5px; text-align: left"
                      v-model="item.student_answer" label="B"><span :class="item.answer !== 'B' && item.student_answer === 'B'
                        ? 'red'
                        : item.answer === 'B' && item.student_answer === 'B'
                          ? 'green'
                          : ''
                        ">B：{{ item.content.itemB }}</span></el-radio>
                    <el-radio disabled v-if="item.content.photoB == null" style="margin-bottom: 5px; text-align: left"
                      v-model="item.student_answer" label="B"><span :class="item.answer !== 'B' && item.student_answer === 'B'
                        ? 'red'
                        : item.answer === 'B' && item.student_answer === 'B'
                          ? 'green'
                          : ''
                        ">B：{{ item.content.itemB }}</span></el-radio>
                    <img v-if="item.content.photoC != null" style="width: 200px; height: 60px; text-align: left"
                      :src="item.content.photoB" alt="" />
                  </div>
                  <div>
                    <el-radio disabled v-if="item.content.photoC != null" style="margin-bottom: 5px; text-align: left ;"
                      v-model="item.student_answer" label="C"><span :class="item.answer !== 'C' && item.student_answer === 'C'
                        ? 'red'
                        : item.answer === 'C' && item.student_answer === 'C'
                          ? 'green'
                          : ''
                        ">C：{{ item.content.itemC }}</span></el-radio>
                    <el-radio disabled v-if="item.content.photoC == null" style="margin-bottom: 5px; text-align: left"
                      v-model="item.student_answer" label="C"><span :class="item.answer !== 'C' && item.student_answer === 'C'
                        ? 'red'
                        : item.answer === 'C' && item.student_answer === 'C'
                          ? 'green'
                          : ''
                        ">C：{{ item.content.itemC }}</span></el-radio>
                    <img v-if="item.content.photoC != null" style="width: 200px; height: 60px; text-align: left"
                      :src="item.content.photoC" alt="" />
                  </div>
                  <div>
                    <el-radio disabled v-if="item.content.photoD != null" style="margin-bottom: 5px; text-align: left ;"
                      v-model="item.student_answer" label="D"><span :class="item.answer !== 'D' && item.student_answer === 'D'
                        ? 'red'
                        : item.answer === 'D' && item.student_answer === 'D'
                          ? 'green'
                          : ''
                        ">D：{{ item.content.itemD }}</span></el-radio>
                    <el-radio disabled v-if="item.content.photoD == null" style="margin-bottom: 5px; text-align: left"
                      v-model="item.student_answer" label="D"><span :class="item.answer !== 'D' && item.student_answer === 'D'
                        ? 'red'
                        : item.answer === 'D' && item.student_answer === 'D'
                          ? 'green'
                          : ''
                        ">D：{{ item.content.itemD }}</span></el-radio>
                    <img v-if="item.content.photoD != null" style="width: 200px; height: 60px; text-align: left"
                      :src="item.content.photoD" alt="" />
                  </div>
                </div>
              </div>
              <div v-if="num === index && item.type === '文字问答题'" style="text-align: left">
                <span v-if="item.is_must === 1" style="color: red">* </span>
                <span>{{ index + 1 }}.{{ item.stem }}</span>
                <div>
                  <img v-if="item.photo != null" style="width: 200px; text-align: left" :src="item.photo" alt="" />
                </div>
                <el-input style="text-align: left; margin-top: 30px" :autosize="{ minRows: 10, maxRows: 10 }"
                  type="textarea" :rows="2" v-model="item.student_answer" placeholder="请输入内容">
                </el-input>
              </div>
              <!-- 自动测评 -->
              <div v-if="num === index && item.type === '自动测评编程题'" style="display: flex; flex-direction:column">
                <div style="display: flex; justify-content: space-between; align-items: center;">
                  <div>
                    <span v-if="item.is_must === 1" style="color: red">* </span>
                    <span>{{ index + 1 }}.{{ item.stem }}
                      <i style="
                          margin-left: 5px;
                          font-size: 10px;
                          color: rgb(239, 88, 10);
                          font-weight: bold;
                        ">点击提交按钮，答案方可提交</i></span>
                  </div>
                  <div style="margin-top: 30px">
                    <img src="../../src/assets/img/big.png" style="width: 20px" alt="" @click="dianji" />
                  </div>
                </div>
                <iframe :src="iframeSrc" allow="fullscreen" scrolling="no"
                  style="width: 100%; height: 440px; margin-bottom: 20px" frameborder="0" id="iframeId"></iframe>
              </div>
            </div>
          </div>
          <div class="opr_field" style="margin-top: 400px">
            <div v-for="(item, index) in questions" :key="index">
              <div v-if="num === index && item.type === '单项选择题'">
                <div class="flex">
                  <span v-if="item.student_answer === item.answer" class="right_answer">{{ $t("message.correct") }}</span>
                  <span v-if="item.student_answer !== item.answer" class="wrong_answer">{{ $t("message.wrong") }}</span>
                  <p style="text-align: left">
                    {{ $t("message.answer") }}：{{ item.answer }}
                  </p>
                </div>
                <p style="float: left; margin-left: 50px" class="explain">
                  {{ $t("message.explanation") }}：{{ item.explanation }}
                </p>
                <div>
                  <div style="
                    text-align: right;
                    margin-right: 20px;
                    color: #56c12b;
                    font-size: 20px;
                  ">
                    得分:
                    <span style="font-size: 22px">{{ item.sscore }}</span>
                  </div>
                </div>
              </div>
              <div v-if="num === index && item.type === '文字问答题'">
                <div style="display: flex; justify-content: space-between; align-items: center;">
                  <div class="flex">
                    <p style="text-align: left">
                      {{ $t("message.answer") }}：{{ item.answer }}
                    </p>
                  </div>
                  <div>
                    <div style="
                      text-align: right;
                      margin-right: 20px;
                      color: #56c12b;
                      font-size: 20px;
                    ">
                      得分:
                      <span style="font-size: 20px">{{ item.sscore }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="num === index && item.type === '自动测评编程题'">
                <div style="display: flex; justify-content: space-between; align-items: center;">
                  <div>
                    <span v-if="item.judge_status === '挑战成功'" class="right_answer" style="font-size: 20px">{{
                    $t("message.correct") }}</span>
                    <span v-if="item.judge_status !== '挑战成功'" class="wrong_answer" style="font-size: 20px">{{ $t("message.wrong")
                    }}</span>
                  </div>
                  <div>
                    <div style="
                      text-align: right;
                      margin-right: 20px;
                      color:#56c12b;
                      font-size: 20px;
                    ">
                      得分:
                      <span style="font-size: 22px">{{ item.sscore }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="btn_field">
            <a href="javascript:;" :class="n === 1 ? 'btn home_btn' : 'btn home_btn btn_light'" @click="prex"
              :disabled="preDisabled">
              {{ $t("message.previousquestion") }}</a>
            <a href="javascript:;" :class="n === 2 ? 'btn home_btn' : 'btn home_btn btn_light'" @click="next"
              :disabled="nextDisabled">{{ $t("message.nextquestion") }}</a>
          </div>
        </div>

        <div class="box box_right" style="width: 23%; height: 705px">
          <div class="top_info">
            <div style="
                color: #00979c;
                text-align: left;
                border-bottom: solid #a0d8da 1px;
                line-height: 50px;
                padding-right: 15px;
              ">
              <img src="../../src/assets/img/test_correct.png" style="float: left" alt="" />
              <div v-for="(item, index) in questions" :key="index">
                <span v-if="num === index">{{ $t("message.grade") }}:</span>
                <em v-if="num === index">{{ grade }}</em>
              </div>
            </div>
            <div class="correct_percent" style="margin-top: 10px">
              <span>{{ $t("message.time") }}：{{ finish_time1 }}</span>
              <span></span>
            </div>
          </div>
          <div class="question_list_container">
            <div class="question_title"></div>
            <div class="question_list clearfix">
              <div v-for="(item, index) in questions" :key="index">
                <a v-if="item.type === '单项选择题'" :class="item.student_answer === item.answer
                  ? 'q_item right q_item right current'
                  : 'q_item wrong q_item wrong current'
                  " @click="pick(index)">{{ index + 1 }}</a>
                <a v-if="item.type === '文字问答题'" class="q_item" @click="pick(index)">{{ index + 1 }}</a>
                <a v-if="item.type === '自动测评编程题'" :class="item.judge_status === '挑战成功'
                  ? 'q_item right q_item right current'
                  : 'q_item wrong q_item wrong current'
                  " @click="pick(index)">{{ index + 1 }}</a>
              </div>
            </div>
          </div>
          <div class="bottom_btn">
            <router-link :to="{ name: 'coursedetail', params: { id: kcid } }" style="width: 100%; margin: 0"
              class="btn home_btn btn_light">{{ $t("message.return") }}</router-link>
          </div>
        </div>
      </div>
    </div>
    <EmptyFooter />
  </div>
</template>

<script>

import EmptyFooter from "@/components/EmptyFooter.vue";
import { test } from "@/http/api.js"; // 导入接口
import { submit } from "@/http/api.js"; // 导入接口
export default {
  name: "coursetest",
  data() {
    return {
      uid: null,
      schoolid: null,
      verify: null,

      mark: 0,
      id: null,
      kcid: "",
      csid: "",
      status: "",
      type2: "",

      preDisabled: true, //上禁用按钮
      nextDisabled: false, //下禁用按钮
      num: 0, //第几题
      percent: "",
      n: 0,
      en: 0,
      sum1: 0,
      sum2: 0,

      questions: [],
      questions2: [],
      length: "", //questions数组长度
      duration: "",
      restatus: "",

      postquestions: [],
      answers: [],
      grade: "",
      finish_time: "",

      count: "", //倒计时
      seconds: null, // 倒计时的秒数
      time: "",
      finish_time1: "",
      tijiao: null,
      panduan: 1,
      countDownTime: null, //设置默认时间,(单位秒)
      timer: null, //计数器名称,便于后面清除,
      language_type: null,
      sample_input: "",
      cfile_id: null,
      template_file_id: null,
      judge_status: null
    };
  },

  created() {
    this.getUserInfo();
    this.id = this.$route.params.id;
    this.kcid = this.$route.params.kcid;
    let token = JSON.parse(localStorage.getItem("Authorization"));
    let verify = localStorage.getItem("verify");
    console.log({
      user_id: token.user_id,
      module_id: this.id,
      uid: token.user_id,
      schoolid: token.school_id,
      verify: verify,
    });
    test({
      user_id: token.user_id,
      module_id: this.id,
      uid: token.user_id,
      schoolid: token.school_id,
      verify: verify,
    }).then((res) => {
      this.questions = res.questions;
      if (res.questions != null) {
        let i = 0;
        for (i; i < this.questions.length; i++) {
          if (this.questions[i].front_question === 0) {
            this.questions2[0] = this.questions[i];
            var x = i;
            break;//ww:加了一个break这里应该就可以结束了吧，这段代码应该是要找出第一个问题?
          }
        }
        var k = 1;
        for (k; k < this.questions.length; k++) {
          for (var j = 0; j < this.questions.length; j++) {
            if (this.questions[j].front_question === this.questions[x].id) {
              this.questions2[k] = this.questions[j];
              var x = j;
              break;
            }
          }
        }
        this.questions = this.questions2;
      }
      localStorage.setItem(
        "question_id",
        JSON.stringify(this.questions[this.mark].id)
      );
      if (res.questions != null) {
        this.length = res.questions.length;
      }
      if (this.questions[0].typeid === 3) {
        this.language_type = this.questions[0].content.type;
        this.sample_input = this.questions[0].content.sample_input;
        this.template_file_id = this.questions[this.mark].content.template_file_id;
        if(this.questions[0].student_answer != null && this.questions[0].student_answer != ""){
          this.cfile_id = parseInt(this.questions[0].student_answer);
          this.judge_status = this.questions[0].judge_status;
        }else{
          this.cfile_id = null;
          this.judge_status = '未提交';
        }
      }
      this.restatus = res.status;
      if (this.restatus === 1) {
        this.panduan = 0;
        window.sessionStorage.removeItem("exam_time");
      }
      this.csid = res.id;
      this.grade = res.grade;
      this.finish_time1 = res.finish_time;
      if (
        window.sessionStorage.getItem("exam_time") === null &&
        this.restatus === 0
      ) {
        this.countDownTime = res.duration * 60;
      }
      this.time = res.duration * 60;

      let h = parseInt(this.finish_time1 / (60 * 60));
      h = h < 10 ? "0" + h : h;
      let m = parseInt((this.finish_time1 - h * 3600) / 60);
      m = m < 10 ? "0" + m : m;
      let s = parseInt(this.finish_time1 - h * 3600 - m * 60);
      s = s < 10 ? "0" + s : s;
      this.finish_time1 = h + "时" + m + "分" + s + "秒";
    });
    if (window.sessionStorage.getItem("exam_time") === null) {
      this.startCountDown();
    } else {
      this.countDownTime = Number(window.sessionStorage.getItem("exam_time"));
      this.startCountDown();
    }
  },
  computed: {
    // 计算属性自动计算countDownTime,并转换为倒计时
    countDown() {
      const minutes = Math.floor(this.countDownTime / 60);
      const seconds = this.countDownTime % 60;
      const m = minutes < 10 ? "0" + minutes : minutes;
      const s = seconds < 10 ? "0" + seconds : seconds;
      return `${m}:${s}`;
    },
    iframeSrc: function () {
      let template_file_id =
        this.template_file_id != null
          ? this.template_file_id
          : "'" + "null" + "'";
      let cfile_id = this.cfile_id != null ? this.cfile_id : "'" + "null" + "'";
      let sample_input = "'" + this.sample_input + "'";
      let judge_status = "'" + this.judge_status + "'";
      let restatus = this.restatus != null ? this.restatus : "'" + "null" + "'";
      console.log('222',judge_status);
      return `static/Coj/index4.html?language_type=${this.language_type}&input=${sample_input}&template_file_id=${template_file_id}&cfile_id=${cfile_id}&judge_status=${judge_status}&restatus=${restatus}`;
    },
  },
  beforeRouteLeave(to, from, next) {

    if (this.restatus === 0) {
      if (confirm('禁止离开当前测试')) {
        next(false); // 如果点击了"确认"按钮，则继续路由切换
      } else {
        next(false); // 如果点击了"取消"按钮或关闭对话框，则取消路由切换
      }
    } else {
      next();
    }


  },
  methods: {
    getUserInfo() {
      let newUserInfo = JSON.parse(localStorage.getItem("Authorization"));
      this.uid = newUserInfo.user_id;
      this.schoolid = newUserInfo.school_id;
      this.verify = localStorage.getItem("verify");
    },
    startCountDown() {
      this.timer = setInterval(() => {
        if (this.countDownTime <= 0) {
          //当监测到countDownTime为0时,清除计数器并且移除sessionStorage,然后执行提交试卷逻辑
          clearInterval(this.timer);
          window.sessionStorage.setItem("exam_time", null);
          sessionStorage.removeItem("exam_time");
          // alert("提交试卷");
        } else if (this.countDownTime > 0) {
          //每秒让countDownTime -1秒,并设置到sessionStorage中
          this.countDownTime--;

          if (this.restatus === 1) {
            window.sessionStorage.removeItem("exam_time");
          } else {
            window.sessionStorage.setItem("exam_time", this.countDownTime);
          }
        }
        this.finish_time = this.time + this.countDownTime;
        if (this.countDownTime <= 0 && this.panduan === 1) {
          this.panduan = 0;
          let token = JSON.parse(localStorage.getItem("Authorization"));
          let verify = localStorage.getItem("verify");
          let answer = JSON.parse(localStorage.getItem("answer"));
          let ojjudgereturn = JSON.parse(localStorage.getItem("ojjudgereturn"));
          if (this.questions != null) {
            for (let i = 0; i < this.questions.length; i++) {
              this.answers.push({});

              this.$set(this.answers[i], "question_id", this.questions[i].id);
              this.$set(this.answers[i], "user_id", token.user_id);

              if (this.questions[i].student_answer === null) {
                this.$set(this.answers[i], "answer", null);
              } else {
                this.$set(
                  this.answers[i],
                  "answer",
                  this.questions[i].student_answer
                );
              }
              if (answer != null) {
                for (let x = 0; x < answer.length; x++) {
                  if (answer[x].question_id === this.questions[i].id) {
                    this.$set(this.answers[i], "answer", answer[x].answer);
                  }
                }
              }
              if (answer !== null) {
                for (let y = 0; y < answer.length; y++) {
                  if (answer[y].question_id === this.questions[i].id) {
                    this.$set(this.answers[i], "file_name");
                    this.$set(
                      this.answers[i],
                      "status",
                      answer[y].ojreturn.status
                    );
                    this.$set(
                      this.answers[i],
                      "run_status",
                      answer[y].ojreturn.run_status
                    );
                    this.$set(
                      this.answers[i],
                      "cpu_time",
                      answer[y].ojreturn.cpu_time
                    );
                    this.$set(
                      this.answers[i],
                      "memory",
                      answer[y].ojreturn.memory
                    );
                    this.$set(
                      this.answers[i],
                      "total_tests",
                      answer[y].ojreturn.total_tests
                    );
                  }
                }
              } else {
              }
            }
          }

          console.log('提交的数据')
          console.log({ test_id: this.csid,
            answers: this.answers,
            user_id: token.user_id,
            finish_time: this.finish_time,
            uid: token.user_id,
            schoolid: token.school_id,
            verify: verify,})
          submit({
            test_id: this.csid,
            answers: this.answers,
            user_id: token.user_id,
            finish_time: this.finish_time,
            uid: token.user_id,
            schoolid: token.school_id,
            verify: verify,
          }).then(
            (res) => {
              console.log(res);

              if (res.status == 1) {
                window.sessionStorage.removeItem("exam_time");
                this.success = 1;
                this.$alert("" + res.msg, "提示", {
                  confirmButtonText: "确定",
                  showConfirmButton: false,
                  showCancelButton: false,
                  showClose: false,
                });
                setTimeout(() => {
                  this.$msgbox.close();
                  window.location.reload();
                }, 2000);
              }
              if (res.status != 1) {
                this.$alert("" + res.msg, "错误提示", {
                  confirmButtonText: "确定",
                  showConfirmButton: false,
                  showCancelButton: false,
                  showClose: false,
                });
                setTimeout(() => {
                  this.$msgbox.close();
                  window.location.reload();
                }, 2000);
              }
            },
            function (error) { }
          );
        }
      }, 1000);
    },
    fanhui() {
      if (this.$route.params.back2 === 3) {

        this.$router.push({ name: "taskcenter" });

      } else {

        this.$router.push({
          name: "coursedetail",
          params: { id: this.kcid },
        });

      }
    },
    dianji() {
      //设置后就是id==con_lf_top_div 的容器全屏
      let case1 = document.getElementById("iframeId");
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (case1.requestFullscreen) {
          case1.requestFullscreen();
        } else if (case1.webkitRequestFullScreen) {
          case1.webkitRequestFullScreen();
        } else if (case1.mozRequestFullScreen) {
          case1.mozRequestFullScreen();
        } else if (case1.msRequestFullscreen) {
          // IE11
          case1.msRequestFullscreen();
        }
      }
    },
    next() {
      this.mark++;
      if (this.mark < this.questions.length) {
        localStorage.setItem(
          "question_id",
          JSON.stringify(this.questions[this.mark].id)
        );
        let answer = JSON.parse(localStorage.getItem("answer"));
        let saveanswer = JSON.parse(localStorage.getItem("saveanswer"));
        if (this.questions[this.mark].type === "自动测评编程题") {
          this.language_type = this.questions[this.mark].content.type;
          this.sample_input = this.questions[this.mark].content.sample_input;
          this.template_file_id = this.questions[this.mark].content.template_file_id;
          if (this.restatus === 0) {//如果尚未提交
            //确定cfile_id
            let result = [];
            if(answer != null){
              result = answer.filter(item => item.question_id === this.questions[this.mark].id && item.is_new === 1);
            }
            let data = null;
            if (result.length > 0) {
              data = result[0]
              this.cfile_id = data.answer;
            } else {
              // 如果在answer中没有找到，再从saveanswer数组中查找
              let result2 =[];
              if(saveanswer != null){
                result2 = saveanswer.filter(item => item.question_id === this.questions[this.mark].id && item.is_new === 1);
              }
              if (result2.length > 0) {
                data = result2[0]
                this.cfile_id = data.answer;
              } else {
                this.cfile_id = null;
              }
            }
            //确定judge_status
            let judgeresult = [];
            if(answer != null){
              judgeresult = answer.filter(item => item.question_id === this.questions[this.mark].id);
            }
            if (judgeresult.length > 0) {
              this.judge_status = judgeresult[0].ojreturn.msg;
            } else {
              this.judge_status = "未提交";
            }
          } else {//已经提交
            if(this.questions[this.mark].student_answer != null && this.questions[this.mark].student_answer != ""){
              this.cfile_id = parseInt(this.questions[this.mark].student_answer);
              this.judge_status = this.questions[this.mark].judge_status;
            }else{
              this.cfile_id = null;
              this.judge_status = '未提交';
            }
          }
        }
        this.n = 2;
        this.preDisabled = false;
        if (this.num < this.questions.length - 1 && this.questions != null) {
          this.num += 1;
        }
      } else {
        this.mark--;
      }
    },
    prex() {
      this.mark--;
      if (this.mark >= 0) {
        localStorage.setItem(
          "question_id",
          JSON.stringify(this.questions[this.mark].id)
        );
        let answer = JSON.parse(localStorage.getItem("answer"));
        let saveanswer = JSON.parse(localStorage.getItem("saveanswer"));
        if (this.questions[this.mark].type === "自动测评编程题") {
          this.language_type = this.questions[this.mark].content.type;
          this.sample_input = this.questions[this.mark].content.sample_input;
          this.template_file_id = this.questions[this.mark].content.template_file_id;
          if (this.restatus === 0) {//如果尚未提交
            //确定cfile_id
            let result = [];
            if(answer != null){
              result = answer.filter(item => item.question_id === this.questions[this.mark].id && item.is_new === 1);
            }
            let data = null;
            if (result.length > 0) {
              data = result[0]
              this.cfile_id = data.answer;
            } else {
              // 如果在answer中没有找到，再从saveanswer数组中查找
              let result2 = [];
              if(saveanswer != null){
                result2 = saveanswer.filter(item => item.question_id === this.questions[this.mark].id && item.is_new === 1);
              }
              if (result2.length > 0) {
                data = result2[0]
                this.cfile_id = data.answer;
              } else {
                this.cfile_id = null;
              }
            }
            //确定judge_status
            let judgeresult = [];
            if(answer != null){
              judgeresult = answer.filter(item => item.question_id === this.questions[this.mark].id);
            }
            if (judgeresult.length > 0) {
              this.judge_status = judgeresult[0].ojreturn.msg;
            } else {
              this.judge_status = "未提交";
            }
          } else {//已经提交
            if(this.questions[this.mark].student_answer != null && this.questions[this.mark].student_answer != ""){
              this.cfile_id = parseInt(this.questions[this.mark].student_answer);
              this.judge_status = this.questions[this.mark].judge_status;
            }else{
              this.cfile_id = null;
              this.judge_status = '未提交';
            }
          }
        }

        this.n = 1;
        if (this.num === 0) {
          this.num = 0;
        } else {
          this.num -= 1;
        }
      } else {
        this.mark++;
      }
    },
    pick(index) {
      this.num = index;
      this.mark = index;
      localStorage.setItem(
        "question_id",
        JSON.stringify(this.questions[this.mark].id)
      );
      let answer = JSON.parse(localStorage.getItem("answer"));
      let saveanswer = JSON.parse(localStorage.getItem("saveanswer"));
      if (this.questions[this.mark].type === "自动测评编程题") {
        this.language_type = this.questions[this.mark].content.type;
        this.sample_input = this.questions[this.mark].content.sample_input;
        this.template_file_id = this.questions[this.mark].content.template_file_id;
        if (this.restatus === 0) {//如果尚未提交
          //确定cfile_id
          let result = []
          if(answer != null){
            result = answer.filter(item => item.question_id === this.questions[this.mark].id && item.is_new === 1);
          }
          let data = null;
          if (result.length > 0) {
            data = result[0]
            this.cfile_id = data.answer;
          } else {
            // 如果在answer中没有找到，再从saveanswer数组中查找
            let result2 = [];
            if(saveanswer != null){
              result2 = saveanswer.filter(item => item.question_id === this.questions[this.mark].id && item.is_new === 1);
            }
            if (result2.length > 0) {
              data = result2[0]
              this.cfile_id = data.answer;
            } else {
              this.cfile_id = null;
            }
          }
          //确定judge_status
          let judgeresult = [];
          if(answer != null){
            judgeresult = answer.filter(item => item.question_id === this.questions[this.mark].id);
          }
          if (judgeresult.length > 0) {
            this.judge_status = judgeresult[0].ojreturn.msg;
          } else {
            this.judge_status = "未提交";
          }
        } else {//已经提交
          if(this.questions[this.mark].student_answer != null && this.questions[this.mark].student_answer != ""){
            this.cfile_id = parseInt(this.questions[this.mark].student_answer);
            this.judge_status = this.questions[this.mark].judge_status;
          }else{
            this.cfile_id = null;
            this.judge_status = '未提交';
          }
        }
      }
    },
    submit() {
      // 不知道如何在testsubmit之前自动提交oj
      this.panduan = 0;
      localStorage.setItem("tijiao", 1);
      let token = JSON.parse(localStorage.getItem("Authorization"));
      let verify = localStorage.getItem("verify");
      let answer = JSON.parse(localStorage.getItem("answer"));
      if (this.questions != null) {
        for (let i = 0; i < this.questions.length; i++) {
          this.answers.push({});

          this.$set(this.answers[i], "question_id", this.questions[i].id);
          this.$set(this.answers[i], "user_id", token.user_id);

          if (this.questions[i].student_answer === null) {
            this.$set(this.answers[i], "answer", null);
          } else {
            this.$set(
              this.answers[i],
              "answer",
              this.questions[i].student_answer
            );
          }
          this.$set(this.answers[i], "type", this.questions[i].typeid);
          if (answer !== null) {
            for (let x = 0; x < answer.length; x++) {
              if (answer[x].question_id === this.questions[i].id) {
                console.log("answer", this.answers);
                this.$set(this.answers[i], "answer", answer[x].answer);
              }
            }
          }

          if (answer !== null) {
            for (let y = 0; y < answer.length; y++) {
              if (answer[y].question_id === this.questions[i].id) {
                this.$set(this.answers[i], "file_name");
                this.$set(
                  this.answers[i],
                  "status",
                  answer[y].ojreturn.status
                );
                this.$set(
                  this.answers[i],
                  "run_status",
                  answer[y].ojreturn.run_status
                );
                this.$set(
                  this.answers[i],
                  "cpu_time",
                  answer[y].ojreturn.cpu_time
                );
                this.$set(
                  this.answers[i],
                  "memory",
                  answer[y].ojreturn.memory
                );
                this.$set(
                  this.answers[i],
                  "total_tests",
                  answer[y].ojreturn.total_tests
                );
              }
            }
          } else {
          }
        }
      }
      console.log({test_id: this.csid,
        answers: this.answers,
        user_id: token.user_id,
        finish_time: this.time - (this.finish_time - this.time),
        uid: token.user_id,
        schoolid: token.school_id,
        verify: verify});
      submit({
        test_id: this.csid,
        answers: this.answers,
        user_id: token.user_id,
        finish_time: this.time - (this.finish_time - this.time),
        uid: token.user_id,
        schoolid: token.school_id,
        verify: verify,
      }).then(
        (res) => {
          console.log(res);

          if (res.status == 1) {
            this.success = 1;
            window.sessionStorage.removeItem("exam_time");
            this.$alert("" + res.msg, "提示", {
              confirmButtonText: "确定",
              showConfirmButton: false,
              showCancelButton: false,
              showClose: false,
            });
            localStorage.setItem("answer", null);
            localStorage.setItem("saveanswer", null);
            setTimeout(() => {
              this.$msgbox.close();
              window.location.reload();
            }, 2000);
          }
          if (res.status != 1) {
            this.$alert("" + res.msg, "错误提示", {
              confirmButtonText: "确定",
              showConfirmButton: false,
              showCancelButton: false,
              showClose: false,
            });
            setTimeout(() => {
              this.$msgbox.close();
              // window.location.reload();
            }, 2000);
          }
        },
        function (error) { }
      );
    },
  },
  mounted() {

  },
  components: {
    EmptyFooter,
  },
};
</script>

<style lang="css" src="../../src/assets/.css/common.css" scoped></style>
<style lang="css" src="../../src/assets/.css/index.css" scoped></style>
<style lang="css" src="../../src/assets/.css/page.css" scoped></style>
<style lang="css" scoped>
.red {
  font-weight: bolder;
  color: red;
}

.green {
  font-weight: bolder;

  color: #56c12b;
}

* {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
