<template>
  <div>
  
    <el-select v-model="selectValue" @change="langChange" placeholder="语言">
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  data() {

    return {

      selectValue: '',
      options: [
        {
          value: 'zhcn',
          label: '中文'
        }, {
          value: 'en',
          label: 'English'
        }
      ]
    }
  },
  created() {
    let that = this;
    console.log(localStorage.lang)
    that.selectValue = localStorage.lang == undefined ? 'cn' : localStorage.lang
  },
  methods: {
    langChange(e) {
      console.log(e)
      localStorage.setItem('lang', e);
      this.$i18n.locale = e;
    }
  }
}
</script>

<style></style>