<template>
  <el-dialog title="添加保密问题" :visible.sync="changeVisible">
    <div class="cell">
      <div class="cell_hd">密保问题设置</div>
      <div class="cell_bd">
        <el-select
          size="medium"
          v-model="question_id"
          placeholder="请选择"
          style="width: 390px"
        >
          <el-option
            style="padding-left: 20px"
            v-for="(item, index) in questions"
            :key="index"
            :value="item.id"
            :label="item.question"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="cell">
      <div class="cell_hd">密保问题答案</div>
      <div class="cell_bd">
        <input
          type="text"
          style="height: 36px"
          v-model="answer"
          placeholder="请输入"
        />
      </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="clickConfirm">确定</el-button>
      <el-button
        type="info"
        plain
        style="margin-left: 40px"
        @click="changeVisible = false"
        >取消</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { info } from "@/http/api.js"; // 导入接口
import { question } from "@/http/api.js"; // 导入接口
export default {
  name: "Addquestion",
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    type: {
      // false-添加 true-修改
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      questions: [],
      question_id: "",
      answer: "",
    };
  },
  watch: {},
  computed: {
    changeVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
        this.initDialog();
      },
    },
  },
  methods: {
    initDialog() {},
    clickConfirm() {
      let token = JSON.parse(localStorage.getItem("Authorization"));
      let verify = localStorage.getItem("verify");

      question({
        user_id: token.user_id,
        question_id: this.question_id,
        answer: this.answer,
        uid: token.user_id,
        schoolid: token.school_id,
        verify: verify,
      }).then(
        (res) => {
          if (res.status == 1) {
            this.$router.push({ name: "common" });
          }
          if (res.status != 1) {
            this.$alert("" + res.msg, "错误提示", {
              confirmButtonText: "确定",
            });
          }
        },
        function (error) {}
      );
    },
  },
  created() {},
  mounted() {
    let token = JSON.parse(localStorage.getItem("Authorization"));
    info({ user_id: token.user_id }).then((res) => {
      this.question_id = res.question_id;
      this.questions = res.question_array;
    });
  },
};
</script>

<style></style>
