<template>
  <div>
    <div>
      <div>
        <div>
          <div style="text-align: center; width: 100%">
            <el-carousel height="230px" v-if="swiper !== null">
              <el-carousel-item v-for="(item, index) in swiper" :key="index">
                <el-image
                  style="width: 100%; height: 230px; fit: cover"
                  :src="item.url"
                >
                </el-image>
              </el-carousel-item>
            </el-carousel>
            <el-carousel height="230px" v-if="swiper === null">
              <el-carousel-item v-for="(item, index) in imglist" :key="index">
                <el-image
                  style="width: 100%; height: 230px; fit: cover"
                  :src="item.url"
                >
                </el-image>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <!-- <div class="hot_course wrapper">
            <h2>
              <span><em>平台简介</em><i>Introduction to the platform</i></span>
            </h2>
            <p class="zi">{{ introduction }}</p>
          </div> -->
      </div>

      <div class="hardware_course" v-if="courses !== null">
        <div class="wrapper">
          <div
            class="hot_course wrapper"
            style="width: 99%; margin-left: 0.5%; margin-right: 0.5%"
          >
            <h2>
              <span
                ><em>{{ $t("message.Featurecourse") }}</em></span
              >
              <a
                v-if="n === 1"
                @click="spread1"
                class="more arrow_linkright"
                style="cursor: pointer"
                >{{ $t("message.morestarcourse") }}</a
              >
              <a
                v-if="n === 2"
                @click="spread2"
                class="more arrow_linkdown"
                style="cursor: pointer"
                >{{ $t("message.morestarcourse") }}</a
              >
            </h2>
          </div>

          <div v-if="courses !== null" class="clearfix" style="width: 100%">
            <div
              class="hc_item box"
              v-for="(item, index) in courses"
              :key="index"
              v-if="index < show1"
              style="
                float: left;
                margin-right: 22px;
                margin-bottom: 20px;
                height: 210px;
              "
            >
              <div class="cover_area">
                <!-- 传一下course_id -->
                <router-link v-if="visitor === 1" :to="{ name: 'login' }">
                  <img
                    style="height: 150px"
                    :src="item.cover_url"
                    alt=""
                    class="object_fit cover"
                  />
                </router-link>
                <router-link
                  v-if="visitor !== 1"
                  :to="{
                    name: 'coursedetail',
                    params: { id: item.course_id, back: 2 },
                  }"
                >
                  <img
                    style="height: 150px"
                    :src="item.cover_url"
                    alt=""
                    class="object_fit cover"
                  />
                </router-link>
              </div>
              <div class="border">
                <div class="hot_range">{{ $t("message.Featurec") }}</div>
                <span data-pjax="0" href=""
                  ><span
                    style="margin-top: 10px; margin-left: 10px"
                    class="ellipsis"
                    >{{ item.title }}</span
                  ></span
                >
                <div class="flex_between" style="margin: 10px; margin-top: 2px">
                  <em style="font-size: 13px; color: gray"
                    >{{ $t("message.teacher") }}：{{ item.teacher_name }}</em
                  >
                  <em style="font-size: 13px; color: gray"
                    >{{ $t("message.coursestudy") }}：{{
                      item.students_count
                    }}</em
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="wrapper" v-if="visitor !== 1">
          <div
            class="hot_course wrapper"
            style="width: 99%; margin-left: 0.5%; margin-right: 0.5%"
          >
            <h2>
              <span
                ><em>{{ $t("message.ExcellentWork") }}</em></span
              >
              <a
                v-if="x === 1"
                @click="spread3"
                class="more arrow_linkright"
                style="cursor: pointer"
                >{{ $t("message.morestarwork") }}</a
              >
              <a
                v-if="x === 2"
                @click="spread4"
                class="more arrow_linkdown"
                style="cursor: pointer"
                >{{ $t("message.morestarwork") }}</a
              >
            </h2>
          </div>

          <div v-if="goodworks !== null" class="clearfix" style="width: 100%">
            <div
              class="hc_item box"
              v-for="(item, index) in goodworks"
              :key="index"
              v-if="index < show2"
              style="
                float: left;
                margin-right: 22px;
                margin-bottom: 20px;
                height: 210px;
              "
            >
              <router-link
                :to="{
                  name: 'goodworkdetail',
                  params: { id: item.id, back: 2 },
                }"
              >
                <div class="cover_area">
                  <img
                    style="height: 150px"
                    :src="item.cover_path"
                    alt=""
                    class="object_fit cover"
                  />
                </div>
              </router-link>
              <div class="border">
                <div class="hot_range" style="font-size: 12px">
                  {{ $t("message.Excellentw") }}
                </div>
                <span data-pjax="0" href="">
                  <span
                    style="margin-top: 10px; margin-left: 10px; font-size: 12px"
                    class="ellipsis"
                  >
                    <span
                      style="
                        font-size: 16px;
                        color: black;
                        white-space: nowrap;
                        width: 20%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      "
                      :title="name"
                    >
                      {{ item.name }}</span
                    >

                    <span
                      class="tag"
                      style="float: right; margin-right: 20px; font-size: 13px"
                      >{{ item.type_name.slice(5, 15) }}</span
                    >
                  </span>
                </span>
                <div class="flex_between" style="margin: 10px; margin-top: 2px">
                  <em style="font-size: 13px; color: gray"
                    >{{ $t("message.author") }}：{{ item.author_name }}</em
                  >
                  <em style="font-size: 13px; color: gray"
                    >{{ item.click_num }}{{ $t("message.check") }}</em
                  >
                </div>
              </div>
            </div>
          </div>
          <div v-if="goodworks === null" style="text-align: center">
            <div>
              <img
                src="../../src/assets/img/nomessage.png"
                style="width: 50px; text-align: center; position: absolute"
              />
              <span
                style="
                  height: 100px;
                  line-height: 100px;
                  left: 60px;
                  position: relative;
                  top: -20px;
                  color: grey;
                "
                >暂无优秀作品</span
              >
            </div>
          </div>
        </div>
      </div>
      <div
        class="hardware_course"
        v-if="courses === null && visitor === 1"
        style="height: 800px"
      >
        <div class="wrapper">
          <div
            class="hot_course wrapper"
            style="width: 99%; margin-left: 0.5%; margin-right: 0.5%"
          >
            <h2>
              <span
                ><em>{{ $t("message.Featurecourse") }}</em></span
              >
              <a
                v-if="n === 1"
                @click="spread1"
                class="more arrow_linkright"
                style="cursor: pointer"
                >{{ $t("message.morestarcourse") }}</a
              >
              <a
                v-if="n === 2"
                @click="spread2"
                class="more arrow_linkdown"
                style="cursor: pointer"
                >{{ $t("message.morestarcourse") }}</a
              >
            </h2>
          </div>

          <div v-if="courses === null" style="text-align: center">
            <div>
              <img
                src="../../src/assets/img/nomessage.png"
                style="width: 50px; text-align: center; position: absolute"
              />
              <span
                style="
                  height: 100px;
                  line-height: 100px;
                  left: 60px;
                  position: relative;
                  top: -20px;
                  color: grey;
                "
                >暂无特色课程</span
              >
            </div>
          </div>
        </div>
        <div class="wrapper" v-if="visitor !== 1">
          <div
            class="hot_course wrapper"
            style="width: 99%; margin-left: 0.5%; margin-right: 0.5%"
          >
            <h2>
              <span
                ><em>{{ $t("message.ExcellentWork") }}</em></span
              >
              <a
                v-if="x === 1"
                @click="spread3"
                class="more arrow_linkright"
                style="cursor: pointer"
                >{{ $t("message.morestarwork") }}</a
              >
              <a
                v-if="x === 2"
                @click="spread4"
                class="more arrow_linkdown"
                style="cursor: pointer"
                >{{ $t("message.morestarwork") }}</a
              >
            </h2>
          </div>

          <div v-if="goodworks !== null" class="clearfix" style="width: 100%">
            <div
              class="hc_item box"
              v-for="(item, index) in goodworks"
              :key="index"
              v-if="index < show2"
              style="
                float: left;
                margin-right: 22px;
                margin-bottom: 20px;
                height: 210px;
              "
            >
              <router-link
                :to="{
                  name: 'goodworkdetail',
                  params: { id: item.id, back: 2 },
                }"
              >
                <div class="cover_area">
                  <img
                    style="height: 150px"
                    :src="item.cover_path"
                    alt=""
                    class="object_fit cover"
                  />
                </div>
              </router-link>
              <div class="border">
                <div class="hot_range" style="font-size: 12px">
                  {{ $t("message.Excellentw") }}
                </div>
                <span data-pjax="0" href="">
                  <span
                    style="margin-top: 10px; margin-left: 10px; font-size: 12px"
                    class="ellipsis"
                    >{{ $t("message.workname") }}：

                    <el-popover
                      placement="top-start"
                      width="20"
                      trigger="hover"
                      :content="item.name"
                    >
                      <!-- <div v-if="realname.length > 3" style="line-height:70px ;float: right;" slot="reference">{{ realname
                        |ellipsis }}</div> -->
                      <span
                        v-if="item.name.length > 3"
                        style="
                          white-space: nowrap;
                          width: 20%;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        "
                        slot="reference"
                      >
                        {{ item.name | ellipsis }}</span
                      >
                    </el-popover>
                    <span
                      v-if="item.name.length <= 3"
                      style="
                        white-space: nowrap;
                        width: 20%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      "
                      :title="name"
                    >
                      {{ item.name }}</span
                    >

                    <span
                      class="tag"
                      style="float: right; margin-right: 20px; font-size: 13px"
                      >{{ item.type_name.slice(5, 15) }}</span
                    >
                  </span>
                </span>
                <div class="flex_between" style="margin: 10px">
                  <em style="font-size: 13px; color: gray"
                    >{{ $t("message.author") }}：{{ item.author_name }}</em
                  >
                  <em style="font-size: 13px; color: gray"
                    >{{ item.click_num }}{{ $t("message.check") }}</em
                  >
                </div>
              </div>
            </div>
          </div>
          <div v-if="goodworks === null" style="text-align: center">
            <div>
              <img
                src="../../src/assets/img/nomessage.png"
                style="width: 50px; text-align: center; position: absolute"
              />
              <span
                style="
                  height: 100px;
                  line-height: 100px;
                  left: 60px;
                  position: relative;
                  top: -20px;
                  color: grey;
                "
                >暂无优秀作品</span
              >
            </div>
          </div>
        </div>
      </div>
      <div
        class="hardware_course"
        v-if="courses === null && visitor !== 1"
        style="height: 800px"
      >
        <div class="wrapper">
          <div
            class="hot_course wrapper"
            style="width: 99%; margin-left: 0.5%; margin-right: 0.5%"
          >
            <h2>
              <span
                ><em>{{ $t("message.Featurecourse") }}</em></span
              >
              <a
                v-if="n === 1"
                @click="spread1"
                class="more arrow_linkright"
                style="cursor: pointer"
                >{{ $t("message.morestarcourse") }}</a
              >
              <a
                v-if="n === 2"
                @click="spread2"
                class="more arrow_linkdown"
                style="cursor: pointer"
                >{{ $t("message.morestarcourse") }}</a
              >
            </h2>
          </div>

          <div v-if="courses === null" style="text-align: center">
            <div>
              <img
                src="../../src/assets/img/nomessage.png"
                style="width: 50px; text-align: center; position: absolute"
              />
              <span
                style="
                  height: 100px;
                  line-height: 100px;
                  left: 60px;
                  position: relative;
                  top: -20px;
                  color: grey;
                "
                >暂无特色课程</span
              >
            </div>
          </div>
        </div>
        <div class="wrapper" v-if="visitor !== 1">
          <div
            class="hot_course wrapper"
            style="width: 99%; margin-left: 0.5%; margin-right: 0.5%"
          >
            <h2>
              <span
                ><em>{{ $t("message.ExcellentWork") }}</em></span
              >
              <a
                v-if="x === 1"
                @click="spread3"
                class="more arrow_linkright"
                style="cursor: pointer"
                >{{ $t("message.morestarwork") }}</a
              >
              <a
                v-if="x === 2"
                @click="spread4"
                class="more arrow_linkdown"
                style="cursor: pointer"
                >{{ $t("message.morestarwork") }}</a
              >
            </h2>
          </div>

          <div v-if="goodworks !== null" class="clearfix" style="width: 100%">
            <div
              class="hc_item box"
              v-for="(item, index) in goodworks"
              :key="index"
              v-if="index < show2"
              style="
                float: left;
                margin-right: 22px;
                margin-bottom: 20px;
                height: 210px;
              "
            >
              <router-link
                :to="{
                  name: 'goodworkdetail',
                  params: { id: item.id, back: 2 },
                }"
              >
                <div class="cover_area">
                  <img
                    style="height: 150px"
                    :src="item.cover_path"
                    alt=""
                    class="object_fit cover"
                  />
                </div>
              </router-link>
              <div class="border">
                <div class="hot_range" style="font-size: 12px">
                  {{ $t("message.Excellentw") }}
                </div>
                <span data-pjax="0" href="">
                  <span
                    style="margin-top: 10px; margin-left: 10px; font-size: 12px"
                    class="ellipsis"
                    >{{ $t("message.workname") }}：

                    <el-popover
                      placement="top-start"
                      width="20"
                      trigger="hover"
                      :content="item.name"
                    >
                      <!-- <div v-if="realname.length > 3" style="line-height:70px ;float: right;" slot="reference">{{ realname
                        |ellipsis }}</div> -->
                      <span
                        v-if="item.name.length > 3"
                        style="
                          white-space: nowrap;
                          width: 20%;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        "
                        slot="reference"
                      >
                        {{ item.name | ellipsis }}</span
                      >
                    </el-popover>
                    <span
                      v-if="item.name.length <= 3"
                      style="
                        white-space: nowrap;
                        width: 20%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      "
                      :title="name"
                    >
                      {{ item.name }}</span
                    >

                    <span
                      class="tag"
                      style="float: right; margin-right: 20px; font-size: 13px"
                      >{{ item.type_name.slice(5, 15) }}</span
                    >
                  </span>
                </span>
                <div class="flex_between" style="margin: 10px">
                  <em style="font-size: 13px; color: gray"
                    >{{ $t("message.author") }}：{{ item.author_name }}</em
                  >
                  <em style="font-size: 13px; color: gray"
                    >{{ item.click_num }}{{ $t("message.check") }}</em
                  >
                </div>
              </div>
            </div>
          </div>
          <div v-if="goodworks === null" style="text-align: center">
            <div>
              <img
                src="../../src/assets/img/nomessage.png"
                style="width: 50px; text-align: center; position: absolute"
              />
              <span
                style="
                  height: 100px;
                  line-height: 100px;
                  left: 60px;
                  position: relative;
                  top: -20px;
                  color: grey;
                "
                >暂无优秀作品</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <br /><br /><br />
    <div>
      <CommonFooter
        style="position: fixed; bottom: 0px; min-width: 100%"
      ></CommonFooter>
    </div>
  </div>
</template>

<script>
import { indexpage, adminSysIndexPage } from "@/http/api.js"; // 导入接口
import { excelentpage } from "@/http/api.js"; // 导入接口
import CommonFooter from "@/components/CommonFooter.vue";

export default {
  created() {
    //已登录状态再进入登陆页面，直接跳转到管理首页。不允许登陆状态再次登陆

    localStorage.setItem("visitor", JSON.stringify(1));

    let login = JSON.parse(localStorage.getItem("Authorization"));
    if (login === null) {
      let visitor = JSON.parse(localStorage.getItem("visitor"));
      this.visitor = visitor;
    } else if (login.type > 2) {
      this.$router.push({
        name: "MainView",
      });
    }
  },
  data() {
    return {
      imglist: [{ url: require("../../src/assets/img/banner_m.png") }],
      // logo,
      // webname,
      status: 10,
      type: 10,
      realname: "",
      visible: false,

      introduction: "",
      courses: [],
      goodworks: [],
      n: 1,
      x: 1,
      show1: 4,
      show2: 4,
      swiper: [],
      language: "",
      visitor: 2,
      name: "",
    };
  },
  components: {
    CommonFooter,
  },
  filters: {
    ellipsis(value) {
      if (!value) return "";
      if (value.length > 3) {
        return value.slice(0, 3) + "...";
      }
      return value;
    },
  },

  methods: {
    spread1() {
      this.n = 2;
      this.show1 = 99;
    },
    spread2() {
      this.n = 1;
      this.show1 = 4;
    },
    spread3() {
      this.x = 2;

      this.show2 = 99;
    },
    spread4() {
      this.x = 1;

      this.show2 = 4;
    },
  },
  mounted() {
    let token = JSON.parse(localStorage.getItem("Authorization"));
    let verify = localStorage.getItem("verify");

    if (this.visitor === 1) {
    } else {
      excelentpage({
        school_id: token.school_id,
        uid: token.user_id,
        schoolid: token.school_id,
        verify: verify,
      }).then((res) => {
        console.log(res);
        this.goodworks = res.works;
      });
    }

    indexpage({}).then((res) => {
      console.log("page", res);

      this.webname = res.webname;

      this.realname = res.realname;

      this.introduction = res.introduction;
      this.courses = res.courses;
    });
    adminSysIndexPage({}).then((res) => {
      console.log("swiper", res);
      this.swiper = res.swiper;
    });
    this.language = localStorage.getItem("lang");
  },
};
</script>

<style>
.zi {
  font-family: 微软雅黑;
  line-height: 40px;
  font-size: 20px;
  text-indent: 40px;
  text-align: left;
  margin-top: 50px;
  margin-bottom: 50px;
}

.img {
  background-size: 100%;
  width: 100%;
  height: 100%;
  position: fixed;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.border {
  position: relative;
  padding: 15px 20px;
  border-top: solid #00979c 4px;
}
</style>

<style lang="css" src="../../src/assets/.css/common.css" scoped></style>
<style lang="css" src="../../src/assets/.css/index.css" scoped></style>
<style lang="css" src="../../src/assets/.css/page.css" scoped></style>
