<template>
    <div style="min-width:1000px">

        <!--header begin-->
        <div class="header clearfix flex_start">
            <img class="logo" style="width:320px" :src="logo" alt="" id="logo">

            <div class="header_container flex_between">

                <ul class="nav" style="margin-left:10px">
                    <li :style="language === 'en' ? 'width:100px' : 'width:100px'">

                        <router-link active-class="current" to="/common">
                            <img style="width:25px" src="../assets/img/home.png" alt="">
                            <!-- <img src="../assets/img/nav_1_s.png" alt="" v-if="!userShow"> -->
                            <span>{{ $t('message.home') }}</span>
                        </router-link>

                    </li>
                    <li :style="language === 'en' ? 'width:140px' : 'width:140px'">
                        <router-link to="/login">
                            <img style="width:30px" src="../assets/img/course.png" alt="">
                            <span>{{ $t('message.course') }}</span>
                        </router-link>
                        <!-- <a href="" style="">
                    <img src="../assets/img/nav_2.png" alt="">
                    <span>课程中心</span>
                </a> -->
                    </li>
                    <li :style="language === 'en' ? 'width:100px' : 'width:100px'">
                        <router-link to="/login">
                            <img style="width:23px" src="../assets/img/assignment.png" alt="">
                            <span>{{ $t('message.task') }}</span>
                        </router-link>
                    </li>
                    <!-- <li :style="language === 'en' ? 'width:100px' : 'width:100px'">
                        <router-link to="/login">
                            <img style="width:27px" src="../assets/img/work.png" alt="">
                            <span>{{ $t('message.work') }}</span>
                        </router-link>
                    </li> -->
                    <!-- <li :style="language === 'en' ? 'width:160px' : 'width:140px'">
                        <router-link to="/login">
                            <img style="width:27px" src="../assets/img/competition.png" alt="">
                            <span>{{ $t('message.competition') }}</span>
                        </router-link>
                    </li> -->
                    <!-- <li>
                        <div :style="language === 'en' ? 'width:100px;margin-left:10px' : 'margin-left:10px'"
                            style="margin-top:17px;margin-left:50px;width:120px">

                            <el-select v-model="selectValue" @change="langChange" placeholder="语言">
                                <el-option style="padding-left:20px" v-for="item in options" :key="item.value"
                                    :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                    </li> -->
                </ul>


                <div class="header_right login_field flex">
                    <div style=" margin-right:20px; width: 120px">
                        <el-select
                        v-model="selectValue"
                        @change="langChange"
                        placeholder="语言"
                        >
                        <el-option
                            style="padding-left: 20px"
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                        </el-select>
                    </div>
                    <router-link to="/register" class="register_link " style="width:90px;">{{ $t('message.register') }}</router-link>
                    <router-link to="/login" style="width:80px;margin-left:-5px" class="btn">{{ $t('message.login') }}</router-link>
                </div>


                <!-- <div class="header_right login_field flex">
                <a href="" class="user flex_end">
                    <img class="userimg" src="" alt="">
                    <span></span>
                </a>
               
            </div> -->

            </div>
            <a class="mobile_only menu">

            </a>
        </div>
        <!--header end-->


    </div>
</template>

<script>
import { indexpage,getparamfile } from '@/http/api.js' // 导入接口
export default {
    name: 'CommonLayout',
    data() {
        return {
            logo: '',
            selectValue: '',
            options: [
                {
                    value: 'zhcn',
                    label: '中文'
                }, {
                    value: 'en',
                    label: 'English'
                }
            ],
            language: 'zhcn',
            visitor: null
        }
    },
    created() {
        let that = this;
        console.log(localStorage.lang)
        let visitor = JSON.parse(localStorage.getItem("visitor"));
        this.visitor = visitor
        that.selectValue = localStorage.lang == undefined ? '中文' : localStorage.lang
    },
    methods: {
        langChange(item) {
            console.log(item)
            localStorage.setItem('lang', item);
            this.$i18n.locale = item;
            this.language = item

        },

    },
    mounted() {
        indexpage()
            .then(res => {
                // this.logo = res.logo
                this.webname = res.webname
                this.status = res.status
                this.realname = res.realname
                this.type = res.type
                this.introduction = res.introduction
                this.course = res.course

            })
        this.language = localStorage.getItem("lang");

        getparamfile({
            name: "front_logo",

        }).then((res) => {
            console.log('page', res)
            this.logo = res.value



        });
    }
}
</script>

<style scoped lang="css">
.el-dropdown-hover {

    color: #00979c;
}

/* @import url('../../src/assets/css/common.css');
@import url('../../src/assets/css/swiper-bundle.min.css');
@import url('../../src/assets/css/index.css');
@import url('../../src/assets/css/page.css'); */
</style>
<style lang="css"  src='../../src/assets/.css/common.css'  scoped></style>
<style lang="css"  src='../../src/assets/.css/index.css'  scoped></style>
<style lang="css"  src='../../src/assets/.css/page.css'  scoped></style>