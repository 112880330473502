import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
const zhcn = {
    message: {
        'login': '登录',
        'register': '立即注册',
        'text': '欢迎来到vue应用',
        'Featurecourse': '特色课程',
        'Featurec': '特色课',
        'ExcellentWork': '优秀作品',
        'Excellentw': '优秀作品',

        'pleaseChoose': '请选择',
        'returnFront': '返回主页',
        'logOut': '退出',

        'home': '首页',
        'course': '课程',
        'task': '任务',
        'work': '作品',
        'competition': '活动',
        'tool': '工具',
        'morestarcourse': '更多特色课程',
        'morestarwork': '更多优秀作品',
        'tel': '电话',
        'email': '邮箱',
        'add': '地址',
        //course
        'programtask': '编程任务',
        'coursewarepdf': '课件-PDF',
        'coursewarevideo': '课件-视频',
        'attachment': '附件',
        'generaldocumentation': '一般文件',
        'richtext': '富文本',
        'test': '测试',
        'questionnaire': '问卷',
        'electedcourse': '已选课',
        'attachmentDetail': '附件详情',
        'attachmentdownload': '附件下载',
        'wcstate': '完成情况',
        'all': '全部',
        'selected': '已选课程',
        'unselected': '未选课程',
        'classification': '分类',
        'coursename': '课程名称',
        'teacher': '任课教师',
        'coursestudy': '学习人数',
        'entercourse': '进入学习',
        'choose': '选课',
        'underreview': '审核中',
        'return': '返回',
        'coursedetail': '课程详情',
        'courseoutline': '课程大纲',
        'homeworkdetail': '作业详情',
        'homeworkdescription': '作业描述',
        'releasetime': '发布时间',
        'endtime': '截止时间',
        'completionform': '完成形式',
        'score': '作业得分',
        'courseware': '课件',
        'video': '视频播放',
        'questionnairedetail': '问卷详情',
        'previousquestion': ' 上一题',
        'nextquestion': '下一题',
        'correct': '正确',
        'wrong': '错误',
        'answer': '正确答案',
        'explanation': '解析',
        'grade': '总分',
        'time': '用时',
        'timeremaining': '剩余时间',
        'question': '题目数量',
        'testdetail': '测试详情',
        'enter': '进入',
        'homeworkname': '作业名',
        'public': '公开课',
        'access': '暂未选课，无法进入学习',
        'enlarge': '点击图标放大',
        'nosubmit': '不在提交时间内，无法提交',
        'upload': '上传文件',
        'submitfile': '请先点击上方按钮上传文件',
        'under100': '上传文件大小不超过100Mb',
        'clicksubmit': '请先点击上方按钮上传文件,新建成功后方可提交',
        'download': '点击图标下载查看',
        'teacherc': '教师评价',
        'time11': '时间',
        'noehomework': '暂无优秀作业',
        'tishu': '题数',
        //task
        'compolish': '完成情况',
        'tasktype': '任务类别',
        'homeworktask': '作业',
        'testtask': '测试',
        'questiontask': '问卷',

        'select': '筛选',
        'confirm': '查询',
        'reset': '重置',
        'addtime': '发布时间',
        'endtime': '截止时间',

        'type': '类型',
        'grade': '分数',
        'operation': '操作',
        'taskname': '任务名称',
        'taskcourse': '所属课程',
        'submit': '提交',
        'chakan': '查看',
        'morestartask': '更多优秀作业',
        'Excellenttask': '优秀作业',

        //work
        'editwork': '编辑作品',
        'step1': '步骤 1',
        'step2': '步骤 2',
        'step3': '步骤 3',
        'workcontent': '作品内容',
        'pubwork': '发布作品',
        'excellentwork': '优秀作品',
        'mywork': '我的作品',
        'worktype': '作品类型',
        'newwork': '新建作品',
        'workname': '作品名称',
        'summary': '简介',
        'author': '作者',
        'check': '人查看',
        'privatework': '私有作品',
        'excellent': '优秀作品',
        'workdetail': '作品详情',
        'updatedon': '更新于',
        'edit': '编辑',
        'apply': '申请优秀作品',
        'applying': '申请中',
        'delete': '删除',
        'workcontent': '作品内容',
        'goodworkdisplay': '优秀作品展示',
        'workcover': '作品封面',
        'updatecode': '上传代码',
        'name': '作品名称',
        'next': '下一步',
        'last': '上一步',
        'externalrelease': '对外发布',
        'accomplish': '完成',
        //competition
        'registrationdeadline': '报名截止时间',
        'competitiontime': '时间',
        'address': '地点',
        'registrationlimit': '报名上限',
        'enroll': '报名',
        'loseefficacy': '已失效',
        'share': '分享',
        'end': '报名截止',
        'competitionsummary': '活动简介',
        'competitionnotice': '活动公告',
        'competitionwork': '活动作品',
        'competitiondetail': '活动详情',
        'nocompetition': '暂无活动',
        'enrolled': '已报名',
        'ncw': '新建活动作品',
        'mcw': '我的活动作品',
        'ecw': '编辑活动作品',
        'rcw': '活动作品排名',
        'cwn': '活动作品名称',
        'cwt': '活动作品类型',
        'djl': '点击量',
        'oas': '总评审成绩',
        'mcwd': '我的活动作品详情',
        //user
        'last7days': '近7天',
        'last30days': '近30天',
        'lasthalfyear': '近半年',
        'lastyear': '近一年',
        'coursenum': '选课数量',
        'homenum': '累计作业',
        'testnum': '累计测试',
        'worknum': '累计作品',
        'state': '状态',
        'deve': '发展',
        'perform': '表现',
        'Developmentassessment': '发展评估',
        'information': '基本信息',
        'changePassword': '修改密码',
        'avatar': '头像',
        'realname': '姓名',
        'account': '账号',
        'birthday': '生日',
        'gender': '性别',
        'school': '学校',
        'class': '班级',
        'prequestion': '预设问题',
        'answer': '问题答案',
        'confirmedit': '确认修改',
        'oldpassword': '原始密码：',
        'password': '密码：',
        'confirmpassword': '确认密码：',
        'comtask': '完成作业',
        'sumtask': '总作业',
        'comtest': '完成测试',
        'sumtest': '总测试',
        'taskaverscore': '作业平均分',
        'testaverscore': '测试平均分',
        'pi': '个人信息',
        'bm': '后台管理',
        'quit': '退出'
    },
    admin: {
        'mainPage': '首页',
        'dataView': '数据概览',
        'importantItem': '重要指标',
        'courseNumber': '课程数量',
        'teacherNumber': '教师数量',
        'studentNumber': '学生数量',
        'workNumber': '作品数量',
        'testNumber': '测试数量',
        'quesNumber': '问卷数量',
        'homeworkNumber': '作业数量',
        'weekNew': '本周新增',
        'monthNew': '本月新增',
        'hyearNew': '近半年新增',
        'yearNew': '本年新增',
        'courseManagement': '课程信息',
        'classManagement': '班级信息',
        'teacherManagement': '教师信息',
        'studentManagement': '学生信息',
        'homeworkManagement': '作业信息',
        'workManagement': '作品信息',
        'testManagement': '测试信息',
        'quesManagement': '问卷信息',
        'workScoreRank': '作品积分排名',
        'more': '更多',
        'courseList': '课程列表',
        'createCourse': '创建课程',
        'school': '学校',
        'category': '分类',
        'keyword': '关键字',
        'search': '搜索',
        'reset': '重置',
        'noResult': '暂无信息',
        'noResultText': '并没找到您所搜索的信息，请重试或联系管理员~',
        'back': '返回',
        'pleaseInput': '请输入',
        'pleaseInputCourseName': '请输入课程名称',
        'pleaseChoose': '请选择',
        'chooseDate': '请选择时间',
        'yes': '是',
        'no': '否',
        'cancel': '取消',
        'confirm': '确认',
        'chooseFile': '选择文件',
        'clickDownload': '点击下载',
        'todo': '待办事项',
        'courseApply': '课程申请审核',
        'homeworkCorrect': '学生作业评分',
        'testCorrect': '课程测试管理',
        'competitionWorkCorrect': '活动作品评分',
        'personalInfo': '我的信息',
        'submitModify': '保存修改',
        'clickModify': '点击修改',
        'oldPassword': '原密码',
        'newPassword': '新密码',
        'confirmPassword': '重复新密码',
    },
    course: {
        'daifabu': '待发布',
        'yigongkai': '已公开',
        'yifabu': '已发布',
        'daishenhe': '待审核',
        'basicInfo': '基本信息',
        'courseOutline': '课程大纲',
        'courseCover': '课程封面',
        'school': '所属学校',
        'courseName': '课程名称',
        'type': '课程类型',
        'summary': '课程简介',
        'beginTime': '开始时间',
        'endTime': '结束时间',
        'code': '选课码',
        'recode': '重新生成',
        'scope': '范围',
        'insideSchool': '学校内部',
        'regionalCourse': '区域课程',
        'next': '下一步',
        'addFirst': '添加第一级',
        'finish': '完成',
        'homeworkList': '作业列表',
        'keyword': '关键字',
        'pleaseInputCHName': '请输入课程名/作业名',
        'pleaseInputCTName': '请输入课程名/测试名',
        'pleaseInputCQName': '请输入课程名/问卷名',
        'operation': '操作',
        'passAudit': '通过',
        'notPassAudit': '不通过',
        'homeworkDetail': '作业详情',
        'secondAudit': '二次审核',
        'ifPublish': '是否发布',
        'noCourse': '暂无课程~',
        // lessonCard
        'teacher': '开课教师',
        'students': '选课学生',
        'manage': '管理',
        'publish': '发布',
        'unpublish': '撤销发布',
        'audit': '审核',
        'waitingForSchool': '等待学校管理员审核',
        'waitingForArea': '等待区域管理员审核',
        'WithdrawalOfPublicity': '撤销公开',
        "setAsPublic": "设为公开课",
        "applyForPublic": "申请公开",
        'beginLessonTime': '开课时间',
        // lessonDetail
        'lessonDetail': '课程详情',
        'startTime': '开课时间',
        'to': '至',
        'noCodeLesson': '当前课程无选课码',
        'modify': '修改',
        'Unpublish': '撤销发布',
        'applyPublic': '申请公开',
        'cancelApply': '撤销申请',
        'delete': '删除',
        'outline': '课程大纲',
        'all': '全部',
        'notAudit': '未审核',
        'passed': '已通过',
        'failed': '未通过',
        'name': '姓名',
        'account': '学号',
        'email': '邮箱',
        'sex': '性别',
        'boy': '男',
        'girl': '女',
        'batchImport': '批量导入',
        'checkImportTemplate': '查看导入模板',
        'batchDelete': '批量移除',
        'batchAudit': '批量审核通过',
        'fillModule': '填充信息',
        'modifyModule': '修改信息',
        // modifyLesson
        'modifyCourse': '修改课程',
        'courseName': '课程名称',
        'courseCate': '课程分类',
        'courseSummary': '课程简介',
        'courseBeginTime': '开课时间',
        'courseEndTime': '结课时间',
        'courseCode': '选课码',
        'noCode': '无需选课码',
        'createCode': '生成选课码',
        'clickImgChangeCover': '点击图片更换封面',
        'outline': '课程大纲',
        'removeStudent': '移除学生',
        'detail': '详情',
        // outline
        'newHomework': '新建作业',
        'newTest': '新建测试',
        'newQn': '新建问卷',
        'newCourseware': '新建课件',
        'newSource': '新建资源',
        'newCodeExercise': '新建编程练习',
        'homeworkType': '作业类型',
        'coursewareType': '课件类型',
        'homeworkName': '作业名称',
        'testName': '测试名称',
        'qnName': '问卷名称',
        'coursewareName': '课件名称',
        'sourceName': '资源名称',
        'exerciseName': '练习名称',
        'sourceType': '类型',
        'link': '链接',

        'childChapter': '添加子章节',
        'createModule': '新建活动',
        'removeChapter': '删除节',
        'nextChapter': '添加下一章节',
        'chapterName': '章节名称',
        'homework': '作业',
        'test': '测试',
        'qn': '问卷',
        'courseware': '课件',
        'attachment': '附件',
        'groupDiscussion': '分组讨论',
        'codeExercise': '编程练习',
        'confirmRemoveChapter': '确认删除此节及其涵盖内容?',

        'saveOutline': '保存课程大纲',
        // homework
        'addHomework': '新建作业',
        'homeworkName': '作业名称',
        'course': '课程',
        'chapter': '所属章节',
        'homeworkSummary': '作业描述',
        'image': '描述图片',
        'homeworkCate': '作业类型',
        'inputExample': '输入样例',
        'outputExample': '输出样例',
        'addExample': '添加样例',
        'inputExampleDescribe': '输入说明',
        'outputExampleDescribe': '输出说明',
        'hint': '提示',
        'judgeExample': '判题样例',
        'uploadZipFile': '提交zip文件',
        'chooseFile': '选择文件',
        'clickDownload': '点击下载',
        'templateFile': '模板文件',
        'homeworkScore': '作业满分',
        'is_public': '是否可见',
        'timeLimit': '时间限制(ms)',
        'internalStorageLimit': '内存限制(kb)',
        'publishHomework': '发布作业',
        'point': '分',
        'language': '编程语言',

        'modifyHomework': '修改作业',
        // test
        'testList': '测试列表',
        'testDetail': '测试详情',
        'createTest': '添加测试',
        'testName': '测试名称',
        'testScore': '测试满分',
        'testDuration': '测试限时',
        'testSummary': '测试简介',
        'saveBasicInfo': '保存基本信息',
        'itemSetting': '题目设置',
        'addItem': '新增题目',
        'modifyItem': '修改题目',
        'edit': '编辑',
        'itemName': '题目名称',
        'itemSummary': '题目描述',
        'itemType': '题目类型',
        'option': '选项',
        'correctAnswer': '设为正确答案',
        'answerExplanation': '答案解析',
        'order': '排序',
        'itemScore': '该题分值',

        'modifyTest': '修改测试',
        'testName': '测试名称',
        // ques
        'quesList': '问卷列表',
        'quesDetail': '问卷详情',
        'addQues': '添加问卷',
        'quesName': '问卷名称',
        'quesSummary': '问卷简介',
        //exercise
        'createCodeExercise': '添加编程练习',
        'exerciseName': '练习名称',
        'knowledge': '知识点',
        'exerciseSummary': '练习简介',
        'is_adaptive': '自适应推荐',
        "tree": "知识树",
        'modifyCodeExercise': '修改编程练习',
        'viewCode': '查看代码',
    },
    user: {
        // user
        'userList': '用户列表',
        'school': '学校',
        'class': '班级',
        'identity': '身份',
        'pInputNameorAccount': '请输入姓名或账号',
        'batchImportUserInfo': '批量导入',
        'batchModifyUserInfo': '批量修改',
        'evalutionReportOnDevelopment': '发展评估报告',
        'behaviorData': '行为数据',
        'addUser': '添加用户',
        'role': '身份',
        'name': '姓名',
        'account': '账号',
        'selectSchoolFirst': '请先选择学校',
        'currentCate': 'Current Cates',
        // behavior
        'behaviorManagement': '行为数据',
        // school
        'schoolList': '学校列表',
        'addSchool': '添加学校',
        'modifySchool': '修改学校',
        'schoolName': '学校名称',
        'courseCate': '课程类别',
        'currentCate': '已有类别',
        // class
        'classList': '班级列表',
        'addClass': '添加班级',
        'modifyClass': '修改班级',
        'className': '班级名称',
        'grade': '年级',
        'classId': '班号（请输入数字，例如1）',
        'classSchool': '所属学校',
    },
    knowledge: {
        'knowledgeTreeList': '知识树列表',
        'pleaseInputTreeName': '知识树名',
        'knowledgeTree': '知识树',
        'knowledge': '知识点',
        'editTree': '修改知识树',
        'addFirst': '添加第一级',
        'childKnowledge': '添加子节点',
        'removeKnowledge': '删除节点',
        'nextKnowledge': '添加下一节点',
        'knowledgeName': '知识点名',
        'confirmRemoveKnowledge': '确认删除此知识点?',
        'saveTree': '保存知识树',
        'confirm': '确认',
        'cancel': '取消',
        'viewOrEditTree': '查看/修改知识树',
    },
    codeExercise: {
        'codeExerciseList': '编程练习列表',
        'pleaseInputExerciseName': '编程练习名',
        'summary': '练习描述',
        'beginExercise': '开始练习',
        'exerciseDetail': '练习详情',
        'exercise': '练习',
        'recommendMethod': '推荐方式',
        'analysis': '练习分析',
        'submitCount': '提交次数',
        'submitProblems': '提交题数',
        'correctCount': '正确次数',
        'correctProblems': '正确题数',
        'correctRate': '正确率',
        'studentAnalysis': '学生分析',
        'submitDetail': '提交详情',
        'studentExerciseDetail': '学生练习详情',
        'totalProblems': '总题目数',
        'records': '提交记录',
        'studentCodeDetail': '学生代码详情',
        'returnExercise': '返回练习',
    },
    ojProblem: {
        'ojProblemList': '编程题目列表',
        'pleaseInputProblemName': '编程题目名',
        'addOjProblem': '添加编程题目',
        'editOjProblem': '修改编程题目',
        'publishProblem': '发布题目',
        'difficulty': '题目难度',
        'uploadCode': '上传示例代码',
        'title': "题目标题",
        'description': "题目描述",
        'difficulty': "难度",
        'knowledge': "知识点",
        'knowledgeDetect': "知识点检测",
        'batchImport': "批量导入",
        'input': '输入说明',
        'output': '输出说明',
        'timeLimit': '时间限制',
        'memoryLimit': '内存限制',
    },
    work: {
        'workList': '作品列表',
        'pleaseInputWorkName': '请输入作品名',
        'detail': '作品详情',
        'goodWork': '设为优秀作品',
        'keyword': '关键字',
        'type': '类型',
        'detail': '作品详情',
        'setAsexcellent': '设为优秀作品',
        'pleaseInputAuthororName': '作者名/作品名',
        'competitionEnrollInfo': '报名信息',
    },
    competition: {
        'competitionWorkDetail': "作品详情",
    },
    ...zhLocale
}

export default zhcn;