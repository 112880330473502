import 'element-ui/lib/theme-chalk/index.css';
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import mavonEditor from "mavon-editor";
import "mavon-editor/dist/css/index.css";
import ElementUI from 'element-ui';

import '@/assets/.css/admin.css'

require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')

import router from '../src/router'
import VueRouter from 'vue-router'
import global from './components/Global'
import VideoPlayer from 'vue-video-player'
import App from './App.vue'
import * as echarts from 'echarts'
// import VueAxios from 'vue-axios';
import axios from 'axios'
import qs from 'qs'
import VueQuillEditor from 'vue-quill-editor'
import Vue from 'vue'
import i18n from './i18/i18n'
import '../src/assets/styles/icon/iconfont.css'
import '../public/global.css'
import './plugins/element.js'



// import 'lib-flexible/flexible'


// 设置登录过期时间（七天）
let EXPIRESTIME = 604800000


// 校验登录
router.beforeEach(function(to, from, next) {
  to.meta.title && (document.title = to.meta.title);
  next();

    //页面是否登录，本地存储中是否有token(uid)数据，否：跳转登录页面
    let item = localStorage.getItem("timestamp");
    if (item) {
      let date = new Date().getTime();
      // 如果大于就是过期了，如果小于或等于就还没过期
      if (date - item > EXPIRESTIME) {
          localStorage.clear();
          next({
            name: "MainTwo"
          });
      } else {
        next();
      }
    } else {
      next();
    }
 
});




Vue.use(VideoPlayer);
Vue.use(mavonEditor);

Vue.prototype.$global = global

Vue.prototype.$echarts = echarts
require('./mock');

Vue.use(VueQuillEditor);

Vue.config.productionTip = false
Vue.use(ElementUI);

Vue.prototype.$axios = axios;
Vue.use(VueRouter)

Vue.prototype.$qs = qs;


new Vue({
  i18n,
  axios,
  router:router,

  render: h => h(App),
}).$mount('#app')




