import { render, staticRenderFns } from "./register.vue?vue&type=template&id=22bde945&scoped=true&"
import script from "./register.vue?vue&type=script&lang=js&"
export * from "./register.vue?vue&type=script&lang=js&"
import style0 from "./register.vue?vue&type=style&index=0&id=22bde945&prod&scoped=true&lang=css&"
import style1 from "../../src/assets/.css/common.css?vue&type=style&index=1&id=22bde945&prod&lang=css&scoped=true&"
import style2 from "../../src/assets/.css/login.css?vue&type=style&index=2&id=22bde945&prod&lang=css&scoped=true&"
import style3 from "../../src/assets/.css/register.css?vue&type=style&index=3&id=22bde945&prod&lang=css&scoped=true&"
import style4 from "./register.vue?vue&type=style&index=4&id=22bde945&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22bde945",
  null
  
)

export default component.exports