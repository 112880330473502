<template>
  <div>
    <div>
      <div
        class="bread"
        style="padding: 20px 0; text-align: left; margin-left: 5%"
      >
        <router-link
          :to="{ name: 'coursedetail', params: { id: kcid } }"
          style="font-size: 20px"
          >{{ $t("message.return") }}</router-link
        >
        <span style="margin-left: ; font-size: 20px">{{
          $t("message.courseware")
        }}</span>
      </div>

      <div class="wrapper flex video_container">
        <!-- <div class="video_list">
          <h3>{{ $t('message.courseoutline') }}</h3>
          <el-tree
            :data="result"
            :props="defaultProps"
            @node-click="handleNodeClick"
            style="margin-left: 50px"
          ></el-tree>
        </div> -->
        <div style="width: 100%; height: 600px; background-color: white">
          <iframe
            :src="Url"
            style="width: 100%; height: 100%; background-color: white"
            frameborder="0"
          ></iframe>
        </div>
      </div>

      <!-- <div class="wrapper course_resource_container">
        <h2>
          <span>{{ name }}-{{ cname }}</span>
        </h2>
      </div> -->
    </div>

    <EmptyFooter />
  </div>
</template>

<script>
import arrayToTree from "array-to-tree";
// import pdf from 'vue-pdf'

import { index } from "@/http/api.js"; // 导入接口
import { videopage } from "@/http/api.js"; // 导入接口

import CommonLayout from "@/components/CommonLayout.vue";

import EmptyFooter from "@/components/EmptyFooter.vue";
import CommonStudent from "@/components/CommonStudent.vue";
import CommonTeacher from "@/components/CommonTeacher.vue";
export default {
  name: "coursepdf",
  data() {
    return {
      status: "",
      type2: "",
      id: "",
      kcid: "",

      Url: "",

      reid: "",
      module_id: "",
      name: "",
      cname: "",

      outline: [],
      file_url: "",

      result: [],
      defaultProps: {
        children: "children",
        label: "title",
      },
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    this.kcid = this.$route.params.kcid;

    let token = JSON.parse(localStorage.getItem("Authorization"));
    let verify = localStorage.getItem("verify");
    videopage({
      module_id: this.$route.params.id,
      uid: token.user_id,
      schoolid: token.school_id,
      verify: verify,
    }).then((res) => {
      console.log(res);
      this.reid = res.id; //课件id
      this.module_id = res.module_id;
      this.name = res.name;
      this.cname = res.cname;
      this.Url = res.file_url;

      this.outline = res.outline;

      this.result = arrayToTree(this.outline, {
        parentProperty: "upper_module",
        customID: "id",
        childrenProperty: "children",
      });
      // console.log(this.result);
    });
  },
  methods: {
    handleNodeClick(data) {
      // console.log(data);
      if (data.type == "pdf") {
        this.$router.push({ name: "coursepdf" });
      }
      if (data.type == "video") {
        this.$router.push({ name: "coursevideo" });
      }
      if (data.type == "program_task") {
        this.$router.push({ name: "coursehomework" });
      }
    },
  },
  components: {
    EmptyFooter,
    // pdf,
  },
};
</script>

<style></style>

<style lang="css" src="../../src/assets/.css/common.css" scoped></style>
<style
  lang="css"
  src="../../src/assets/.css/swiper-bundle.min.css"
  scoped
></style>
<style lang="css" src="../../src/assets/.css/index.css" scoped></style>
<style lang="css" src="../../src/assets/.css/page.css" scoped></style>
<style lang="css" scoped>
* {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>