<template>
    <div class="footer" style="background: #fff">
   
    </div>
</template>

<script>
export default {
    name: 'EmptyFooter'
}
</script>
<style lang="css" scoped>



</style>

