import { render, staticRenderFns } from "./coursetest.vue?vue&type=template&id=2b56d1a4&scoped=true&"
import script from "./coursetest.vue?vue&type=script&lang=js&"
export * from "./coursetest.vue?vue&type=script&lang=js&"
import style0 from "../../src/assets/.css/common.css?vue&type=style&index=0&id=2b56d1a4&prod&lang=css&scoped=true&"
import style1 from "../../src/assets/.css/index.css?vue&type=style&index=1&id=2b56d1a4&prod&lang=css&scoped=true&"
import style2 from "../../src/assets/.css/page.css?vue&type=style&index=2&id=2b56d1a4&prod&lang=css&scoped=true&"
import style3 from "./coursetest.vue?vue&type=style&index=3&id=2b56d1a4&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b56d1a4",
  null
  
)

export default component.exports