<template>
  <div>
    <div
      class="bread"
      style="padding: 20px 0; text-align: left; margin-left: 5%"
    >
      <!-- <a  style="margin-left:-1060px; font-size: 20px;">返回</a> -->
      <router-link
        :to="{ name: 'workcenter', params: { n: 2 } }"
        style="font-size: 20px"
        >{{ $t("message.return") }}</router-link
      >
      <span v-if="work_id === null" style="font-size: 20px">{{
        $t("message.newwork")
      }}</span>
      <span v-if="work_id !== null" style="font-size: 20px">{{
        $t("message.editwork")
      }}</span>
    </div>
    <div class="wrapper">
      <div class="box edit_work" style="margin-top: 40px">
        <el-steps
          align-center
          :space="4000"
          :active="active"
          finish-status="success"
        >
          <el-step
            style="text-align: left"
            :title="$t('message.step1')"
            :description="$t('message.information')"
          ></el-step>
          <el-step
            style="text-align: left"
            :title="$t('message.step2')"
            :description="$t('message.workcontent')"
          ></el-step>
        </el-steps>
        <div v-if="active === 0" class="flex_between">
          <div style="margin-top: 50px" class="form_feild">
            <!-- 作品封面上传未完成 -->
            <div class="cell">
              <div class="cell_hd">{{ $t("message.workcover") }}：</div>
              <el-upload
                :class="{ 'hide-upload-btn': photoHide }"
                class="avatar-uploader annaul-ticket-imgs"
                action="#"
                name="file"
                list-type="picture-card"
                :file-list="fileList"
                :limit="limit"
                :show-file-list="true"
                :http-request="handleFileUpload"
              >
                <i slot="default" class="el-icon-plus"></i>
                <div slot="file" slot-scope="{ file }">
                  <img
                    style="width: 148px; height: 148px; object-fit: fill"
                    class="el-upload-list__item-thumbnail"
                    :src="file.url"
                    alt=""
                  />
                  <span class="el-upload-list__item-actions">
                    <span
                      class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview(file)"
                    >
                      <i class="el-icon-zoom-in"></i>
                    </span>

                    <span
                      v-if="!disabled"
                      class="el-upload-list__item-delete"
                      @click="handleRemove(file)"
                    >
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </div>
            <div class="cell" style="color: grey; margin-left: 100px">
              图片上传不超过100kb
            </div>
            <div class="cell">
              <div class="cell_hd">{{ $t("message.name") }}：</div>
              <!-- <input type="text"> -->
              <div>
                <el-input v-model="name" style="width: 650px"></el-input>
              </div>
            </div>

            <div class="cell">
              <div class="cell_hd">{{ $t("message.summary") }}：</div>
              <div class="cell_bd">
                <el-input
                  type="textarea"
                  style="width: 650px"
                  v-model="summary"
                ></el-input>
              </div>
            </div>
            <div class="cell">
              <div class="cell_hd">{{ $t("message.updatecode") }}：</div>
              <div
                class="cell_bd code"
                style="display: inline-flex; margin-top: 5px"
              >
                <el-radio
                  v-for="(item, index) in list.slice(0, 7)"
                  :key="index"
                  v-model="module_type"
                  :label="item.id"
                >
                  <span>
                    {{ item.name }}
                  </span>
                </el-radio>
              </div>
            </div>

            <div class="cell">
              <div class="cell_hd"></div>
              <div class="cell_bd"></div>
            </div>
          </div>
        </div>
        <div v-if="active === 1" style="height: 600px">
          <img
            src="../../src/assets/img/big.png"
            style="width: 50px; display: inline; margin-left: 20px"
            alt=""
            @click="dianji"
          />
          <div v-if="module_type === 5">
            <iframe
              src="API/mixly2.0_src-master/boards/index.html?thirdPartyBoard=false&boardIndex=.%2Fboards%2Fdefault%2Fmicropython_nrf51822_mithoncc%2Findex.xml&boardType=Mithon%20CC&boardImg=.%2Fboards%2Fdefault%2Fmicropython_nrf51822_mithoncc%2Fmedia%2Fmithon_compressed.png&language=MicroPython"
              scrolling="no"
              style="width: 100%; height: 600px"
              frameborder="0"
              allow="fullscreen"
              id="iframeId"
            ></iframe>
          </div>
          <div v-if="module_type === 6">
            <iframe
              src="API/mixly2.0_src-master/boards/index.html?thirdPartyBoard=false&boardIndex=.%2Fboards%2Fdefault%2Fmicropython_nrf51822_microbit%2Findex.xml&boardType=BBC%20micro%3Abit&boardImg=.%2Fboards%2Fdefault%2Fmicropython_nrf51822_microbit%2Fmedia%2Fmicrobit_compressed.png&language=MicroPython"
              scrolling="no"
              style="width: 100%; height: 600px"
              frameborder="0"
              allow="fullscreen"
              id="iframeId"
            ></iframe>
          </div>
          <div v-if="module_type === 7">
            <iframe
              src="API/mixly2.0_src-master/boards/index.html?thirdPartyBoard=false&boardIndex=.%2Fboards%2Fdefault%2Fmicropython_esp32s2%2Findex.xml&boardType=MixGo%20CE&boardImg=.%2Fboards%2Fdefault%2Fmicropython_esp32s2%2Fmedia%2Fmixgoce_compressed.png&language=MicroPython"
              scrolling="no"
              style="width: 100%; height: 600px"
              frameborder="0"
              allow="fullscreen"
              id="iframeId"
            ></iframe>
          </div>
          <div v-if="module_type === 8">
            <iframe
              src="API/mixly2.0_src-master/boards/index.html?thirdPartyBoard=false&boardIndex=.%2Fboards%2Fdefault%2Fpython_skulpt%2Findex.xml&boardType=Python%203%20Lite&boardImg=.%2Fboards%2Fdefault%2Fpython_skulpt%2Fmedia%2Fwebpy.png&language=Python"
              scrolling="no"
              style="width: 100%; height: 600px"
              frameborder="0"
              allow="fullscreen"
              id="iframeId"
            ></iframe>
          </div>
          <div v-if="module_type === 26">
            <iframe
              src="API/mixly2.0_src-master/boards/index.html?thirdPartyBoard=false&boardIndex=.%2Fboards%2Fdefault%2Fpython_skulpt_car%2Findex.xml&boardType=Python%203%20Car&boardImg=.%2Fboards%2Fdefault%2Fpython_skulpt%2Fmedia%2Fwebpy.png&language=Python"
              scrolling="no"
              style="width: 100%; height: 600px"
              frameborder="0"
              allow="fullscreen"
              id="iframeId"
            ></iframe>
          </div>
          <div v-if="module_type === 24">
            <iframe
              src="API/mixly2.0_src-master/boards/index.html?thirdPartyBoard=false&boardIndex=.%2Fboards%2Fdefault%2Fmicropython_k210_mixgoai%2Findex.xml&boardType=MixGo%20AI&boardImg=.%2Fboards%2Fdefault%2Fmicropython_k210_mixgoai%2Fmedia%2Fmixgoai_compressed.png&language=MicroPython"
              scrolling="no"
              style="width: 100%; height: 600px"
              frameborder="0"
              allow="fullscreen"
              id="iframeId"
            ></iframe>
          </div>
        </div>

        <div v-if="active === 1" class="flex_between">
          <img
            src="../../src/assets/img/last.png"
            style="width: 50px; margin-top: 100px; display: flex"
            @click="pre"
          />
          <el-button
            type="success"
            class="edit"
            style="margin-top: 105px; color: white"
            @click="submit"
            >{{ $t("message.accomplish") }}</el-button
          >
        </div>

        <div v-if="active === 0">
          <img
            src="../../src/assets/img/next.png"
            style="width: 50px; margin-right: -1000px"
            @click="next"
          />
        </div>
      </div>
    </div>
    <EmptyFooter />
  </div>
</template>

<script>
import { newworkpage } from "@/http/api.js"; // 导入接口
import { stepone, steptwo, workapply } from "@/http/api.js"; // 导入接口

import { adminAxiosUpload } from "@/http/api.js"; // 导入接口

import CommonLayout from "@/components/CommonLayout.vue";

import EmptyFooter from "@/components/EmptyFooter.vue";
import CommonStudent from "@/components/CommonStudent.vue";
import CommonTeacher from "@/components/CommonTeacher.vue";

export default {
  name: "addwork",
  data() {
    return {
      file_id: null,
      fileData: null,
      fileList: [],
      imageUrl: "",
      limit: 2,
      photoHide: false,
      disabled: false,
      dialogImageUrl: "",
      dialogVisible: false,
      status: "",
      type2: "",

      list: [
        { id: 1, name: "富文本" },
        { id: 2, name: "文本编程" },
        { id: 3, name: "图形化编程" },
        { id: 4, name: "其他文件" },
      ],
      fabu: [
        { id: 2, name: "发布" },
        { id: 0, name: "不发布" },
      ],

      name: "",
      summary: "",
      module_type: null,

      file_content: "",
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
            ["blockquote", "code-block"], // 引用  代码块
            [{ header: 1 }, { header: 2 }], // 1、2 级标题
            [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
            [{ script: "sub" }, { script: "super" }], // 上标/下标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            // [{'direction': 'rtl'}],                         // 文本方向
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
            [{ font: [] }], // 字体种类
            [{ align: [] }], // 对齐方式
            ["clean"], // 清除文本格式
            ["link", "image", "video"], // 链接、图片、视频
          ], //工具菜单栏配置
        },
        placeholder: "请在这里添加产品描述", //提示
        readyOnly: false, //是否只读
        theme: "snow", //主题 snow/bubble
        syntax: true,
      },

      active: 0,

      work_id: null,
      steptwo: null,
      change: null,
      change2: null,
    };
  },
  watch: {
    steptwo: {
      handler(newVal, oldVal) {
        // console.log(newVal, oldVal)
      },
      deep: true,
      immediate: true,
    },
    name(newName, oldName) {
      //判断条件
      if (oldName) {
        //检测的值变化执行的方法
        this.change = 1;
        // console.log('change', this.change)
      }
    },
    summary(newName, oldName) {
      //判断条件
      if (oldName) {
        //检测的值变化执行的方法
        this.change = 1;
        // console.log(this.change)
      }
    },
    module_type(newName, oldName) {
      //判断条件
      if (oldName) {
        //检测的值变化执行的方法
        this.change = 1;
        this.change2 = 1;

        console.log("module_typeold", oldName);
        console.log("module_typenew", newName);
      }
    },
    file_id(newName, oldName) {
      //判断条件
      if (oldName) {
        //检测的值变化执行的方法
        this.change = 1;
        // console.log(this.change)
      }
    },
  },

  methods: {
    dianji() {
      //设置后就是id==con_lf_top_div 的容器全屏
      let case1 = document.getElementById("iframeId");
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (case1.requestFullscreen) {
          case1.requestFullscreen();
        } else if (case1.webkitRequestFullScreen) {
          case1.webkitRequestFullScreen();
        } else if (case1.mozRequestFullScreen) {
          case1.mozRequestFullScreen();
        } else if (case1.msRequestFullscreen) {
          // IE11
          case1.msRequestFullscreen();
        }
      }
    },

    removeFile(file, fileList) {
      this.photoHide = false;
    },
    ifNotImage(fileName) {
      let arr = ["jpg", "jpeg", "png", "raw", "tiff", "gif"]; // 图片格式
      return (
        arr.filter((e) => e == fileName.split(".")[1].toLowerCase()).length ===
        0
      );
    },
    imgController(file) {
      if (this.ifNotImage(file.name)) {
        let msg = this.$message({
          type: "warning",
          message: "文件上传格式出错，请重新上传！",
        });
        setTimeout(() => {
          msg.close();
        }, 2000);
        this.fileList = [];
        return false;
      }

      let imgSize = Number(file.size / 1024);
      if (imgSize > 100) {
        this.$msgbox({
          title: "",
          message: "图片大小不能超过100KB，请重新上传。",
          type: "warning",
        });
        this.fileList = [];
        return false;
      }
      return true;
    },
    handleFileUpload(item) {
      if (!this.imgController(item.file)) {
        this.imgList = [];
        return;
      }
      let arr = ["jpg", "png", "jpeg", "bmp", "gif"];
      if (
        arr.filter((e) => e == item.file.name.split(".")[1].toLowerCase())
          .length === 0
      ) {
        let msg = this.$message({
          type: "warning",
          message: "出错了，请重新上传图片",
        });
        setTimeout(() => {
          msg.close();
        }, 2000);
        this.fileList = [];
        return;
      }
      this.photoHide = true;

      var fileObj = item.file;
      console.log("fileobj", fileObj);
      // FormData 对象
      var forms = new FormData();
      // 文件对象
      forms.append("file", fileObj);
      let token = JSON.parse(localStorage.getItem("Authorization"));
      forms.append("user_id", token.user_id);
      adminAxiosUpload(forms)
        .then((res) => {
          console.log("上传", res);
          if (res.data.status === 3) {
            this.$alert("" + res.data.msg, "提示", {
              confirmButtonText: "确定",
              showConfirmButton: false,
            });
            this.fileList = [];
            this.photoHide = false;
          }
          this.file_id = res.data.file_id;
          console.log("file_id", this.file_id);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    handleRemove(file) {
      this.file_id = null;
      this.fileList = [];
      this.photoHide = false;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file);
    },
    pre() {
      let work_id = JSON.parse(localStorage.getItem("work_id"));
      let token = JSON.parse(localStorage.getItem("Authorization"));
      let verify = localStorage.getItem("verify");
      newworkpage({
        work_id: work_id,
        uid: token.user_id,
        schoolid: token.school_id,
        verify: verify,
      }).then((res) => {
        this.list = res.types;

        if (this.fileList.length != 1) {
          let vote = {};
          vote.url = res.work.cover_path;
          this.fileList.push(vote);
          console.log(this.fileList);
        }
        this.name = res.work.name;
        this.summary = res.work.summary;
        this.module_type = res.work.type_id;
        this.file_id = res.work.cover_id;

        localStorage.setItem("file_id", JSON.stringify(res.work.file_id));
      });

      this.active--;
    },
    next() {
      console.log("name", this.name);
      console.log("module_type", this.module_type);
      console.log("file_id", this.file_id);
      if (this.active === 0) {
        if (
          this.name === null ||
          this.module_type === null ||
          this.file_id === null
        ) {
          this.$alert(
            "" + "请补全作品信息，包括：作品封面、作品名称、作品类型",
            "提示",
            {
              confirmButtonText: "确定",
            }
          );
        } else {
          let token = JSON.parse(localStorage.getItem("Authorization"));
          let verify = localStorage.getItem("verify");
          localStorage.setItem("module_type", JSON.stringify(this.module_type));
          console.log("change", this.change);
          if (this.change === 1) {
            stepone({
              author_id: token.user_id,
              cover_id: this.file_id,
              name: this.name,
              summary: this.summary,
              type_id: this.module_type,
              work_id: this.work_id,
              uid: token.user_id,
              schoolid: token.school_id,
              verify: verify,
            }).then(
              (res) => {
                localStorage.setItem("work_id", JSON.stringify(res.work_id));
                localStorage.setItem(
                  "file_name",
                  JSON.stringify(this.name + ".xml")
                );
                this.work_id = res.work_id;
                if (this.change2 === 1) {
                  localStorage.setItem("file_id", null);
                }
                if (res.status == 1) {
                  this.active++;
                }
                if (res.status != 1) {
                  this.$alert("" + res.msg, "错误提示", {
                    confirmButtonText: "确定",
                  });
                }
              },
              function (error) {}
            );
          } else {
            this.active++;
          }
        }
      }
    },
    submit() {
      console.log("status", this.status);
      if (this.status != 0) {
        workapply({
          work_id: this.work_id,
          apply_excellent: this.status,
        }).then(
          (res) => {
            this.work_id = res.work_id;
            localStorage.setItem("work_id", JSON.stringify(this.work_id));
            if (res.status == 1) {
              this.$alert("" + res.msg, "提示", {
                confirmButtonText: "确定",
                showConfirmButton: false,
              });
              setTimeout(() => {
                this.$msgbox.close();
                this.$router.push({ name: "workcenter", params: { n: 2 } });
              }, 2000);
            }
            if (res.status != 1) {
              this.$alert("" + res.msg, "错误提示", {
                confirmButtonText: "确定",
              });
            }
          },
          function (error) {}
        );
      } else {
        this.$alert("" + "新建作品成功", "提示", {
          confirmButtonText: "确定",
          showConfirmButton: false,
        });
        setTimeout(() => {
          this.$msgbox.close();
          this.$router.push({ name: "workcenter", params: { n: 2 } });
        }, 2000);
      }
    },
    onEditorBlur(editor) {},
    // 获得焦点
    onEditorFocus(editor) {},
    // 开始
    onEditorReady(editor) {},
    // 值发生变化
    onEditorChange(editor) {
      this.content = editor.html;
      // console.log(editor);
    },
  },
  mounted() {
    localStorage.setItem("showsubmit", 0);
    this.work_id = this.$route.params.work_id;
    localStorage.setItem("work_id", JSON.stringify(this.$route.params.work_id));
    console.log(this.$route.params.work_id);
    let token = JSON.parse(localStorage.getItem("Authorization"));
    let verify = localStorage.getItem("verify");
    if (this.$route.params.work_id === null) {
      newworkpage({
        uid: token.user_id,
        schoolid: token.school_id,
        verify: verify,
      }).then((res) => {
        this.list = res.types;
        localStorage.setItem("file_id", null);
        localStorage.setItem("file_name", JSON.stringify("文件名"));
        localStorage.setItem("ddl", true);
      });
    } else {
      console.log("work_id", this.$route.params.work_id);
      newworkpage({
        work_id: this.$route.params.work_id,
        uid: token.user_id,
        schoolid: token.school_id,
        verify: verify,
      }).then((res) => {
        console.log("listxiugai", res);

        this.list = res.types;

        if (this.fileList.length != 1) {
          let vote = {};
          if (res.work !== null) {
            vote.url = res.work.cover_path;
            this.fileList.push(vote);
            console.log(this.fileList);
            this.name = res.work.name;
            this.summary = res.work.summary;
            this.module_type = res.work.type_id;
            this.file_id = res.work.cover_id;
            localStorage.setItem(
              "module_type",
              JSON.stringify(res.work.type_id)
            );
            localStorage.setItem("file_id", JSON.stringify(res.work.file_id));
            localStorage.setItem("ddl", true);
          }
        }
      });
    }
    this.steptwo = JSON.parse(localStorage.getItem("steptwo"));
  },

  components: {
    EmptyFooter,
  },
};
</script>

<style></style>

<style lang="css" src="../../src/assets/.css/common.css" scoped></style>
<style
  lang="css"
  src="../../src/assets/.css/swiper-bundle.min.css"
  scoped
></style>
<style lang="css" src="../../src/assets/.css/index.css" scoped></style>
<style lang="css" src="../../src/assets/.css/page.css" scoped></style>
<style lang="css" scoped>
* {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.edit {
  color: #fff;
  background-color: rgb(0, 149, 154);
  border-color: rgb(0, 149, 154);
}

.edit:hover,
.edit:focus {
  background: rgb(129, 188, 190);
  border-color: rgb(129, 188, 190);
  color: #fff;
}
</style>
