<template>
  <div>
    <div>
      <div
        class="bread"
        style="padding: 20px 0; text-align: left; margin-left: 5%"
      >
        <!-- <a  style="margin-left:-1060px; font-size: 20px;">返回</a> -->
        <router-link
          v-if="this.$route.params.back !== 2"
          to="/coursecenter"
          style="font-size: 20px"
          >{{ $t("message.return") }}</router-link
        >
        <router-link
          v-if="this.$route.params.back === 2"
          to="/common"
          style="font-size: 20px"
          >{{ $t("message.return") }}</router-link
        >
        <span style="font-size: 20px">{{ $t("message.coursedetail") }}</span>
      </div>
      <div style="width: 90%; margin: 0 auto">
        <div class="box top_course_info flex" style="width: 100%">
          <img :src="coverurl" class="cover object_fit" alt="" />
          <div class="padding_left_30">
            <div style="margin-left: 0px; font-size: 20px; float: left">
              {{ title }}
            </div>
            <div
              class="update"
              style="float: left; margin-top: -2px; margin-left: 10px"
            >
              {{ cate_name }}
            </div>
            <el-button
              class="edit"
              @click="choose"
              size="mini"
              type="primary"
              style="
                height: 30px;
                width: 80px;
                font-size: 15px;
                font-weight: bold;
                float: right;
                margin-top: 100px;
              "
              v-if="restatus == 0"
              >{{ $t("message.choose") }}</el-button
            >
            <el-button
              class="edit"
              disabled
              size="mini"
              type="primary"
              style="
                height: 30px;
                width: 80px;
                font-size: 15px;
                font-weight: bold;
                float: right;
                margin-top: 100px;
              "
              v-if="restatus == 2 || restatus == 3"
              >{{ $t("message.underreview") }}</el-button
            >
            <el-button
              class="edit"
              size="mini"
              type="primary"
              style="
                height: 30px;
                width: 80px;
                font-size: 15px;
                font-weight: bold;
                float: right;
                margin-top: 100px;
                user-select: none;
                cursor: not-allowed;
              "
              v-if="restatus == 1"
              >{{ $t("message.electedcourse") }}</el-button
            >
            <div
              class="padding_left_30"
              style="text-align: left; margin-top: 50px; display: block"
            >
              <span style="margin-left: -38px"
                >【{{ $t("message.summary") }}】</span
              >
              <p class="zi" style="white-space: pre-wrap; height: 60px">
                <span style="margin-top: 10px">{{ summary }}</span>
              </p>
            </div>
            <div class="flex_between" style="margin-top: 15px">
              <em>{{ $t("message.teacher") }}：{{ teacher_name }}</em>
              <em style="float: left"
                >{{ $t("message.coursestudy") }}：{{ student_count }}</em
              >
            </div>
          </div>
        </div>
        <div class="box course_intro" style="width: 100%">
          <div class="intro_text">
            <h3 style="text-align: left">
              {{ $t("message.courseoutline") }}
              <!-- <span v-if="restatus === 1" class="notice">hhh</span> -->
              <span v-if="restatus === 2 || restatus === 3" class="notice">{{
                $t("message.access")
              }}</span>
            </h3>
            <detail-tree :status="restatus" :data="outline" :course_id="course_id"></detail-tree>
          </div>
        </div>
      </div>
    </div>
    <EmptyFooter />
  </div>
</template>

<script>
import { detailspage } from "@/http/api.js"; // 导入接口
import { coursechoose } from "@/http/api.js"; // 导入接口
import EmptyFooter from "@/components/EmptyFooter.vue";
import DetailTree from "@/components/detailTree.vue";
export default {
  name: "coursedetail",

  data() {
    return {
      lesson: null,
      type2: "",
      statue: "",
      id: "",
      type: "",
      kcid: "",
      back: null,

      reid: "",
      cate_name: "",
      coverurl: "",
      restatus: null,
      title: "",
      summary: "",
      sdCount: "",
      outline: [],
      teacher_name: "",
      student_count: "",
      course_id: Number,
      code_exist: "",
    };
  },
  components: {
    EmptyFooter,
    DetailTree,
  },

  mounted() {},
  watch: {
    // lesson: {
    //   handler: function (n, o) {
    //     this.dataInit(n.outline);
    //   },
    // },
    // deep: true
  },
  methods: {
    queryData() {
      // 将query数据赋值到当前网页
      this.course_id = parseInt(this.$route.params.id);
      console.log("cid", this.course_id);
    },
    dataInit(outline) {
      let outlineArray = outline;
      if (!outline) {
        this.outline = [];
      } else {
        // 存储处理后的平面数组
        this.outline = outlineArray.reduce((memo, current) => {
          let item = {
            id: current.id,
            pid: current.upper_module,
            fid: current.front_module,
            label: current.title,
            exist: current.exist,
            type: current.type,
            is_public: current.is_public,
          };
          memo.push(item);
          return memo;
        }, []);
      }
    },
    choose() {
      let token = JSON.parse(localStorage.getItem("Authorization"));
      let verify = localStorage.getItem("verify");
      if (this.code_exist === 0) {
        coursechoose({
          course_id: this.course_id,
          student_code: null,
          user_id: token.user_id,
          uid: token.user_id,
          schoolid: token.school_id,
          verify: verify,
        }).then(
          (res) => {
            if (res.status === 1 || res.status === 3) {
              this.$alert("" + res.msg, "状态提示", {
                confirmButtonText: "确定",
              });
              this.restatus = res.status;
              // window.location.reload();
            } else {
            }
          },
          function (error) {}
        );
      } else {
        this.$prompt("请输入验证码", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }).then(({ value }) => {
          console.log("code", value);
          coursechoose({
            course_id: this.course_id,
            student_code: value,
            user_id: token.user_id,
            uid: token.user_id,
            schoolid: token.school_id,
            verify: verify,
          }).then(
            (res) => {
              console.log("reschoose", res);
              if (
                res.status === 3 ||
                res.status === 2 ||
                res.status === 1 ||
                res.status === 0
              ) {
                this.$alert("" + res.msg, "状态提示", {
                  confirmButtonText: "确定",
                });
                this.restatus = res.status;
                // window.location.reload();
              } else {
                this.$alert("" + res.msg, "状态提示", {
                  confirmButtonText: "确定",
                });
              }
            },
            function (error) {}
          );
        });
      }
    },
  },
  created() {
    this.queryData();
    // this.course_id = this.$route.params.id
    console.log("back", this.$route.params.back);
    let token = JSON.parse(localStorage.getItem("Authorization"));
    let verify = localStorage.getItem("verify");
    detailspage({
      user_id: token.user_id,
      course_id: this.course_id,
      uid: token.user_id,
      schoolid: token.school_id,
      verify: verify,
    }).then((res) => {
      this.lesson = res;
      this.reid = res.id;
      this.cate_name = res.cate_name;
      this.coverurl = res.cover_url;
      this.restatus = res.status;
      this.title = res.title;
      this.summary = res.summary;
      this.sdCount = res.sdCount;
      this.teacher_name = res.teacher_name;
      this.student_count = res.student_count;
      this.code_exist = res.code_exist;
      this.dataInit(res.outline);
    });
  },
};
</script>

<style scoped lang="css">
.zi {
  font-family: 微软雅黑;
  /* line-height: 2px; */
  font-size: 15px;
  text-indent: 2px;
  text-align: left;
  width: 750px;
  /* margin-top: 5px; */
  margin-bottom: 50px;
  white-space: pre-wrap;
}

.edit,
.edit:focus {
  color: rgb(239, 88, 10);
  background-color: rgb(253, 246, 236);
  border-color: rgb(239, 88, 10);
}

.edit:hover {
  background: rgb(239, 88, 10);
  border-color: rgb(239, 88, 10);
  color: white;
}

.notice {
  background: rgb(247, 227, 217);
  color: rgb(239, 88, 10);
  height: 25px;
  display: inline-block;
  margin-top: 7px;
  border-radius: 16px;
  line-height: 25px;
  padding: 0 15px;
  font-size: 15px;
  margin-bottom: 7px;
}

/* @import url('../../src/assets/css/common.css');
@import url('../../src/assets/css/page.css'); */
</style>
<style lang="css" src="../../src/assets/.css/common.css" scoped></style>
<style lang="css" src="../../src/assets/.css/index.css" scoped></style>
<style lang="css" src="../../src/assets/.css/page.css" scoped></style>
<style lang="css" scoped>
* {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>