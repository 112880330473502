<template>
  <div>
    <!-- <div v-if="type2===null&&status===0">
      
      <CommonLayout></CommonLayout>
         </div>
         <div v-if="type2===1&&status===1">
      <CommonStudent></CommonStudent>
         </div>
         <div v-if="type2===2&&status===1">
      <CommonTeacher></CommonTeacher>
         </div> -->

    <div>
      <div
        class="bread"
        style="padding: 20px 0; text-align: left; margin-left: 5%"
      >
        <router-link
          v-if="this.$route.params.back2 !== 3"
          :to="{ name: 'coursedetail', params: { id: kcid } }"
          style="font-size: 20px"
          >{{ $t("message.return") }}</router-link
        >
        <router-link
          v-if="this.$route.params.back2 === 3"
          :to="{ name: 'taskcenter' }"
          style="font-size: 20px"
          >{{ $t("message.return") }}</router-link
        >
        <span style="margin-left: ; font-size: 20px">{{
          $t("message.questionnairedetail")
        }}</span>
      </div>

      <!-- <div class="bread" style="margin-bottom: -15px">
        <router-link :to="{ name: 'coursedetail', params: { id: kcid } }" style="margin-left: -1060px; font-size: 20px">{{
          $t('message.return') }}</router-link>
        <span style="margin-left: ; font-size: 20px">{{ $t('message.questionnairedetail') }}</span>
      </div> -->

      <div class="wrapper">
        <h2 class="padding_bottom_20"></h2>
      </div>

      <div
        v-if="restatus1 === 0"
        class="wrapper two_box_container flex_between"
      >
        <div class="box box_left">
          <div class="main_content">
            <div
              class="answer form_feild"
              v-for="(item, index) in questions"
              :key="index"
              style="width: 100%"
            >
              <div
                v-if="num === index && item.type === '选择题'"
                style="text-align: left"
              >
                <span v-if="item.is_must === 1" style="color: red">* </span>
                <span>{{ index + 1 }}.{{ item.stem }}</span>
                <div>
                  <img
                    v-if="item.photo != null"
                    style="width: 200px; text-align: left"
                    :src="item.photo"
                    alt=""
                  />
                </div>
                <!-- <div style="left:10px;position:relative"></div> -->
                <!-- <span>{{ item.stem }}</span> -->
                <div :key="index" style="">
                  <div style="">
                    <div>
                      <el-radio
                        v-if="item.content.photoA != null"
                        style="margin-bottom: 5px; text-align: left"
                        v-model="item.student_answer"
                        label="A"
                        >A.{{ item.content.itemA }}</el-radio
                      >
                      <el-radio
                        v-if="item.content.photoA == null"
                        style="margin-bottom: 5px; text-align: left"
                        v-model="item.student_answer"
                        label="A"
                        >A.{{ item.content.itemA }}</el-radio
                      >
                      <img
                        v-if="item.content.photoA != null"
                        style="width: 200px; height: 60px; text-align: left"
                        :src="item.content.photoA"
                        alt=""
                      />
                    </div>
                    <div>
                      <el-radio
                        v-if="item.content.photoB != null"
                        style="margin-bottom: 5px; text-align: left"
                        v-model="item.student_answer"
                        label="B"
                        >B.{{ item.content.itemB }}</el-radio
                      >
                      <el-radio
                        v-if="item.content.photoB == null"
                        style="margin-bottom: 5px; text-align: left"
                        v-model="item.student_answer"
                        label="B"
                        >B.{{ item.content.itemB }}</el-radio
                      >
                      <img
                        v-if="item.content.photoB != null"
                        style="width: 200px; height: 60px; text-align: left"
                        :src="item.content.photoB"
                        alt=""
                      />
                    </div>
                    <div>
                      <el-radio
                        v-if="item.content.photoC != null"
                        style="margin-bottom: 5px; text-align: left"
                        v-model="item.student_answer"
                        label="C"
                        >C.{{ item.content.itemC }}</el-radio
                      >
                      <el-radio
                        v-if="item.content.photoC == null"
                        style="margin-bottom: 5px; text-align: left"
                        v-model="item.student_answer"
                        label="C"
                        >C.{{ item.content.itemC }}</el-radio
                      >
                      <img
                        v-if="item.content.photoC != null"
                        style="width: 200px; height: 60px; text-align: left"
                        :src="item.content.photoC"
                        alt=""
                      />
                    </div>
                    <div>
                      <el-radio
                        v-if="item.content.photoD != null"
                        style="margin-bottom: 5px; text-align: left"
                        v-model="item.student_answer"
                        label="D"
                        >D.{{ item.content.itemD }}
                      </el-radio>
                      <el-radio
                        v-if="item.content.photoD == null"
                        style="margin-bottom: 5px; text-align: left"
                        v-model="item.student_answer"
                        label="D"
                        >D.{{ item.content.itemD }}
                      </el-radio>
                      <img
                        v-if="item.content.photoD != null"
                        style="width: 200px; height: 60px; text-align: left"
                        :src="item.content.photoD"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="num === index && item.type === '文字问答题'"
                style="text-align: left"
              >
                <span v-if="item.is_must === 1" style="color: red">* </span>
                <span>{{ index + 1 }}.{{ item.stem }}</span>
                <el-input
                  style="text-align: left; margin-top: 30px"
                  :autosize="{ minRows: 10, maxRows: 10 }"
                  type="textarea"
                  :rows="2"
                  placeholder="请输入内容"
                  v-model="item.student_answer"
                >
                </el-input>
              </div>
            </div>
          </div>
          <div>
            <div class="btn_field">
              <input type="hidden" name="step" id="step" value="" />
              <a
                href="javascript:;"
                :class="n === 1 ? 'btn home_btn' : 'btn home_btn btn_light'"
                @click="prex"
                :disabled="preDisabled"
              >
                {{ $t("message.previousquestion") }}</a
              >
              <a
                href="javascript:;"
                :class="n === 2 ? 'btn home_btn' : 'btn home_btn btn_light'"
                @click="next"
                :disabled="nextDisabled"
                >{{ $t("message.nextquestion") }}</a
              >
            </div>
          </div>
        </div>

        <div class="box box_right">
          <div class="top_info">
            <div class="flex_between">
              <img
                src="../../src/assets/img/question.png"
                style="width: 50px"
                alt=""
              />
              <div class="release_time">
                <span v-if="questions != null"
                  >{{ $t("message.question") }}:{{ questions.length }}</span
                >
              </div>
            </div>
          </div>
          <div class="question_list_container">
            <div class="question_title flex_between">
              <!-- <span>{{ $t('message.grade') }}：0</span> -->
              <!-- <span>题数：提数 </span> -->
            </div>
            <div class="question_list clearfix">
              <div v-for="(item, index) in questions" :key="index">
                <a
                  :class="
                    item.student_answer !== null ? 'q_item done' : 'q_item'
                  "
                  @click="pick(index)"
                  >{{ index + 1 }}</a
                >
              </div>
            </div>
          </div>
          <div class="bottom_btn">
            <input type="hidden" name="is_submit" id="is_submit" value="0" />
            <input
              type="button"
              value="交卷"
              style="width: 100%; margin: 0"
              class="btn home_btn"
              @click="submit"
            />
          </div>
        </div>
      </div>
      <div
        v-if="restatus1 === 1"
        class="wrapper two_box_container flex_between"
      >
        <div class="box box_left" style="">
          <div class="main_content" style="height: 70px">
            <div
              class="answer form_feild"
              v-for="(item, index) in questions"
              :key="index"
              style="width: 100%"
            >
              <div
                v-if="num === index && item.type === '选择题'"
                style="text-align: left"
              >
                <span v-if="item.is_must === 1" style="color: red">* </span>
                <span>{{ index + 1 }}.{{ item.stem }}</span>
                <div>
                  <img
                    v-if="item.photo != null"
                    style="width: 200px; text-align: left"
                    :src="item.photo"
                    alt=""
                  />
                </div>
                <div :key="index">
                  <div>
                    <el-radio
                      disabled
                      v-if="item.content.photoA != null"
                      style="margin-bottom: 5px; text-align: left ;"
                      v-model="item.student_answer"
                      label="A"
                      ><span>A：{{ item.content.itemA }}</span></el-radio
                    >
                    <el-radio
                      disabled
                      v-if="item.content.photoA == null"
                      style="margin-bottom: 5px; text-align: left"
                      v-model="item.student_answer"
                      label="A"
                      ><span>A：{{ item.content.itemA }}</span></el-radio
                    >
                    <img
                      v-if="item.content.photoA != null"
                      style="width: 200px; height: 60px; text-align: left"
                      :src="item.content.photoA"
                      alt=""
                    />
                  </div>
                  <div>
                    <el-radio
                      disabled
                      v-if="item.content.photoB != null"
                      style="margin-bottom: 5px; text-align: left"
                      v-model="item.student_answer"
                      label="B"
                      ><span>B：{{ item.content.itemB }}</span></el-radio
                    >
                    <el-radio
                      disabled
                      v-if="item.content.photoB == null"
                      style="margin-bottom: 5px; text-align: left"
                      v-model="item.student_answer"
                      label="B"
                      ><span>B：{{ item.content.itemB }}</span></el-radio
                    >
                    <img
                      v-if="item.content.photoC != null"
                      style="width: 200px; height: 60px; text-align: left"
                      :src="item.content.photoB"
                      alt=""
                    />
                  </div>
                  <div>
                    <el-radio
                      disabled
                      v-if="item.content.photoC != null"
                      style="margin-bottom: 5px; text-align: left ;"
                      v-model="item.student_answer"
                      label="C"
                      ><span>C：{{ item.content.itemC }}</span></el-radio
                    >
                    <el-radio
                      disabled
                      v-if="item.content.photoC == null"
                      style="margin-bottom: 5px; text-align: left"
                      v-model="item.student_answer"
                      label="C"
                      ><span>C：{{ item.content.itemC }}</span></el-radio
                    >
                    <img
                      v-if="item.content.photoC != null"
                      style="width: 200px; height: 60px; text-align: left"
                      :src="item.content.photoC"
                      alt=""
                    />
                  </div>
                  <div>
                    <el-radio
                      disabled
                      v-if="item.content.photoD != null"
                      style="margin-bottom: 5px; text-align: left ;"
                      v-model="item.student_answer"
                      label="D"
                      ><span>D：{{ item.content.itemD }}</span></el-radio
                    >
                    <el-radio
                      disabled
                      v-if="item.content.photoD == null"
                      style="margin-bottom: 5px; text-align: left"
                      v-model="item.student_answer"
                      label="D"
                      ><span>D：{{ item.content.itemD }}</span></el-radio
                    >
                    <img
                      v-if="item.content.photoD != null"
                      style="width: 200px; height: 60px; text-align: left"
                      :src="item.content.photoD"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div
                v-if="num === index && item.type === '文字问答题'"
                style="text-align: left"
              >
                <span v-if="item.is_must === 1" style="color: red">* </span>
                <span>{{ index + 1 }}.{{ item.stem }}</span>
                <el-input
                  style="text-align: left; margin-top: 30px"
                  :autosize="{ minRows: 10, maxRows: 10 }"
                  type="textarea"
                  :rows="2"
                  v-model="item.student_answer"
                  placeholder="请输入内容"
                >
                </el-input>
              </div>
            </div>
          </div>

          <div class="btn_field">
            <input type="hidden" name="step" id="step" value="" />
            <a
              href="javascript:;"
              :class="n === 1 ? 'btn home_btn' : 'btn home_btn btn_light'"
              @click="prex"
              :disabled="preDisabled"
            >
              {{ $t("message.previousquestion") }}</a
            >
            <a
              href="javascript:;"
              :class="n === 2 ? 'btn home_btn' : 'btn home_btn btn_light'"
              @click="next"
              :disabled="nextDisabled"
              >{{ $t("message.nextquestion") }}</a
            >
          </div>
        </div>

        <div class="box box_right">
          <div class="top_info">
            <!-- <div class="flex_between top_info_score">
              <img src="../../src/assets/img/test_correct.png" alt="" />
              <div v-for="(item, index) in questions" :key="index">
                <span v-if="num === index">{{ $t('message.grade') }}：</span>
                <em v-if="num === index" style="font-size: 10px">{{
                  grade
                }}</em>
              </div>
            </div> -->
            <div class="correct_percent">
              <img src="../../src/assets/img/test_correct.png" alt="" />
              <span style="font-weight: bold"
                >{{ $t("message.time") }}：{{ time }}s</span
              >
              <span></span>
            </div>
          </div>
          <div class="question_list_container">
            <div class="question_title flex_between">
              <!-- <span>单题分数：</span>
              <span>题数：提数</span> -->
            </div>
            <div class="question_list clearfix">
              <div v-for="(item, index) in questions" :key="index">
                <a
                  class="item.student_answer === null ? '' : 'q_item right q_item right current'"
                  @click="pick(index)"
                  >{{ index + 1 }}</a
                >
              </div>
            </div>
          </div>
          <div class="bottom_btn">
            <router-link
              :to="{ name: 'coursedetail', params: { id: kcid } }"
              style="width: 100%; margin: 0"
              class="btn home_btn btn_light"
              >{{ $t("message.return") }}</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <EmptyFooter />
  </div>
</template>

<script>
import CommonLayout from "@/components/CommonLayout.vue";

import EmptyFooter from "@/components/EmptyFooter.vue";
import CommonStudent from "@/components/CommonStudent.vue";
import CommonTeacher from "@/components/CommonTeacher.vue";
import { questionnairepage } from "@/http/api.js"; // 导入接口
import { questionnairesubmit } from "@/http/api.js"; // 导入接口
export default {
  name: "coursetest",
  data() {
    return {
      id: "",
      kcid: "",
      csid: "",
      status: "",
      type2: "",

      preDisabled: true, //上禁用按钮
      nextDisabled: false, //下禁用按钮
      num: 0, //第几题
      percent: "",
      n: 0,
      en: 0,
      sum1: 0,
      sum2: 0,

      questions: [],
      length: "", //questions数组长度
      duration: "",
      restatus1: "",

      postquestions: [],
      answers: [],
      grade: "",
      finish_time: 0,
      time: "",
      tijiao: null,
    };
  },

  created() {
    this.getTime(); //只要该页面一渲染创建，就开始倒计时
  },
  methods: {
    next() {
      this.n = 2;
      this.preDisabled = false;
      //   console.log(this.questions[0].length)
      console.log(this.questions[0].student_answer);
      console.log(this.questions[1].student_answer);

      if (this.num < this.questions.length - 1 && this.questions != null) {
        this.num += 1;
      }
    },
    prex() {
      this.n = 1;
      if (this.num === 0) {
        this.num = 0;
      } else {
        this.num -= 1;
      }
    },
    pick(index) {
      this.num = index;
    },
    getTime() {
      this.timer = setInterval(() => {
        this.finish_time++;
      }, 1000);
    },
    submit() {
      let token = JSON.parse(localStorage.getItem("Authorization"));
      let verify = localStorage.getItem("verify");
      if (this.questions != null) {
        for (let i = 0; i < this.questions.length; i++) {
          this.answers.push({});

          this.$set(this.answers[i], "question_id", this.questions[i].id);
          this.$set(this.answers[i], "user_id", token.user_id);
          this.$set(
            this.answers[i],
            "answer",
            this.questions[i].student_answer
          );
        }

        for (let x = 0; x < this.questions.length; x++) {
          if (
            this.questions[x].is_must === 1 &&
            this.questions[x].student_answer === null
          ) {
            this.tijiao = 1;
          }
        }
      }

      if (this.tijiao === 1) {
        this.$alert("" + "必做题未全部完成", "提示", {
          confirmButtonText: "确定",
        });
        window.location.reload();
      } else {
        questionnairesubmit({
          questionnaire_id: this.csid,
          answers: this.answers,
          user_id: token.user_id,
          finish_time: this.finish_time,
          uid: token.user_id,
          schoolid: token.school_id,
          verify: verify,
        }).then(
          (res) => {
            console.log(res);

            if (res.status == 1) {
              this.success = 1;
              this.$alert("" + res.msg, "提示", {
                confirmButtonText: "确定",
                showConfirmButton: false,
              });
              setTimeout(() => {
                this.$msgbox.close();
                window.location.reload();
              }, 2000);
            }
            if (res.status != 1) {
              this.$alert("" + res.msg, "错误提示", {
                confirmButtonText: "确定",
                showConfirmButton: false,
              });
              setTimeout(() => {
                this.$msgbox.close();
                window.location.reload();
              }, 2000);
            }
          },
          function (error) {}
        );
      }
    },
  },
  mounted() {
    this.id = this.$route.params.id;
    this.kcid = this.$route.params.kcid;

    console.log(this.kcid);

    let token = JSON.parse(localStorage.getItem("Authorization"));
    let verify = localStorage.getItem("verify");
    questionnairepage({
      user_id: token.user_id,
      module_id: this.id,
      uid: token.user_id,
      schoolid: token.school_id,
      verify: verify,
    }).then((res) => {
      console.log(res);
      this.questions = res.questions;
      if (res.questions != null) {
        this.length = res.questions.length;
        let i = 0;
        for (i; i < this.questions.length; i++) {
          if (this.questions[i].front_question === 0) {
            this.questions2[0] = this.questions[i];
            var x = i;
          }
        }
        var k = 1;
        for (k; k < this.questions.length; k++) {
          for (var j = 0; j < this.questions.length; j++) {
            if (this.questions[j].front_question === this.questions[x].id) {
              this.questions2[k] = this.questions[j];
              console.log("j", j);
              var x = j;
              break;
            }
          }
          console.log("k", k);
        }
        console.log("this.questions2", this.questions2);
        this.questions = this.questions2;
      }

      this.duration = res.duration;
      this.restatus1 = res.status;
      this.csid = res.id;
      this.grade = res.grade;
      this.time = res.finish_time;
    });
  },
  components: {
    EmptyFooter,
  },
};
</script>

<style lang="css" src="../../src/assets/.css/common.css" scoped></style>
<style lang="css" src="../../src/assets/.css/index.css" scoped></style>
<style lang="css" src="../../src/assets/.css/page.css" scoped></style>
<style lang="css" scoped>
.red {
  font-weight: bolder;
  font-size: 30px;
  color: red;
}

.green {
  font-weight: bolder;
  font-size: 30px;

  color: green;
}

* {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
