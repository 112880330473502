<template>
  <div class="footer">
    <div class="wrapper">
      <div class="contact flex_between">
        <div>
          <!-- <img style="width:200px;" src="./米思齐创客教学平台_files/logo_white.png" alt="" class="logo_btm"> -->
          <span class="phone" style="font-size: 15px">
            {{ $t("message.tel") }}：{{ tel }}
          </span>
        </div>
        <!-- <div class="flex qr_code">
                <img src="../../src/assets/img/qr_text.png" alt="">
                <img src="../../src/assets/img/code2.jpg" alt="" style="width:85px;">
            </div> -->
      </div>
      <div class="btm_info flex_between">
        <div class="email" style="font-size: 15px">
          {{ $t("message.email") }}：{{ email }}
          <span class="address" style="font-size: 15px; margin-left: 10px">
            {{ $t("message.add") }}：{{ address }}</span
          >
        </div>
        <span> {{ copyright }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getparam } from "@/http/api.js";
export default {
  name: "CommonFooter",
  data() {
    return {
      email: "",
      address: "",
      tel: "",
      copyright: "",
    };
  },
  mounted() {
    let token = JSON.parse(localStorage.getItem("Authorization"));
    let verify = localStorage.getItem("verify");
    getparam({
      name: "email",
    }).then((res) => {
      console.log("page", res);
      this.email = res.value;
    });
    getparam({
      name: "tel",
    }).then((res) => {
      console.log("page", res);
      this.tel = res.value;
    });
    getparam({
      name: "address",
    }).then((res) => {
      console.log("page", res);
      this.address = res.value;
    });
    getparam({
      name: "copyright",
    }).then((res) => {
      console.log("page", res);
      this.copyright = res.value;
    });
  },
};
</script>
<style lang="css" src="../../src/assets/.css/common.css" scoped></style>
<style lang="css" src="../../src/assets/.css/index.css" scoped></style>
<style lang="css" src="../../src/assets/.css/page.css" scoped></style>
