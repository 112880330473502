import { render, staticRenderFns } from "./addwork.vue?vue&type=template&id=3b0dfd91&scoped=true&"
import script from "./addwork.vue?vue&type=script&lang=js&"
export * from "./addwork.vue?vue&type=script&lang=js&"
import style1 from "../../src/assets/.css/common.css?vue&type=style&index=1&id=3b0dfd91&prod&lang=css&scoped=true&"
import style2 from "../../src/assets/.css/swiper-bundle.min.css?vue&type=style&index=2&id=3b0dfd91&prod&lang=css&scoped=true&"
import style3 from "../../src/assets/.css/index.css?vue&type=style&index=3&id=3b0dfd91&prod&lang=css&scoped=true&"
import style4 from "../../src/assets/.css/page.css?vue&type=style&index=4&id=3b0dfd91&prod&lang=css&scoped=true&"
import style5 from "./addwork.vue?vue&type=style&index=5&id=3b0dfd91&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b0dfd91",
  null
  
)

export default component.exports