import enLocale from 'element-ui/lib/locale/lang/en'
const en = {
    message: {
        'login': 'Login',
        'register': 'Register',
        'text': 'welcome to your vue App',
        'Featurecourse': 'Feature course',
        'Featurec': 'Feature',
        'ExcellentWork': 'Excellent Work',
        'Excellentw': 'Excellent',

        'pleaseChoose': 'Please Choose One',
        'returnFront': 'Return to Home Page',
        'logOut': 'Log Out',

        'home': 'Home',
        'course': 'Course',
        'task': 'Task',
        'work': 'Work',
        'competition': 'Activity',
        'tool': 'Tool',
        'morestarcourse': 'More Star Course',
        'morestarwork': 'More Excellent Work',
        'tel': 'Tel',
        'email': 'E-mail',
        'add': 'Address',
        //course
        'programtask': 'Programming Tasks',
        'coursewarepdf': 'Courseware-PDF',
        'coursewarevideo': 'Courseware-Video',
        'attachment': 'Attachment',
        'generaldocumentation': 'General Documentation',
        'richtext': 'Rich Text',
        'test': 'Test',
        'questionnaire': 'Questionnaire',
        'electedcourse': 'Elected',
        'attachmentdownload': 'Attachment Download',
        'attachmentDetail': 'Attachment Detail',
        'wcstate': 'Completion Status',
        'all': 'All',
        'selected': 'Selected',
        'unselected': 'Unselected',
        'classification': 'Classification',
        'coursename': 'Course Name',
        'teacher': 'Teacher',
        'coursestudy': 'Study Number',
        'entercourse': 'Enter',
        'choose': 'Choose',
        'underreview': 'Examining',
        'return': 'Return',
        'coursedetail': 'Course Detail',
        'courseoutline': 'Outlines',
        'homeworkdetail': 'Homework Detail',
        'homeworkdescription': 'Homework Description',
        'releasetime': 'Release Time',
        'endtime': 'End Time',
        'completionform': 'Completion Form',
        'score': 'Score',
        'courseware': 'Courseware',
        'video': 'Video',
        'questionnairedetail': 'Questionnaire Detail',
        'previousquestion': 'Previous Question',
        'nextquestion': 'Next Question',
        'correct': 'Correct',
        'wrong': 'Wrong',
        'answer': 'Answer',
        'explanation': 'Explanation',
        'grade': 'score',
        'time': 'Time',
        'timeremaining': 'Time Remaining',
        'question': 'Question Number',
        'testdetail': 'Test Detail',
        'enter': 'Enter',
        'homeworkname': 'Homework Name',
        'public': 'Public',
        'access': 'No course has been selected yet and cannot be accessed',
        'enlarge': 'Click to enlarge',
        'nosubmit': 'Not within submission time',
        'upload': 'Upload File',
        'submitfile': 'Please click the button above to upload the file first',
        'under100': 'Upload files up to 100Mb in size',
        'clicksubmit': 'Please click the button above to upload the file first, and submit it only after the new one is successfully created.',
        'download': 'Download View',
        'teacherc': 'Teacher Comment',
        'time11': 'Time',
        'noehomework': 'No Excellent Homework',
        'tishu': 'Number of questions',

        //task
        'compolish': ' Execution',
        'tasktype': ' Task Type',
        'homeworktask': 'Homework',
        'testtask': 'Test',
        'questiontask': 'Questionnaire',

        'select': 'Select',
        'confirm': 'Search',
        'reset': 'Reset',
        'addtime': 'Add Time',
        'endtime': 'End Time',

        'type': 'Type',
        'grade': 'Grade',
        'operation': 'Operation',
        'taskname': 'Task Name',
        'taskcourse': 'Course',
        'submit': 'Submit',
        'chakan': 'Check',
        'morestartask': 'More Star Tasks',
        'Excellenttask': 'Excellent Task',
        //work
        'editwork': 'Edit Work',
        'step1': 'Step1',
        'step2': 'Step2',
        'step3': 'Step3',
        'workcontent': 'Work Content',
        'pubwork': 'Publish Work',
        'excellentwork': 'Excellent Work',
        'mywork': 'My Work',
        'worktype': 'Work Type',
        'newwork': 'New Work',
        'workname': 'Work Name',
        'summary': 'Summary',
        'author': 'Author',
        'check': 'Viewed',
        'privatework': 'Private',
        'excellent': 'Excellent',
        'workdetail': 'Work Detail',
        'updatedon': 'Updated on',
        'edit': 'Edit',
        'apply': 'Apply',
        'applying': 'Applying',
        'delete': 'Delete',
        'workcontent': 'Work Content',
        'goodworkdisplay': 'Excellent Work Display',
        'workcover': 'Cover',
        'updatecode': 'Type',
        'name': 'Name',
        'next': 'Next',
        'last': 'Last',
        'externalrelease': 'External Release',
        'accomplish': 'Accomplish',
        //competition
        'registrationdeadline': ' Deadline',
        'competitiontime': 'Time',
        'address': 'Address',
        'registrationlimit': 'Limitation',
        'enroll': 'Enroll',
        'loseefficacy': 'Lose Efficacy',
        'share': 'Share',
        'end': 'End',
        'competitionsummary': 'Activity Summary',
        'competitionnotice': 'Activity Notice',
        'competitionwork': 'Activity Work',
        'competitiondetail': 'Activity Detail',
        'nocompetition': 'No Activity',
        'enrolled': 'Enrolled',
        'ncw': 'New Activity Work',
        'mcw': 'My Activity Work',
        'ecw': 'Edit Activity Work',
        'rcw': 'Roll of honor',
        'cwn': 'Name',
        'cwt': 'Type',
        'djl': 'Hit',
        'oas': 'Overall Results',
        'mcwd': 'My Activity Work Detail',


        //user
        'last7days': 'Last 7 days',
        'last30days': 'Last 30 days',
        'lasthalfyear': 'Last half year',
        'lastyear': 'Last year',
        'coursenum': 'Course Number',
        'homenum': 'Homework Number',
        'testnum': 'Test Number',
        'worknum': 'Work Number',
        'state': 'State',
        'deve': 'Development',
        'perform': 'Performance',
        'Developmentassessment': 'Development assessment',
        'information': 'Essential information',
        'changePassword': 'Change Password',
        'avatar': 'Avatar',
        'realname': 'Name',
        'account': 'Account',
        'birthday': 'Birthday',
        'gender': 'Gender',
        'school': 'School',
        'class': 'Class',
        'prequestion': 'Question',
        'answer': 'Answer',
        'confirmedit': 'Confirm Modification',
        'oldpassword': 'Original Password：',
        'password': 'Password：',
        'confirmpassword': 'Confirm Password：',
        'comtask': 'Completed task',
        'sumtask': 'Tatal ',
        'comtest': 'Completed Test',
        'sumtest': 'Tatal',
        'taskaverscore': 'Task Average Score',
        'testaverscore': 'Test Average Score',
        'pi': 'Personal Information',
        'bm': 'Management',
        'quit': 'Quit'






    },
    admin: {
        'mainPage': 'Home',
        'dataView': 'Data Overview',
        'importantItem': 'Important Items',
        'courseNumber': 'Course Count',
        'teacherNumber': 'Teacher Count',
        'studentNumber': 'Student Count',
        'workNumber': 'Work Count',
        'testNumber': 'Test Count',
        'quesNumber': 'Questionnaire Count',
        'homeworkNumber': 'Homework Count',
        'weekNew': 'Week Increment',
        'monthNew': 'Month Increment',
        'hyearNew': '6 Months Increment',
        'yearNew': 'Year Increment',
        'courseManagement': 'Course',
        'classManagement': 'Class',
        'teacherManagement': 'Teacher',
        'studentManagement': 'Student',
        'homeworkManagement': 'Homework',
        'workManagement': 'Work',
        'testManagement': 'Test',
        'quesManagement': 'Questionnaire',
        'workScoreRank': 'Rank of Score',
        'more': 'More',
        'courseList': 'Course List',
        'createCourse': 'Create Course',
        'school': 'School',
        'category': 'Category',
        'keyword': 'Keyword',
        'search': 'Search',
        'reset': 'Reset',
        'noResult': 'No Information',
        'noResultText': 'The information you searched for was not found',
        'back': 'Back',
        'pleaseInput': 'Please Input',
        'pleaseInputCourseName': 'Please Input Course Name',
        'pleaseChoose': 'Please Choose',
        'chooseDate': 'Please Choose Time',
        'yes': 'Yes',
        'no': 'No',
        'cancel': 'Cancel',
        'confirm': 'Confirm',
        'chooseFile': 'Select File',
        'clickDownload': 'Download',
        // todo
        'todo': 'To-do List',
        'courseApply': 'Course Apply',
        'homeworkCorrect': 'Homework Correct',
        'testCorrect': 'Test Correct',
        'competitionWorkCorrect': 'Activity Correct',

        // my info
        'personalInfo': 'My Info',
        'submitModify': 'Save Changes',
        'clickModify': 'Modify',
        'oldPassword': 'Old Password',
        'newPassword': 'New Password',
        'confirmPassword': 'Repeat Password',
    },
    course: {
        'daifabu': 'Unpublished',
        'yigongkai': 'Public',
        'yifabu': 'Published',
        'daishenhe': 'Under Review',
        'basicInfo': 'Basic Information',
        'courseOutline': 'Course Outline',
        'courseCover': 'Cover',
        'school': 'School',
        'courseName': 'Name',
        'type': 'Type',
        'teacher': 'Teacher',
        'summary': 'Summary',
        'beginTime': 'Begin Time',
        'endTime': 'End Time',
        'code': 'Course Code',
        'recode': 'Refresh Code',
        'audit': 'Audit',
        'publish': 'Publish',
        'scope': 'Scope',
        'insideSchool': 'Inside the school',
        'regionalCourse': 'Regional Course',
        'next': 'Next',
        'addFirst': 'Add First Node',
        'finish': 'Finish',
        'homeworkList': 'Homework List',
        'keyword': 'Keyword',
        'pleaseInputCHName': 'Please Input title of Course/Homework',
        'pleaseInputCTName': 'Please Input title of Course/Test',
        'pleaseInputCQName': 'Please Input title of Course/Questionnaire',
        'operation': 'Operation',
        'passAudit': 'Pass',
        'notPassAudit': 'Fail',
        'homeworkDetail': 'Detail',
        'noCourse': 'No Course Now~',
        // lessonCard
        'students': 'Students',
        'manage': 'Manage',
        'unpublish': 'Unpublish',
        'waitingForSchool': 'School Manager Auditing',
        'waitingForArea': 'Area Manager Auditing',
        'WithdrawalOfPublicity': 'Non-public',
        "setAsPublic": "Set as Public Lesson",
        "applyForPublic": "Apply For Public",
        'beginLessonTime': 'Begin Time',
        // lessonDetail
        'lessonDetail': 'Detail',
        'startTime': 'Start Time',
        'to': 'To',
        'noCodeLesson': 'No Code for this Lesson',
        'modify': 'Modify',
        'applyPublic': 'Apply for Publicity',
        'cancelApply': 'Cancel Application',
        'delete': 'Delete',
        'outline': 'Outline',
        'all': 'All',
        'notAudit': 'Not Audit',
        'passed': 'Passed',
        'failed': 'Failed',
        'name': 'Name',
        'account': 'ID',
        'email': 'Email',
        'sex': 'Sex',
        'boy': 'Male',
        'girl': 'Female',
        'batchImport': 'Import in Batch',
        'checkImportTemplate': 'Check Template',
        'batchDelete': 'Delete in Batch',
        'batchAudit': 'Pass in Batch',
        'fillModule': 'Supply Information',
        'modifyModule': 'Modify Information',
        // modifyLesson
        'modifyCourse': 'Modify Course',
        'courseName': 'Name',
        'courseCate': 'Category',
        'courseSummary': 'Summary',
        'courseBeginTime': 'Begin Time',
        'courseEndTime': 'End Time',
        'courseCode': 'Code',
        'noCode': 'No Code',
        'createCode': 'Generate Code',
        'secondAudit': 'Audit',
        'ifPublish': 'Publish',
        'clickImgChangeCover': 'Change cover by clicking Image',
        'outline': 'Outline',
        'removeStudent': 'Remove Student',
        'detail': 'Detail',
        // outline
        'newHomework': 'Create Homework',
        'newTest': 'Create Test',
        'newQn': 'Create Questionnaire',
        'newCourseware': 'Create Courseware',
        'newSource': 'Create Source',
        'newCodeExercise': 'New Code Exercise',
        'homeworkType': 'Homework Type',
        'coursewareType': 'Courseware Type',
        'homeworkName': 'Homework Name',
        'testName': 'Test Name',
        'qnName': 'Questionnaire Name',
        'coursewareName': 'Courseware Name',
        'sourceName': 'Source Name',
        'exerciseName': 'Exercise Name',
        'sourceType': 'Type',
        'link': 'Link',

        'childChapter': 'Child Chapter',
        'createModule': 'Module',
        'removeChapter': 'Remove',
        'nextChapter': 'Next Chapter',
        'chapterName': 'Name',
        'homework': 'Homework',
        'test': 'Test',
        'qn': 'Questionnaire',
        'courseware': 'Courseware',
        'attachment': 'Attachment',
        'groupDiscussion': 'Discussion',
        'codeExercise': 'Code Exercise',
        'confirmRemoveChapter': 'Do you confirm to remove this Chapter/Module and the content it includes?',

        'saveOutline': 'Save Outline',
        // homework
        // addHomework
        'addHomework': 'Create Homework',
        'homeworkName': 'Name',
        'course': 'Course',
        'chapter': 'Chapter',
        'homeworkSummary': 'Summary',
        'image': 'Image',
        'homeworkCate': 'Category',
        'inputExample': 'Input Example',
        'outputExample': 'Output Example',
        'addExample': 'Add Example',
        'inputExampleDescribe': 'Input Description',
        'outputExampleDescribe': 'Output Description',
        'hint': 'Hint',
        'judgeExample': 'Judge Example',
        'uploadZipFile': 'Upload Zip File',
        'attachment': 'Attachment',
        'templateFile': 'Template File',
        'homeworkScore': 'Score',
        'is_public': 'Visable',
        'timeLimit': 'Time Limit(ms)',
        'internalStorageLimit': 'RAM Limit(kb)',
        'publishHomework': 'Publich Homework',
        'point': 'point(s)',
        'language': 'language',


        'modifyHomework': 'Modify Homework',
        // test
        'testList': 'Test List',
        'testDetail': 'Detail',
        'createTest': 'Create Test',
        'testName': 'Test Name',
        'testScore': 'Test Score',
        'testDuration': 'Duration',
        'testSummary': 'Summary',
        'saveBasicInfo': 'Save Basic Infomation',
        'itemSetting': 'Item Setting',
        'addItem': 'Add Item',
        'modifyItem': 'Modify Item',
        'edit': 'Edit',
        'itemName': 'Topic',
        'itemSummary': 'Summary',
        'itemType': 'Type',
        'option': 'Option',
        'correctAnswer': 'Correct Answer',
        'answerExplanation': 'Explanation',
        'order': 'Order',
        'itemScore': 'Score',

        'modifyTest': 'Modify Test',
        'testName': 'Name',
        // questionnaire
        'quesList': 'Questionnaire List',
        'quesDetail': 'Detail',
        'addQues': 'Create Questionnaire',
        'quesName': 'Name',
        'quesSummary': 'Summary',
        //exercise
        'createCodeExercise': 'Create Code Exercise',
        'exerciseName': 'Exercise Name',
        'knowledge': 'Knowledge',
        'exerciseSummary': 'Summary',
        'is_adaptive': 'Adaptive',
        "tree": "Knowledge Tree",
        'modifyCodeExercise': 'Modify Code Exercise',
        'viewCode': 'View Code',
    },
    user: {
        'userList': 'User List',
        'school': 'School',
        'class': 'Class',
        'identity': 'Identity',
        'pInputNameorAccount': 'Please Input Name/Account',
        'batchImportUserInfo': 'Import in Batch',
        'batchModifyUserInfo': 'Modify in Batch',
        'evalutionReportOnDevelopment': 'Evalution Report On Development',
        'behaviorData': 'Behavior Data',
        'addUser': 'Create User',
        'role': 'Role',
        'school': 'School',
        'class': 'Class',
        'name': 'Name',
        'account': 'Account',
        'selectSchoolFirst': 'School First',
        // behavior
        'behaviorManagement': 'Bahavior Data',
        // school
        'schoolList': 'School List',
        'addSchool': 'Create School',
        'modifySchool': 'Modify School',
        'schoolName': 'Name',
        'courseCate': 'Category',
        'currentCate': 'Current Categories',
        // class
        'classList': 'Class List',
        'addClass': 'Add Class',
        'modifyClass': 'Modify Class',
        'className': 'Name',
        'grade': 'Grade',
        'classId': 'ClassID (input Number)',
        'classSchool': 'School',
    },
    knowledge: {
        'knowledgeTreeList': 'Knowledge Tree List',
        'pleaseInputTreeName': 'Tree Name',
        'knowledgeTree': 'Knowledge Tree',
        'knowledge': 'Knowledge',
        'editTree': 'Edit Tree',
        'addFirst': 'Add First Node',
        'childKnowledge': 'Add Child Knowledge',
        'removeKnowledge': 'Remove Knowledge',
        'nextKnowledge': 'Add Next Knowledge',
        'knowledgeName': 'Knowledge Name',
        'confirmRemoveKnowledge': 'Do you confirm to remove this knowledge?',
        'saveTree': 'Save Knowledge Tree',
        'confirm': 'Confirm',
        'cancel': 'Cancel',
        'viewOrEditTree': 'view/edit tree',
    },
    codeExercise: {
        'codeExerciseList': 'Code Exercise List',
        'pleaseInputExerciseName': 'Exercise Name',
        'summary': 'Summary',
        'beginExercise': 'Begin Exercise',
        'exerciseDetail': 'Exercise Detail',
        'exercise': 'Exercise',
        'recommendMethod': 'Recommend Method',
        'analysis': 'Exercise Analysis',
        'submitCount': 'Submit Count',
        'correctCount': 'Correct Count',
        'submitProblems': 'Submit Problems',
        'correctProblems': 'Correct Problems',
        'correctRate': 'Correct Rate',
        'studentAnalysis': 'Student Analysis',
        'submitDetail': 'Submit Details',
        'studentExerciseDetail': 'Student Exercise Detail',
        'totalProblems': 'Total Problems',
        'records': 'Records',
        'studentCodeDetail': 'Student Code Detail',
        'returnExercise': 'Return Exercise',
    },
    ojProblem: {
        'ojProblemList': 'Oj Problem List',
        'pleaseInputProblemName': 'Problem Name',
        'addOjProblem': 'Add Oj Problem',
        'editOjProblem': 'Edit Oj Problem',
        'publishProblem': 'Publish Problem',
        'difficulty': 'Difficulty',
        'uploadCode': 'Upload Code',
        'title': "Problem Title",
        'description': "Problem Description",
        'difficulty': "Problem Difficulty",
        'knowledge': "Knowledge",
        'knowledgeDetect': "Knowledge Detect",
        'batchImport': "Batch Import",
        'input': 'Input',
        'output': 'Output',
        'timeLimit': 'Time Limit',
        'memoryLimit': 'Memory Limit',
    },
    work: {
        'workList': 'Work List',
        'pleaseInputWorkName': 'Please Input Work Name',
        'detail': 'Detail',
        'goodWork': 'Excellent',
        'keyword': 'Keyword',
        'type': 'Type',
        'detail': 'WorkDetail',
        'setAsexcellent': 'Excellent',
        'pleaseInputAuthororName': 'Author Name or Work Name',
        'competitionEnrollInfo': 'Registration List',
    },
    competition: {
        'competitionWorkDetail': "Work Detail",
    },
    ...enLocale
}

export default en;