<template>
  <div>
    <div class="main" style="width: 100%">
      <div
        class="bread"
        style="padding: 20px 0; text-align: left; margin-left: 5%"
      >
        <!-- <a  style="margin-left:-1060px; font-size: 20px;">返回</a> -->
        <router-link
          v-if="this.$route.params.back !== 2"
          :to="{ name: 'workcenter', params: { n: 1 } }"
          style="font-size: 20px"
          >{{ $t("message.return") }}</router-link
        >
        <router-link
          v-if="this.$route.params.back === 2"
          to="/common"
          style="font-size: 20px"
          >{{ $t("message.return") }}</router-link
        >
        <span style="font-size: 20px">{{ $t("message.workdetail") }}</span>
      </div>

      <div class="course_detail work_list wrapper">
        <div
          class="box top_course_info top_work_info item_course_info flex"
          style="width: 100%"
        >
          <img :src="cover_url" class="cover object_fit" alt="" />
          <div class="padding_left_30">
            <h2 class="">{{ name }}</h2>
            <div class="flex">
              <!-- <i class="tag ">hhhh</i> -->
              <em
                class="ellipsis"
                style="text-align: left; color: black; font-size: 15px"
                >{{ $t("message.summary") }}：{{ summary }}</em
              >
            </div>
            <div class="flex_between work_user_info">
              <div href="" class="user flex">
                <img class="userimg" :src="avatar" alt="" />
                <span>{{ author_name }}</span>
                <span style="margin-left: 50px; margin-top: 6px"
                  ><img src="../../src/assets/img/time.png" alt=""
                /></span>
                <span style="margin-left: 0px"
                  >{{ $t("message.updatedon") }}：{{ add_time }}
                </span>
              </div>
              <span class="view_num">
                {{ click_num }}{{ $t("message.check") }}</span
              >
            </div>
          </div>
        </div>

        <div class="box course_intro" style="width: 100%">
          <div class="intro_text" style="width: 100%">
            <img
              src="../../src/assets/img/big.png"
              style="width: 30px; display: inline; margin-left: 20px"
              alt=""
              @click="dianji"
            />
            <div v-if="type_id === 5">
              <iframe
                src="API/mixly2.0_src-master/boards/index.html?thirdPartyBoard=false&boardIndex=.%2Fboards%2Fdefault%2Fmicropython_nrf51822_mithoncc%2Findex.xml&boardType=Mithon%20CC&boardImg=.%2Fboards%2Fdefault%2Fmicropython_nrf51822_mithoncc%2Fmedia%2Fmithon_compressed.png&language=MicroPython"
                scrolling="no"
                style="width: 100%; height: 500px"
                frameborder="0"
                allow="fullscreen"
                id="iframeId"
              ></iframe>
            </div>
            <div v-if="type_id === 6">
              <iframe
                src="API/mixly2.0_src-master/boards/index.html?thirdPartyBoard=false&boardIndex=.%2Fboards%2Fdefault%2Fmicropython_nrf51822_microbit%2Findex.xml&boardType=BBC%20micro%3Abit&boardImg=.%2Fboards%2Fdefault%2Fmicropython_nrf51822_microbit%2Fmedia%2Fmicrobit_compressed.png&language=MicroPython"
                scrolling="no"
                style="width: 100%; height: 500px"
                frameborder="0"
                allow="fullscreen"
                id="iframeId"
              ></iframe>
            </div>
            <div v-if="type_id === 7">
              <iframe
                src="API/mixly2.0_src-master/boards/index.html?thirdPartyBoard=false&boardIndex=.%2Fboards%2Fdefault%2Fmicropython_esp32s2%2Findex.xml&boardType=MixGo%20CE&boardImg=.%2Fboards%2Fdefault%2Fmicropython_esp32s2%2Fmedia%2Fmixgoce_compressed.png&language=MicroPython"
                scrolling="no"
                style="width: 100%; height: 500px"
                frameborder="0"
                allow="fullscreen"
                id="iframeId"
              ></iframe>
            </div>
            <div v-if="type_id === 8">
              <iframe
                src="API/mixly2.0_src-master/boards/index.html?thirdPartyBoard=false&boardIndex=.%2Fboards%2Fdefault%2Fpython_skulpt%2Findex.xml&boardType=Python%203%20Lite&boardImg=.%2Fboards%2Fdefault%2Fpython_skulpt%2Fmedia%2Fwebpy.png&language=Python"
                scrolling="no"
                style="width: 100%; height: 500px"
                frameborder="0"
                allow="fullscreen"
                id="iframeId"
              ></iframe>
            </div>
            <div v-if="type_id === 26">
              <iframe
                src="API/mixly2.0_src-master/boards/index.html?thirdPartyBoard=false&boardIndex=.%2Fboards%2Fdefault%2Fpython_skulpt_car%2Findex.xml&boardType=Python%203%20Car&boardImg=.%2Fboards%2Fdefault%2Fpython_skulpt%2Fmedia%2Fwebpy.png&language=Python"
                scrolling="no"
                style="width: 100%; height: 500px"
                frameborder="0"
                allow="fullscreen"
                id="iframeId"
              ></iframe>
            </div>
            <div v-if="type_id === 24">
              <iframe
                src="API/mixly2.0_src-master/boards/index.html?thirdPartyBoard=false&boardIndex=.%2Fboards%2Fdefault%2Fmicropython_k210_mixgoai%2Findex.xml&boardType=MixGo%20AI&boardImg=.%2Fboards%2Fdefault%2Fmicropython_k210_mixgoai%2Fmedia%2Fmixgoai_compressed.png&language=MicroPython"
                scrolling="no"
                style="width: 100%; height: 500px"
                frameborder="0"
                allow="fullscreen"
                id="iframeId"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
    <EmptyFooter />
  </div>
</template>

<script>
import { myworkpage, indexpage } from "@/http/api.js"; // 导入接口
import { apply } from "@/http/api.js"; // 导入接口
import { deletework } from "@/http/api.js"; // 导入接口
import { adminAxiosUpload } from "@/http/api.js"; // 导入接口

import CommonLayout from "@/components/CommonLayout.vue";

import EmptyFooter from "@/components/EmptyFooter.vue";
import CommonStudent from "@/components/CommonStudent.vue";
import CommonTeacher from "@/components/CommonTeacher.vue";
export default {
  name: "coursepdf",
  data() {
    return {
      // loading: false,
      file_id: null, // 暂时获取不到
      fileData: null,
      fileList: [],
      imageUrl: "",
      limit: 1,
      photoHide: false,
      status: "",
      type2: "",

      work_id: null,
      name: "", //作品名称
      cover_url: "", //封面图片
      summary: "", //作业简介
      author_name: "", //作者名字
      add_time: "",
      click_num: "",
      type_id: null,
      avatar: null,
    };
  },

  mounted() {
    localStorage.setItem("showsubmit", 1);
    // this.iframeLoad();
    console.log(this.$route.params.id); //是workcenter传来的id，也可以是editwork返回来的id
    let token = JSON.parse(localStorage.getItem("Authorization"));
    let verify = localStorage.getItem("verify");
    myworkpage({
      work_id: this.$route.params.id,
      uid: token.user_id,
      schoolid: token.school_id,
      verify: verify,
    }).then((res) => {
      console.log("myworkpage", res);
      this.work_id = res.work_id;
      this.name = res.name;
      this.cover_url = res.cover_url;
      this.summary = res.summary;
      this.author_name = res.author_name;
      this.add_time = res.add_time;
      this.click_num = res.click_num;
      this.type_id = res.type_id;
      localStorage.setItem("file_id", JSON.stringify(res.file_id));
      localStorage.setItem("file_name", JSON.stringify(res.name + ".xml"));
      localStorage.setItem("ddl", false);
    });

    indexpage({ user_id: token.user_id }).then((res) => {
      console.log("res", res);
      this.logo = res.logo;
      this.webname = res.webname;
      this.introduction = res.introduction;
      this.course = res.course;
      this.real_name = token.realname;

      if (res.avatar === null) {
        this.avatar = require("../../src/assets/img/avatar.png");
      } else {
        this.avatar = res.avatar;
      }
    });
  },
  methods: {
    // iframeLoad(){
    //   this.loading = true;
    //   const iframe = this.$refs.iframeref;
    //   if (iframe.attachEvent) { // IE
    //     iframe.attachEvent('onload', () => { this.loading = false; });
    //   } else { // 非IE
    //     iframe.onload =  () => { this.loading = false; };
    //   }
    // },
    dianji() {
      //设置后就是id==con_lf_top_div 的容器全屏
      let case1 = document.getElementById("iframeId");
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (case1.requestFullscreen) {
          case1.requestFullscreen();
        } else if (case1.webkitRequestFullScreen) {
          case1.webkitRequestFullScreen();
        } else if (case1.mozRequestFullScreen) {
          case1.mozRequestFullScreen();
        } else if (case1.msRequestFullscreen) {
          // IE11
          case1.msRequestFullscreen();
        }
      }
    },

    removeFile(file, fileList) {
      this.photoHide = false;
    },
    handleFileUpload(item) {
      this.photoHide = true;

      var that = this; //避免this指针在后面的请求以后改变
      var fileObj = item.file;
      console.log("fileobj", fileObj);
      // FormData 对象
      var forms = new FormData();
      // 文件对象
      forms.append("file", fileObj);
      let token = JSON.parse(localStorage.getItem("Authorization"));
      forms.append("user_id", token.user_id);
      adminAxiosUpload(forms)
        .then((res) => {
          console.log(res);
          this.file_id = res.data.file_id;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  components: {
    EmptyFooter,
  },
};
</script>

<style></style>

<style lang="css" src="../../src/assets/.css/common.css" scoped></style>
<style
  lang="css"
  src="../../src/assets/.css/swiper-bundle.min.css"
  scoped
></style>
<style lang="css" src="../../src/assets/.css/index.css" scoped></style>
<style lang="css" src="../../src/assets/.css/page.css" scoped></style>
<style lang="css" scoped>
* {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
