<template>
  <div style="min-width: 1250px">
    <!--header begin-->
    <div class="header clearfix flex_start">
      <img class="logo" style="width: 320px" :src="logo" alt="" id="logo" />
      <div class="header_container flex_between">
        <ul class="nav">
          <li :style="language === 'en' ? 'width:100px' : 'width:100px'">
            <router-link active-class="current" to="/common">
              <img style="width: 25px" src="../assets/img/home.png" alt="" />
              <!-- <img src="../assets/img/nav_1_s.png" alt="" v-if="!userShow"> -->
              <span>{{ $t("message.home") }}</span>
            </router-link>
          </li>
          <li :style="language === 'en' ? 'width:180px' : 'width:140px'">
            <router-link active-class="current" to="/coursecenter">
              <img style="width: 30px" src="../assets/img/course.png" alt="" />
              <span>{{ $t("message.course") }}</span>
            </router-link>
            <!-- <a href="" style="">
                    <img src="../assets/img/nav_2.png" alt="">
                    <span>课程中心</span>
                </a> -->
          </li>
          <li :style="language === 'en' ? 'width:100px' : 'width:100px'">
            <router-link active-class="current" to="/taskcenter">
              <img
                style="width: 23px"
                src="../assets/img/assignment.png"
                alt=""
              />
              <span>{{ $t("message.task") }}</span>
            </router-link>
          </li>
          <!-- <li :style="language === 'en' ? 'width:100px' : 'width:100px'">
            <router-link active-class="current" to="/workcenter">
              <img style="width: 27px" src="../assets/img/work.png" alt="" />
              <span>{{ $t("message.work") }}</span>
            </router-link>
          </li> -->
          <!-- <li :style="language === 'en' ? 'width:220px' : 'width:140px'">
            <router-link active-class="current" to="/competitioncenter">
              <img
                style="width: 27px"
                src="../assets/img/competition.png"
                alt=""
              />
              <span>{{ $t("message.competition") }}</span>
            </router-link>
          </li> -->
          <!-- <li :style="language === 'en' ? 'width:100px' : 'width:100px'">

            <el-dropdown>
              <a href="#" style="">
                <img style="width:32px" src="../assets/img/tool.png" alt="">
                <span>{{ $t('message.tool') }}</span>
              </a>
              <el-dropdown-menu slot="dropdown" style="width:100px;padding-left:20px">
                <el-dropdown-item>文本编程</el-dropdown-item>
                <el-dropdown-item>Mixly编程</el-dropdown-item>
                <el-dropdown-item>物联网</el-dropdown-item>

              </el-dropdown-menu>
            </el-dropdown>
          </li> -->
          <li style="width: 100px" v-if="dev">
            <router-link active-class="current" to="/ai_teacher">
              <img
                style="width: 23px"
                src="../assets/img/assignment.png"
                alt=""
              />
              <span>AI教师</span>
            </router-link>
          </li>
        </ul>

        <div class="header_right login_field flex">
          <div style="margin-right: 20px; width: 120px">
            <el-select
              v-model="selectValue"
              @change="langChange"
              placeholder="语言"
            >
              <el-option
                style="padding-left: 20px"
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <!-- <a href="" class="register_link ">
                立即注册
            </a> -->
          <!-- <router-link to="/register" class="register_link ">立即注册</router-link> -->

          <!-- <div @click="login" style="width:100px" class="btn">登 录</div> -->
          <!-- <router-link to="/login" style="width:100px" class="btn">登录</router-link> -->

          <!-- el-dropdown-item  -->
          <el-dropdown>
            <img
              style="
                width: 50px;
                height: 50px;
                margin-top: 7px;
                margin-right: 10px;
              "
              :src="avatar"
              class="user"
              split-button="true"
            />
            <el-dropdown-menu style="margin-top: -10px" slot="dropdown">
              <el-dropdown-item
                style="width: 100px; padding-left: 20px"
                @click.native="info"
                >{{ $t("message.pi") }}</el-dropdown-item
              >
              <el-dropdown-item
                style="width: 100px; padding-left: 20px"
                @click.native="quit"
                >{{ $t("message.quit") }}</el-dropdown-item
              >
            </el-dropdown-menu>
            <el-popover
              placement="top-start"
              width="20"
              trigger="hover"
              :content="realname"
            >
              <div
                v-if="realname.length > 3"
                style="line-height: 70px; float: right"
                slot="reference"
              >
                {{ realname | ellipsis }}
              </div>
            </el-popover>
            <div
              v-if="realname.length <= 3"
              style="line-height: 70px; float: right"
            >
              {{ realname }}
            </div>
          </el-dropdown>
        </div>

        <!-- <div class="header_right login_field flex">
                <a href="" class="user flex_end">
                    <img class="userimg" src="" alt="">
                    <span></span>
                </a>
               
            </div> -->
      </div>
      <a class="mobile_only menu"> </a>
    </div>
    <!--header end-->
  </div>
</template>

<script>
import { indexpage, info, getparamfile } from "@/http/api.js"; // 导入接口
import { setback } from "@/http/api.js"; // 导入接口
import { config } from "@/http/api.js"; // 导入接口
export default {
  name: "CommonStudent",
  created() {
    // 判断开发模式
    config().then((res) => {
      this.dev = res.dev;
      console.log("dev", this.dev);
    });
    //已登录状态再进入登陆页面，直接跳转到管理首页。不允许登陆状态再次登陆
    let login = JSON.parse(localStorage.getItem("Authorization"));
    if (login !== null) {
      this.realname = login.realname;
    }
    let that = this;
    console.log(localStorage.lang);
    that.selectValue =
      localStorage.lang == undefined ? "中文" : localStorage.lang;
  },
  data() {
    return {
      dev: false,

      logo: "",
      realname: "",
      visible: false,
      selectValue: "",
      options: [
        {
          value: "zhcn",
          label: "中文",
        },
        {
          value: "en",
          label: "English",
        },
      ],
      language: "zhcn",
      avatar: "",
    };
  },
  filters: {
    ellipsis(value) {
      if (!value) return "";
      if (value.length > 3) {
        return value.slice(0, 3) + "...";
      }
      return value;
    },
  },
  methods: {
    // beforeunloadHandler() {
    //   this.beforeUnload_time = new Date().getTime();
    //   console.log('beforeUnload_time', this.beforeUnload_time)
    // },
    // unloadHandler() {
    //   this.gap_time = new Date().getTime() - this.beforeUnload_time;
    //   console.log('gap_time', this.gap_time)
    //   if (this.gap_time <= 20) {

    //     localStorage.clear();
    //     console.log('localstorage', localStorage)
    //   } else {
    //   }
    // },
    langChange(item) {
      console.log(item);
      localStorage.setItem("lang", item);
      this.$i18n.locale = item;
      this.language = item;
    },
    quit() {
      this.$router.push({ name: "common" });
      localStorage.clear();
      window.location.reload();
    },
    info() {
      this.$router.push({ name: "usercenter" });
    },
  },
  // destroyed() {
  //   window.removeEventListener("beforeunload", () => this.beforeunloadHandler());
  //   window.removeEventListener("unload", () => this.unloadHandler());
  // },
  mounted() {
    // window.addEventListener("beforeunload", () => this.beforeunloadHandler());
    // window.addEventListener("unload", () => this.unloadHandler());
    getparamfile({
      name: "front_logo",
    }).then((res) => {
      console.log("page", res);
      this.logo = res.value;
    });
    let token = JSON.parse(localStorage.getItem("Authorization"));
    let verify = localStorage.getItem("verify");
    indexpage({
      user_id: token.user_id,
      school_id: token.school_id,
      verify: verify,
    }).then((res) => {
      console.log("res", res);
      // this.logo = res.logo
      this.webname = res.webname;
      this.introduction = res.introduction;
      this.course = res.course;
      this.real_name = token.realname;

      // if (res.avatar === null) {
      //   this.avatar = require('../../src/assets/img/avatar.png')
      // } else {
      //   this.avatar = res.avatar
      // }
    });
    this.language = localStorage.getItem("lang");

    info({
      user_id: token.user_id,
      uid: token.user_id,
      schoolid: token.school_id,
      verify: verify,
    }).then((res) => {
      console.log("user11", res);

      // this.avatar_url = res.avatar_url

      if (res.avatar_id === null) {
        this.avatar = require("../../src/assets/img/avatar.png");
      } else {
        this.avatar = res.avatar_url;
      }
    });
  },
};
</script>

<style scoped lang="css">
/* @import url('../../src/assets/css/common.css');
@import url('../../src/assets/css/swiper-bundle.min.css');
@import url('../../src/assets/css/index.css');
@import url('../../src/assets/css/page.css'); */
</style>

<style lang="css" src="../../src/assets/.css/common.css" scoped></style>
<style lang="css" src="../../src/assets/.css/index.css" scoped></style>
<style lang="css" src="../../src/assets/.css/page.css" scoped></style>
