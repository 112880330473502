<template>
  <div class="loginBody registerBody commonBody">

    <body>
      <div>
        <div>
          <div class="top wrapper">
            <div class="flex_between">
              <span></span>
              <router-link class="btn btn_light" style=";width:80px" to="/login">登录</router-link>
            </div>
            <div class="flex_between">
              <!-- logo图片 -->
              <img style="width:400px;margin:0 auto;" class="logo" :src="logo" alt="" id="logo" />

            </div>
           

          </div>
          <div class="register_cotainer" >
            <div class="box register_box">
              <h2>注册</h2>
              <div>
                <div class="form_feild">
                  <div class="cell">
                    <!-- 表单 -->

                    <div class="cell_hd"><span style="color:red;">* </span>姓名</div>
                    <el-form :model="form" :rules="rules" ref="form">
                      <el-form-item prop="realname">
                        <el-input v-model="form.realname" placeholder="请输入" style="width: 390px" size="medium"></el-input>
                      </el-form-item>
                    </el-form>
                  </div>
                  <div class="cell">
                    <div class="cell_hd">密保问题</div>
                    <div class="cell_bd">
                      <el-select size="medium" v-model="form.question_id" placeholder="请选择" style="width: 390px">
                        <el-option style="padding-left: 20px" v-for="(item, index) in questions" :key="index"
                          :value="item.id" :label="item.question">
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                  <div class="cell">
                    <div class="cell_hd">密保问题答案</div>
                    <div class="cell_bd">
                      <input type="text" style="height: 36px" v-model="form.answer" placeholder="请输入" />
                    </div>
                  </div>

                  <div class="cell">
                    <div class="cell_hd"><span style="color:red;">* </span>所在学校</div>
                    <div class="cell_bd select_sf">
                      <el-select size="medium" v-model="form.school_id" placeholder="请选择" style="width: 390px">
                        <el-option style="padding-left: 20px" v-for="(item, index) in schools" :key="index"
                          :label="item.name" :value="item.id"></el-option>
                      </el-select>
                    </div>
                  </div>

                  <div class="cell">
                    <div class="cell_hd"><span style="color:red;">* </span>身份选择</div>
                    <div class="cell_bd select_sf">
                      <el-select style="height: 36px" v-model="form.type" placeholder="请选择">
                        <el-option style="padding-left: 40px" v-for="(item, index) in list" :key="index"
                          :label="item.name" :value="item.id"></el-option>
                      </el-select>
                      <el-form :model="form" :rules="rules" ref="form">
                        <el-form-item prop="account">
                          <el-input v-model="form.account" placeholder="学号或教工号" style="width: 270px"></el-input>
                        </el-form-item>
                      </el-form>
                    </div>
                  </div>
<!-- 
                  <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" style="margin-left: -140px;"
                    label-width="200px" class="demo-ruleForm">

                    <el-form-item prop="password" label='登陆密码' style="margin-left: 40px; width: 500px;margin-top:20px">
                      <el-input type="password" style="margin-left: 10px; " v-model="ruleForm.password"
                        autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item prop="checkPass" label='确认密码'
                      style="margin-left: 40px; margin-bottom:20px;width: 500px;margin-top:20px">
                      <el-input type="password" style="margin-left: 10px; " v-model="ruleForm.checkPass"
                        autocomplete="off"></el-input>
                    </el-form-item>
                  </el-form> -->
                  <div class="cell">
                    <div class="cell_hd"><span style="color:red;">* </span>登陆密码</div>
                    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm">
                      <el-form-item prop="password">
                        <el-input v-model="ruleForm.password" placeholder="请输入" style="width: 390px" size="medium"></el-input>
                      </el-form-item>
                    </el-form>
                  </div>
                  <div class="cell">
                    <div class="cell_hd"><span style="color:red;">* </span>确认密码</div>
                    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm">
                      <el-form-item prop="checkPass">
                        <el-input v-model="ruleForm.checkPass" placeholder="请输入" style="width: 390px" size="medium"></el-input>
                      </el-form-item>
                    </el-form>
                  </div>

                  <!-- <div class="cell cell_code">
                    <div class="cell_hd"><span style="color:red;">* </span>验证码</div>
                    <div class="cell_bd">
                      <el-form :model="form" :rules="rules" ref="form">
                        <el-form-item prop="verifyCode">
                          <el-input v-model="form.verifyCode" placeholder="验证码" style="width: 270px"></el-input>
                        </el-form-item>
                      </el-form>
                      <div @click="click()">
                        <img :src="verifycode" />
                      </div>
                    </div>
                  </div> -->
                  <!-- <div class="xieyi">
                    <input type="checkbox" name="XcUser[rememberMe]" checked id="c01" value="1" />
                    <label for="c01" class="flex">我同意<a href="javascript:;">注册协议</a></label>
                  </div> -->
                </div>
              </div>
              <div @click="send()">
                <el-alert v-if="n === 1" type="error" center show-icon>{{ msg }}
                </el-alert>
              </div>

              <button @click="send()" class="btn" style="font-size:15px;;width:300px" name="login-button" id="loginbtn">
                完成注册
              </button>
              <br />
            </div>
            <div></div>
          </div>
        </div>
        <div class="footer">
          <p>{{ copyright }}</p>
          <p>支持电话：{{ tel }}</p>
        </div>
      </div>
    </body>
  </div>
</template>

<script>
import { register, registerpage, getparam, getparamfile } from "@/http/api.js"; // 导入接口
import FingerprintJS from "@fingerprintjs/fingerprintjs";
export default {
  name: "registor",
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
   
    return {
      copyright: "",
      tel: null,
      ruleForm: {
        old: '',
        password: '',
        checkPass: '',
        realname: '',
        account: '',

      },
      select: "",
      n: 0,
      logo: "",
      // verifycode: "",
      questions: [],
      schools: [],
      list: [
        { id: 1, name: "学生" },
        { id: 2, name: "老师" },
      ],
      status: "",
      msg: "",
      form: {

      },
      rules: {
        checkPass: [
          { required: true, validator: validatePass2,trigger: 'blur' }
        ],
        password: [
          {
            required: true,
            min: 6,
            message: "不得少于6个字符",
          },
        ],
        account: [
          { required: true, message: "请输入用户账户", trigger: "blur" },
        ],
        realname: [
          { required: true, message: "请输入用户名称", trigger: "blur" },
        ],
        // verifyCode: [
        //   {
        //     required: true,
        //     length: 4,
        //     message: "为四个字符",
        //   },
        // ],
      },
    };
  },
  created() {
    // FingerprintJS.load().then((fp) => {
    //   fp.get().then((result) => {
    //     this.$global.clientUid = result.visitorId;
    //   });
    // });
  },
  methods: {
    
    clearCookie() {
      this.setCookie("", "", -1); // 清空并设置天数为负1天
    },
    setCookie(account, password, exdays) {
      var exdate = new Date(); // 获取当前登录的时间
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); // 将当前登录的时间加上七天，就是cookie过期的时间，也就是保存的天数
      // 字符串拼接cookie,因为cookie存储的形式是name=value的形式
      window.document.cookie = "account" + "=" + account + ";path=/;expires=" + exdate.toGMTString();
      window.document.cookie = "password" + "=" + password + ";path=/;expires=" + exdate.toGMTString();
      window.document.cookie = "isRemember" + "=" + this.isRemember + ";path=/;expires=" + exdate.toGMTString();
    },
    // click() {
    //   verifycode({ clientUid: this.$global.clientUid }).then((res) => {
    //     this.verifycode = res.verifycode;
    //   });
    // },
    send() {

      if (this.ruleForm.password === this.ruleForm.checkPass && this.ruleForm.password.length>=6) {
        register({
          account: this.form.account,
          question_id: this.form.question_id,
          answer: this.form.answer,
          school_id: this.form.school_id,
          type: this.form.type,
          password: this.ruleForm.password,
          // verifycode: this.form.verifyCode,
          realname: this.form.realname,
          // clientUid: this.$global.clientUid,
        }).then(
          (res) => {

            if (res.status == 1) {
              this.success = 1;
              this.$alert("" + "注册成功", "提示", {
                confirmButtonText: "确定",
                showConfirmButton: false,
                showCancelButton: false,
                showClose: false,

              });
              setTimeout(() => {
                this.$msgbox.close();
                this.$router.push({ name: "login" });
                this.clearCookie();
              }, 2000);

            }
            if (res.status != 1) {
              this.$alert("" + res.msg, "错误提示", {
                confirmButtonText: "确定",
                beforeClose: (action, instance, done) => {
                  if (action === "confirm") {
                    instance.confirmButtonLoading = true;
                    instance.confirmButtonText = "执行中...";
                    // setTimeout(() => {
                    //   done();
                    //   setTimeout(() => {
                    //     instance.confirmButtonLoading = false;
                    //     instance.confirmButtonLoading = false;
                    //     verifycode({ clientUid: this.$global.clientUid }).then(
                    //       (res) => {
                    //         this.verifycode = res.verifycode;
                    //       }
                    //     );
                    //   }, 100);
                    // }, 100);
                  } else {
                    done();
                  }
                },
              });
            }
          },
          function (error) { }
        );
      }
      if(this.ruleForm.password !== this.ruleForm.checkPass ) {
        this.$alert("" + '密码输入不一致', "提示", {
          confirmButtonText: "确定",
          showConfirmButton: false,
        });
        setTimeout(() => {
          this.$msgbox.close();
        }, 2000);
      }
      if(this.ruleForm.password.length < 6) {
        this.$alert("" + '密码不得少于6个字符', "提示", {
          confirmButtonText: "确定",
          showConfirmButton: false,
        });
        setTimeout(() => {
          this.$msgbox.close();
        }, 2000);
      }
    },
    keyDown(e) {
      // 回车则执行登录方法 enter键的ASCII是13
      if (e.keyCode === 13) {
        this.send() // 需要执行的方法方法
      }
    },
  },
  mounted() {
    window.addEventListener('keydown', this.keyDown)
    setTimeout(() => {
      getparam({
        name: "tel",

      }).then((res) => {
        console.log('page', res)
        this.tel = res.value


      });
      getparam({
        name: "copyright",

      }).then((res) => {
        console.log('page', res)
        this.copyright = res.value


      });
      getparamfile({
        name: "logo",

      }).then((res) => {
        console.log('page', res)
        this.logo = res.value



      });
      registerpage({  }).then((res) => {

        this.questions = res.questions;
        this.schools = res.schools;
        // this.schools.unshift(...[{ id: 0, name: "--" }]);
      });
    }, 200);


  },
  destroyed() {
    window.removeEventListener('keydown', this.keyDown, false)
  }
};
</script>

<style scoped lang="css">
.select_sf {
  flex-grow: initial;
}
</style>

<style lang="css"  src='../../src/assets/.css/common.css'  scoped></style>
<style lang="css"  src='../../src/assets/.css/login.css'  scoped></style>
<style lang="css"  src='../../src/assets/.css/register.css'  scoped></style>
<style lang="css" scoped>
* {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}
</style>

