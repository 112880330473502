<template>
  <div>
    <div style="position: absolute; min-width: 1250px">
      <div class="img_title flex_center">
        <img
          style="width: 100%"
          src="../../src/assets/img/task_center_bg.png"
          alt=""
        />
      </div>
      <div class="wrapper">
        <div
          style="margin-left: 8px"
          class="wrapper course_filter flex_between"
        >
          <ul class="tab list_tab" style="text-align: left">
            <li>
              <a
                href="javascript:;"
                @click="zuoye"
                :class="n === 1 ? 'current' : ''"
                >{{ $t("message.homeworktask") }}</a
              >
            </li>
            <li>
              <a
                href="javascript:;"
                @click="ceshi"
                :class="n === 2 ? 'current' : ''"
                >{{ $t("message.testtask") }}</a
              >
            </li>
            <li>
              <a
                href="javascript:;"
                @click="wenjuan"
                :class="n === 3 ? 'current' : ''"
                >{{ $t("message.questiontask") }}</a
              >
            </li>
          </ul>
        </div>
        <ul v-if="n === 1" class="tab list_tab" style="text-align: left">
          <li style="margin-top: 7px">{{ $t("message.select") }}:</li>
          <li>
            <el-select
              size="medium"
              v-model="jindu"
              :placeholder="$t('message.compolish')"
              style="width: 150px"
            >
              <el-option
                v-for="(item, index) in list"
                :key="index"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </li>
          <li>
            <el-select
              size="medium"
              v-model="course"
              :placeholder="$t('message.coursename')"
              style="width: 150px"
            >
              <el-option
                v-for="(item, index) in courses"
                :key="index"
                :label="item.title"
                :value="item.title"
              ></el-option>
            </el-select>
          </li>
          <li>
            <el-select
              size="medium"
              v-model="type"
              :placeholder="$t('message.tasktype')"
              style="width: 150px"
            >
              <el-option
                v-for="(item, index) in module_type"
                :key="index"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </li>
          <li>
            <el-button type="primary" class="edit" @click="select">{{
              $t("message.confirm")
            }}</el-button>
          </li>
          <li>
            <el-button
              type="primary"
              class="edit"
              @click="reset"
              style="margin-left: -18px"
              >{{ $t("message.reset") }}</el-button
            >
          </li>
        </ul>
        <ul v-if="n === 2" class="tab list_tab" style="text-align: left">
          <li style="margin-top: 7px">{{ $t("message.select") }}:</li>
          <li>
            <el-select
              size="medium"
              v-model="jindu"
              :placeholder="$t('message.compolish')"
              style="width: 150px"
            >
              <el-option
                v-for="(item, index) in list"
                :key="index"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </li>
          <li>
            <el-select
              size="medium"
              v-model="course"
              :placeholder="$t('message.coursename')"
              style="width: 150px"
            >
              <el-option
                v-for="(item, index) in courses"
                :key="index"
                :label="item.title"
                :value="item.title"
              ></el-option>
            </el-select>
          </li>

          <li>
            <el-button type="primary" class="edit" @click="select">{{
              $t("message.confirm")
            }}</el-button>
          </li>
          <li>
            <el-button
              type="primary"
              class="edit"
              @click="reset"
              style="margin-left: -18px"
              >{{ $t("message.reset") }}</el-button
            >
          </li>
        </ul>
        <ul v-if="n === 3" class="tab list_tab" style="text-align: left">
          <li style="margin-top: 7px">{{ $t("message.select") }}:</li>
          <li>
            <el-select
              size="medium"
              v-model="jindu"
              :placeholder="$t('message.compolish')"
              style="width: 150px"
            >
              <el-option
                v-for="(item, index) in list"
                :key="index"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </li>
          <li>
            <el-select
              size="medium"
              v-model="course"
              :placeholder="$t('message.coursename')"
              style="width: 150px"
            >
              <el-option
                v-for="(item, index) in courses"
                :key="index"
                :label="item.title"
                :value="item.title"
              ></el-option>
            </el-select>
          </li>

          <li>
            <el-button type="primary" class="edit" @click="select">{{
              $t("message.confirm")
            }}</el-button>
          </li>
          <li>
            <el-button
              type="primary"
              class="edit"
              @click="reset"
              style="margin-left: -18px"
              >{{ $t("message.reset") }}</el-button
            >
          </li>
        </ul>
      </div>
      <div class="main" style="width: 90%; margin-left: 5%; margin-right: 5%">
        <el-table
          :data="
            result
              ? result.slice(
                  (currentPage - 1) * pageSize,
                  currentPage * pageSize
                )
              : null
          "
          style="width: 100%; margin-top: 30px"
        >
          <el-table-column
            prop="name"
            :label="$t('message.taskname')"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="course_name"
            :label="$t('message.taskcourse')"
            width="150"
          >
            <template slot-scope="scope">
              <span style="" plain>{{ scope.row.course_title }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="begin_time" :label="$t('message.addtime')">
          </el-table-column>
          <el-table-column prop="end_time" :label="$t('message.endtime')">
          </el-table-column>

          <el-table-column prop="module_type" :label="$t('message.type')">
          </el-table-column>
          <el-table-column prop="grade" :label="$t('message.grade')">
          </el-table-column>
          <el-table-column :label="$t('message.operation')">
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.submit_time === null"
                class="click"
                style=""
                @click="handleClick(scope.row)"
                type="primary"
                plain
                >{{ $t("message.submit") }}</el-button
              >
              <el-button
                v-if="scope.row.submit_time !== null"
                class="click"
                style=""
                @click="handleClick(scope.row)"
                type="primary"
                plain
                >{{ $t("message.chakan") }}</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div style="position: relative; top: 640px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[4, 8, 12, 16]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="result ? result.length : 0"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
var _self;
import arrayToTree from "array-to-tree";

import { taskpage } from "@/http/api.js"; // 导入接口

import CommonLayout from "@/components/CommonLayout.vue";

import CommonFooter from "@/components/CommonFooter.vue";
import CommonStudent from "@/components/CommonStudent.vue";
import CommonTeacher from "@/components/CommonTeacher.vue";
export default {
  name: "coursepdf",
  data() {
    return {
      status: "",
      type2: "",

      courses: [],
      module_type: [],
      tasks: [],
      list: [
        { id: 1, name: "全部" },
        { id: 2, name: "已完成" },
        { id: 3, name: "未完成" },
      ],
      result: [],

      jindu: "",
      course: "",
      type: "",

      n: 1,
      currentPage: 1,
      pageSize: 4,
      operation: null,
      tests: [],
      questionnaires: [],
    };
  },
  beforeCreate() {
    _self = this;
  },
  methods: {
    zuoye() {
      this.active = "current";
      this.n = 1;
      this.result = this.tasks;
      console.log("zuoye", this.result);
    },
    ceshi() {
      this.active = "current";
      this.n = 2;
      this.result = this.tests;
      if (this.result != null) {
        this.result = this.tests.filter(function (num) {
          return num.module_type === "测试";
        });
      }

      console.log("ceshi", this.result);
    },
    wenjuan() {
      this.active = "current";
      this.n = 3;
      this.result = this.tests;
      if (this.result !== null) {
        this.result = this.tests.filter(function (num) {
          return num.module_type === "问卷";
        });
      }
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
    },
    handleClick(row) {
      console.log("row", row);
      if (
        row.module_type === "编程任务-mithonCC" ||
        row.module_type === "编程任务-microbit" ||
        row.module_type === "编程任务-mixgoCE" ||
        row.module_type === "编程任务-python3" ||
        row.module_type === "编程任务-mithon" ||
        row.module_type === "自动测评任务" ||
        row.module_type === "一般任务-文件" ||
        row.module_type === "一般任务-富文本" ||
        row.module_type == "编程任务-mixgoAI" ||
        row.module_type == "编程任务-mixgoCar"
      ) {
        this.$router.push({
          name: "coursehomework",
          params: { id: row.module_id, type: row.module_type, back2: 3 },
        });
      }
      if (row.module_type === "测试") {
        this.$router.push({
          name: "coursetest",
          params: { id: row.module_id, kcid: row.course_id, back2: 3 },
        });
      }
      if (row.module_type === "问卷") {
        this.$router.push({
          name: "coursequestion",
          params: { id: row.module_id, kcid: row.course_id, back2: 3 },
        });
      }
      if (row.module_type === "讨论") {
        this.$router.push({
          name: "coursediscussion",
          params: { id: row.module_id, kcid: row.course_id, back2: 3 },
        });
      }
    },

    select() {
      if (this.tests !== null && this.n === 2) {
        if (this.course === "" && this.jindu === "") {
          this.result = this.tests.filter(function (num) {
            return num.module_type === "测试";
          });
        }
        if (this.course !== "" && this.jindu !== "") {
          this.result = this.tests;
          if (this.course === "全部" && this.jindu === "全部") {
            this.result = this.tests.filter(function (num) {
              return num.module_type === "测试";
            });
          }

          if (this.course === "全部" && this.jindu !== "全部") {
            if (this.jindu === "已完成") {
              this.result = this.tests.filter(function (num) {
                // console.log(_self.course);
                return num.submit_time !== null && num.module_type === "测试";
              });
            }
            if (this.jindu === "未完成") {
              this.result = this.tests.filter(function (num) {
                // console.log(_self.course);
                return num.submit_time === null && num.module_type === "测试";
              });
            }
          }

          if (this.course !== "全部" && this.jindu === "全部") {
            this.result = this.tests.filter(function (num) {
              return (
                num.course_title === _self.course && num.module_type === "测试"
              );
            });
          }

          if (this.course !== "全部" && this.jindu !== "全部") {
            if (this.jindu === "已完成") {
              this.result = this.tests.filter(function (num) {
                // console.log(_self.course);
                return (
                  num.submit_time !== null &&
                  num.course_title === _self.course &&
                  num.module_type === "测试"
                );
              });
            }
            if (this.jindu === "未完成") {
              this.result = this.tests.filter(function (num) {
                // console.log(_self.course);
                return (
                  num.submit_time === null &&
                  num.course_title === _self.course &&
                  num.module_type === "测试"
                );
              });
            }
          }
        }
        if (this.course === "" && this.jindu !== "") {
          this.result = this.tests;
          if (this.jindu === "已完成") {
            this.result = this.tests.filter(function (num) {
              return num.submit_time !== null && num.module_type === "测试";
            });
          }
          if (this.jindu === "未完成") {
            this.result = this.tests.filter(function (num) {
              // console.log(_self.course);
              return num.submit_time === null && num.module_type === "测试";
            });
          }
          if (this.jindu === "全部") {
            this.result = this.tests.filter(function (num) {
              return _self.tests && num.module_type === "测试";
            });
          }
        }
        if (this.course !== "" && this.jindu === "") {
          this.result = this.tests;
          this.result = this.tests.filter(function (num) {
            console.log("this.course", _self.course);
            if (_self.course !== "全部") {
              return (
                num.course_title === _self.course && num.module_type === "测试"
              );
            } else {
              return _self.tests && num.module_type === "测试";
            }
          });
        }
      }
      if (this.tests !== null && this.n === 3) {
        if (this.course === "" && this.jindu === "") {
          this.result = this.tests.filter(function (num) {
            return num.module_type === "问卷";
          });
        }
        if (this.course !== "" && this.jindu !== "") {
          this.result = this.tests;
          if (this.course === "全部" && this.jindu === "全部") {
            this.result = this.tests.filter(function (num) {
              return num.module_type === "问卷";
            });
          }

          if (this.course === "全部" && this.jindu !== "全部") {
            if (this.jindu === "已完成") {
              this.result = this.tests.filter(function (num) {
                // console.log(_self.course);
                return num.submit_time !== null && num.module_type === "问卷";
              });
            }
            if (this.jindu === "未完成") {
              this.result = this.tests.filter(function (num) {
                // console.log(_self.course);
                return num.submit_time === null && num.module_type === "问卷";
              });
            }
          }

          if (this.course !== "全部" && this.jindu === "全部") {
            this.result = this.tests.filter(function (num) {
              return (
                num.course_title === _self.course && num.module_type === "问卷"
              );
            });
          }

          if (this.course !== "全部" && this.jindu !== "全部") {
            if (this.jindu === "已完成") {
              this.result = this.tests.filter(function (num) {
                // console.log(_self.course);
                return (
                  num.submit_time !== null &&
                  num.course_title === _self.course &&
                  num.module_type === "问卷"
                );
              });
            }
            if (this.jindu === "未完成") {
              this.result = this.tests.filter(function (num) {
                // console.log(_self.course);
                return (
                  num.submit_time === null &&
                  num.course_title === _self.course &&
                  num.module_type === "问卷"
                );
              });
            }
          }
        }
        if (this.course === "" && this.jindu !== "") {
          this.result = this.tests;
          if (this.jindu === "已完成") {
            this.result = this.tests.filter(function (num) {
              return num.submit_time !== null && num.module_type === "问卷";
            });
          }
          if (this.jindu === "未完成") {
            this.result = this.tests.filter(function (num) {
              // console.log(_self.course);
              return num.submit_time === null && num.module_type === "问卷";
            });
          }
          if (this.jindu === "全部") {
            this.result = this.tests.filter(function (num) {
              return _self.tests && num.module_type === "问卷";
            });
          }
        }
        if (this.course !== "" && this.jindu === "") {
          this.result = this.tests;
          this.result = this.tests.filter(function (num) {
            console.log("this.course", _self.course);
            if (_self.course !== "全部") {
              return (
                num.course_title === _self.course && num.module_type === "问卷"
              );
            } else {
              return _self.tests && num.module_type === "问卷";
            }
          });
        }
      }
      // if (this.questionnaires !== null && this.n === 3) {
      //   if (this.course === "" && this.jindu === "") {
      //     this.result = this.questionnaires;

      //   }
      //   if (this.course !== "" && this.jindu !== "") {
      //     this.result = this.questionnaires;
      //     if (this.course === "全部" && this.jindu === "全部") {
      //       this.result = this.questionnaires;
      //     }

      //     if (this.course === "全部" && this.jindu !== "全部") {
      //       if (this.jindu === "已完成") {
      //         this.result = this.questionnaires.filter(function (num) {
      //           // console.log(_self.course);
      //           return num.submit_time !== null;
      //         });
      //       }
      //       if (this.jindu === "未完成") {
      //         this.result = this.questionnaires.filter(function (num) {
      //           // console.log(_self.course);
      //           return num.submit_time === null;
      //         });
      //       }
      //     }

      //     if (this.course !== "全部" && this.jindu === "全部") {
      //       return num.course_title === _self.course;

      //     }

      //     if (this.course !== "全部" && this.jindu !== "全部") {
      //       if (this.jindu === "已完成") {
      //         this.result = this.questionnaires.filter(function (num) {
      //           // console.log(_self.course);
      //           return num.submit_time !== null && num.course_title === _self.course;
      //         });
      //       }
      //       if (this.jindu === "未完成") {
      //         this.result = this.questionnaires.filter(function (num) {
      //           // console.log(_self.course);
      //           return num.submit_time === null && num.course_title === _self.course;
      //         });
      //       }

      //     }

      //   }
      //   if (this.course === "" && this.jindu !== "") {
      //     this.result = this.questionnaires;
      //     if (this.jindu === "已完成") {
      //       this.result = this.questionnaires.filter(function (num) {
      //         return num.submit_time !== null;
      //       });
      //     }
      //     if (this.jindu === "未完成") {
      //       this.result = this.questionnaires.filter(function (num) {
      //         // console.log(_self.course);
      //         return num.submit_time === null;
      //       });
      //     }
      //     if (this.jindu === "全部") {
      //       return _self.questionnaires
      //     }
      //   }
      //   if (this.course !== "" && this.jindu === "") {
      //     this.result = this.questionnaires;
      //     this.result = this.questionnaires.filter(function (num) {
      //       console.log('this.course', _self.course);
      //       if (_self.course !== "全部") {
      //         return num.course_title === _self.course;
      //       } else {
      //         return _self.questionnaires;
      //       }
      //     });
      //   }
      // }

      if (this.tasks !== null && this.n === 1) {
        if (this.course === "" && this.jindu === "" && this.type != "") {
          this.result = this.tasks;
          this.result = this.tasks.filter(function (num) {
            if (_self.type !== "全部") {
              return num.module_type === _self.type;
            } else {
              return _self.tasks;
            }
          });
        }

        if (this.course !== "" && this.jindu === "" && this.type === "") {
          this.result = this.tasks;
          this.result = this.tasks.filter(function (num) {
            console.log("this.course", _self.course);
            if (_self.course !== "全部") {
              return num.course_title === _self.course;
            } else {
              return _self.tasks;
            }
          });
        }
        if (this.course === "" && this.jindu !== "" && this.type === "") {
          this.result = this.tasks;
          if (this.jindu === "已完成") {
            this.result = this.tasks.filter(function (num) {
              // console.log(_self.course);
              return num.submit_time !== null;
            });
          }
          if (this.jindu === "未完成") {
            this.result = this.tasks.filter(function (num) {
              // console.log(_self.course);
              return num.submit_time === null;
            });
          }
          if (this.jindu === "全部") {
            return _self.tasks;
          }
        }

        if (this.course !== "" && this.jindu === "" && this.type != "") {
          this.result = this.tasks;
          if (_self.type !== "全部" && _self.course !== "全部") {
            this.result = this.tasks.filter(function (num) {
              return (
                num.module_type === _self.type &&
                num.course_title === _self.course
              );
            });
          }
          if (_self.type === "全部" && _self.course !== "全部") {
            this.result = this.tasks.filter(function (num) {
              return num.course_title === _self.course;
            });
          }
          if (_self.type !== "全部" && _self.course === "全部") {
            this.result = this.tasks.filter(function (num) {
              return num.module_type === _self.type;
            });
          }
          if (_self.type === "全部" && _self.course === "全部") {
            this.result = this.tasks.filter(function (num) {
              return this.tasks;
            });
          }
        }
        if (this.course !== "" && this.jindu !== "" && this.type != "") {
          this.result = this.tasks;
          if (this.jindu === "已完成") {
            if (_self.course === "全部" && _self.type === "全部") {
              this.result = this.tasks.filter(function (num) {
                return num.submit_time !== null;
              });
            }
            if (_self.course !== "全部" && _self.type === "全部") {
              this.result = this.tasks.filter(function (num) {
                return (
                  num.submit_time !== null && num.course_title === _self.course
                );
              });
            }
            if (_self.course === "全部" && _self.type !== "全部") {
              this.result = this.tasks.filter(function (num) {
                return (
                  num.submit_time !== null && num.module_type === _self.type
                );
              });
            }
            if (_self.course !== "全部" && _self.type !== "全部") {
              this.result = this.tasks.filter(function (num) {
                return (
                  num.submit_time !== null &&
                  num.module_type === _self.type &&
                  num.course_title === _self.course
                );
              });
            }
          }
          if (this.jindu === "未完成") {
            if (_self.course === "全部" && _self.type === "全部") {
              this.result = this.tasks.filter(function (num) {
                return num.submit_time === null;
              });
            }
            if (_self.course !== "全部" && _self.type === "全部") {
              this.result = this.tasks.filter(function (num) {
                return (
                  num.submit_time === null && num.course_title === _self.course
                );
              });
            }
            if (_self.course === "全部" && _self.type !== "全部") {
              this.result = this.tasks.filter(function (num) {
                return (
                  num.submit_time === null && num.module_type === _self.type
                );
              });
            }
            if (_self.course !== "全部" && _self.type !== "全部") {
              this.result = this.tasks.filter(function (num) {
                return (
                  num.submit_time === null &&
                  num.module_type === _self.type &&
                  num.course_title === _self.course
                );
              });
            }
          }
          if (this.jindu === "全部") {
            if (_self.course === "全部" && _self.type === "全部") {
              this.result = this.tasks.filter(function (num) {
                return this.tasks;
              });
            }
            if (_self.course !== "全部" && _self.type === "全部") {
              this.result = this.tasks.filter(function (num) {
                return num.course_title === _self.course;
              });
            }
            if (_self.course === "全部" && _self.type !== "全部") {
              this.result = this.tasks.filter(function (num) {
                return num.module_type === _self.type;
              });
            }
            if (_self.course !== "全部" && _self.type !== "全部") {
              this.result = this.tasks.filter(function (num) {
                return (
                  num.module_type === _self.type &&
                  num.course_title === _self.course
                );
              });
            }
          }
        }
        if (this.course !== "" && this.jindu !== "" && this.type === "") {
          this.result = this.tasks;
          if (this.jindu === "已完成") {
            if (_self.course === "全部") {
              this.result = this.tasks.filter(function (num) {
                return num.submit_time !== null;
              });
            }
            if (_self.course !== "全部") {
              this.result = this.tasks.filter(function (num) {
                return (
                  num.submit_time !== null && num.course_title === _self.course
                );
              });
            }
          }
          if (this.jindu === "未完成") {
            if (_self.course === "全部") {
              this.result = this.tasks.filter(function (num) {
                return num.submit_time === null;
              });
            }
            if (_self.course !== "全部") {
              this.result = this.tasks.filter(function (num) {
                return (
                  num.submit_time === null && num.course_title === _self.course
                );
              });
            }
          }
          if (this.jindu === "全部") {
            if (_self.course === "全部") {
              this.result = this.tasks.filter(function (num) {
                return this.tasks;
              });
            }
            if (_self.course !== "全部") {
              this.result = this.tasks.filter(function (num) {
                return num.course_title === _self.course;
              });
            }
          }
        }

        if (this.course === "" && this.jindu !== "" && this.type !== "") {
          this.result = this.tasks;
          if (this.jindu === "已完成") {
            if (_self.type === "全部") {
              this.result = this.tasks.filter(function (num) {
                return num.submit_time !== null;
              });
            }
            if (_self.type !== "全部") {
              this.result = this.tasks.filter(function (num) {
                return (
                  num.submit_time !== null && num.module_type === _self.type
                );
              });
            }
          }
          if (this.jindu === "未完成") {
            if (_self.type === "全部") {
              this.result = this.tasks.filter(function (num) {
                return num.submit_time === null;
              });
            }
            if (_self.type !== "全部") {
              this.result = this.tasks.filter(function (num) {
                return (
                  num.submit_time === null && num.module_type === _self.type
                );
              });
            }
          }
          if (this.jindu === "全部") {
            if (_self.type === "全部") {
              this.result = this.tasks.filter(function (num) {
                return this.tasks;
              });
            }
            if (_self.type !== "全部") {
              this.result = this.tasks.filter(function (num) {
                return num.module_type === _self.type;
              });
            }
          }
        }
      }
    },
    reset() {
      if (this.n === 1) {
        this.course = "";
        this.jindu = "";
        this.type = "";
        this.result = this.tasks;
      }
      if (this.n === 2) {
        this.course = "";
        this.jindu = "";
        this.type = "";
        this.result = this.tests;
      }
      if (this.n === 3) {
        this.course = "";
        this.jindu = "";
        this.type = "";
        this.result = this.questionnaires;
      }
    },
  },
  mounted() {
    let token = JSON.parse(localStorage.getItem("Authorization"));
    let verify = localStorage.getItem("verify");
    taskpage({
      user_id: token.user_id,
      school_id: token.school_id,
      uid: token.user_id,
      schoolid: token.school_id,
      verify: verify,
    }).then((res) => {
      console.log("task", res);
      this.courses = res.course;
      if (res.type !== null) {
        res.type.shift();
      }

      this.module_type = res.type;
      this.tasks = res.tasks;
      this.result = this.tasks;
      this.tests = res.tests;
      this.questionnaires = res.questionnaires;

      if (this.tasks != null) {
        var i = 0;
        for (i; i < this.tasks.length; i++) {
          if (
            this.tasks[i].grade == null &&
            this.tasks[i].submit_time != null
          ) {
            this.tasks[i].grade = "暂无评分";
          }
          if (
            this.tasks[i].grade == null &&
            this.tasks[i].submit_time == null
          ) {
            this.tasks[i].grade = "未完成";
          }
        }
      }
      if (this.tests != null) {
        var x = 0;
        for (x; x < this.tests.length; x++) {
          if (
            this.tests[x].grade == null &&
            this.tests[x].submit_time != null
          ) {
            this.tests[x].grade = "已完成";
          }
          if (
            this.tests[x].grade == null &&
            this.tests[x].submit_time == null
          ) {
            this.tests[x].grade = "未完成";
          }
        }
      }
      if (this.questionnaires != null) {
        var y = 0;
        for (y; y < this.questionnaires.length; i++) {
          if (
            this.questionnaires[y].grade == null &&
            this.questionnaires[y].submit_time != null
          ) {
            this.questionnaires[y].grade = "已完成";
          }
          if (
            this.questionnaires[y].grade == null &&
            this.questionnaires[y].submit_time == null
          ) {
            this.questionnaires[y].grade = "未完成";
          }
        }
      }
      if (this.courses !== null) {
        this.courses.unshift({
          title: "全部",
        });
      }
      if (this.module_type !== null) {
        this.module_type.unshift({
          name: "全部",
        });
      }
    });
  },

  components: {
    CommonLayout,
    CommonFooter,
    CommonStudent,
    CommonTeacher,
  },
};
</script>

<style lang="css" scoped>
.fot {
  position: fixed;
  bottom: 0;
  width: 100%;
  line-height: var(--footer-height);
  color: #fff;
  margin-top: 53px;
  margin-bottom: 5px;
}

.edit {
  color: #fff;
  background-color: rgb(239, 88, 10);
  border-color: rgb(239, 88, 10);
}

.edit:hover,
.edit:focus {
  background: rgb(235, 157, 121);
  border-color: rgb(235, 157, 121);
  color: #fff;
}

.click,
.click:focus {
  color: rgb(239, 88, 10);
  background-color: rgb(253, 246, 236);
  border-color: rgb(239, 88, 10);
}

.click:hover {
  background: rgb(239, 88, 10);
  border-color: rgb(239, 88, 10);
  color: white;
}
</style>

<style lang="css" src="../../src/assets/.css/common.css" scoped></style>
<style
  lang="css"
  src="../../src/assets/.css/swiper-bundle.min.css"
  scoped
></style>
<style lang="css" src="../../src/assets/.css/index.css" scoped></style>
<style lang="css" src="../../src/assets/.css/page.css" scoped></style>
<style lang="css" scoped>
* {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: #2c3e50; */
}
</style>
