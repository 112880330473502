import { render, staticRenderFns } from "./findpwd.vue?vue&type=template&id=45445e4d&scoped=true&"
import script from "./findpwd.vue?vue&type=script&lang=js&"
export * from "./findpwd.vue?vue&type=script&lang=js&"
import style0 from "./findpwd.vue?vue&type=style&index=0&id=45445e4d&prod&lang=css&scoped=true&"
import style1 from "../../src/assets/.css/common.css?vue&type=style&index=1&id=45445e4d&prod&lang=css&scoped=true&"
import style2 from "../../src/assets/.css/login.css?vue&type=style&index=2&id=45445e4d&prod&lang=css&scoped=true&"
import style3 from "../../src/assets/.css/register.css?vue&type=style&index=3&id=45445e4d&prod&lang=css&scoped=true&"
import style4 from "./findpwd.vue?vue&type=style&index=4&id=45445e4d&prod&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45445e4d",
  null
  
)

export default component.exports