<template>
  <div>
    <div>
      <div v-if="n !== 9" class="bread" style="padding: 20px 0;text-align: left;margin-left:5%">
        <router-link v-if="this.$route.params.back2 !== 3" :to="{ name: 'coursedetail', params: { id: kcid } }"
          style=" font-size: 20px">{{
            $t('message.return') }}</router-link>
        <router-link v-if="this.$route.params.back2 === 3" :to="{ name: 'taskcenter' }" style=" font-size: 20px">{{
          $t('message.return') }}</router-link>
        <span style="margin-left: ; font-size: 20px">{{ $t('message.homeworkdetail') }}</span>
      </div>
      <div v-if="n === 9" class="bread" style="padding: 20px 0;text-align: left;margin-left:5%">
        <router-link v-if="this.$route.params.back2 !== 3" :to="{ name: 'coursedetail', params: { id: kcid } }"
          style=" font-size: 20px">{{
            $t('message.return') }}</router-link>
        <router-link v-if="this.$route.params.back2 === 3" :to="{ name: 'taskcenter' }" style=" font-size: 20px">{{
          $t('message.return') }}</router-link>
        <span style="margin-left: ; font-size: 20px">{{ $t('message.attachmentDetail') }}</span>
      </div>

      <div class="wrapper" style="width: 90%;margin: 0 auto;" v-if="n !== 9">
        <div class="box top_detail">
          <div class="detail_title flex">
            <img src="../../src/assets/img/green_zy_large.png" height="26" alt="" />
            <span>{{ name }}</span>
          </div>

          <p style="text-align: left;color:rgb(44,62,80)">{{ $t('message.homeworkdescription') }}：{{ summary }}</p>

          <div v-if="n === 5" style="margin: 20px;margin-top:-10px;color:grey;width:100%">

            <div v-if="input !== ''" style="text-align:left;color:grey;">输入说明：{{ input }}</div>
            <div v-if="input === ''" style="text-align:left;color:grey;">输入说明：暂无</div>
            <div v-if="output !== ''" style="text-align:left;color:grey;">输出说明：{{ output }}</div>
            <div v-if="output === ''" style="text-align:left;color:grey;">输出说明：暂无</div>




            <div v-if="sample_input !== ''" style="text-align:left;;color:grey;">输入示例：{{ sample_input }}</div>
            <div v-if="sample_input === ''" style="text-align:left;;color:grey;">输入示例：暂无</div>
            <div v-if="sample_output !== ''" style="text-align:left;color:grey;">输出示例：{{ sample_output }}</div>
            <div v-if="sample_output === ''" style="text-align:left;color:grey;">输出示例：暂无</div>

          </div>



          <div v-if="n === 3 || n === 2" class="detail_info_bar">
            <div class="info_item" style="width:25%;text-align: left">
              <img src="../../src/assets/img/info_bar_time.png" alt="" />
              <span style="text-align:left;width:150px">{{ $t('message.releasetime') }}：</span>
              <span style="text-align:left;width:180px">{{ begin_time }}</span>
            </div>
            <div class="info_item" style="width:25%;text-align: left">
              <img src="../../src/assets/img/info_bar_deadline.png" alt="" />
              <span style="text-align:left;width:150px">{{ $t('message.endtime') }}：</span>
              <span style="text-align:left;width:180px">{{ deadline }}</span>
            </div>
            <div class="info_item" style="width:25%;text-align: left">
              <img src="../../src/assets/img/info_bar_type.png" alt="" />
              <span style="text-align:left;width:130px">{{ $t('message.homeworkname') }}：</span>
              <span style="text-align:left;width:200px">{{ file_name }}</span>
            </div>
            <div class="info_item" style="width:25%;text-align: left">
              <img src="../../src/assets/img/info_bar_score.png" alt="" />
              <span style="text-align:left;width:140px">{{ $t('message.score') }}：</span>
              <span style="text-align:left;width:190px">{{ grade }}</span>
            </div>

          </div>

          <div v-if="n === 5" class="detail_info_bar">
            <div class="info_item" style="width:25%;text-align: left">
              <img src="../../src/assets/img/info_bar_time.png" alt="" />
              <span style="text-align:left;width:150px">{{ $t('message.releasetime') }}：</span>
              <span style="text-align:left">{{ begin_time }}</span>
            </div>
            <div class="info_item" style="width:25%;text-align: left">
              <img src="../../src/assets/img/info_bar_deadline.png" alt="" />
              <span style="text-align:left;width:150px">{{ $t('message.endtime') }}：</span>
              <span style="text-align:left">{{ deadline }}</span>
            </div>

            <div class="info_item" style="width:25%;text-align: left">
              <img src="../../src/assets/img/info_bar_score.png" alt="" />
              <span style="text-align:left;width:150px">{{ $t('message.score') }}：</span>
              <span style="text-align:left">{{ grade }}</span>
            </div>
            <div class="info_item" style="width:25%">
              <span style="text-align:left;width:230px" @click="dianji">{{ $t('message.enlarge') }}：</span>
              <img style="width:35px" src="../../src/assets/img/dianji.png" alt="" @click="dianji" />
            </div>

          </div>

          <div v-if="n !== 5 && n !== 3 && n !== 2" class="detail_info_bar">
            <div class="info_item" style="width:20%;text-align: left">
              <img src="../../src/assets/img/info_bar_time.png" alt="" />
              <span style="text-align:left;width:150px">{{ $t('message.releasetime') }}：</span>
              <span style="text-align:left;">{{ begin_time }}</span>
            </div>
            <div class="info_item" style="width:20%;text-align: left">
              <img src="../../src/assets/img/info_bar_deadline.png" alt="" />
              <span style="text-align:left;width:150px">{{ $t('message.endtime') }}：</span>
              <span style="text-align:left">{{ deadline }}</span>
            </div>
            <div class="info_item" style="width:20%;text-align: left">
              <img src="../../src/assets/img/info_bar_type.png" alt="" />
              <span style="text-align:left;width:130px">{{ $t('message.homeworkname') }}：</span>
              <span style="text-align:left">{{ file_name }}</span>
            </div>
            <div class="info_item" style="width:20%;text-align: left">
              <img src="../../src/assets/img/info_bar_score.png" alt="" />
              <span style="text-align:left;width:160px">{{ $t('message.score') }}：</span>
              <span style="text-align:left;">{{ grade }}</span>
            </div>
            <div v-if="n === 10 || n === 5 || n === 4 || n === 6 || n === 7 || n === 8 || n === 1" class="info_item"
              style="width:20%">

              <span style="text-align:left;width:150px" @click="dianji">{{ $t('message.enlarge') }}：</span>
              <img style="width:35px;x" src="../../src/assets/img/dianji.png" alt="" @click="dianji" />
            </div>

          </div>
        </div>
      </div>
      <div class="wrapper" style="width: 90%;margin: 0 auto;" v-if="n === 9">
        <div class="box top_detail">
          <div class="detail_title flex">
            <img src="../../src/assets/img/green_zy_large.png" height="26" alt="" />
            <span>{{ name }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="wrapper box task_info margin_bottom_30" style="width: 90%;margin: 0 auto;">
      <!-- python -->
      <div v-if="n === 1">
        <iframe
          src="API/mixly2.0_src-master/boards/index.html?thirdPartyBoard=false&boardIndex=.%2Fboards%2Fdefault%2Fpython_skulpt%2Findex.xml&boardType=Python%203%20Lite&boardImg=.%2Fboards%2Fdefault%2Fpython_skulpt%2Fmedia%2Fwebpy.png&language=Python"
          scrolling="no" allow="fullscreen" style="width: 100%; height: 600px;margin-bottom:-5px;" frameborder="0"
          id="iframeId"></iframe>
      </div>
      <!-- 富文本作业 -->
      <div v-if="n === 2">
        <div style="margin:20px;text-align:left;margin-left:30px">
          作业名称：<el-input style="width:300px" v-model="file_name1" placeholder="请输入作业名称"></el-input>
        </div>


        <quill-editor style="height:400px;margin:30px" class="editor" ref="myTextEditor" v-model="content"
          :options="editorOption" @blur="onEditorBlur($event)" @focus="onEditorFocus($event)"
          @ready="onEditorReady($event)" @change="onEditorChange($event)">
        </quill-editor>
        <el-button class="edit" v-if="ddl[0] === false" style="margin:50px;color:white;cursor: not-allowed"
          type="success">{{ $t('message.submit') }}</el-button>
        <el-button class="edit" v-if="ddl[0] === true" style="margin:50px;color:white" @click="submit" type="success">{{
          $t('message.submit') }}</el-button>
        <div v-if="ddl[0] === false" style="margin-top:-50px"> <img src="../../src/assets/img/nomessage.png"
            style="width:20px;text-align: center;position:absolute;margin-top:24px">
          <span style="height: 100px; line-height: 100px;left:35px;position:relative;top:-15px;color:grey">{{
            $t('message.nosubmit') }}</span>
        </div>
      </div>
      <!-- 文件上传类型 -->
      <div v-if="n === 3">
        <div style="text-align:left; margin-left:30px;margin-top: 15px;bold:3px;font-size:20px;color:rgb(0,149,154)">{{
          $t('message.upload') }}：</div>
        <el-upload v-if="ddl[0] === true" class="upload-demo" action name="file" :http-request="handleFileUpload">

          <img v-if="ddl[0] === false" style="width:80px;cursor: not-allowed" src="../../src/assets/img/submit.png"
            alt="" />
          <img v-if="ddl[0] === true" style="width:80px" src="../../src/assets/img/submit.png" alt="" />
          <div v-if="ddl[0] === false" style="color:rgb(0,149,154);cursor: not-allowed">{{ $t('message.under100') }}
            <em style="color:grey">{{ $t('message.under100') }}</em>
          </div>
          <div v-if="ddl[0] === true" style="color:rgb(0,149,154);">{{ $t('message.under100') }}
            <em style="color:grey">{{ $t('message.under100') }}</em>
          </div>


        </el-upload>
        <el-upload v-if="ddl[0] === false" disabled class="upload-demo" action name="file"
          :http-request="handleFileUpload">

          <img v-if="ddl[0] === false" style="width:80px;cursor: not-allowed" src="../../src/assets/img/submit.png"
            alt="" />
          <img v-if="ddl[0] === true" style="width:80px" src="../../src/assets/img/submit.png" alt="" />
          <div v-if="ddl[0] === false" style="color:rgb(0,149,154);cursor: not-allowed">{{ $t('message.clicksubmit') }}
            <em style="color:grey">{{ $t('message.under100') }}</em>
          </div>
          <div v-if="ddl[0] === true" style="color:rgb(0,149,154);">{{ $t('message.clicksubmit') }}
            <em style="color:grey">{{ $t('message.under100') }}</em>
          </div>


        </el-upload>

        <div v-if="ddl[0] === false"> <img src="../../src/assets/img/nomessage.png"
            style="width:20px;text-align: center;position:absolute;margin-top:24px">
          <span style="height: 100px; line-height: 100px;left:35px;position:relative;top:-15px;color:grey">{{
            $t('message.nosubmit') }}</span>
        </div>

        <div style="text-align: left; padding:30px;bold:3px;font-size:20px;"><a :href="file_path"
            style="color:rgb(0,149,154)">{{ $t('message.download') }}：<em style="color:black;font-size:16px">{{ file_name
            }}</em></a></div>



      </div>
      <!-- Mithon CC -->
      <div v-if="n === 4">
        <iframe
          src="API/mixly2.0_src-master/boards/index.html?thirdPartyBoard=false&boardIndex=.%2Fboards%2Fdefault%2Fmicropython_nrf51822_mithoncc%2Findex.xml&boardType=Mithon%20CC&boardImg=.%2Fboards%2Fdefault%2Fmicropython_nrf51822_mithoncc%2Fmedia%2Fmithon_compressed.png&language=MicroPython"
          allow="fullscreen" scrolling="no" style="width: 100%; height: 600px;margin-bottom:-5px;" frameborder="0"
          id="iframeId"></iframe>
      </div>
      <!-- Oj -->
      <div v-if="n === 5">
        <iframe :src="iframeSrc" allow="fullscreen" scrolling="no" style="width: 100%; height: 600px;margin-bottom:-5px;"
          frameborder="0" id="iframeId"></iframe>
      </div>
      <!-- Microbit -->
      <div v-if="n === 6">
        <iframe
          src="API/mixly2.0_src-master/boards/index.html?thirdPartyBoard=false&boardIndex=.%2Fboards%2Fdefault%2Fmicropython_nrf51822_microbit%2Findex.xml&boardType=BBC%20micro%3Abit&boardImg=.%2Fboards%2Fdefault%2Fmicropython_nrf51822_microbit%2Fmedia%2Fmicrobit_compressed.png&language=MicroPython"
          allow="fullscreen" scrolling="no" style="width: 100%; height: 600px;margin-bottom:-5px;" frameborder="0"
          id="iframeId"></iframe>
      </div>
      <!-- mixgo CE -->
      <div v-if="n === 7">
        <iframe
          src="API/mixly2.0_src-master/boards/index.html?thirdPartyBoard=false&boardIndex=.%2Fboards%2Fdefault%2Fmicropython_esp32s2%2Findex.xml&boardType=MixGo%20CE&boardImg=.%2Fboards%2Fdefault%2Fmicropython_esp32s2%2Fmedia%2Fmixgoce_compressed.png&language=MicroPython"
          allow="fullscreen" scrolling="no" style="width: 100%; height: 600px;margin-bottom:-5px;" frameborder="0"
          id="iframeId"></iframe>
      </div>
      <!-- MixGo AI -->
      <div v-if="n === 8">
        <iframe
          src="API/mixly2.0_src-master/boards/index.html?thirdPartyBoard=false&boardIndex=.%2Fboards%2Fdefault%2Fmicropython_k210_mixgoai%2Findex.xml&boardType=MixGo%20AI&boardImg=.%2Fboards%2Fdefault%2Fmicropython_k210_mixgoai%2Fmedia%2Fmixgoai_compressed.png&language=MicroPython"
          allow="fullscreen" scrolling="no" style="width: 100%; height: 600px;margin-bottom:-5px;" frameborder="0"
          id="iframeId"></iframe>
      </div>
      <!-- 附件 -->
      <div v-if="n === 9" style="height:200px">
        <div style="text-align:left;margin-left:18px; margin-top: 15px;bold:3px;font-size:20px;color:rgb(0,149,154)">{{
          $t('message.download') }}：</div>
        <span @click="downloadFile" style="color:rgb(0,149,154)"><img style="width:10%;fit:cover"
            src="../../src/assets/img/attachment.png" alt="" /></span>
      </div>
      <!-- MixGo Car -->
      <div v-if="n === 10">
        <iframe
          src="API/mixly2.0_src-master/boards/index.html?thirdPartyBoard=false&boardIndex=.%2Fboards%2Fdefault%2Fpython_skulpt_car%2Findex.xml&boardType=Python%203%20Car&boardImg=.%2Fboards%2Fdefault%2Fpython_skulpt%2Fmedia%2Fwebpy.png&language=Python"
          allow="fullscreen" scrolling="no" style="width: 100%; height: 600px;margin-bottom:-5px;" frameborder="0"
          id="iframeId"></iframe>
      </div>

    </div>

    <div class="wrapper" style="padding-top:30px;width: 90%;margin: 0 auto;" v-if="file_namef !== null && n !== 9">
      <div class="box top_detail">
        <div class="detail_title flex">
          <img style="width:40px;height:40px;margin-right:10px" src="../../src/assets/img/filepackage.png" height="50"
            alt="" />{{ $t('message.attachmentdownload') }}：

        </div>
        <div style="text-align:left;margin-left:100px;margin-bottom:20px">
          <a :href="file_pathf"> <span style="font-size:16px">{{ file_namef }}</span>
          </a>
        </div>


      </div>
    </div>

    <div class="wrapper" style="padding-top:30px;width: 90%;margin: 0 auto;" v-if="comment !== null && n !== 9">
      <div class="box top_detail">
        <div class="detail_title flex">
          <img src="../../src/assets/img/icon2.png" height="50" alt="" />{{ $t('message.teacherc') }}：

        </div>
        <p class="zi" style="white-space: pre-wrap;height:50px;margin-top:-25px"> <span>{{ comment }}</span>
        </p>
      </div>
    </div>

    <div class="wrapper" style="width: 100%;margin: 0 auto;" v-if="sfile_id != null && n !== 9">
      <div class="hot_course wrapper">
        <h2>
          <span><em style="text-align:left">{{ $t('message.Excellenttask') }}</em></span>
          <a v-if="x === 1" @click="spread3" class="more arrow_linkright">{{ $t('message.morestartask') }}</a>
          <a v-if="x === 2" @click="spread4" class="more arrow_linkdown">{{ $t('message.morestartask') }}</a>
        </h2>
      </div>

      <div v-if="goodtasks !== null" class="clearfix" style="width:90%;margin: 0 auto;">
        <div class="hc_item " v-for="(item, index) in goodtasks" :key="index" v-if="index < show2"
          style=" border: solid rgb(239, 88, 10) 1px; border-radius: 6px;background-color: rgb(253, 246, 236);float: left; margin-right: 22px; margin-bottom: 20px">

          <div>

            <div style="margin: 10px;text-align:left">
              <em style="font-size: 15px; color: black">{{ $t('message.author') }}：{{ item.name }}</em>
            </div>
            <div style="margin-top: 10px;margin-left: 10px;text-align:left">
              <em style="font-size: 15px; color: black">{{ $t('message.time11') }}：{{ item.update_time }}</em>
            </div>
          </div>
        </div>
      </div>
      <div v-if="goodtasks === null" style="text-align: center">
        <div> <img src="../../src/assets/img/nomessage.png" style="width:50px;text-align: center;position:absolute">
          <span style="height: 100px; line-height: 100px;left:60px;position:relative;top:-20px;color:grey">{{
            $t('message.noehomework') }}</span>
        </div>


      </div>




    </div>


    <EmptyFooter />
  </div>
</div></template>

<script>
// import download from 'downloadjs'

import { saveAs as FileSaver } from 'file-saver'
import arrayToTree from "array-to-tree";
import { saveAs } from 'file-saver';


import { homeworkpage, attachmentpage } from "@/http/api.js"; // 导入接口
import { homeworksubmit, adminAxiosUpload, fwbhomeworksubmit } from "@/http/api.js"; // 导入接口

import CommonLayout from "@/components/CommonLayout.vue";

import EmptyFooter from "@/components/EmptyFooter.vue";
import CommonStudent from "@/components/CommonStudent.vue";
import CommonTeacher from "@/components/CommonTeacher.vue";
export default {
  name: "coursehomework",
  data () {
    return {
      wcstate: "",
      id: "", //模块id
      kcid: "", //课程id
      type: "",
      status: "",
      type2: "",
      n: "",
      reid: "", //返回的作业id
      module_id: "",
      name: "",
      summary: "",
      begin_time: "",
      stext: "",
      grade: "",
      good_works: "",
      success: "",
      deadline: "",
      language_type: null,
      content: "",
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
            ["blockquote", "code-block"], // 引用  代码块
            [{ header: 1 }, { header: 2 }], // 1、2 级标题
            [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
            [{ script: "sub" }, { script: "super" }], // 上标/下标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            // [{'direction': 'rtl'}],                         // 文本方向
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
            [{ font: [] }], // 字体种类
            [{ align: [] }], // 对齐方式
            ["clean"], // 清除文本格式
            ["link", "image", "video"], // 链接、图片、视频
          ], //工具菜单栏配置
        },
        placeholder: "请在这里填写答案", //提示
        readyOnly: false, //是否只读
        theme: "snow", //主题 snow/bubble
        syntax: true,

        // file_id1: '',
        // file_id: ''
      },
      file_id1: '',
      file_id: '',
      cfile_id: null,
      chuan: null,
      iframe: [],
      input: "",
      output: "",
      fullscreen: "",
      file_path: "",
      file_pathf: "",
      file_name: "",
      file_namef: "",
      file_name1: "",
      ddl: false,
      havesubmit: null,
      x: 1,
      show2: 4,
      goodtasks: [],
      sfile_id: "",
      comment: null,
      panduan: 1,
      sample_output: '',
      sample_input: '',
      file: {
        url: null,
        name: null
      }



    };
  },


  methods: {

    downloadFile () {
      let token = JSON.parse(localStorage.getItem("Authorization"));
      let verify = localStorage.getItem("verify")
      attachmentpage({
        module_id: this.id,
        uid: token.user_id,
        schoolid: token.school_id,
        verify: verify
      }).then((res) => {
        // saveAs(res.file_url, res.name)
        // window.location.href = res.file_url
        let url = res.file_url
        const a = document.createElement('a')
        a.href = url
        a.download = res.name// 下载后文件名
        a.style.display = 'none'
        document.body.appendChild(a)
        a.click() // 点击下载
      
      });
    },
    spread3 () {
      this.x = 2

      this.show2 = 99
    },
    spread4 () {
      this.x = 1

      this.show2 = 4
    },
    dianji () {
      //设置后就是id==con_lf_top_div 的容器全屏
      let case1 = document.getElementById("iframeId");
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (case1.requestFullscreen) {
          case1.requestFullscreen();
        } else if (case1.webkitRequestFullScreen) {
          case1.webkitRequestFullScreen();
        } else if (case1.mozRequestFullScreen) {
          case1.mozRequestFullScreen();
        } else if (case1.msRequestFullscreen) {
          // IE11
          case1.msRequestFullscreen();
        }
      }
    },


    question () {
      this.$alert("" + this.describe, "问题描述", {
        confirmButtonText: "确定",

      });
    },
    onEditorBlur (editor) { },
    // 获得焦点
    onEditorFocus (editor) { },
    // 开始
    onEditorReady (editor) { },
    // 值发生变化
    onEditorChange (editor) {
      this.content = editor.html;
      console.log(this.content)
      // console.log(editor);
    },


    submit () {

      let token = JSON.parse(localStorage.getItem("Authorization"));
      let verify = localStorage.getItem("verify")
      setTimeout(() => {
        fwbhomeworksubmit({
          file_id: this.file_id1,
          module_id: this.module_id,
          user_id: token.user_id,
          uid: token.user_id,
          schoolid: token.school_id,
          verify: verify
        }).then(
          (res) => {
            console.log(res)

            this.$alert("" + res.msg, "提示", {
              confirmButtonText: "确定",

            });
            setTimeout(() => {
              window.location.reload()
            }, 1500);
          },
          function (error) { }
        );
      }, 1000);
      homeworksubmit({
        user_id: token.user_id,
        module_type: 18,
        content: this.content,
        file_name: this.file_name1 + '.rtf',
        uid: token.user_id,
        schoolid: token.school_id,
        verify: verify
      }).then(
        (res) => {
          console.log(res)

          this.file_id1 = res.file_id

        },
      );



    },
    handleFileUpload (item) {
      console.log("item", item);
      let token = JSON.parse(localStorage.getItem("Authorization"));
      let sub = new FormData();
      let module_type = JSON.parse(localStorage.getItem("module_type"));
      sub.append("user_id", token.user_id);
      sub.append("file", item.file);
      sub.append("module_type", this.module_type);
      sub.append("taskname", item.file.name);
      console.log("sub", sub);
      adminAxiosUpload(sub)
        .then((res) => {
          console.log('res', res);
          this.file_id = res.data.file_id;
          if (res.data.status == 1) {


            let token = JSON.parse(localStorage.getItem("Authorization"));
            let verify = localStorage.getItem("verify")
            fwbhomeworksubmit({
              file_id: this.file_id,
              module_id: this.module_id,
              user_id: token.user_id,
              uid: token.user_id,
              schoolid: token.school_id,
              verify: verify
            }).then(
              (res) => {
                console.log(res)

                if (res.status == 1) {
                  this.$alert("" + res.msg, "提示", {
                    confirmButtonText: "确定",
                    // showConfirmButton: false,
                  });
                  setTimeout(() => {
                    let token = JSON.parse(localStorage.getItem("Authorization"));
                    let verify = localStorage.getItem("verify")
                    homeworkpage({
                      module_id: this.id,
                      user_id: token.user_id,
                      uid: token.user_id,
                      schoolid: token.school_id,
                      verify: verify
                    }).then((res) => {
                      
                      this.reid = res.id;
                      this.module_id = res.module_id;
                      this.name = res.name;
                      this.stext = res.stext;
                      this.summary = res.summary;
                      this.deadline = res.deadline;
                      this.goodtasks = res.good_works
                      this.comment = res.comment
                      localStorage.setItem("language_type", JSON.stringify(res.type));
                      if (this.summary === "") {
                        this.summary = "无"
                      }
                      this.begin_time = res.begin_time;
                      this.grade = res.grade;
                      this.good_works = res.good_works;
                      this.output = res.output
                      this.input = res.input
                      this.file_path = res.sfile_path
                      this.file_name = res.sfile_name
                      this.file_pathf = res.file_path
                      this.file_namef = res.file_name
                      this.sfile_id = res.sfile_id
                      this.sample_output = res.sample_output
                      this.sample_input = res.sample_input

                      if (res.sfile_name != null) {
                        this.file_name = this.file_name.substring(0, this.file_name.indexOf('.'))
                      } else {
                        this.file_name = "尚未提交"
                      }
                      if (res.sfile_name === "mix.xml") {
                        this.file_name = "尚未提交"
                      }

                      this.content = res.scontents
                      if (res.grade === null) {
                        this.grade = "暂无评分"
                      }

                      localStorage.setItem("file_id", JSON.stringify(res.sfile_id));
                      localStorage.setItem("module_type", JSON.stringify(res.module_type));
                      localStorage.setItem("file_name", JSON.stringify(res.sfile_name));
                      console.log("homeworkpage", res);
                    });
                  }, 2000);
                }


              },
              function (error) { }

            );



          }
          else {
            this.$alert("" + res.data.msg, "提示", {
              confirmButtonText: "确定",
            });
          }

        })



    },


  },

  computed: {
    editor () {
      return this.$refs.myTextEditor.quillEditor;
    },
    iframeSrc: function () {
      let template_file_id = this.template_file_id != null ? this.template_file_id : "'" + 'null' + "'";
      let cfile_id = this.cfile_id != null ? this.cfile_id : "'" + 'null' + "'";
      let sample_input = "'" + this.sample_input + "'";
      let ddl = "'" + this.ddl + "'";
      return `static/Coj/index2.html?language_type=${this.language_type}&input=${sample_input}&template_file_id=${template_file_id}&module_id=${this.module_id}&cfile_id=${cfile_id}&ddl=${ddl}`;
    }
  },
  created () {
    if (this.file_name === "尚未提交") {
      file_name2 = null
    }
    if (this.$route.params.type === "编程任务-python3") {
      this.n = 1;
      this.type = "线上编程";
    }
    if (this.$route.params.type === "一般任务-富文本") {
      this.n = 2;
      this.type = "富文本";
    }
    if (this.$route.params.type === "一般任务-文件") {
      this.n = 3;
      this.type = "文件上传";
    }
    if (this.$route.params.type === "编程任务-mithonCC") {
      this.n = 4;
      this.type = "线上编程";
    }
    if (this.$route.params.type === "自动测评任务") {
      this.n = 5;
      this.type = "自动测评";
    }
    if (this.$route.params.type === "编程任务-microbit") {
      this.n = 6;
      this.type = "线上编程";
    }
    if (this.$route.params.type === "编程任务-mixgoCE") {
      this.n = 7;
      this.type = "线上编程";
    }
    if (this.$route.params.type === "编程任务-mixgoAI") {
      this.n = 8;
      this.type = "线上编程";
    }
    if (this.$route.params.type === "编程任务-mixgoCar") {
      this.n = 10;
      this.type = "线上编程";
    }
    if (this.$route.params.type === "附件") {
      this.n = 9;
      this.type = "附件资源";
    }

    this.id = this.$route.params.id;
    this.kcid = this.$route.params.kcid;
    let token = JSON.parse(localStorage.getItem("Authorization"));
    let verify = localStorage.getItem("verify")


    if (this.$route.params.type === "附件") {
      attachmentpage({
        module_id: this.id,
        uid: token.user_id,
        schoolid: token.school_id,
        verify: verify
      }).then((res) => {
        this.file_path = res.file_url
        this.name = res.name
      });
    } else {
      homeworkpage({
        module_id: this.id,
        user_id: token.user_id,
        uid: token.user_id,
        schoolid: token.school_id,
        verify: verify
      }).then((res) => {
        this.reid = res.id;
        this.module_id = res.module_id;
        this.module_type = res.module_type;
        this.name = res.name;
        this.stext = res.stext;
        this.summary = res.summary;
        this.deadline = res.deadline;
        this.goodtasks = res.good_works
        this.language_type = res.type
        if (this.summary === "") {
          this.summary = "无"
        }
        this.begin_time = res.begin_time;
        this.grade = res.grade;
        this.good_works = res.good_works;
        this.output = res.output
        this.input = res.input
        this.file_path = res.sfile_path
        this.file_name = res.sfile_name
        this.file_name1 = res.sfile_name
        this.file_pathf = res.file_path
        this.file_namef = res.file_name
        this.sfile_id = res.sfile_id
        this.comment = res.comment
        this.cfile_id = res.cfile_id
        this.sample_output = res.sample_output
        this.sample_input = res.sample_input
        if (res.sfile_name != null) {
          this.file_name = this.file_name.substring(0, this.file_name.indexOf('.'))
          this.file_name1 = this.file_name1.substring(0, this.file_name1.indexOf('.'))
        } else {
          this.file_name = "尚未提交"
          this.file_name1 = ""
        }
        if (res.sfile_name === "mix.xml") {
          this.file_name = "尚未提交"
        }
        this.content = res.scontents
        if (res.grade === null) {
          this.grade = "暂无评分"
        }
        if (res.submit_id === null) {
          this.wcstate = "未完成"
        } else {
          this.wcstate = "已完成"
        }
        localStorage.setItem("file_name", JSON.stringify(res.sfile_name));
        localStorage.setItem("file_id", JSON.stringify(res.sfile_id));
        localStorage.setItem("module_id", JSON.stringify(res.module_id));
        localStorage.setItem("template_file_id", JSON.stringify(res.template_file_id));
        localStorage.setItem("submit_id", JSON.stringify(res.submit_id));
        localStorage.setItem("module_type", JSON.stringify(res.module_type));
        let finishstate = JSON.parse(localStorage.getItem("finishstate"));
        this.wcstate = finishstate
        //年
        let year = new Date().getFullYear();
        //月份是从0月开始获取的，所以要+1;
        let month = new Date().getMonth() + 1;
        //日
        let day = new Date().getDate();
        //时
        let hour = new Date().getHours();
        //分
        let minute = new Date().getMinutes();
        //秒
        let second = new Date().getSeconds();
        let time = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second
        //比较两个日期大小。格式：2018-8-12 12:30
        const compareDate = (d1, d2) => {
          return ((new Date(d1.replace(/\-/g, "\/"))) > (new Date(d2.replace(/\-/g, "\/"))));
        }

        if (this.deadline !== null && this.begin_time !== null) {
          this.ddl = [compareDate(this.deadline, time) && compareDate(time, this.begin_time)]
          localStorage.setItem("ddl", this.ddl);
        }
        if (this.ddl[0] === false && this.panduan === 1) {
          this.panduan = 0
          this.$alert("" + "不在任务完成时间", "状态提示", {
            confirmButtonText: "确定",
            showConfirmButton: false,
          });

          setTimeout(() => {
            this.$msgbox.close();
          }, 2000);

        }


      });
    }
  },
  mounted () {

  },
  components: {
    EmptyFooter
  },
};
</script>
<style lang="css"  src='../../src/assets/.css/common.css'  scoped></style>
<style lang="css"  src='../../src/assets/.css/index.css'  scoped></style>
<style lang="css"  src='../../src/assets/.css/page.css'  scoped></style>
<style lang="css" scoped>
* {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.edit {
  color: #fff;
  background-color: rgb(0, 149, 154);
  border-color: rgb(0, 149, 154);
}

.edit:hover,
.edit:focus {
  background: rgb(129, 188, 190);
  border-color: rgb(129, 188, 190);
  color: #fff;
}

.zi {
  font-family: 微软雅黑;
  /* line-height: 2px; */
  font-size: 15px;
  text-indent: 2px;
  text-align: left;
  width: 750px;
  /* margin-top: 5px; */
  margin-bottom: 50px;
  margin-left: 80px;
  white-space: pre-wrap;
}
</style>