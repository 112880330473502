<template>
  <div>
    <div>
      <!-- 返回栏 -->
      <div class="bread" style="padding: 20px 0;text-align: left;margin-left:5%">
        <router-link :to="{ name: 'coursedetail', params: { id: course_id } }" style=" font-size: 20px">{{
          $t('message.return') }}</router-link>
        <span style="margin-left: ; font-size: 20px">{{ $t('codeExercise.exerciseDetail') }}</span>
      </div>
      <!-- 信息栏 -->
      <div style="width: 90%;margin: 0 auto;">
        <div class="box top_detail">
          <!-- 标题栏 -->
          <div class="detail_title flex exercisebg">
            <img src="../../src/assets/img/green_zy_large.png" height="26" alt="" />
            <span>{{ $t('codeExercise.exercise') }}：{{ name }}</span>
          </div>
          <p style="text-align: left;color:rgb(44,62,80);background-color:#f7fcfc">{{ $t('codeExercise.summary') }}：{{
            summary }}</p>
          <!-- 详细信息 -->
          <div class="detail_info_bar2">
            <div class="info_item" style="width:25%;text-align: left">
              <img src="../../src/assets/img/info_bar_time.png" alt="" />
              <span style="text-align:left;width:100px">{{ $t('message.releasetime') }}：</span>
              <span style="text-align:left">{{ begin_time }}</span>
            </div>
            <div class="info_item" style="width:25%;text-align: left">
              <img src="../../src/assets/img/info_bar_deadline.png" alt="" />
              <span style="text-align:left;width:100px">{{ $t('message.endtime') }}：</span>
              <span style="text-align:left">{{ end_time }}</span>
            </div>
            <div class="info_item" style="width:25%;text-align: left">
              <img src="../../src/assets/img/info_bar_score.png" alt="" />
              <span style="text-align:left;width:100px">{{ $t('knowledge.knowledge') }}：</span>
              <span style="text-align:left">{{ tree }} - {{ knowledge }}</span>
            </div>
            <div class="info_item" style="width:25%;text-align: left">
              <img src="../../src/assets/img/status_course.png" alt="" />
              <span style="text-align:left;width:100px">{{ $t('codeExercise.recommendMethod') }}：</span>
              <span style="text-align:left">{{ recommendMethod }}</span>
            </div>
          </div>
          <div class="detail_info_bar2" style="background-color:white">
            <div class="info_item" style="width:25%;text-align: left">
              <img src="../../src/assets/img/all_num.png" alt="" style="width:23px" />
              <span style="text-align:left;width:100px">{{ $t('codeExercise.totalProblems') }}：</span>
              <span style="text-align:left">{{ all_problem_num }}</span>
            </div>
            <div class="info_item" style="width:25%;text-align: left">
              <img src="../../src/assets/img/correct_num.png" alt="" style="width:27px" />
              <span style="text-align:left;width:100px">{{ $t('codeExercise.correctProblems') }}：</span>
              <span style="text-align:left">{{ correct_problems }}</span>
            </div>
            <div class="info_item" style="width:25%;text-align: left">
              <img src="../../src/assets/img/submit_num.png" alt="" style="width:20px" />
              <span style="text-align:left;width:100px">{{ $t('codeExercise.submitProblems') }}：</span>
              <span style="text-align:left">{{ total_problems }}</span>
            </div>
            <div class="info_item" style="width:25%;text-align: left">
              <img src="../../src/assets/img/correct_rate.png" alt="" style="width:30px" />
              <span style="text-align:left;width:100px">{{ $t('codeExercise.correctRate') }}：</span>
              <span style="text-align:left">{{ correct_rate }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="margin_bottom_30"
      style="width: 90%;margin: 0 auto;display: flex; align-items: center; justify-content: flex-start;"
      v-show="finishAll && buttonShow">
      <el-button icon="el-icon-notebook-1" @click="problemset" style="margin-right: 5px;margin-bottom:10px"
        ref="problemsetButton">
        题库
      </el-button>
    </div>
    <div class="margin_bottom_30"
      style="width: 90%;margin: 0 auto;display: flex; align-items: center; justify-content: flex-start;"
      v-show="ojShow && intime == 1">

      <el-button v-if="problemsetShow" icon="el-icon-notebook-1" @click="problemset"
        style="margin-right: 5px;margin-bottom:10px" ref="problemsetButton">
        题库
      </el-button>
      <el-button round icon="el-icon-arrow-left" @click="prevproblem" style="margin-right: 5px;margin-bottom:10px"
        ref="prevButton">
        上一题
      </el-button>
      <el-button round @click="nextproblem" style="margin-right: 5px;margin-bottom:10px" ref="nextButton">
        下一题
        <i class="el-icon-arrow-right" style="margin-left: 5px;"></i>
      </el-button>
      <el-button round v-if="randomShow" icon="el-icon-refresh" @click="randomproblem"
        style="margin-right: 5px;margin-bottom:10px" ref="randomButton">
        随机一题
      </el-button>
    </div>
    <!-- Oj -->
    <div class="box container" v-show="ojShow">
      <div class="info">
        <div style="display: flex; align-items: center;margin-bottom:20px">
          <!-- <img src="../../src/assets/img/problem.png" height="40" alt="" /> -->
          <h2 style="margin-left:8px"> 题目：{{ title }}</h2>
        </div>
        <div class="basic-info">
          <div style="display: flex; align-items: center;margin-bottom:20px">
            <img src="../../src/assets/img/knowledge.png" height="26" alt="" />
            <span style="text-align:left;margin-left:8px"> {{ $t('knowledge.knowledge') }}：</span>
            <el-tag color="#F7FCFC"
              style="text-align:center;margin-top:5px;min-width:100px;font-size:16px;margin-right: 10px;color:#00979C"
              v-for=" (knowledge, index) in knowledge_array" :key="index">
              {{ knowledge }}
            </el-tag>
            <!-- <span style="text-align:left">{{ problem_knowledge }}</span> -->
          </div>
          <div style="display: flex; align-items: center;margin-bottom:20px">
            <img src="../../src/assets/img/difficulty_orange.png" height="26" alt="" />
            <span style="text-align:left;margin-left:8px"> {{ $t('ojProblem.difficulty') }}：</span>
            <span style="text-align:left">
              <el-tag :type="tagtype" style="text-align:center;margin-top:5px;min-width:100px;font-size:16px">{{
                difficultyshow }}</el-tag>
            </span>
          </div>
          <div style="display: flex; align-items: center;margin-bottom:20px">
            <img src="../../src/assets/img/greytime.png" height="26" alt="" />
            <span style="text-align:left;margin-left:8px"> {{ $t('ojProblem.timeLimit') }}(ms)：</span>
            <span style="text-align:left;margin-top:5px;">{{ time_limit }}</span>
          </div>
          <div style="display: flex; align-items: center;">
            <img src="../../src/assets/img/memory_grey.png" height="26" alt="" />
            <span style="text-align:left;margin-left:8px"> {{ $t('ojProblem.memoryLimit') }}(kb)：</span>
            <span style="text-align:left;margin-top:5px;">{{ memory_limit }}</span>
          </div>
        </div>

        <h2 style="margin-top:20px;">描述</h2>
        <!-- <vue-markdown :source="description"></vue-markdown> -->
        <mavon-editor :value="description" :subfield="false" defaultOpen="preview" :toolbarsFlag="false" :editable="false"
          :scrollStyle="true"
          style="width:95%;min-height:40px;margin-top:20px;margin-bottom:20px;padding-top:15px;background-color:#FBFBFB"></mavon-editor>
        <h2>输入格式</h2>
        <mavon-editor :value="input" :subfield="false" defaultOpen="preview" :toolbarsFlag="false" :editable="false"
          :scrollStyle="true"
          style="width:95%;min-height:40px;margin-top:20px;margin-bottom:20px;padding-top:15px;background-color:#FBFBFB"></mavon-editor>
        <!-- <div class="ql-snow info-card">
          <div class="ql-editor">
            <div v-html="input" style="text-align:left"></div>
          </div>
        </div> -->
        <h2>输出格式</h2>
        <mavon-editor :value="output" :subfield="false" defaultOpen="preview" :toolbarsFlag="false" :editable="false"
          :scrollStyle="true"
          style="width:95%;min-height:10px;margin-top:20px;margin-bottom:20px;padding-top:15px;background-color:#FBFBFB"></mavon-editor>
        <!-- <div class="ql-snow info-card">
          <div class="ql-editor">
            <div v-html="output" style="text-align:left"></div>
          </div>
        </div> -->
        <div v-for="(sample, index) in samples" :key="index" style="width:100%">
          <el-row>
            <el-col :span='12'>
              <h2>样例输入</h2>
              <div v-html="sample.sample_input" class="sample-style"></div>
              <!-- <div class="sample-style" style="white-space:pre-warp;">{{ sample.sample_input }}</div> -->
            </el-col>
            <el-col :span='12'>
              <h2>样例输出</h2>
              <div v-html="sample.sample_output" class="sample-style"></div>
              <!-- <div class="sample-style" style="white-space:pre-warp;">{{ sample.sample_output }}</div> -->
            </el-col>
          </el-row>
        </div>
        <div style="display: flex;">
          <h2 style="width:10%">提示</h2>
          <el-button type="text" @click="showhint" ref="hintButton">{{ hintText }}</el-button>
        </div>
        <mavon-editor v-if="hintShow" :value="hint" :subfield="false" defaultOpen="preview" :toolbarsFlag="false"
          :editable="false" :scrollStyle="true"
          style="width:95%;min-height:20px;margin-top:20px;margin-bottom:20px;padding-top:15px;background-color:#FBFBFB"></mavon-editor>
      </div>
      <iframe v-show="iframeShouldLoad" :src="iframeSrc" allow="fullscreen" scrolling="no" class="iframeStyle"
        frameborder="0" id="iframeId"></iframe>
    </div>
    <div style="width: 90%;margin: 0 auto;" v-if="listShow">
      <div style="margin-bottom: 30px;display: flex; justify-content: flex-start;">
        <span style="margin-top: 6px;">{{ $t("admin.keyword") }}：</span>
        <el-input class="exerciseName" v-model="searchContent"
          :placeholder="$t('ojProblem.pleaseInputProblemName')"></el-input>
        <span>&nbsp;&nbsp;&nbsp;</span>
        <el-button class="searchBtn" plain ref="search" style="margin-left: 20px" @click="handleSearch">{{
          $t("admin.search")
        }}</el-button>
        <el-button class="searchBtn" plain ref="reset" @click="clickReset">{{ $t("admin.reset") }}</el-button>
        <el-button v-if="!beginexercise_show && !finishAll" ref="return" type="warning" plain @click="returnExercise">{{
          $t("codeExercise.returnExercise")
        }}</el-button>
      </div>
      <el-table ref="table" :data="tableShow" style="width: 100%">
        <el-table-column v-for="(     item, index     ) in      $i18n.locale == 'zhcn' ? table : en_table     "
          :key="index" :prop="item.prop" :label="item.label" :width="item.width" :min-width="item.minWidth"
          show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button v-if="item.prop === 'submit_status'" title="点击查看提交代码" @click="openFile(scope.row)" type="text"
              :class="getStatusClass(scope.row.submit_status)">
              {{ scope.row.submit_status }}
            </el-button>
            <span v-else-if="item.prop === 'difficulty'">
              <el-tag :type="getTagType(scope.row.difficulty)" style="width:40px;text-align:center">
                {{ scope.row.difficulty != null ? scope.row.difficulty : '暂无' }}
              </el-tag>
            </span>
            <span v-else-if="item.prop != 'sfile_path'">
              {{ scope.row[item.prop] }}
            </span>
          </template>
        </el-table-column>
        <el-table-column v-if="beginexercise_show" :label="$t('message.operation')" width="auto" min-width="10%">
          <template slot-scope="scope">
            <el-button type="text" @click="beginExercise($event, scope.row)">{{ $t("codeExercise.beginExercise")
            }}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="width: 90%;margin-top: 20px;" v-if="listShow">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
        :page-sizes="[5, 8, 15, 30]" :page-size="8" layout="total, sizes, prev, pager, next, jumper"
        :total="problem_num"></el-pagination>
    </div>
    <div style="padding-top:30px;width: 90%;margin: 0 auto;" v-if="attachment_name !== null">
      <div class="box top_detail">
        <div class="detail_title flex">
          <img style="width:40px;height:40px;margin-right:10px" src="../../src/assets/img/filepackage.png" height="50"
            alt="" />{{ $t('message.attachmentdownload') }}：
        </div>
        <div style="text-align:left;margin-left:100px;margin-bottom:20px">
          <a :href="attachment_path"> <span style="font-size:16px">{{ attachment_name }}</span>
          </a>
        </div>
      </div>
    </div>
    <EmptyFooter />
  </div>
</template>
<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import download from 'downloadjs';
import {
  codeExercisePage, problem, codeexerciseProblemList, codeexerciseNextProblem,
  codeexerciseRandomProblem, codeexerciseAddEvent, codeExerciseSubmitInfo
} from "@/http/api.js"; // 导入接口
import EmptyFooter from "@/components/EmptyFooter.vue";
export default {
  name: "codeexercise",
  data () {
    return {
      //基础信息
      uid: "",
      user_name: "",
      schoolid: null,
      verify: null,
      wcstate: "",//完成状态
      module_id: "", //模块id
      course_id: "", //课程id
      iframeShouldLoad: false,
      ojShow: false,
      listShow: false,
      all_problem_num: 0,//总题数
      total_problems: 0,//提交总题数
      correct_problems: 0,//正确题数
      correct_rate: 0,//正确率
      finishAll: false,
      buttonShow: false,
      //exerciseinfo
      exercise_id: null,
      name: null,
      summary: null,
      begin_time: null,
      end_time: null,
      knowledge_id: null,
      knowledge: null,
      tree_id: null,
      tree: null,
      is_adaptive: null,
      beginexercise_show: false,
      type: null,
      success: "",
      ddl: false,
      have_learned: null,
      //probleminfo
      problem_id: null,
      title: null,
      description: null,
      samples: [
        {
          sample_input: null,
          sample_output: null
        }
      ],
      hint: null,
      time_limit: null,
      memory_limit: null,
      input: null,
      output: null,
      example_id: null,
      difficulty: null,
      attachment_name: null,
      attachment_path: null,
      // cfile_id: null,
      run_status: null,
      problem_knowledge: null,
      knowledge_array: null,
      //其他
      hintShow: false,
      hintText: '点击查看提示',
      file_id: null,//作业文件
      iframe: [],
      fullscreen: null,
      tableShow: [],
      tableData: null,
      currentPage: 1,
      pageSize: 8,
      problem_num: 0,
      searchContent: "",
      keyword: null,
      table: [
        {
          prop: "id",
          label: "序号",
          width: "auto",
          minWidth: "5%",
        },
        {
          prop: "title",
          label: "题名",
          width: "auto",
          minWidth: "15%",
        },
        {
          prop: "knowledge",
          label: "知识点",
          minWidth: "25%",
        },
        {
          prop: "difficulty",
          label: "难度",
          minWidth: "9%",
        },
        {
          prop: "submit_num",
          label: "练习次数",
          width: "auto",
          minWidth: "8%",
        },
        {
          prop: "accept_num",
          label: "通过次数",
          width: "auto",
          minWidth: "8%",
        },
        {
          prop: "accept_rate",
          label: "通过率",
          width: "auto",
          minWidth: "10%",
        },
        {
          prop: "submit_status",
          label: "提交状态",
          width: "auto",
          minWidth: "10%",
        },
      ],
      en_table: [
        {
          prop: "id",
          label: "ID",
          width: "auto",
          minWidth: "5%",
        },
        {
          prop: "title",
          label: "Title",
          width: "auto",
          minWidth: "15%",
        },
        {
          prop: "knowledge",
          label: "Knowledge",
          minWidth: "25%",
        },
        {
          prop: "difficulty",
          label: "Difficulty",
          minWidth: "5%",
        },
        {
          prop: "submit_num",
          label: "Submit Num",
          width: "auto",
          minWidth: "10%",
        },
        {
          prop: "accept_num",
          label: "Accept Num",
          width: "auto",
          minWidth: "10%",
        },
        {
          prop: "accept_rate",
          label: "Accept Rate",
          width: "auto",
          minWidth: "10%",
        },
        {
          prop: "submit_status",
          label: "Submit Status",
          width: "auto",
          minWidth: "10%",
        },
      ],
      prevproblem_ids: [],
    };
  },

  methods: {
    handleMessage (event) {
      const receivedData = event.data;
      codeExerciseSubmitInfo({
        uid: this.uid,
        schoolid: this.schoolid,
        verify: this.verify,
        module_id: this.module_id,
      }).then((res) => {
        this.total_problems = res.total_problems;
        this.correct_problems = res.correct_problems;
        this.correct_rate = res.correct_rate;
      });
    },
    getUserInfo () {
      let newUserInfo = JSON.parse(localStorage.getItem("Authorization"));
      this.uid = newUserInfo.user_id;
      this.user_name = newUserInfo.user_name;
      this.schoolid = newUserInfo.school_id;
      this.verify = localStorage.getItem("verify");
    },
    // 获取关键词
    getVal () {
      let inpVal = this.searchContent;
      let arrVal = [];
      //关键字去重
      arrVal = inpVal
        .toLowerCase()
        .trim()
        .split(" ")
        .filter((item) => {
          return item && !arrVal.includes(item);
        });
      return arrVal;
    },
    handleSearch () {
      this.$refs.search.$el.blur();
      this.keyword = this.getVal();
      this.fetchData();
    },
    clickReset () {
      this.$refs.reset.$el.blur();
      this.keyword = null;
      this.searchContent = "";
      this.fetchData();
    },
    returnExercise () {
      this.$refs.return.$el.blur();
      this.ojShow = true;
      this.listShow = false;
    },
    isStringEmpty (str) {
      var trimmedStr = str.trim();
      return trimmedStr.length === 0;
    },
    showhint () {
      this.$refs.hintButton.$el.blur();
      if (this.hint == null || this.isStringEmpty(this.hint)) {
        this.hintText = '暂无提示';
        return;
      }
      this.hintShow = true;
      codeexerciseAddEvent({
        uid: this.uid,
        schoolid: this.schoolid,
        verify: this.verify,
        problem_id: this.problem_id,
        exercise_id: this.exercise_id,
        type: "hint"
      });
    },
    getStatusClass (status) {
      switch (status) {
        case '尚未提交':
          return 'status-gray';
        case '挑战成功':
          return 'status-green';
        default:
          return 'status-red';
      }
    },
    openFile (row) {
      if (row.sfile_path != null) {
        window.open(row.sfile_path, '_blank');
      }
    },
    getTagType (difficulty) {
      return difficulty ? difficulty < 4 ? "success" : difficulty < 8 ? "warning" : " danger " : "info";
    },
    dianji () {
      //设置后就是id==con_lf_top_div 的容器全屏
      let case1 = document.getElementById("iframeId");
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (case1.requestFullscreen) {
          case1.requestFullscreen();
        } else if (case1.webkitRequestFullScreen) {
          case1.webkitRequestFullScreen();
        } else if (case1.mozRequestFullScreen) {
          case1.mozRequestFullScreen();
        } else if (case1.msRequestFullscreen) {
          // IE11
          case1.msRequestFullscreen();
        }
      }
    },
    beginExercise (e, row) {
      this.hintText = '点击查看提示';
      this.hintShow = false;
      problem({
        uid: this.uid,
        schoolid: this.schoolid,
        verify: this.verify,
        problem_id: row.id
      }).then((res) => {
        //题目信息
        let probleminfo = res.probleminfo;
        this.problem_id = probleminfo.id;
        this.title = probleminfo.title;
        this.description = probleminfo.description;
        this.samples = probleminfo.samples;
        this.hint = probleminfo.hint;
        this.time_limit = probleminfo.time_limit;
        this.memory_limit = probleminfo.memory_limit;
        this.input = probleminfo.input ? probleminfo.input : '暂无';
        this.output = probleminfo.output ? probleminfo.output : '暂无';
        this.example_id = probleminfo.example_id ? probleminfo.example_id : null;
        this.difficulty = probleminfo.difficulty ? probleminfo.difficulty : '暂无';
        this.attachment_name = probleminfo.name ? probleminfo.name : null;
        this.attachment_path = probleminfo.path ? probleminfo.path : null;
        this.template_file_id = probleminfo.template_file_id ? probleminfo.template_file_id : null;
        // this.cfile_id = probleminfo.cfile_id ? probleminfo.cfile_id : null;
        this.run_status = probleminfo.run_status;
        this.problem_knowledge = probleminfo.knowledge;
        this.knowledge_array = this.problem_knowledge.split(';').filter(Boolean);
        //隐藏列表，显示ide，加载iframe
        this.listShow = false;
        this.ojShow = true;
        this.iframeShouldLoad = true;
      });
    },
    problemset (e, row) {
      codeexerciseAddEvent({
        uid: this.uid,
        schoolid: this.schoolid,
        verify: this.verify,
        problem_id: this.problem_id,
        exercise_id: this.exercise_id,
        type: "problemset"
      });
      this.$refs.problemsetButton.$el.blur();
      //隐藏ide，显示列表
      if (this.is_adaptive == 0) {
        this.prevproblem_ids.push(this.problem_id);//添加已经做过的问题
        this.iframeShouldLoad = false;
      }
      this.ojShow = false;
      this.listShow = true;
      this.buttonShow = false;
      this.fetchData();
    },
    prevproblem () {
      this.$refs.prevButton.$el.blur();
      let length = this.prevproblem_ids.length
      if (length == 0) {
        alert('已没有做过的题！');
      } else {
        codeexerciseAddEvent({
          uid: this.uid,
          schoolid: this.schoolid,
          verify: this.verify,
          problem_id: this.problem_id,
          exercise_id: this.exercise_id,
          type: "prev"
        });
        this.hintText = '点击查看提示';
        this.hintShow = false;
        let lastItem = this.prevproblem_ids.pop();
        problem({
          uid: this.uid,
          schoolid: this.schoolid,
          verify: this.verify,
          problem_id: lastItem
        }).then((res) => {
          //题目信息
          let probleminfo = res.probleminfo;
          this.problem_id = probleminfo.id;
          this.title = probleminfo.title;
          this.description = probleminfo.description;
          this.samples = probleminfo.samples;
          this.hint = probleminfo.hint;
          this.time_limit = probleminfo.time_limit;
          this.memory_limit = probleminfo.memory_limit;
          this.input = probleminfo.input ? probleminfo.input : '暂无';
          this.output = probleminfo.output ? probleminfo.output : '暂无';
          this.example_id = probleminfo.example_id ? probleminfo.example_id : null;
          this.difficulty = probleminfo.difficulty ? probleminfo.difficulty : '暂无';
          this.attachment_name = probleminfo.name ? probleminfo.name : null;
          this.attachment_path = probleminfo.path ? probleminfo.path : null;
          this.template_file_id = probleminfo.template_file_id ? probleminfo.template_file_id : null;
          // this.cfile_id = probleminfo.cfile_id ? probleminfo.cfile_id : null;
          this.run_status = probleminfo.run_status;
          this.problem_knowledge = probleminfo.knowledge;
          this.knowledge_array = this.problem_knowledge.split(';').filter(Boolean);
        });
      }
    },
    nextproblem () {
      codeexerciseAddEvent({
        uid: this.uid,
        schoolid: this.schoolid,
        verify: this.verify,
        problem_id: this.problem_id,
        exercise_id: this.exercise_id,
        type: "next"
      });
      this.$refs.nextButton.$el.blur();
      this.hintText = '点击查看提示';
      this.hintShow = false;
      // 找到当前问题在数组中的位置
      const index = this.tableShow.findIndex(item => item.id === this.problem_id);
      if (this.is_adaptive == 0 && index !== -1 && index < this.tableShow.length - 1) {
        // 获取下一条数据的 id
        const nextItemId = this.tableShow[index + 1].id;
        problem({
          uid: this.uid,
          schoolid: this.schoolid,
          verify: this.verify,
          problem_id: nextItemId
        }).then((res) => {
          //题目信息
          let probleminfo = res.probleminfo;
          this.prevproblem_ids.push(this.problem_id);//添加已经做过的问题
          this.problem_id = probleminfo.id;
          this.title = probleminfo.title;
          this.description = probleminfo.description;
          this.samples = probleminfo.samples;
          this.hint = probleminfo.hint;
          this.time_limit = probleminfo.time_limit;
          this.memory_limit = probleminfo.memory_limit;
          this.input = probleminfo.input ? probleminfo.input : '暂无';
          this.output = probleminfo.output ? probleminfo.output : '暂无';
          this.example_id = probleminfo.example_id ? probleminfo.example_id : null;
          this.difficulty = probleminfo.difficulty ? probleminfo.difficulty : '暂无';
          this.attachment_name = probleminfo.name ? probleminfo.name : null;
          this.attachment_path = probleminfo.path ? probleminfo.path : null;
          this.template_file_id = probleminfo.template_file_id ? probleminfo.template_file_id : null;
          // this.cfile_id = probleminfo.cfile_id ? probleminfo.cfile_id : null;
          this.run_status = probleminfo.run_status;
          this.problem_knowledge = probleminfo.knowledge;
          this.knowledge_array = this.problem_knowledge.split(';').filter(Boolean);
        });
      } else {
        codeexerciseNextProblem({
          uid: this.uid,
          schoolid: this.schoolid,
          verify: this.verify,
          problem_id: this.problem_id,
          exercise_knowledge: this.knowledge_id,
          is_adaptive: this.is_adaptive,
          tree_id: this.tree_id
        }).then((res) => {
          if (res.probleminfo == null) {
            this.$alert("已经练习完了所有题目！", "状态提示", {
              confirmButtonText: "确定",
              showClose: false,
            });
            this.ojshow = false;
            this.listShow = false;
            this.iframeId = false;
            return;
          }

          if (this.have_learned == 0 && res.have_learned == 1) {
            this.$alert("恭喜你学完了所有知识点！进入能力提升阶段！", "状态提示", {
              confirmButtonText: "确定",
              showClose: false,
            });
          }
          //更新是否学会了所有知识点
          this.have_learned = res.have_learned;
          //题目信息
          let probleminfo = res.probleminfo;
          this.prevproblem_ids.push(this.problem_id);//添加已经做过的问题
          this.problem_id = probleminfo.id;
          this.title = probleminfo.title;
          this.description = probleminfo.description;
          this.samples = probleminfo.samples;
          this.hint = probleminfo.hint;
          this.time_limit = probleminfo.time_limit;
          this.memory_limit = probleminfo.memory_limit;
          this.input = probleminfo.input ? probleminfo.input : '暂无';
          this.output = probleminfo.output ? probleminfo.output : '暂无';
          this.example_id = probleminfo.example_id ? probleminfo.example_id : null;
          this.difficulty = probleminfo.difficulty ? probleminfo.difficulty : '暂无';
          this.attachment_name = probleminfo.name ? probleminfo.name : null;
          this.attachment_path = probleminfo.path ? probleminfo.path : null;
          this.template_file_id = probleminfo.template_file_id ? probleminfo.template_file_id : null;
          // this.cfile_id = probleminfo.cfile_id ? probleminfo.cfile_id : null;
          this.run_status = probleminfo.run_status;
          this.problem_knowledge = probleminfo.knowledge;
          this.knowledge_array = this.problem_knowledge.split(';').filter(Boolean);
        });
      }
    },
    randomproblem () {
      codeexerciseAddEvent({
        uid: this.uid,
        schoolid: this.schoolid,
        verify: this.verify,
        problem_id: this.problem_id,
        exercise_id: this.exercise_id,
        type: "random"
      });
      this.hintText = '点击查看提示';
      this.hintShow = false;
      this.$refs.randomButton.$el.blur();
      codeexerciseRandomProblem({
        uid: this.uid,
        schoolid: this.schoolid,
        verify: this.verify,
        knowledge_id: this.knowledge_id
      }).then((res) => {
        //题目信息
        let probleminfo = res.probleminfo;
        this.prevproblem_ids.push(this.problem_id);//添加已经做过的问题
        this.problem_id = probleminfo.id;
        this.title = probleminfo.title;
        this.description = probleminfo.description;
        this.samples = probleminfo.samples;
        this.hint = probleminfo.hint;
        this.time_limit = probleminfo.time_limit;
        this.memory_limit = probleminfo.memory_limit;
        this.input = probleminfo.input ? probleminfo.input : '暂无';
        this.output = probleminfo.output ? probleminfo.output : '暂无';
        this.example_id = probleminfo.example_id ? probleminfo.example_id : null;
        this.difficulty = probleminfo.difficulty ? probleminfo.difficulty : '暂无';
        this.attachment_name = probleminfo.name ? probleminfo.name : null;
        this.attachment_path = probleminfo.path ? probleminfo.path : null;
        this.template_file_id = probleminfo.template_file_id ? probleminfo.template_file_id : null;
        // this.cfile_id = probleminfo.cfile_id ? probleminfo.cfile_id : null;
        this.run_status = probleminfo.run_status;
        this.problem_knowledge = probleminfo.knowledge;
        this.knowledge_array = this.problem_knowledge.split(';').filter(Boolean);
      });
    },
    handleSizeChange (pageSize) {
      this.pageSize = pageSize;
      this.fetchData();
    },
    handleCurrentChange (currentPage) {
      this.currentPage = currentPage;
      this.fetchData();
    },
    fetchData () {
      let submitInfo = {
        uid: this.uid,
        schoolid: this.schoolid,
        verify: this.verify,

        knowledge_id: this.knowledge_id,

        pagesize: this.pageSize,
        currentpage: this.currentPage,
        keyword: this.keyword
      };

      codeexerciseProblemList(submitInfo).then((res) => {
        this.tableShow = res.probleminfo ? res.probleminfo : [];
        this.tableData = res.probleminfo;
        this.problem_num = res.problem_num;
        this.currentPage = parseInt(res.currentpage);
      });
    },
  },

  computed: {
    tagtype: function () {
      let difficulty = this.difficulty;
      if (difficulty == null) {
        return "info";
      } else if (difficulty < 4) {
        return "success";
      } else if (difficulty < 8) {
        return "warning";
      } else {
        return "danger";
      };
    },
    recommendMethod: function () {
      if (this.is_adaptive == 1) {
        return '自适应推荐';
      } else if (this.is_adaptive == 0) {
        return '推荐+自选';
      } else {
        return null;
      }
    },
    difficultyshow: function () {
      return this.difficulty ? this.difficulty : "暂无";
    },
    iframeSrc: function () {
      let template_file_id = this.template_file_id != null ? this.template_file_id : "'" + 'null' + "'";
      let tree_id = this.tree_id != null ? this.tree_id : "'" + 'null' + "'";
      let problem_id = this.problem_id != null ? this.problem_id : "'" + 'null' + "'";
      let ddl = "'" + this.ddl + "'";
      let run_status = "'" + this.run_status + "'";
      let intime = this.intime != null ? this.intime : "'" + 'null' + "'";
      //这个方法比较弱智，仅供参考哈哈哈哈
      return `static/Coj/index3.html?language_type=${this.type}&intime=${intime}&template_file_id=${template_file_id}&exercise_id=${this.exercise_id}&tree_id=${tree_id}&problem_id=${problem_id}&ddl=${ddl}&run_status=${run_status}`;
    },
    problemsetShow: function () {
      // return this.ojShow && this.is_adaptive == 0 && this.have_learned == 1;
      return this.ojShow && this.have_learned == 1;
    },
    randomShow: function () {
      return this.ojShow && this.is_adaptive == 0 && this.have_learned == 1;
    }
  },
  created () {
    this.module_id = this.$route.params.id;
    this.course_id = this.$route.params.kcid;
    this.getUserInfo();

    codeExercisePage({
      module_id: this.module_id,
      uid: this.uid,
      schoolid: this.schoolid,
      verify: this.verify,
      pagesize: this.pageSize,
      currentpage: this.currentPage,
    }).then((res) => {
      this.is_adaptive = res.is_adaptive;
      this.beginexercise_show = this.is_adaptive == 0 ? true : false;
      this.have_learned = res.have_learned;
      //练习信息
      let exerciseinfo = res.exerciseinfo;
      this.exercise_id = exerciseinfo.id;
      this.name = exerciseinfo.name;
      this.summary = exerciseinfo.summary;
      this.begin_time = exerciseinfo.begin_time;
      this.end_time = exerciseinfo.end_time;
      this.knowledge_id = exerciseinfo.knowledge_id;
      this.knowledge = exerciseinfo.knowledge_name;
      this.tree_id = exerciseinfo.tree_id;
      this.tree = exerciseinfo.tree_name;
      this.type = exerciseinfo.type;
      this.intime = null;
      this.all_problem_num = res.submitinfo.all_problem_num;
      this.total_problems = res.submitinfo.total_problems;
      this.correct_problems = res.submitinfo.correct_problems;
      this.correct_rate = res.submitinfo.correct_rate;
      //题目信息
      if (this.is_adaptive == 1 || (this.is_adaptive == 0 && this.have_learned == 0)) {
        let probleminfo = res.probleminfo;
        if (probleminfo == null) {
          this.$alert("已经练习完了所有题目！", "状态提示", {
            confirmButtonText: "确定",
            showClose: false,
          });
          this.finishAll = true;
          this.buttonShow = true;
          return;
        }
        this.problem_id = probleminfo.id;
        this.title = probleminfo.title;
        this.description = probleminfo.description;
        this.samples = probleminfo.samples;
        this.hint = probleminfo.hint;
        this.memory_limit = probleminfo.memory_limit ? probleminfo.memory_limit : 暂无;
        this.time_limit = probleminfo.time_limit ? probleminfo.time_limit : 暂无;
        this.input = probleminfo.input ? probleminfo.input : '暂无';
        this.output = probleminfo.output ? probleminfo.output : '暂无';
        this.example_id = probleminfo.example_id ? probleminfo.example_id : null;
        this.difficulty = probleminfo.difficulty ? probleminfo.difficulty : '暂无';
        this.attachment_name = probleminfo.name ? probleminfo.name : null;
        this.attachment_path = probleminfo.path ? probleminfo.path : null;
        this.template_file_id = probleminfo.template_file_id ? probleminfo.template_file_id : null;
        // this.cfile_id = probleminfo.cfile_id ? probleminfo.cfile_id : null;
        this.run_status = probleminfo.run_status;
        this.problem_knowledge = probleminfo.knowledge;
        this.knowledge_array = this.problem_knowledge.split(';').filter(Boolean);
      } else {
        this.tableShow = res.probleminfo ? res.probleminfo : [];
        this.tableData = res.probleminfo;
        this.problem_num = res.problem_num;
      }

      //这里先都定为未完成，之后再说
      this.wcstate = "未完成"
      //比较两个日期大小。格式：2018-8-12 12:30
      let year = new Date().getFullYear();
      let month = new Date().getMonth() + 1;
      let day = new Date().getDate();
      let hour = new Date().getHours();
      let minute = new Date().getMinutes();
      let second = new Date().getSeconds();
      let time = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second
      const compareDate = (t1, t2) => {
        return ((new Date(t1.replace(/\-/g, "\/"))) > (new Date(t2.replace(/\-/g, "\/"))));
      }
      if (this.end_time !== null && this.begin_time !== null) {
        this.ddl = [compareDate(this.end_time, time) && compareDate(time, this.begin_time)][0];
      }
      if (this.ddl === false) {
        this.intime = 0
        this.$alert("" + "不在任务完成时间", "状态提示", {
          confirmButtonText: "确定",
          showConfirmButton: false,
        });
        setTimeout(() => {
          this.$msgbox.close();
        }, 2000);
      } else {
        this.intime = 1
      }
      //开始加载iframe
      if (this.is_adaptive == 1 || (this.is_adaptive == 0 && this.have_learned == 0)) {
        this.ojShow = true;
        this.iframeShouldLoad = true;
        this.listShow = false;
      } else {
        this.ojShow = false;
        this.listShow = true;
      }
    });
  },
  mounted () {
    window.addEventListener('message', this.handleMessage);
  },
  beforeDestroy () {
    window.removeEventListener('message', this.handleMessage);
  },
  components: {
    EmptyFooter
  },
};
</script>
<style lang="css"  src='../../src/assets/.css/common.css'  scoped></style>
<style lang="css"  src='../../src/assets/.css/index.css'  scoped></style>
<style lang="css"  src='../../src/assets/.css/page.css'  scoped></style>
<style lang="css" scoped>
* {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.problembg {
  background-color: rgb(255, 255, 255);
}

.exercisebg {
  background-color: #f7fcfc;
}

.interactive-span:hover {
  color: #31ABAF;
}

.interactive-span:hover .el-icon-refresh {
  color: #31ABAF;
}

.interactive-span:hover .el-icon-arrow-left {
  color: #31ABAF;
}

.interactive-span:hover .el-icon-arrow-right {
  color: #31ABAF;
}

.interactive-span:hover .el-icon-notebook-1 {
  color: #31ABAF;
}

.el-button:hover .el-icon-arrow-right {
  color: #409EFF;
  /* 鼠标悬浮时的颜色，你可以根据实际需要进行调整 */
}

.status-gray {
  color: #bebebe;
}

.status-green {
  color: #98c379;
}

.status-red {
  color: #e06c75;
}

.container {
  display: flex;
  /* 使用Flexbox布局 */
  width: 90%;
  margin: 0 auto;
  height: 600px;
  padding: 10px;
  /* 设置外层div的高度 */
}

.info {
  flex: 40%;
  /* 占据40%的空间 */
  overflow-y: auto;
  /* 允许垂直方向滚动 */
  padding: 10px;
  /* 添加一些内边距 */
  box-sizing: border-box;
  /* 确保内边距不影响总宽度 */
  text-align: left;
}

.iframeStyle {
  flex: 60%;
  /* iframe占据剩余的60%空间 */
  height: 100%;
  /* iframe的高度与外层div相同 */
  margin-bottom: -5px;
  /* 根据需要调整的样式 */
}

.sample-style {
  text-align: left;
  width: 90%;
  background-color: #F1F1F1;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.basic-info {
  text-align: left;
  width: 95%;
  background-color: #FBFBFB;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 5%;
}

.info-card {
  margin-top: 20px;
  margin-left: 10px;
  margin-bottom: 20px;
  width: 90%;
}

.hint {
  width: 95%;
  background-color: white;
  padding: 10px;
  margin-top: 20px;
  box-sizing: border-box;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.searchTitle {
  font-size: 16px;
}

.exerciseName {
  width: 20%;
}

.searchBtn {
  width: 100px;
}

.titleContainer {
  display: flex;
  align-items: center;
}

.greenCard {
  background-color: rgba(169, 247, 210, 0.1);
}

.recordContainer {
  display: flex;
  width: 100%;
}

.flexBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.recordContent {
  width: 33%;
  padding: 0 20px;
  color: #00979c;
}

.rightBorder {
  border-right: #ddd 1px solid;
}
</style>