<template>
  <div class="loginBody registerBody commonBody">

    <body>
      <div>
        <div>
          <div class="top wrapper">
            <div class="flex_between">
              <span></span>
              <router-link class="btn btn_light" style=";width:80px" to="/login">登录</router-link>
            </div>
            <div class="flex_between">
              <!-- logo图片 -->
              <img style="width:400px;margin:0 auto;" class="logo" :src="logo" alt="" id="logo" />

            </div>
          
          </div>
          <div class="register_cotainer" >
            <div class="box register_box">
              <h2>找回密码</h2>
              <div>
                <div class="form_feild">
                  <div class="cell">
                    <div class="cell_hd">账号</div>
                    <div class="cell_bd">
                      <input style="height: 36px" v-model="form.account" type="text" placeholder="请输入"
                        autocomplete="off" />
                    </div>
                  </div>

                  <div class="cell">
                    <div class="cell_hd">密保问题</div>
                    <div class="cell_bd">
                      <el-select size="medium" v-model="form.question_id" placeholder="请选择" style="width: 390px">
                        <el-option style="padding-left: 20px" v-for="(item, index) in question" :key="index"
                          :value="item.id" :label="item.question">
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                  <div class="cell">
                    <div class="cell_hd">密保问题答案</div>
                    <div class="cell_bd">
                      <input style="height: 36px" v-model="form.answer" type="text" placeholder="请输入"
                        autocomplete="off" />
                    </div>
                  </div>
                  <div class="cell">
                    <div class="cell_hd">所在学校</div>
                    <div class="cell_bd select_sf">
                      <el-select size="medium" v-model="form.school_id" placeholder="请选择" style="width: 390px">
                        <el-option style="padding-left: 20px" v-for="(item, index) in schools" :key="index"
                          :label="item.name" :value="item.id"></el-option>
                      </el-select>
                    </div>
                  </div>

                  <div class="cell">
                    <div class="cell_hd">登录密码</div>
                    <div class="cell_bd">
                      <!-- <el-form :model="form" :rules="rules" ref="form">
                        <el-form-item prop="password">
                          <el-input
                            type="password"
                            v-model="form.password"
                            placeholder="输入密码"
                            style="width: 390px"
                            size="medium"
                          ></el-input>
                        </el-form-item>
                      </el-form> -->
                      <el-form :model="form" status-icon :rules="rules" ref="form">
                        <el-form-item prop="password">
                          <el-input type="password" v-model="form.password" placeholder="输入密码" style="width: 390px"
                            size="medium"></el-input>
                        </el-form-item>
                      </el-form>
                    </div>
                  </div>
                  <div class="cell">
                    <div class="cell_hd">确认密码</div>
                    <div class="cell_bd">
                      <!-- <el-form :model="form" :rules="rules" ref="form">
                        <el-form-item prop="password">
                          <el-input
                            type="password"
                            v-model="form.password"
                            placeholder="输入密码"
                            style="width: 390px"
                            size="medium"
                          ></el-input>
                        </el-form-item>
                      </el-form> -->
                      <el-form :model="form" status-icon :rules="rules" ref="form">
                        <el-form-item prop="checkPass">
                          <el-input type="password" v-model="form.checkPass" placeholder="输入密码" style="width: 390px"
                            size="medium"></el-input>
                        </el-form-item>
                      </el-form>
                    </div>
                  </div>

                  <!-- <div class="cell">
                    <div class="cell_hd">确认密码</div>
                    <div class="cell_bd">
                      <input style="height: 36px" id="repassword" type="password" placeholder="请输入" autocomplete="off" />
                    </div>
                  </div> -->
                  <!-- <div class="cell cell_code">
                    <div class="cell_hd">验证码</div>
                    <div class="cell_bd">
                      <el-form :model="form" :rules="rules" ref="form">
                        <el-form-item prop="verifyCode">
                          <el-input v-model="form.verifyCode" placeholder="验证码" style="width: 270px"></el-input>
                        </el-form-item>
                      </el-form>
                      <div @click="click()">
                        <img :src="verifycode" />
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
              <div @click="find()">
                <el-alert v-if="n === 1" type="error" center show-icon>{{ msg }}
                </el-alert>
              </div>

              <button @click="find()" class="btn" style="font-size:15px;width:300px" name="login-button" id="loginbtn">
                找回密码
              </button>

              <br />
            </div>
            <div></div>
          </div>
        </div>
        <div class="footer">
          <p>{{ copyright }}</p>
          <p>支持电话：{{ tel }}</p>
        </div>
      </div>
    </body>
  </div>
</template>

<script>
import { passwordpage, password, verifycode, getparam, getparamfile } from "@/http/api.js"; // 导入接口
import FingerprintJS from "@fingerprintjs/fingerprintjs";
export default {
  name: "findpwd",
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.form.checkPass !== '') {
          this.$refs.form.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.form.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      tel: "",
      copyright: "",
      logo: "",
      verifycode: "",
      question: [],
      schools: [],
      n: 0,
      msg: "",
      form: {},
      rules: {

        checkPass: [
          { validator: validatePass2, trigger: 'blur' }
        ],
        password: [
          {
            validator: validatePass,
            required: true,
            min: 6,
            trigger: 'blur',
            message: "不得少于6个字符",
          },
        ],
        verifyCode: [
          {
            required: true,
            length: 4,
            message: "为四个字符",
          },
        ],
      },
    };
  },
  created() {
    // FingerprintJS.load().then((fp) => {
    //   fp.get().then((result) => {
    //     this.$global.clientUid = result.visitorId;
    //   });
    // });
  },
  methods: {
    // click() {
    //   verifycode({ clientUid: this.$global.clientUid }).then((res) => {
    //     this.verifycode = res.verifycode;
    //   });
    // },
    find() {
      password({
        account: this.form.account,
        question_id: this.form.question_id,
        school_id: this.form.school_id,
        answer: this.form.answer,
        password: this.form.password,
        // verifycode: this.form.verifyCode,
        // clientUid: this.$global.clientUid,
      }).then(
        (res) => {
          console.log(this.form.answer);
          if (res.status == 1) {
            this.$alert("" + "密码修改成功", "提示", {
                confirmButtonText: "确定",
                showConfirmButton: false,
                showCancelButton: false,
                showClose: false,

              });
              setTimeout(() => {
                this.$msgbox.close();
                this.$router.push({ name: "login" });
                this.clearCookie();
              }, 2000);
          }
          if (res.status != 1) {
            this.$alert("" + res.msg, "错误提示", {
              confirmButtonText: "确定",
              beforeClose: (action, instance, done) => {
                if (action === "confirm") {
                  instance.confirmButtonLoading = true;
                  instance.confirmButtonText = "执行中...";
                  // setTimeout(() => {
                  //   done();
                  //   setTimeout(() => {
                  //     instance.confirmButtonLoading = false;
                  //     verifycode({ clientUid: this.$global.clientUid }).then(
                  //       (res) => {
                  //         this.verifycode = res.verifycode;
                  //       }
                  //     );
                  //   }, 100);
                  // }, 100);
                } else {
                  done();
                }
              },
            });
          }
        },
        function (error) { }
      );
    },
    keyDown(e) {
      // 回车则执行登录方法 enter键的ASCII是13
      if (e.keyCode === 13) {
        this.find() // 需要执行的方法方法
      }
    },
  },
  mounted() {
    window.addEventListener('keydown', this.keyDown)
    setTimeout(() => {
      getparam({
        name: "tel",

      }).then((res) => {
        console.log('page', res)
        this.tel = res.value


      });
      getparam({
        name: "copyright",

      }).then((res) => {
        console.log('page', res)
        this.copyright = res.value


      });
      getparamfile({
        name: "logo",

      }).then((res) => {
        console.log('page', res)
        this.logo = res.value



      });
      console.log(this.$global.clientUid);
      passwordpage({ }).then((res) => {
        this.question = res.question;
        this.schools = res.schools;
        this.schools.unshift(...[{ id: 0, name: "--" }]);
      });
    }, 200);

  },
  destroyed() {
    window.removeEventListener('keydown', this.keyDown, false)
  }
};
</script>

<style lang="css" scoped>
.select_sf {
  flex-grow: initial;
}

#xcuser-type {
  height: 42px;
  line-height: 100%;
  width: 95px;
}

.register {
  background: none;
}
</style>

<style lang="css"  src='../../src/assets/.css/common.css'  scoped></style>
<style lang="css"  src='../../src/assets/.css/login.css'  scoped></style>
<style lang="css"  src='../../src/assets/.css/register.css'  scoped></style>
<style lang="css" scoped>
* {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}
</style>