<template>
  <div>
    <div style="position: absolute; min-width: 1250px">
      <div class="img_title flex_center">
        <img
          style="width: 100%"
          src="../../src/assets/img/competition_bg.png"
          alt=""
        />
      </div>
      <div class="course_list">
        <div class="clearfix" style="width: 100%">
          <div
            class="hc_item box"
            v-for="(item, index) in competition_array"
            :key="index"
            v-if="
              [(currentPage - 1) * pageSize] <= index &&
              index < currentPage * pageSize
            "
            style="
              width: 20%;
              height: 210px;
              float: left;
              margin-left: 85px;
              margin-right: -60px;
              margin-bottom: 20px;
            "
          >
            <div class="cover_area">
              <router-link
                :to="{ name: 'competitiondetail', params: { id: item.id } }"
              >
                <img
                  style="height: 150px"
                  :src="item.cover_url"
                  alt=""
                  class="object_fit cover"
                />
              </router-link>
            </div>
            <div class="item_course_info">
              <div style="margin-top: -10px">
                <span class="ellipsis" style="color: rgb(44, 62, 80)"
                  >{{ item.name
                  }}<i
                    style="
                      margin-left: 5px;
                      font-size: 10px;
                      color: rgb(239, 88, 10);
                      font-weight: bold;
                    "
                    v-if="item.school_id === 0"
                    >全区活动</i
                  ></span
                >
                <div
                  class="ellipsis"
                  style="
                    font-size: 15px;
                    text-align: left;
                    padding-left: 10px;
                    margin-top: -12px;
                  "
                >
                  {{ $t("message.registrationdeadline") }}：<i
                    class="tag"
                    style="font-size: 13px"
                    >{{ item.entry_deadline }}</i
                  >
                </div>

                <div
                  class="flex_between"
                  style="padding-left: 10px; padding-right: 10px"
                >
                  <em v-if="item.limitation === -1"
                    >{{ $t("message.registrationlimit") }}：未设置上限</em
                  >
                  <em v-if="item.limitation != -1"
                    >{{ $t("message.registrationlimit") }}：{{
                      item.limitation
                    }}</em
                  >
                  <em>{{ item.click_num }}{{ $t("message.check") }}</em>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="competition_array === null"
            style="text-align: center; margin-top: 200px"
          >
            <div>
              <img
                src="../../src/assets/img/nomessage.png"
                style="width: 50px; text-align: center; position: absolute"
              />
              <span
                style="
                  height: 100px;
                  line-height: 100px;
                  left: 60px;
                  position: relative;
                  top: -20px;
                  color: grey;
                "
                >{{ $t("message.nocompetition") }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="position: relative; top: 640px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[8, 16, 24, 32]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="competition_array ? competition_array.length : 0"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import CommonFooter from "@/components/CommonFooter.vue";
import { competitioncenter } from "@/http/api.js"; // 导入接口
export default {
  name: "competitioncenter",
  data() {
    return {
      competition_array: [],
      currentPage: 1,
      pageSize: 8,
    };
  },
  components: {
    CommonFooter,
  },
  methods: {
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
    },
  },
  mounted() {
    let token = JSON.parse(localStorage.getItem("Authorization"));
    let verify = localStorage.getItem("verify");
    competitioncenter({
      type: token.type,
      uid: token.user_id,
      schoolid: token.school_id,
      verify: verify,
    }).then((res) => {
      console.log("competition_array", res);
      this.competition_array = res.competition_array;
    });
  },
};
</script>
<style lang="css" src="../../src/assets/.css/common.css" scoped></style>
<style lang="css" src="../../src/assets/.css/index.css" scoped></style>
<style lang="css" src="../../src/assets/.css/page.css" scoped></style>
<style lang="css" scoped>
* {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
<style scoped lang="css">
.fot {
  position: fixed;
  bottom: 0;
  width: 100%;
  line-height: var(--footer-height);
  color: #fff;
  margin-bottom: 5px;
}
</style>
