<template>
  <div class="loginBody">
    <body>
      <div class="login_feild">
        <div class="login_container">
          <!-- logo图片 -->
          <img
            style="height: 50px; margin-top: 50px"
            class="logo"
            :src="front_logo"
          />

          <div class="login_box">
            <h2>登录</h2>
            <div>
              <div class="form_feild">
                <div class="cell" style="height: 75px; width: 320px">
                  <div
                    class="cell_hd icon_uname"
                    style="margin-top: 15px"
                  ></div>
                  <div class="cell_bd">
                    <!-- 表单 -->
                    <el-form
                      :model="form"
                      :rules="rules"
                      ref="form"
                      style="margin-top: 15px"
                    >
                      <el-form-item prop="username">
                        <el-input
                          type="text"
                          v-model="form.username"
                          placeholder="学号或教工号"
                          style="width: 270px"
                        ></el-input>
                      </el-form-item>
                    </el-form>
                  </div>
                </div>
                <div
                  class="cell"
                  style="height: 75px; width: 320px; margin-top: -10px"
                >
                  <div class="cell_hd1 icon_sch"></div>
                  <div class="cell_bd">
                    <!-- 表单 -->
                    <el-select
                      size="medium"
                      v-model="form.school_id"
                      placeholder="请选择"
                      style="width: 270px; margin-top: 15px"
                    >
                      <el-option
                        v-for="(item, index) in schools"
                        :key="index"
                        :label="item.name"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </div>
                </div>

                <div
                  class="cell"
                  style="height: 75px; width: 320px; margin-top: -10px"
                >
                  <div class="cell_hd icon_upwd" style="margin-top: 15px"></div>
                  <div class="cell_bd">
                    <el-form :model="form" :rules="rules" ref="form">
                      <el-form-item prop="password">
                        <el-input
                          type="password"
                          v-model="form.password"
                          clearable
                          placeholder="输入密码"
                          style="width: 270px; margin-top: 15px"
                        ></el-input>
                      </el-form-item>
                    </el-form>
                  </div>
                </div>
                <!-- <div
                  class="cell cell_code"
                  style="height: 75px; margin-top: -10px; width: 320px"
                >
                  <div class="cell_hd icon_code" style="margin-top: 15px"></div>
                  <div class="cell_bd">
                    <el-form :model="form" :rules="rules" ref="form">
                      <el-form-item prop="verifyCode">
                        <el-input
                          v-model="form.verifyCode"
                          placeholder="验证码"
                          style="width: 150px; margin-top: 15px"
                        ></el-input>
                      </el-form-item>
                    </el-form>
                    <div @click="click()">
                      <img
                        style="margin-top: 20px; margin-left: 10px"
                        :src="verifycode"
                      />
                    </div>
                  </div>
                </div> -->
                <div class="link_feild" style="margin-top: 5px">
                  <div>
                    <input type="checkbox" v-model="isRemember" />
                    <label>记住密码</label>
                  </div>
                </div>
              </div>
            </div>
            <router-link to="/findpwd" style="float: right; margin-top: -20px"
              >忘记密码？</router-link
            >
            <button
              v-on:click="loginPage()"
              class="btn"
              style="font-size: 15px; margin-top: -10px"
            >
              登录
            </button>
            <router-link
              to="/register"
              class="register_link"
              style="margin-top: 0px"
              >注册账户</router-link
            >
          </div>
        </div>
      </div>
      <div class="footer">
        <p>{{ copyright }}</p>
        <p>支持电话：{{ tel }}</p>
      </div>
    </body>
  </div>
</template>

<script>
import { loginpage, verifycode, login } from "@/http/api.js"; // 导入接口
import { getparam, getparamfile } from "@/http/api.js";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
export default {
  name: "login",
  created() {
    let token = JSON.parse(localStorage.getItem("Authorization"));
    if (token != null) {
      this.$router.push("common");
    }
    // FingerprintJS.load().then((fp) => {
    //   fp.get().then((result) => {
    //     this.$global.clientUid = result.visitorId;
    //     this.clientUid=result.visitorId;
    //   });
    // });
  },

  data() {
    return {
      front_logo: "",
      tel: null,
      copyright: "",
      isRemember: false,
      logo: "",
      schools: [],
      verifycode: "", //验证码图片
      // clientUid: "",
      form: {
        username: "",
        school_id: "",
        password: "",
        verifyCode: "",
      },
      rules: {
        username: [
          { required: true, message: "请输入用户名称", trigger: "blur" },
        ],
        password: [
          {
            required: true,
            min: 6,
            message: "不得少于6个字符",
            trigger: "blur",
          },
        ],
        // verifyCode: [
        //   {
        //     required: true,
        //     min: 4,
        //     max: 4,
        //     message: "为四个字符",
        //     trigger: "blur",
        //   },
        // ],
      },
    };
  },
  

  methods: {
    /* 第3步，当用户执行登录操作的时候，先看看用户名密码对不对
         若不对，就提示登录错误
         若对，就再看一下用户有没有勾选记住密码
            若没勾选，就及时清空cookie，回到最初始状态
            若勾选了，就把用户名和密码存到cookie中并设置7天有效期，以供使用
             （当然也有可能是更新之前的cookie时间）
    */

    loginPage() {
      // 发请求看看用户输入的用户名和密码是否正确
      let submitInfo = {
        account: this.form.username,
        school_id: this.form.school_id,
        password: this.form.password,
      };
      // 当然，无论用户是否勾选了cookie，路由该跳转还是要跳转的
      login(submitInfo).then(
        (res) => {
          if (res.status == 1) {
            var localStorage = window.localStorage;
            var sessionStorage = window.sessionStorage;
            localStorage.setItem("timestamp", new Date().getTime());
            sessionStorage.setItem("greenPath", 1);
            localStorage.setItem("Authorization", JSON.stringify(res.loginfo));
            localStorage.setItem("verify", this.form.password);
            let visitor = localStorage.removeItem("visitor");
            console.log(res.loginfo);
            if (this.isRemember === true) {
              this.setCookie(this.form.username, this.form.password, 7);
            } else {
              this.clearCookie();
            }
            if (
              res.loginfo.type == 3 ||
              res.loginfo.type == 4 ||
              res.loginfo.type == 5
            ) {
              let visitor = localStorage.removeItem("visitor");
              this.$router.push({ name: "DataView" });
            }
            if (res.loginfo.type == 1 || res.loginfo.type == 2) {
              let visitor = localStorage.removeItem("visitor");
              this.$router.push({ name: "common" });
            }
          } else {
            this.$alert("" + res.msg, "错误提示", {
              confirmButtonText: "确定",
              beforeClose: (action, instance, done) => {
                if (action === "confirm") {
                  instance.confirmButtonLoading = true;
                  instance.confirmButtonText = "执行中...";
                  // setTimeout(() => {
                  //   done();
                  //   setTimeout(() => {
                  //     instance.confirmButtonLoading = false;
                  //     verifycode({ }).then(
                  //       (res) => {
                  //         this.verifycode = res.verifycode;
                  //       }
                  //     );
                  //   }, 100);
                  // }, 100);
                } else {
                  done();
                }
              },
            });
          }
        },
        function (error) {}
      );
    },
    keyDown(e) {
      // 回车则执行登录方法 enter键的ASCII是13
      if (e.keyCode === 13) {
        this.loginPage(); // 需要执行的方法方法
      }
    },

    // 设置cookie
    setCookie(username, password, exdays) {
      var exdate = new Date(); // 获取当前登录的时间
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); // 将当前登录的时间加上七天，就是cookie过期的时间，也就是保存的天数
      // 字符串拼接cookie,因为cookie存储的形式是name=value的形式
      window.document.cookie =
        "username" + "=" + username + ";path=/;expires=" + exdate.toGMTString();
      window.document.cookie =
        "password" + "=" + password + ";path=/;expires=" + exdate.toGMTString();
      window.document.cookie =
        "isRemember" +
        "=" +
        this.isRemember +
        ";path=/;expires=" +
        exdate.toGMTString();
    },
    // 第2步，若cookie中有用户名和密码的话，就通过两次切割取出来存到form表单中以供使用，若是没有就没有
    getCookie() {
      if (document.cookie.length > 0) {
        var arr = document.cookie.split("; "); //因为是数组所以要切割。打印看一下就知道了
        // console.log(arr,"切割");
        for (var i = 0; i < arr.length; i++) {
          var arr2 = arr[i].split("="); // 再次切割
          console.log("arr2", arr2);
          // 判断查找相对应的值
          if (arr2[0] === "username") {
            // 转存一份保存用户名和密码
            this.form.username = arr2[1];
          } else if (arr2[0] === "password") {
            this.form.password = arr2[1]; //可解密
          } else if (arr2[0] === "isRemember") {
            this.isRemember = Boolean(arr2[1]);
          }
        }
      }
    },
    // 清除cookie
    clearCookie() {
      this.setCookie("", "", -1); // 清空并设置天数为负1天
    },

    send() {},

    // click() {
    //   verifycode({ clientUid: this.clientUid }).then((res) => {
    //     this.verifycode = res.verifycode;
    //   });
    // },
  },
  mounted() {
    window.addEventListener("keydown", this.keyDown);

    setTimeout(() => {
      getparam({
        name: "tel",
      }).then((res) => {
        console.log("page", res);
        this.tel = res.value;
      });
      getparam({
        name: "copyright",
      }).then((res) => {
        console.log("page", res);
        this.copyright = res.value;
      });

      getparamfile({
        name: "logo",
      }).then((res) => {
        console.log("page", res);
        this.front_logo = res.value;
      });

      this.getCookie();
    

      loginpage({ }).then((res) => {
        this.logo = res.logo;
        this.verifycode = res.verifycode;
        this.schools = res.schools;
        this.schools.unshift(...[{ id: 0, name: "--" }]);
      });
    }, 200);
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyDown, false);
  },
};
</script>
<style lang="css" src="../../src/assets/.css/login.css" scoped></style>
<style lang="css" scoped>
* {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
