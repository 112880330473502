<template>
  <div>
    <!-- <div v-if="type2===null&&status===0">
  
 <CommonLayout></CommonLayout>
    </div>
    <div v-if="type2===1&&status===1">
 <CommonStudent></CommonStudent>
    </div>
    <div v-if="type2===2&&status===1">
 <CommonTeacher></CommonTeacher>
    </div> -->
    <div>
      <div class="bread" style="padding: 20px 0; text-align: left; margin-left: 5%">
        <router-link :to="{ name: 'coursedetail', params: { id: kcid } }" style="font-size: 20px">{{ $t("message.return")
        }}</router-link>
        <span style="font-size: 20px">{{ $t("message.video") }}</span>
      </div>

      <div class="wrapper flex video_container">
        <!-- <div class="video_list">
          <h3>{{ $t('message.courseoutline') }}</h3>
          <el-tree
            :data="result"
            :props="defaultProps"
            @node-click="handleNodeClick"
            style="margin-left: 50px"
          ></el-tree>
        </div> -->
        <div v-if="src === '' && link === ''">视频播放失败</div>

        <!-- <videoPlayer v-else style="width:1200px" ref="videoPlayer" :playsinline="true" :options="playerOptions">
        </videoPlayer> -->
        <iframe v-else-if="link !== '' && src === ''" style="margin-left: 200px" :src="link" scrolling="no" border="0"
          frameborder="no" framespacing="0" allowfullscreen="true">
        </iframe>
        <iframe v-else="src !== ''" style="margin-left: 200px" :src="src" scrolling="no" border="0" frameborder="no"
          framespacing="0" allowfullscreen="true">
        </iframe>
        <videoPlayer v-else style="width: 1200px;" ref="videoPlayer" :playsinline="true" :options="playerOptions">
        </videoPlayer>
      </div>

      <!-- <div class="wrapper course_resource_container">
        <h2>
          <span>{{ name }}-{{ cname }}</span>
        </h2>
      </div> -->
    </div>

    <EmptyFooter />
  </div>
</template>

<script>
import arrayToTree from "array-to-tree";
import { videopage } from "@/http/api.js";
import CommonLayout from "@/components/CommonLayout.vue";
import EmptyFooter from "@/components/EmptyFooter.vue";
import CommonStudent from "@/components/CommonStudent.vue";
import CommonTeacher from "@/components/CommonTeacher.vue";
import { videoPlayer } from "vue-video-player";
export default {
  name: "coursevideo",
  // props: {
  // 	src: {
  // 		type: String,
  // 		default: ''
  // 	}
  // },
  data () {
    return {
      link: "",
      status: "",
      type2: "",
      id: "",
      kcid: "",
      file_url: "",

      reid: "",
      module_id: "",
      name: "",
      cname: "",

      outline: [],

      result: [],
      defaultProps: {
        children: "children",
        label: "title",
      },
      srcvideo: "", //视频地址
      src: "",
      playerOptions: {
        playbackRates: [1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: true, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4", // 类型
            src: "", // url地址
          },
        ],
        poster: "", // 封面地址
        notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: true, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      },
      watch: {
        src: {
          handler (newval) {
            // console.log(newval);
            this.playerOptions.sources[0].src = newval;
            // console.log(this.playerOptions.sources[0].src, '视频赋值');
            this.srcvideo = newval;
          },
        },
      },
      created () {
        this.playerOptions.sources[0].src = this.src;
      },

      computed: {
        player: {
          get () {
            return this.$refs.videoPlayer.player;
          },
          set (newValue) {
            return newValue;
          },
        },
      },
    };
  },
  //   computed: {
  //     player() {
  //       return this.$refs.videoPlayer.player;
  //     },
  //   },
  mounted () {
    this.id = this.$route.params.id;
    this.kcid = this.$route.params.kcid;
    let token = JSON.parse(localStorage.getItem("Authorization"));
    let verify = localStorage.getItem("verify");
    videopage({
      module_id: this.$route.params.id,
      uid: token.user_id,
      schoolid: token.school_id,
      verify: verify,
    }).then((res) => {
      console.log(res);
      this.reid = res.id; //课件id
      this.module_id = res.module_id;
      this.name = res.name;
      this.cname = res.cname;
      this.playerOptions.sources[0].src = res.file_url;
      this.src = this.playerOptions.sources[0].src;
      if (res.link !== null) {
        this.link = "//" + res.link;
      }
      this.outline = res.outline;

      this.result = arrayToTree(this.outline, {
        parentProperty: "upper_module",
        customID: "id",
        childrenProperty: "children",
      });
      console.log(this.result);
    });
  },
  methods: {
    handleNodeClick (data) {
      console.log(data);
      if (data.type == "pdf") {
        this.$router.push({ name: "coursepdf" });
      }
      if (data.type == "video") {
        this.$router.push({ name: "coursevideo" });
      }
      if (data.type == "program_task") {
        this.$router.push({ name: "coursehomework" });
      }
    },
  },
  components: {
    EmptyFooter,
    videoPlayer,
  },
};
</script>

<style></style>

<style lang="css" src="../../src/assets/.css/common.css" scoped></style>
<style lang="css" src="../../src/assets/.css/index.css" scoped></style>
<style lang="css" src="../../src/assets/.css/page.css" scoped></style>
<style lang="css" scoped>
* {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
