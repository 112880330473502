import VueRouter from 'vue-router'
import login from '../../views/login/login'
import register from '../../views/register/register'
import findpwd from '../../views/findpwd/findpwd'
import common from '../../views/common/common'
import coursecenter from '../../views/course/coursecenter'
import taskcenter from '../../views/task/taskcenter'
import workcenter from '../../views/work/workcenter'
import addwork from '../../views/work/addwork'
import workdetail from '../../views/work/workdetail'
import goodworkdetail from '../../views/work/goodworkdetail'
import competitioncenter from '../../views/competition/competitioncenter'
import competitiondetail from '../../views/competition/competitiondetail'
import competitionaddwork from '../../views/competition/competitionaddwork'
import goodcompetitiondetail from '../../views/competition/goodcompetitiondetail'
import mycompetitiondetail from '../../views/competition/mycompetitiondetail'
import coursedetail from '../../views/course/coursedetail'
import coursepdf from '../../views/course/coursepdf'
import coursevideo from '../../views/course/coursevideo'
import coursequestion from '../../views/course/coursequestion'
import coursetest from '../../views/course/coursetest'
import coursediscussion from '../../views/course/coursediscussion'
import discussiondetail from '../../views/course/discussiondetail'
import coursehomework from '../../views/course/coursehomework'
import codeexercise from '../../views/codeexercise/codeexercise'

import usercenter from '../../views/user/usercenter'

import { redirectF } from '@/util/router'

// 避免进入同一路由时报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [
    {

        path: '/',
        name: 'MainTwo',
        component: () => import('@/../views/Maintwo.vue'),
        meta: { title: "首页 | 米思齐信息科技教学平台" },
        // redirect: '/common',
        redirect: () => { return redirectF() },
        children: [{
            path: '/common',
            name: 'common',
            component: common,
            meta: { title: "米思齐信息科技教学平台" }

        },
        {
            path: '/coursecenter',
            name: 'coursecenter',
            component: coursecenter,
            meta: { title: "课程中心 | 米思齐信息科技教学平台" }
        },
        {
            path: '/coursedetail/:id?/:back?',
            name: 'coursedetail',
            component: coursedetail,
            meta: { title: "课程详情 | 米思齐信息科技教学平台" }

        },
        {
            path: '/taskcenter',
            name: 'taskcenter',
            component: taskcenter,
            meta: { title: "任务中心 | 米思齐信息科技教学平台" }
        },
        {
            path: '/workcenter/:n?',
            name: 'workcenter',
            component: workcenter,
            meta: { title: "作品中心 | 米思齐信息科技教学平台" }
        },
        {
            path: '/addwork/:work_id?',
            name: 'addwork',
            component: addwork,
            meta: { title: "新建作品 | 米思齐信息科技教学平台" }
        },
        {
            path: '/workdetail/:id?',
            name: 'workdetail',
            component: workdetail,
            meta: { title: "作品详情 | 米思齐信息科技教学平台" }
        },
        {
            path: '/goodworkdetail/:id',
            name: 'goodworkdetail',
            component: goodworkdetail,
            meta: { title: "优秀作品详情 | 米思齐信息科技教学平台" }
        },
        {
            path: '/competitioncenter',
            name: 'competitioncenter',
            component: competitioncenter,
            meta: { title: "活动中心 | 米思齐信息科技教学平台" }
        },
        {
            path: '/competitiondetail/:id',
            name: 'competitiondetail',
            component: competitiondetail,
            meta: { title: "活动详情 | 米思齐信息科技教学平台" }
        },
        {
            path: '/competitionaddwork/:competition_id/:id',
            name: 'competitionaddwork',
            component: competitionaddwork,
            meta: { title: "新建活动作品 | 米思齐信息科技教学平台" }
        },
        {
            path: '/goodcompetitiondetail/:id/:competition_id',
            name: 'goodcompetitiondetail',
            component: goodcompetitiondetail,
            meta: { title: "活动作品详情 | 米思齐信息科技教学平台" }
        },
        {
            path: '/mycompetitiondetail/:id?/:competition_id?',
            name: 'mycompetitiondetail',
            component: mycompetitiondetail,
            meta: { title: "我的活动作品 | 米思齐信息科技教学平台" }
        },
        {
            path: '/usercenter',
            name: 'usercenter',
            component: usercenter,
            meta: { title: "用户中心 | 米思齐信息科技教学平台" }
        },
        {
            path: '/coursepdf/:id/:kcid',
            name: 'coursepdf',
            component: coursepdf,
            meta: { title: "课件-PDF | 米思齐信息科技教学平台" }

        },
        {
            path: '/coursevideo/:id/:kcid',
            name: 'coursevideo',
            component: coursevideo,
            meta: { title: "课件-视频 | 米思齐信息科技教学平台" }

        },
        {
            path: '/coursetest/:id/:kcid/:back2',
            name: 'coursetest',
            component: coursetest,
            meta: { title: "测试 | 米思齐信息科技教学平台" }

        },
        {
            path: '/coursequestion/:id/:kcid/:back2',
            name: 'coursequestion',
            component: coursequestion,
            meta: { title: "问卷 | 米思齐信息科技教学平台" }

        },
        {
            path: '/coursediscussion/:id/:kcid',
            name: 'coursediscussion',
            component: coursediscussion,
            meta: { title: "讨论 | 米思齐信息科技教学平台" }

        },
        {
            path: '/discussiondetail/:id/:module_id/:kcid',
            name: 'discussiondetail',
            component: discussiondetail,
            meta: { title: "讨论详情 | 米思齐信息科技教学平台" }

        },
        {
            path: '/coursehomework/:id?/:kcid?/:type?/:back2?',
            name: 'coursehomework',
            component: coursehomework,
            meta: { title: "作业详情 | 米思齐信息科技教学平台" }

        },
        {
            path: '/codeexercise',
            name: 'codeexercise',
            component: codeexercise,
            meta: { title: "编程练习 | 米思齐信息科技教学平台" }

        },
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: login,
        meta: { title: "登录 | 米思齐信息科技教学平台" }
    },
    {
        path: '/register',
        name: 'register',
        component: register,
        meta: { title: "注册 | 米思齐信息科技教学平台" }
    },
    {
        path: '/findpwd',
        name: 'findpwd',
        component: findpwd,
        meta: { title: "找回密码 | 米思齐信息科技教学平台" }
    },

    {
        path: '/admin',
        name: 'MainView',
        component: () => import('@/../views/Main.vue'),
        meta: { title: "首页 | 米思齐信息科技教学平台" },
        redirect: () => { return redirectF() },
        children: [{
            path: '/data',
            name: 'DataView',
            component: () => import('@/../views/admin/data'),
            meta: { title: "首页 | 米思齐信息科技教学平台" },
        },
        {
            path: '/data/todoList',
            name: 'TodoList',
            component: () => import('@/../views/admin/data/todolist.vue'),
            meta: { title: "待办事项 | 米思齐信息科技教学平台" },
        },
        {
            path: '/data/myInfo',
            name: 'MyInfo',
            component: () => import('@/../views/admin/data/myInfo.vue'),
            meta: { title: "我的信息 | 米思齐信息科技教学平台" },
        },
        {
            path: '/lessonList',
            name: 'LessonList',
            component: () => import('@/../views/admin/lesson/lessonList.vue'),
            meta: { title: "后台 | 米思齐信息科技教学平台" },
            redirect: '/lessonList/all',
            children: [{
                path: '/lessonList/all',
                name: 'AllLesson',
                component: () => import('@/../views/admin/lesson/lessonType/all.vue'),
                meta: { title: "课程列表 | 米思齐信息科技教学平台" },
            },
            {
                path: '/lessonList/public',
                name: 'PublicLesson',
                component: () => import('@/../views/admin/lesson/lessonType/public.vue'),
                meta: { title: "后台 | 米思齐信息科技教学平台" }
            },
            {
                path: '/lessonList/auditing',
                name: 'AuditingLesson',
                component: () => import('@/../views/admin/lesson/lessonType/auditing.vue'),
                meta: { title: "后台 | 米思齐信息科技教学平台" }
            },
            {
                path: '/lessonList/released',
                name: 'ReleasedLesson',
                component: () => import('@/../views/admin/lesson/lessonType/released.vue'),
                meta: { title: "后台 | 米思齐信息科技教学平台" }
            },
            {
                path: '/lessonList/releasing',
                name: 'ReleasingLesson',
                component: () => import('@/../views/admin/lesson/lessonType/releasing.vue'),
                meta: { title: "后台 | 米思齐信息科技教学平台" }
            }
            ]
        },
        {
            path: '/lessonList/newLesson',
            name: 'NewLesson',
            component: () => import('@/../views/admin/lesson/operation/newLesson.vue'),
            meta: { title: "新建课程 | 米思齐信息科技教学平台" },
        },
        {
            path: '/lessonList/lessonDetail',
            name: 'LessonDetail',
            component: () => import('@/../views/admin/lesson/operation/lessonDetail.vue'),
            meta: { title: "课程详情 | 米思齐信息科技教学平台" },
        },
        {
            path: '/lessonList/modifyLesson',
            name: 'ModifyLesson',
            component: () => import('@/../views/admin/lesson/operation/modifyLesson.vue'),
            meta: { title: "修改课程 | 米思齐信息科技教学平台" },
        },
        {
            path: '/lessonList/studentDetail',
            name: 'StudentDetail',
            component: () => import('@/../views/admin/lesson/operation/studentDetail.vue'),
            meta: { title: "学生详情 | 米思齐信息科技教学平台" },
        },
        {
            path: '/lessonList/addCourseware',
            name: 'AddCourseware',
            component: () => import('@/../views/admin/lesson/operation/addCourseware.vue'),
            meta: { title: "课件 | 米思齐信息科技教学平台" },
        },
        {
            path: '/lessonList/addSource',
            name: 'AddSource',
            component: () => import('@/../views/admin/lesson/operation/addSource.vue'),
            meta: { title: "资源 | 米思齐信息科技教学平台" },
        },
        {
            path: '/homeworkList',
            name: 'HomeworkList',
            component: () => import('@/../views/admin/lesson/homeworkList.vue'),
            meta: { title: "作业列表 | 米思齐信息科技教学平台" },
        },
        {
            path: '/homeworkList/homeworkDetail',
            name: 'HomeworkDetail',
            component: () => import('@/../views/admin/lesson/homeworkPages/homeworkDetail.vue'),
            meta: { title: "作业详情 | 米思齐信息科技教学平台" },
        },
        {
            path: '/homeworkList/addHomework',
            name: 'AddHomework',
            component: () => import('@/../views/admin/lesson/homeworkPages/addHomework.vue'),
            meta: { title: "新增作业 | 米思齐信息科技教学平台" },
        },
        {
            path: '/homeworkList/modifyHomework',
            name: 'ModifyHomework',
            component: () => import('@/../views/admin/lesson/homeworkPages/modifyHomework.vue'),
            meta: { title: "修改作业 | 米思齐信息科技教学平台" },
        },
        {
            path: '/homeworkList/homeworkcorrect/:module_id/:student_id',
            name: 'HomeworkCorrect',
            component: () => import('@/../views/back/homeworkcorrect.vue'),
            meta: { title: "作业批改 | 米思齐信息科技教学平台" },
        },
        {
            path: '/testList',
            name: 'TestList',
            component: () => import('@/../views/admin/lesson/testList.vue'),
            meta: { title: "测试列表 | 米思齐信息科技教学平台" },
        },
        {
            path: '/testList/testDetail',
            name: 'TestDetail',
            component: () => import('@/../views/admin/lesson/testPages/testDetail.vue'),
            meta: { title: "测试详情 | 米思齐信息科技教学平台" },
        },
        {
            path: '/testList/addTest',
            name: 'AddTest',
            component: () => import('@/../views/admin/lesson/testPages/addTest.vue'),
            meta: { title: "新增测试 | 米思齐信息科技教学平台" },
        },
        {
            path: '/testList/modifyTest',
            name: 'ModifyTest',
            component: () => import('@/../views/admin/lesson/testPages/modifyTest.vue'),
            meta: { title: "测试列表 | 米思齐信息科技教学平台" }
        },
        {
            path: '/testList/testcorrect/:module_id/:student_id',
            name: 'TestCorrect',
            component: () => import('@/../views/back/testcorrect.vue'),
            meta: { title: "测试批改 | 米思齐信息科技教学平台" },
        },
        {
            path: '/quesList',
            name: 'QuesList',
            component: () => import('@/../views/admin/lesson/quesList.vue'),
            meta: { title: "问卷列表 | 米思齐信息科技教学平台" },
        },
        {
            path: '/quesList/quesDetail',
            name: 'QuesDetail',
            component: () => import('@/../views/admin/lesson/quesPages/quesDetail.vue'),
            meta: { title: "问卷详情 | 米思齐信息科技教学平台" },
        },
        {
            path: '/quesList/addQues',
            name: 'AddQues',
            component: () => import('@/../views/admin/lesson/quesPages/addQues.vue'),
            meta: { title: "新增问卷 | 米思齐信息科技教学平台" },
        },
        {
            path: '/quesList/modifyQues',
            name: 'ModifyQues',
            component: () => import('@/../views/admin/lesson/quesPages/modifyQues.vue'),
            meta: { title: "修改问卷 | 米思齐信息科技教学平台" },
        },
        {
            path: '/quesList/quescorrect/:module_id/:student_id',
            name: 'QuesCorrect',
            component: () => import('@/../views/back/questionnairecorrect.vue'),
            meta: { title: "问卷查阅 | 米思齐信息科技教学平台" },
        },
        {
            path: '/knowledgeTreeList',
            name: 'KnowledgeTreeList',
            component: () => import('@/../views/admin/knowledge/knowledgeTreeList.vue'),
            meta: { title: "知识树列表 | 米思齐信息科技教学平台" },
        },
        {
            path: '/modifyKnowledgeTree',
            name: 'ModifyKnowledgeTree',
            component: () => import('@/../views/admin/knowledge/modifyKnowledgeTree.vue'),
            meta: { title: "修改知识树 | 米思齐信息科技教学平台" },
        },
        {
            path: '/codeExerciseList',
            name: 'CodeExerciseList',
            component: () => import('@/../views/admin/lesson/codeExerciseList.vue'),
            meta: { title: "编程练习列表 | 米思齐信息科技教学平台" },
        },
        {
            path: '/codeExerciseList/addExercise',
            name: 'AddCodeExercise',
            component: () => import('@/../views/admin/lesson/codeExercisePages/addExercise.vue'),
            meta: { title: "新增编程练习 | 米思齐信息科技教学平台" },
        },
        {
            path: '/codeExerciseList/modifyExercise',
            name: 'ModifyCodeExercise',
            component: () => import('@/../views/admin/lesson/codeExercisePages/modifyExercise.vue'),
            meta: { title: "修改编程练习 | 米思齐信息科技教学平台" },
        },
        {
            path: '/codeExerciseList/exerciseDetail',
            name: 'ExerciseDetail',
            component: () => import('@/../views/admin/lesson/codeExercisePages/exerciseDetail.vue'),
            meta: { title: "编程练习详情 | 米思齐信息科技教学平台" },
        },
        {
            path: '/codeExerciseList/studentExerciseDetail',
            name: 'StudentExerciseDetail',
            component: () => import('@/../views/admin/lesson/codeExercisePages/studentExerciseDetail.vue'),
            meta: { title: "学生练习详情 | 米思齐信息科技教学平台" },
        },
        {
            path: '/codeExerciseList/viewCode',
            name: 'ViewCode',
            component: () => import('@/../views/back/viewCode.vue'),
            meta: { title: "学生提交详情 | 米思齐信息科技教学平台" },
        },
        {
            path: '/ojProblemList',
            name: 'OjProblemList',
            component: () => import('@/../views/admin/ojproblem/ojProblemList.vue'),
            meta: { title: "编程题目列表 | 米思齐信息科技教学平台" },
        },
        {
            path: '/ojProblemList/addOjProblem',
            name: 'AddOjProblem',
            component: () => import('@/../views/admin/ojproblem/addOjProblem.vue'),
            meta: { title: "新增编程题目 | 米思齐信息科技教学平台" },
        },
        {
            path: '/ojProblemList/modifyOjProblem',
            name: 'ModifyOjProblem',
            component: () => import('@/../views/admin/ojproblem/modifyOjProblem.vue'),
            meta: { title: "修改编程题目 | 米思齐信息科技教学平台" },
        },
        {
            path: '/groupList/addGroup',
            name: 'AddGroup',
            component: () => import('@/../views/admin/lesson/groupPages/addGroup.vue'),
        },
        {
            path: '/userList',
            name: 'UserList',
            component: () => import('@/../views/admin/user/userList.vue'),
            meta: { title: "用户列表 | 米思齐信息科技教学平台" },
        },
        // {
        //     path: '/studentList',
        //     name: 'StudentList',
        //     component: () => import('@/../views/admin/user/studentList.vue')
        // },
        // {
        //     path: '/teacherList',
        //     name: 'TeacherList',
        //     component: () => import('@/../views/admin/user/teacherList.vue')
        // },
        // {
        //     path: '/adminList',
        //     name: 'AdminList',
        //     component: () => import('@/../views/admin/user/adminList.vue')
        // },
        {
            path: '/userList/modifyUser',
            name: 'ModifyUser',
            component: () => import('@/../views/admin/user/userPages/modifyUser.vue'),
            meta: { title: "修改用户 | 米思齐信息科技教学平台" },
        },
        {
            path: '/behaviorManagement',
            name: 'BehaviorManagement',
            component: () => import('@/../views/admin/user/behavior.vue'),
            meta: { title: "行为数据 | 米思齐信息科技教学平台" },
        },
        {
            path: '/userList/behaviorData',
            name: 'BehaviorData',
            component: () => import('@/../views/admin/user/userPages/behavior.vue'),
            meta: { title: "行为数据 | 米思齐信息科技教学平台" },
        },
        {
            path: '/userList/eval',
            name: 'Eval',
            component: () => import('@/../views/back/eval.vue'),
            meta: { title: "评估报告 | 米思齐信息科技教学平台" },
        },
        {
            path: '/areaManagement',
            name: 'AreaManagement',
            component: () => import('@/../views/admin/user/areaManagement.vue'),
            meta: { title: "后台 | 米思齐信息科技教学平台" }
        },
        {
            path: '/schoolManagement',
            name: 'SchoolManagement',
            component: () => import('@/../views/admin/user/schoolManagement.vue'),
            meta: { title: "学校管理 | 米思齐信息科技教学平台" },
        },
        {
            path: '/classManagement',
            name: 'ClassManagement',
            component: () => import('@/../views/admin/user/classManagement.vue'),
            meta: { title: "班级管理 | 米思齐信息科技教学平台" },
        },
        {
            path: '/workList',
            name: 'WorkList',
            component: () => import('@/../views/admin/work/workList.vue'),
            meta: { title: "作品管理 | 米思齐信息科技教学平台" },
        },
        {
            path: '/workList/workDetail:id?',
            name: 'WorkDetail',
            component: () => import('@/../views/admin/work/workPages/workdetail.vue'),
            meta: { title: "作品详情 | 米思齐信息科技教学平台" },
        },
        {
            path: '/competitionList',
            name: 'CompetitionList',
            component: () => import('@/../views/admin/competition/competitionList.vue'),
            meta: { title: "活动列表 | 米思齐信息科技教学平台" },
        },
        {
            path: '/competitionList/competitiondetail/:id',
            name: 'CompetitionDetail',
            component: () => import('@/../views/admin/competition/competitionPages/competitiondetail.vue'),
            meta: { title: "活动详情 | 米思齐信息科技教学平台" },
        },
        {
            path: '/competitionList/addCompetition',
            name: 'AddCompetition',
            component: () => import('@/../views/admin/competition/competitionPages/addCompetition.vue'),
            meta: { title: "新增活动 | 米思齐信息科技教学平台" },
        },
        {
            path: '/competitionList/modifyCompetition',
            name: 'ModifyCompetition',
            component: () => import('@/../views/admin/competition/competitionPages/modifyCompetition.vue'),
            meta: { title: "修改活动 | 米思齐信息科技教学平台" },
        },
        {
            path: '/competitionList/myWorkDetail',
            name: 'MyWorkDetail',
            component: () => import('@/../views/admin/competition/competitionPages/myWorkDetail.vue'),
            meta: { title: "我的作品 | 米思齐信息科技教学平台" },
        },
        {
            path: '/myAudit',
            name: 'MyAudit',
            component: () => import('@/../views/admin/competition/myAudit.vue'),
            meta: { title: "评分 | 米思齐信息科技教学平台" },
        },
        {
            path: '/myAudit/competitionjudge/:work_id/:competition_name',
            name: 'competitionjudge',
            component: () => import('@/../views/back/competitionjudge.vue'),
            meta: { title: "评分 | 米思齐信息科技教学平台" },
        },
        {
            path: '/homeManagement',
            name: 'HomeManagement',
            component: () => import('@/../views/admin/system/homeManagement.vue'),
            meta: { title: "首页管理 | 米思齐信息科技教学平台" },
        },
        {
            path: '/systemParm',
            name: 'SystemParm',
            component: () => import('@/../views/admin/system/systemParm.vue'),
            meta: { title: "系统参数 | 米思齐信息科技教学平台" },
        },
        {
            path: '/passwordQuestion',
            name: 'PasswordQuestion',
            component: () => import('@/../views/admin/system/passwordQuestion.vue'),
            meta: { title: "密保问题 | 米思齐信息科技教学平台" },
        },
        {
            path: '/userManagement',
            name: 'UserManagement',
            component: () => import('@/../views/admin/system/userManagement.vue'),
            meta: { title: "用户管理 | 米思齐信息科技教学平台" },
        },
        {
            path: '/fileManagement',
            name: 'FileManagement',
            component: () => import('@/../views/admin/system/fileManagement.vue'),
            meta: { title: "文件管理 | 米思齐信息科技教学平台" },
        },
        {
            path: '/dataManagement',
            name: 'DataManagement',
            component: () => import('@/../views/admin/system/dataManagement.vue'),
            meta: { title: "数据管理 | 米思齐信息科技教学平台" },
        },
        ]
    },
    {
        path: '/ai_teacher',
        name: 'AITeacher',
        component: () => import('@/../views/ai_teacher'),
        meta: { title: "AI教师 | 米思齐信息科技教学平台" },
    },
]

const router = new VueRouter({ routes, })

router.beforeEach((to, from, next) => {
    let Authorization = JSON.parse(localStorage.getItem("Authorization"));

    if (to.name !== 'login' && to.name !== 'register' && to.name !== 'findpwd' && to.name !== 'common' && !(Authorization && Authorization.user_id)) {
        // 如果用户未登录且访问的不是登录页面，则重定向到登录页面
        next({ name: 'login' });

    } else {
        // 如果用户已登录或者访问的是登录页面，则允许继续访问
        next();
    }
    if ((to.name === 'DataView'
        || to.name === 'TodoList'
        || to.name === 'MyInfo'
        || to.name === 'LessonList'
        || to.name === 'AllLesson'
        || to.name === 'PublicLesson'
        || to.name === 'AuditingLesson'
        || to.name === 'ReleasedLesson'
        || to.name === 'ReleasingLesson'
        || to.name === 'NewLesson'
        || to.name === 'LessonDetail'
        || to.name === 'ModifyLesson'
        || to.name === 'StudentDetail'
        || to.name === 'AddCourseware'
        || to.name === 'AddSource'
        || to.name === 'HomeworkList'
        || to.name === 'HomeworkDetail'
        || to.name === 'AddHomework'
        || to.name === 'ModifyHomework'
        || to.name === 'HomeworkCorrect'
        || to.name === 'TestList'
        || to.name === 'TestDetail'
        || to.name === 'AddTest'
        || to.name === 'ModifyTest'
        || to.name === 'TestCorrect'
        || to.name === 'QuesList'
        || to.name === 'QuesDetail'
        || to.name === 'AddQues'
        || to.name === 'ModifyQues'
        || to.name === 'QuesCorrect'
        || to.name === 'AddGroup'
        || to.name === 'UserList'
        || to.name === 'Eval'
        || to.name === 'AreaManagement'
        || to.name === 'SchoolManagement'
        || to.name === 'ClassManagement'
        || to.name === 'WorkList'
        || to.name === 'WorkDetail'
        || to.name === 'CompetitionList'
        || to.name === 'CompetitionDetail'
        || to.name === 'AddCompetition'
        || to.name === 'ModifyCompetition'
        || to.name === 'MyWorkDetail'
        || to.name === 'MyAudit'
        || to.name === 'competitionjudge'
        || to.name === 'HomeManagement'
        || to.name === 'SystemParm'
        || to.name === 'PasswordQuestion'
        || to.name === 'UserManagement'
        || to.name === 'FileManagement'
        || to.name === 'DataManagement') && Authorization.type === 1) {
        next({ name: 'common' });
    } else {
        next();
    }

    if ((to.name === 'AddCompetition'
        || to.name === 'ModifyCompetition'
        || to.name === 'competitionjudge'
        || to.name === 'HomeManagement'
        || to.name === 'SystemParm'
        || to.name === 'PasswordQuestion'
        || to.name === 'UserManagement'
        || to.name === 'FileManagement'
        || to.name === 'DataManagement') && Authorization.type === 2) {
        next({ name: 'DataView' });
    } else {
        next();
    }

});

export default router;