<template>
  <div>
    <div>
      <div>
        <div class="img_title flex_center pc_only">
          <img src="../../src/assets/img/personal.png" alt="" />
        </div>
        <div class="wrapper">
          <ul class="tab list_tab">
            <li>
              <a @click="eval" :class="n === 1 ? 'current' : ''">{{
                $t("message.Developmentassessment")
              }}</a>
            </li>
            <li>
              <a @click="info" :class="n === 2 ? 'current' : ''">{{
                $t("message.information")
              }}</a>
            </li>

            <li>
              <a @click="pwd" :class="n === 3 ? 'current' : ''">{{
                $t("message.changePassword")
              }}</a>
            </li>
          </ul>
        </div>

        <div
          v-if="n === 1"
          class="form_feild edit_user_info"
          style="margin-left: 100px; width: 1250px"
        >
          <div class="wrapper student_evaluation">
            <h2>{{ $t("message.state") }}</h2>
            <div class="student_status">
              <div class="status_item" style="margin-right: 50px">
                <div class="flex">
                  <img src="../../src/assets/img/status_course.png" alt="" />
                  <span class="green_text">{{ $t("message.coursenum") }}</span>
                </div>
                <b>{{ eval_array.course_num }}</b>
              </div>
              <div class="status_item status_time" style="margin-right: 50px">
                <div class="flex">
                  <img
                    style="width: 35px"
                    src="../../src/assets/img/homework.png"
                    alt=""
                  />
                  <span class="orange_text">{{ $t("message.homenum") }}</span>
                </div>
                <b>{{ eval_array.homework_num }}</b>
              </div>
              <div
                class="status_item status_chapter"
                style="margin-right: 50px"
              >
                <div class="flex">
                  <img
                    style="width: 40px"
                    src="../../src/assets/img/test.png"
                    alt=""
                  />
                  <span class="blue_text">{{ $t("message.testnum") }}</span>
                </div>
                <b>{{ eval_array.test_num }}</b>
              </div>
              <div class="status_item status_work" style="margin-right: 50px">
                <div class="flex">
                  <img src="../../src/assets/img/status_work.png" alt="" />
                  <span class="purple_text">{{ $t("message.worknum") }}</span>
                </div>
                <b>{{ eval_array.work_num }}</b>
              </div>
            </div>
            <h2>{{ $t("message.deve") }}</h2>
            <div class="course_filter my_grow_container flex_between">
              <div class="flex">
                <div class="filter_tab">
                  <a
                    ><span
                      @click="choose1"
                      :class="ct === 1 ? 'current' : ''"
                      >{{ $t("message.last7days") }}</span
                    ></a
                  >
                  <a
                    ><span
                      @click="choose2"
                      :class="ct === 2 ? 'current' : ''"
                      >{{ $t("message.last30days") }}</span
                    ></a
                  >
                  <a
                    ><span
                      @click="choose3"
                      :class="ct === 3 ? 'current' : ''"
                      >{{ $t("message.lasthalfyear") }}</span
                    ></a
                  >
                  <a
                    ><span
                      @click="choose4"
                      :class="ct === 4 ? 'current' : ''"
                      >{{ $t("message.lastyear") }}</span
                    ></a
                  >
                </div>
              </div>
              <el-select
                style="text-align: center; margin-top: -20px; width: 180px"
                v-model="value"
                :placeholder="$t('message.all')"
              >
                <el-option
                  style="text-align: center"
                  :label="$t('message.coursenum')"
                  :value="$t('message.coursenum')"
                >
                </el-option>
                <el-option
                  style="text-align: center"
                  :label="$t('message.homenum')"
                  :value="$t('message.homenum')"
                >
                </el-option>
                <el-option
                  style="text-align: center"
                  :label="$t('message.worknum')"
                  :value="$t('message.worknum')"
                >
                </el-option>
              </el-select>
              <!-- <el-dropdown>
                <el-button type="primary" plain style="margin-top:-20px">
                  {{ $t('message.classification') }}<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>

                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item style="padding-left: 15px; padding-right: 15px" value="course">
                    <a @click="select1()">{{ $t('message.coursenum') }}</a>
                  </el-dropdown-item>
                  <el-dropdown-item style="padding-left: 15px; padding-right: 15px" value="task">
                    <a @click="select2()">{{ $t('message.homenum') }}</a>
                  </el-dropdown-item>
                  <el-dropdown-item style="padding-left: 15px; padding-right: 15px" value="work">
                    <a @click="select3()">{{ $t('message.worknum') }}</a>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown> -->
            </div>
            <div id="myChart" style="width: 100%; height: 300px"></div>
            <h2>{{ $t("message.perform") }}</h2>
            <div class="student_table_container" style="width: 1100px">
              <div class="t_row t_header">
                <div class="t_th" style="width: 20%">
                  {{ $t("message.coursename") }}
                </div>
                <div class="t_th" style="width: 20%">
                  {{ $t("message.comtask") }}/{{ $t("message.sumtask") }}
                </div>
                <div class="t_th flex_end" style="width: 20%">
                  {{ $t("message.taskaverscore") }}
                </div>
                <div class="t_th" style="width: 20%">
                  {{ $t("message.comtest") }}/{{ $t("message.sumtest") }}
                </div>
                <div class="t_th flex_end" style="width: 20%">
                  {{ $t("message.testaverscore") }}
                </div>
              </div>
              <div
                v-if="show != 1"
                v-for="(item, index) in performance"
                :key="index"
              >
                <div class="t_row" v-if="index % 2 === 0">
                  <div class="name" style="width: 20%; margin-left: 20px">
                    {{ item.title }}
                  </div>
                  <div class="t_td" style="width: 20%; margin-left: -20px">
                    {{ item.finish_hw }}/{{ item.hw }}
                  </div>
                  <div class="t_td flex_end" style="width: 20%">
                    {{ item.hw_ave_grade }}
                  </div>
                  <div class="t_td" style="width: 20%">
                    {{ item.finish_test }}/{{ item.test }}
                  </div>
                  <div class="t_td flex_end" style="width: 20%">
                    {{ item.test_ave_grade }}
                  </div>
                </div>
                <div class="t_row t_header" v-if="index % 2 === 1">
                  <div class="name" style="width: 20%; margin-left: 20px">
                    {{ item.title }}
                  </div>
                  <div class="t_td" style="width: 20%; margin-left: -20px">
                    {{ item.finish_hw }}/{{ item.hw }}
                  </div>
                  <div class="t_td flex_end" style="width: 20%">
                    {{ item.hw_ave_grade }}
                  </div>
                  <div class="t_td" style="width: 20%">
                    {{ item.finish_test }}/{{ item.test }}
                  </div>
                  <div class="t_td flex_end" style="width: 20%">
                    {{ item.test_ave_grade }}
                  </div>
                </div>
              </div>
              <div
                v-if="show === 1"
                style="text-align: center; margin-top: 50px"
              >
                <div>
                  <img
                    src="../../src/assets/img/nomessage.png"
                    style="width: 50px; text-align: center; position: absolute"
                  />
                  <span
                    style="
                      height: 100px;
                      line-height: 100px;
                      left: 60px;
                      position: relative;
                      top: -20px;
                      color: grey;
                    "
                    >暂无表现情况</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="n === 2"
          class="form_feild edit_user_info"
          style="margin-left: 250px"
        >
          <div class="cell">
            <div class="cell_hd">{{ $t("message.avatar") }}：</div>

            <!-- <el-image :src=""/> -->

            <el-upload
              :class="{ 'hide-upload-btn': photoHide }"
              class="avatar-uploader annaul-ticket-imgs"
              action="#"
              name="file"
              list-type="picture-card"
              :file-list="fileList"
              :limit="limit"
              :show-file-list="true"
              :http-request="handleFileUpload"
            >
              <i slot="default" class="el-icon-plus"></i>
              <div slot="file" slot-scope="{ file }">
                <img
                  style="width: 300px; height: 150px; object-fit: fill"
                  class="el-upload-list__item-thumbnail"
                  :src="file.url"
                  alt=""
                />
                <span class="el-upload-list__item-actions">
                  <span
                    class="el-upload-list__item-preview"
                    @click="handlePictureCardPreview(file)"
                  >
                    <i class="el-icon-zoom-in"></i>
                  </span>

                  <span
                    v-if="!disabled"
                    class="el-upload-list__item-delete"
                    @click="handleRemove(file)"
                  >
                    <i class="el-icon-delete"></i>
                  </span>
                </span>
              </div>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img
                width="100%"
                style="margin-left: 30px"
                :src="dialogImageUrl"
                alt=""
              />
            </el-dialog>
          </div>

          <div class="cell" style="color: grey; margin-left: 100px">
            图片上传不超过100kb
          </div>
          <div class="cell">
            <div class="cell_hd">
              <span style="color: red">* </span>{{ $t("message.realname") }}：
            </div>

            <div>
              <el-input
                style="margin-left: 30px; width: 200px"
                v-model="real_name"
              ></el-input>
            </div>
            <div class="cell_hd" style="margin-left: 200px">
              <span style="color: red">* </span>{{ $t("message.account") }}：
            </div>

            <div>
              <el-input
                style="margin-left: 30px; width: 200px"
                v-model="account"
                :disabled="true"
              ></el-input>
            </div>
          </div>

          <div class="cell">
            <div class="cell_hd">{{ $t("message.birthday") }}：</div>

            <div>
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="birthday"
                style="width: 200px; margin-left: 30px"
              ></el-date-picker>
            </div>

            <div class="cell_hd" style="margin-left: 200px">
              {{ $t("message.gender") }}：
            </div>

            <div>
              <el-select
                style="width: 200px; margin-left: 30px"
                v-model="gender"
                placeholder="请选择"
              >
                <el-option
                  style="padding-left: 40px"
                  v-for="(item, index) in options"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="cell">
            <div class="cell_hd">
              <span style="color: red">* </span>{{ $t("message.school") }}：
            </div>

            <div>
              <el-select
                size="medium"
                v-model="school_id"
                placeholder="请选择"
                :disabled="true"
                style="width: 200px; margin-left: 30px"
              >
                <el-option
                  style="padding-left: 50px"
                  v-for="(item, index) in schools"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
            <div class="cell_hd" style="margin-left: 200px">
              {{ $t("message.class") }}：
            </div>

            <div>
              <!-- <div>
              <el-input disabled style="margin-left: 30px; width: 200px" v-model="nb">{{ grade }}年{{ banji }}班</el-input>
            </div> -->
              <el-select
                size="medium"
                v-model="class_id"
                placeholder="请选择"
                style="width: 200px; margin-left: 30px"
              >
                <el-option
                  style="padding-left: 50px"
                  v-for="(item, index) in classes2"
                  :key="index"
                  :label="item.class"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
          </div>

          <div class="cell">
            <div class="cell_hd" style="margin-left: 570px; width: 200px">
              <button
                @click="send2()"
                style="
                  margin-top: 20px;
                  width: 200px;
                  height: 40px;
                  font-size: 15px;
                  background: #00979c;
                  height: 40px;
                  align-items: center;
                  justify-content: center;
                  border-radius: 6px;
                  color: #fff;
                  border: none;
                  padding: 0 15px;
                  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.03);
                "
              >
                {{ $t("message.confirmedit") }}
              </button>
            </div>
            <div class="cell_hd" style="width: 200px">
              <!-- <button @click="zhuxiao()"style="margin-top: 20px;
              width: 200px; 
              height: 40px;
              font-size:15px;
              background: rgb(205, 71, 71);
              height: 40px;
              align-items: center;
              justify-content: center;
              border-radius: 6px;
              color: #fff;
              border: none;
              padding: 0 15px;
              box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.03);">
                注销账号
              </button> -->
            </div>
          </div>
        </div>

        <div v-if="n === 3">
          <el-form
            :model="ruleForm"
            status-icon
            :rules="rules"
            ref="ruleForm"
            label-width="200px"
            style="margin-left: 400px"
            class="demo-ruleForm"
          >
            <el-form-item
              :label="$t('message.oldpassword')"
              prop="old"
              style="margin-left: 30px; width: 500px"
            >
              <el-input
                type="password"
                v-model="ruleForm.old"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('message.password')"
              prop="pass"
              style="margin-left: 30px; width: 500px; margin-top: 20px"
            >
              <el-input
                type="password"
                v-model="ruleForm.pass"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('message.confirmpassword')"
              prop="checkPass"
              style="margin-left: 30px; width: 500px; margin-top: 20px"
            >
              <el-input
                type="password"
                v-model="ruleForm.checkPass"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <el-form-item>
              <el-button
                class="btn"
                name="login-button"
                id="loginbtn"
                style="
                  margin-left: 30px;
                  margin-top: 30px;
                  width: 150px;
                  height: 40px;
                "
                @click="send3()"
              >
                {{ $t("message.submit") }}</el-button
              >
            </el-form-item>
            <Addquestion :visible.sync="dialogFormVisible" />
          </el-form>
        </div>
      </div>
    </div>
    <EmptyFooter />
  </div>
</template>

<script>
import { info } from "@/http/api.js"; // 导入接口
import { adminAxiosUpload } from "@/http/api.js"; // 导入接口
import { inforeturn } from "@/http/api.js"; // 导入接口
import { editpassword } from "@/http/api.js"; // 导入接口
import { develop, indexpage } from "@/http/api.js"; // 导入接口

import CommonLayout from "@/components/CommonLayout.vue";
import EmptyFooter from "@/components/EmptyFooter.vue";
import CommonStudent from "@/components/CommonStudent.vue";
import CommonTeacher from "@/components/CommonTeacher.vue";
import Addquestion from "@/components/Addquestion.vue";
import { Dialog } from "element-ui";
export default {
  name: "usercenter",
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (value.length < 6) {
          callback(new Error("不得少于6个字符"));
        }
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      value: "",
      course: "",
      assignment: "",
      work: "",
      ruleForm: {
        old: "",
        pass: "",
        checkPass: "",
      },
      rules: {
        old: [{ min: 6, message: "不得少于6个字符", trigger: "blur" }],
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [
          {
            validator: validatePass2,
            min: 6,
            message: "不得少于6个字符",
            trigger: "blur",
          },
        ],
      },
      // xhr: new XMLHttpRequest(),
      n: 1,
      file_id: null,
      fileData: null,
      fileList: [],
      imageUrl: "",
      limit: 2,
      photoHide: true,
      disabled: false,
      dialogImageUrl: "",
      dialogVisible: false,
      file_id: null, // 暂时获取不到

      type2: "",
      status: "",

      date: "",
      gender: "",
      // avatar_url: "",
      options: [
        {
          value: 1,
          label: "男",
        },
        {
          value: 2,
          label: "女",
        },
      ],

      id: null,
      real_name: "",
      account: "",
      birthday: null,
      school_id: null,
      schools: [],
      class_id: null,
      classes: [],
      question_id: null,
      questions: [],
      answer: "",
      banji: "",
      grade: "",
      nb: "",
      classes2: [],

      question_set: "",
      dialogFormVisible: false,

      eval_array: [],
      course_array: [],
      work_array: [],
      task_array: [],
      performance: [],
      details: [],
      // time: [],
      // course_num: [],
      // time1: [],
      // task_num: [],
      // time2: [],
      // work_num: [],
      value: "选课数量",

      show: null,
      avatar_url: "",
      ct: 1,
      week_start: "",
      month_start: "",
      hyear_start: "",
      year_start: "",

      type: null,
      time: [],
      time1: [],
      time2: [],
      time3: [],
      time4: [],
      data: [],
      course_num1: [0, 0, 0, 0, 0, 0, 0],
      course_num2: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      course_num3: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      course_num4: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      task_num1: [0, 0, 0, 0, 0, 0, 0],
      task_num2: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      task_num3: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      task_num4: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      work_num1: [0, 0, 0, 0, 0, 0, 0],
      work_num2: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      work_num3: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      work_num4: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    };
  },
  components: {
    EmptyFooter,
    Addquestion,
  },
  watch: {
    value: {
      handler: function (newValue, oldValue) {
        if (newValue === "选课数量" || newValue === "Course Number") {
          this.type = 1;
          if (this.ct === 1) {
            this.time = this.time1;
            this.data = this.course_num1;
          }
          if (this.ct === 2) {
            this.time = this.time2;
            this.data = this.course_num2;
          }
          if (this.ct === 3) {
            this.time = this.time3;
            this.data = this.course_num3;
          }
          if (this.ct === 4) {
            this.time = this.time4;
            this.data = this.course_num4;
          }
          this.drawLine();
        }
        if (newValue === "累计作业" || newValue === "Homework Number") {
          this.type = 2;
          if (this.ct === 1) {
            this.time = this.time1;
            this.data = this.task_num1;
          }
          if (this.ct === 2) {
            this.time = this.time2;
            this.data = this.task_num2;
          }
          if (this.ct === 3) {
            this.time = this.time3;
            this.data = this.task_num3;
          }
          if (this.ct === 4) {
            this.time = this.time4;
            this.data = this.task_num4;
          }
          this.drawLine();
        }
        if (newValue === "累计作品" || newValue === "Work Number") {
          this.type = 3;
          if (this.ct === 1) {
            this.time = this.time1;
            this.data = this.work_num1;
          }
          if (this.ct === 2) {
            this.time = this.time2;
            this.data = this.work_num2;
          }
          if (this.ct === 3) {
            this.time = this.time3;
            this.data = this.work_num3;
          }
          if (this.ct === 4) {
            this.time = this.time4;
            this.data = this.work_num4;
          }
          this.drawLine();
        }
      },
    },
  },

  methods: {
    choose1() {
      this.ct = 1;
      this.time = this.time1;
      if (this.type === 1) {
        this.data = this.course_num1;
      }
      if (this.type === 2) {
        this.data = this.task_num1;
      }
      if (this.type === 3) {
        this.data = this.work_num1;
        console.log("work", this.data);
      }

      this.drawLine();
    },
    choose2() {
      this.ct = 2;
      this.time = this.time2;
      if (this.type === 1) {
        this.data = this.course_num2;
      }
      if (this.type === 2) {
        this.data = this.task_num2;
      }
      if (this.type === 3) {
        this.data = this.work_num2;
      }
      this.drawLine();
    },
    choose3() {
      this.ct = 3;

      this.time = this.time3;
      if (this.type === 1) {
        this.data = this.course_num3;
      }
      if (this.type === 2) {
        this.data = this.task_num3;
      }
      if (this.type === 3) {
        this.data = this.work_num3;
      }
      this.drawLine();
    },
    choose4() {
      this.ct = 4;
      this.time = this.time4;
      if (this.type === 1) {
        this.data = this.course_num4;
      }
      if (this.type === 2) {
        this.data = this.task_num4;
      }
      if (this.type === 3) {
        this.data = this.work_num4;
      }
      this.drawLine();
    },

    // removeFile(file, fileList) {
    //   this.photoHide = false;
    // },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    ifNotImage(fileName) {
      let arr = ["jpg", "jpeg", "png", "raw", "tiff", "gif"]; // 图片格式
      return (
        arr.filter((e) => e == fileName.split(".")[1].toLowerCase()).length ===
        0
      );
    },
    imgController(file) {
      if (this.ifNotImage(file.name)) {
        let msg = this.$message({
          type: "warning",
          message: "文件上传格式出错，请重新上传！",
        });
        setTimeout(() => {
          msg.close();
        }, 2000);
        this.fileList = [];
        return false;
      }

      let imgSize = Number(file.size / 1024);
      if (imgSize > 100) {
        this.$msgbox({
          title: "",
          message: "图片大小不能超过100KB，请重新上传。",
          type: "warning",
        });
        this.fileList = [];
        return false;
      }
      return true;
    },
    handleFileUpload(item) {
      if (!this.imgController(item.file)) {
        this.imgList = [];
        return;
      }
      let arr = ["jpg", "png", "jpeg", "bmp", "gif"];
      if (
        arr.filter((e) => e == item.file.name.split(".")[1].toLowerCase())
          .length === 0
      ) {
        let msg = this.$message({
          type: "warning",
          message: "出错了，请重新上传图片",
        });
        setTimeout(() => {
          msg.close();
        }, 2000);
        this.fileList = [];
        return;
      }
      this.photoHide = true;

      var fileObj = item.file;
      console.log("fileobj", fileObj);
      // FormData 对象
      var forms = new FormData();
      // 文件对象
      forms.append("file", fileObj);
      let token = JSON.parse(localStorage.getItem("Authorization"));
      forms.append("user_id", token.user_id);
      adminAxiosUpload(forms)
        .then((res) => {
          console.log("上传", res);
          if (res.data.status === 3) {
            this.$alert("" + res.data.msg, "提示", {
              confirmButtonText: "确定",
              showConfirmButton: false,
            });
            this.fileList = [];
            this.photoHide = false;
          }
          this.file_id = res.data.file_id;
          console.log("file_id", this.file_id);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    handleRemove(file) {
      // console.log(file);
      this.file_id = null;
      this.fileList = [];
      this.photoHide = false;
    },

    drawLine() {
      let myChart = this.$echarts.init(document.getElementById("myChart"));
      // 绘制图表

      var option = {
        legend: {
          data: ["xuanke"],

          left: 0,
        },
        grid: {
          left: 40,
          right: 40,
          top: 70,
          bottom: 20,
        },
        xAxis: {
          type: "category",
          axisLabel: {
            textStyle: {
              color: "#666",
            },
          },
          axisLine: {
            lineStyle: {
              color: "#ddd",
            },
          },
          axisTick: {
            show: false,
          },
          boundaryGap: false,
          data: this.time,
        },

        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "选课数量",
            data: this.data,
            type: "line",
            symbol: "none",
            lineStyle: {
              color: "#00979c",
              width: 3,
            },
            itemStyle: {
              borderWidth: 0,
              color: "#00979c",
            },
            areaStyle: {
              opacity: "0.2",
            },
          },
        ],
      };
      myChart.setOption(option);
    },

    eval() {
      this.n = 1;
      setTimeout(() => {
        this.drawLine();
      }, 1000);
    },
    info() {
      this.n = 2;
      console.log("info", this.n);
    },
    pwd() {
      this.n = 3;
    },
    send2() {
      let token = JSON.parse(localStorage.getItem("Authorization"));
      let verify = localStorage.getItem("verify");
      localStorage.setItem(
        "Authorization",
        JSON.stringify({
          realname: this.real_name,
          type: token.type,
          user_id: token.user_id,
          school_id: token.school_id,
        })
      );

      inforeturn({
        user_id: token.user_id,
        avatar_id: this.file_id,
        type: token.type,
        sex: this.gender,
        realname: this.real_name,
        birthday: this.birthday,
        school_id: token.school_id,
        class_id: this.class_id,
        uid: token.user_id,
        schoolid: token.school_id,
        verify: verify,
        // question_id: this.question_id,
        // answer: this.answer,
      }).then(
        (res) => {
          if (res.status == 1) {
            this.$alert("" + res.msg, "提示", {
              confirmButtonText: "确定",
              showConfirmButton: false,
            });
            setTimeout(() => {
              this.$msgbox.close();
            }, 2000);
            window.location.reload();
          }
          if (res.status != 1) {
            this.$alert(
              "" + res.msg,
              "错误提示",
              {
                confirmButtonText: "确定",
              }
              //  window.location.reload()
            );
          }
        },
        function (error) {}
      );
    },
    // zhuxiao(){

    // },
    send3() {
      if (this.ruleForm.pass === this.ruleForm.checkPass) {
        let token = JSON.parse(localStorage.getItem("Authorization"));
        let verify = localStorage.getItem("verify");
        editpassword({
          user_id: token.user_id,
          old_password: this.ruleForm.old,
          new_password: this.ruleForm.pass,
          uid: token.user_id,
          schoolid: token.school_id,
          verify: verify,
        }).then(
          (res) => {
            console.log(res);
            if (res.status == 1) {
              this.$alert("" + res.msg, "提示", {
                confirmButtonText: "确定",
                showConfirmButton: false,
              });
              this.question_set = res.question_set;
              if (this.question_set == 2) {
                this.$confirm("是否设置保密问题", "提示", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                })
                  .then(() => {
                    this.dialogFormVisible = true;
                  })
                  .catch(() => {
                    this.$message({
                      type: "info",
                      message: "已取消删除",
                    });
                  });
              } else {
                setTimeout(() => {
                  this.$msgbox.close();
                  this.$router.push({ name: "common" });
                }, 2000);
              }
            }
            if (res.status != 1) {
              this.$alert("" + res.msg, "错误提示", {
                confirmButtonText: "确定",
              });
            }
          },
          function (error) {}
        );
      } else {
        this.$alert("" + "密码输入不一致", "提示", {
          confirmButtonText: "确定",
          showConfirmButton: false,
        });
        setTimeout(() => {
          this.$msgbox.close();
        }, 2000);
      }
    },
  },

  mounted() {
    let token = JSON.parse(localStorage.getItem("Authorization"));
    let verify = localStorage.getItem("verify");
    info({
      user_id: token.user_id,
      uid: token.user_id,
      schoolid: token.school_id,
      verify: verify,
    }).then((res) => {
      // console.log('user', res)
      this.id = res.id;
      this.account = res.account;
      this.birthday = res.birthday;
      // console.log('birthday', res.birthday)
      this.schools = res.school_array;
      this.class_id = res.class_id;
      this.classes = res.class_array;
      this.question_id = res.question_id;
      this.questions = res.question_array;
      this.answer = res.answer;
      this.real_name = token.realname;
      this.school_id = token.school_id;
      this.avatar_url = res.avatar_url;
      this.file_id = res.avatar_id;
      this.gender = res.sex;

      let vote = {};
      if (res.avatar_id === null) {
        vote.url = require("../../src/assets/img/avatar.png");
        this.fileList.push(vote);
      } else {
        vote.url = res.avatar_url;
        this.fileList.push(vote);
      }

      if (this.classes !== null) {
        for (let i = 0; i < this.classes.length; i++) {
          if (this.classes[i].school_id === token.school_id) {
            this.classes2.push({});

            this.$set(this.classes2[i], "id", this.classes[i].id);
            this.$set(
              this.classes2[i],
              "class",
              this.classes[i].grade + "年" + this.classes[i].class + "班"
            );
          }
        }
      }
      this.classes2.unshift(...[{ id: 0, class: "--" }]);
      console.log("classw", this.classes2);
    });

    develop({
      user_id: token.user_id,
      uid: token.user_id,
      schoolid: token.school_id,
      verify: verify,
    }).then((res) => {
      console.log("res", res);
      this.eval_array = res.eval_array;
      this.performance = res.performance;
      if (this.performance === null) {
        this.show = 1;
      }
      this.course_array = res.course_array;
      this.task_array = res.task_array;
      this.work_array = res.work_array;
      this.week_start = res.week_start;
      this.month_start = res.month_start;
      this.hyear_start = res.hyear_start;
      this.year_start = res.year_start;

      console.log("course_array", this.course_array);

      var date1 = new Date(),
        time1 =
          date1.getFullYear() +
          "-" +
          (date1.getMonth() + 1) +
          "-" +
          date1.getDate(); //time1表示当前时间

      const weekDate = (aa) => {
        var date1 = new Date(this.week_start),
          time1 =
            date1.getFullYear() +
            "-" +
            (date1.getMonth() + 1) +
            "-" +
            date1.getDate();
        var date2 = new Date(date1);

        date2.setDate(date1.getDate() + aa);
        var time2 =
          date2.getFullYear() +
          "-" +
          (date2.getMonth() + 1) +
          "-" +
          date2.getDate();
        return time2;
      };

      const monthDate = (aa) => {
        var date1 = new Date(this.month_start),
          time1 =
            date1.getFullYear() +
            "-" +
            (date1.getMonth() + 1) +
            "-" +
            date1.getDate();
        var date2 = new Date(date1);

        date2.setDate(date1.getDate() + aa);
        var time2 =
          date2.getFullYear() +
          "-" +
          (date2.getMonth() + 1) +
          "-" +
          date2.getDate();
        return time2;
      };
      const hyearDate = (aa) => {
        var date1 = new Date(this.hyear_start),
          time1 =
            date1.getFullYear() +
            "-" +
            (date1.getMonth() + 1) +
            "-" +
            date1.getDate();
        var date2 = new Date(date1);

        date2.setDate(date1.getDate() + aa);
        var time2 =
          date2.getFullYear() +
          "-" +
          (date2.getMonth() + 1) +
          "-" +
          date2.getDate();
        return time2;
      };
      const yearDate = (aa) => {
        var date1 = new Date(this.year_start),
          time1 =
            date1.getFullYear() +
            "-" +
            (date1.getMonth() + 1) +
            "-" +
            date1.getDate();
        var date2 = new Date(date1);

        date2.setDate(date1.getDate() + aa);
        var time2 =
          date2.getFullYear() +
          "-" +
          (date2.getMonth() + 1) +
          "-" +
          date2.getDate();
        return time2;
      };

      this.time1 = [
        weekDate(0),
        weekDate(1),
        weekDate(2),
        weekDate(3),
        weekDate(4),
        weekDate(5),
        time1,
      ];
      this.time2 = [
        monthDate(0),
        monthDate(2),
        monthDate(5),
        monthDate(8),
        monthDate(11),
        monthDate(14),
        monthDate(17),
        monthDate(20),
        monthDate(23),
        monthDate(26),
        time1,
      ];
      this.time3 = [
        hyearDate(0),
        hyearDate(14),
        hyearDate(29),
        hyearDate(44),
        hyearDate(59),
        hyearDate(74),
        hyearDate(89),
        hyearDate(104),
        hyearDate(119),
        hyearDate(134),
        hyearDate(149),
        hyearDate(164),
        time1,
      ];
      this.time4 = [
        yearDate(0),
        yearDate(29),
        yearDate(59),
        yearDate(89),
        yearDate(119),
        yearDate(149),
        yearDate(179),
        yearDate(209),
        yearDate(239),
        yearDate(269),
        yearDate(299),
        yearDate(329),
        time1,
      ];
      var i = 0;
      if (this.course_array.details !== null) {
        for (i; i < this.course_array.details.length; i++) {
          this.course_num1[this.course_array.details[i].week_pos - 1]++;
          this.course_num2[this.course_array.details[i].month_pos]++;
          this.course_num3[this.course_array.details[i].hyear_pos]++;
          this.course_num4[this.course_array.details[i].year_pos]++;
        }
      }

      var i = 0;
      if (this.task_array.details !== null) {
        for (i; i < this.task_array.details.length; i++) {
          this.task_num1[this.task_array.details[i].week_pos - 1]++;
          this.task_num2[this.task_array.details[i].month_pos]++;
          this.task_num3[this.task_array.details[i].hyear_pos]++;
          this.task_num4[this.task_array.details[i].year_pos]++;
        }
      }

      var i = 0;
      if (this.work_array.details !== null) {
        for (i; i < this.work_array.details.length; i++) {
          this.work_num1[this.work_array.details[i].week_pos - 1]++;
          this.work_num2[this.work_array.details[i].month_pos]++;
          this.work_num3[this.work_array.details[i].hyear_pos]++;
          this.work_num4[this.work_array.details[i].year_pos]++;
        }
      }
    });

    setTimeout(() => {
      if (this.ct === 1 && this.value === "选课数量") {
        this.time = this.time1;
        this.data = this.course_num1;
      }
      this.drawLine();
    }, 1000);

    if (this.value === "选课数量") {
      this.type = 1;
      if (this.ct === 1) {
        this.time = this.time1;
        this.data = this.course_num1;
      }
      if (this.ct === 2) {
        this.time = this.time2;
        this.data = this.course_num2;
      }
      if (this.ct === 3) {
        this.time = this.time3;
        this.data = this.course_num3;
      }
      if (this.ct === 4) {
        this.time = this.time4;
        this.data = this.course_num4;
      }
      this.drawLine();
    }
  },
};
</script>

<style scoped lang="css">
.btn {
  width: 10%;

  margin-left: 45%;
  margin-right: 45%;
}
</style>

<style lang="css" src="../../src/assets/.css/common.css" scoped></style>
<style lang="css" src="../../src/assets/.css/index.css" scoped></style>
<style lang="css" src="../../src/assets/.css/page.css" scoped></style>

<style lang="less" scoped>
.wrapper {
  margin: 0 auto;
  width: 90%;
  min-width: 800px;
  // min-height: 730px;
}

.annaul-ticket-imgs {
  &.hide-upload-btn {
    /deep/.el-upload--picture-card {
      display: none;
    }
  }

  /deep/.el-upload--picture-card {
    width: 300px;
  }

  /deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 300px;
  }
}

.avatar-uploader .el-upload {
  border: 1px dashed #797979;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  line-height: 148px;
  text-align: center;
}

.avatar {
  // width: 148px;
  // height: 148px;
  // display: block;
  height: 100%;
}

.title {
  margin-top: 20px;

  h3,
  h2 {
    display: inline;
  }

  .divider {
    margin: 0 25px;
    height: 30px;
  }

  h3:hover {
    cursor: pointer;
    color: rgb(3, 148, 141);
  }
}

.steps {
  margin: 30px 0;
}

// .description {
//   font-weight: bold;
// }

.labelContainer {
  margin-top: 50px;
}

.formLabel {
  font-size: 15px;
}

.cover {
  float: left;
  width: 240px;
  height: 150px;
  border-radius: 10px;
}

.fileBtn {
  float: left;
  margin: 50px 0 0 20px;
}

.step2Next {
  margin: 50px 0 0 50px;
}

.form {
  margin: 50px 0;
}

.el-radio /deep/ .el-radio__label {
  font-size: 15px;
}
</style>
