<template>
    <div >
        <div class="main" style="width:100%">
            <div class="bread" style="padding: 20px 0;text-align: left;margin-left:5%">
                <!-- <a  style="margin-left:-1060px; font-size: 20px;">返回</a> -->
                <router-link to="/competitioncenter" style="font-size: 20px">{{
                    $t('message.return') }}</router-link>
                <span style="font-size: 20px">{{
                    $t('message.competitiondetail') }}</span>
            </div>

            <div class="course_detail work_list wrapper">
                <div class="box top_course_info top_work_info item_course_info flex" style="width:100%">
                    <img :src="cover_url" class="cover object_fit" alt="" />
                    <div class="padding_left_30">
                        <h2 class="">{{ name }}</h2>
                        <div class="flex">
                            <em class="ellipsis" style="text-align: left; color: black; font-size: 15px">{{
                                $t('message.registrationdeadline') }}：
                                <em class="tag " style="color:orange">{{ entry_deadline }}</em>
                            </em>
                            <em class="ellipsis"
                                style="text-align: left; margin-left:-100px;color: black; font-size: 15px">{{
                                    $t('message.registrationlimit') }}：
                                <em>{{ limitation }} <em v-if="limitation !== '报名人数无上限'"></em></em>
                            </em>
                        </div>
                        <div class="flex_between work_user_info">
                            <div href="" class="user flex">
                                <img class="userimg" src="../../src/assets/img/logo2.png" alt="" />
                                <span>{{ $t('message.address') }}：{{ place }}</span>
                                <span style="margin-left: 50px; margin-top: 6px"><img src="../../src/assets/img/time.png"
                                        alt="" /></span>
                                <span style="margin-left: 0px">{{ $t('message.competitiontime') }}：{{ begin_time }}~{{
                                    end_time
                                }}</span>
                            </div>

                        </div>
                    </div>

                    <div class="top_work_btn_field">
                        <button v-if="ddl === false" class="btn home_btn">
                            {{ $t('message.end') }} </button>

                        <button v-if="ddl === true && status !== 1 && down !== 1" class="btn home_btn" @click="submit">
                            {{ $t('message.enroll') }} </button>
                        <button v-if="ddl === true && status !== 1 && down === 1" class="btn home_btn" @click="submit">
                            {{ $t('message.enroll') }} </button>
                        <button v-if="ddl === true && status === 1 && down !== 1" class="btn home_btn">
                            {{ $t('message.enrolled') }} </button>
                        <button v-if="ddl === true && status === 1 && down === 1" class="btn home_btn">
                            {{ $t('message.enrolled') }} </button>
                        <!-- 还需要传过来一个值判断活动是否已经提交 -->
                        <button v-if="ddl === true && status === 1 && down !== 1 && tijiao[0] === true" class="btn home_btn"
                            style="background-color:rgb(239, 88, 10);margin-top:10px" @click="toadd">
                            {{ $t('message.ncw') }} <i style="margin-left:30px" class="iconfont icon-jia"></i></button>
                        <button v-if="ddl === true && status === 1 && down === 1" class="btn home_btn"
                            style="background-color:rgb(239, 88, 10);margin-top:10px" @click="tomy">
                            {{ $t('message.mcw') }} <i style="margin-left:30px" class="iconfont icon-jinru"></i></button>
                        <button v-if="ddl === true && status === 1 && down === 1 && tijiao[0] === true" class="btn home_btn"
                            style="background-color:rgb(239, 88, 10);margin-top:10px" @click="bianji">
                            {{ $t('message.ecw') }} <i style="margin-left:30px" class="iconfont icon-bianji"></i></button>

                        <span style="margin-top:50px" class="update_time">{{ click_num }} {{ $t('message.check') }}</span>

                        <div class="opration flex_center">
                            <!-- <img src="../../src/assets/img/icon_edit.png" alt="">
                            <span style="padding-bottom: 0px;">{{ $t('message.end') }}</span> -->


                        </div>

                    </div>
                </div>

                <div class="box course_intro" style="width:100%">
                    <div class="intro_text" style="width:100%">
                        <h3>{{ $t('message.competitionsummary') }}</h3>
                        <div v-if="summary !== ''" class="flex_between">
                            {{ summary }}
                        </div>
                        <div v-if="summary === ''" class="flex_between">
                            暂无
                        </div>
                    </div>

                </div>
                <div class="box course_intro" style="width:100%">
                    <div class="intro_text" style="width:100%">
                        <h3>{{ $t('message.competitionnotice') }}</h3>
                        <div v-if="notice !== ''" class="flex_between">
                            {{ notice }}
                        </div>
                        <div v-if="notice === ''" class="flex_between">
                            暂无
                        </div>
                    </div>
                </div>
                <!-- 已经提交作品才显示 或者不用管 没被改出来的时候也就没有优秀作品 -->
                <div class="wrapper" style="width:100%">
                    <div class="hot_course wrapper" style="width:100%">
                        <h2>
                            <span><em style="text-align:left">{{ $t('message.rcw') }}</em></span>

                        </h2>
                    </div>

                    <div v-if="tableData !== null" class="clearfix">
                        <el-table :data="tableData" :row-class-name="tableRowClassName">
                            <el-table-column prop="paiming" key="paiming">
                                <!-- <i  class="iconfont icon-jinpai"></i> -->

                                <template scope="scope">
                                    <img v-if="scope.row.paiming === 1" style="width:30px"
                                        src="../../src/assets/img/jinpai.png"></img>
                                    <img v-if="scope.row.paiming === 2" style="width:30px"
                                        src="../../src/assets/img/yinpai.png"></img>
                                    <img v-if="scope.row.paiming === 3" style="width:30px"
                                        src="../../src/assets/img/tongpai.png"></img>
                                    <div v-if="scope.row.paiming != 1 && scope.row.paiming != 2 && scope.row.paiming != 3"
                                        style="text-align:left;margin-left:10px;font-size:16px;font-weight:bold">
                                        {{
                                            scope.row.paiming }}</div>
                                </template>
                            </el-table-column>

                            <!-- <el-table-column prop="paiming" label="活动作品排名" width="180">
                            </el-table-column> -->
                            <el-table-column prop="name" :label="$t('message.cwn')">
                            </el-table-column>
                            <el-table-column prop="type_name" :label="$t('message.cwt')">
                            </el-table-column>
                            <el-table-column prop="author_name" :label="$t('message.author')">
                            </el-table-column>
                            <el-table-column prop="click_num" :label="$t('message.djl')">
                            </el-table-column>
                            <el-table-column prop="update_time" :label="$t('message.time')">
                            </el-table-column>
                            <el-table-column prop="total_score" :label="$t('message.oas')">
                            </el-table-column>
                            <el-table-column :label="$t('message.operation')">
                                <template slot-scope="scope">
                                    <el-button @click="handleClick(scope.row)" type="text" size="small">{{
                                        $t('message.chakan') }}</el-button>
                                </template>
                            </el-table-column>




                        </el-table>
                    </div>
                    <div v-if="tableData === null" style="text-align: center">
                        <div> <img src="../../src/assets/img/nomessage.png"
                                style="width:50px;text-align: center;position:absolute">
                            <span
                                style="height: 100px; line-height: 100px;left:60px;position:relative;top:-20px;color:grey">暂无活动作品</span>
                        </div>


                    </div>




                </div>
                <div class="wrapper btn_container">
                    <router-link to="/competitioncenter" class="btn home_btn btn_light">{{
                        $t('message.return') }}</router-link>
                </div>
            </div>


        </div>


    </div>
</template>
     
<script>

import { competitiondetail, WorkChooseCompetition, mypagecompetition, competitionworkpage } from "@/http/api.js"; // 导入接口
import { excellentcompetitionpage } from "@/http/api.js"; // 导入接口
import CommonFooter from "@/components/CommonFooter.vue";
export default {
    name: "coursepdf",
    data() {
        return {
            cover_url: "",
            name: "",
            entry_deadline: "",
            begin_time: "",
            end_time: "",
            place: "",
            click_num: "",
            summary: "",
            notice: "",
            ddl: null,
            limitation: null,
            status: null,
            tableData: [],
            myworks: [],
            n: 1,
            // x: 1,
            show2: 4,
            swiper: [],
            language: "",
            visitor: 2,
            name: '',
            down: null,
            tijiao: [],
            x: null

        };
    },
    filters: {
        ellipsis(value) {
            if (!value) return '';
            if (value.length > 3) {
                return value.slice(0, 3) + '...'


            }
            return value
        }
    },
    mounted() {


        console.log(this.$route.params.id);
        let token = JSON.parse(localStorage.getItem("Authorization"));
        let verify = localStorage.getItem("verify")
        competitiondetail({
            user_id: token.user_id,
            competition_id: this.$route.params.id,
            uid: token.user_id,
            schoolid: token.school_id,
            verify: verify
        }).then((res) => {
            console.log('competitiondetail', res);
            this.cover_url = res.cover_url
            this.name = res.name
            this.entry_deadline = res.entry_deadline
            this.begin_time = res.begin_time
            this.end_time = res.end_time
            this.place = res.place
            this.click_num = res.click_num
            this.summary = res.summary
            this.notice = res.notice
            this.limitation = res.limitation
            this.status = res.status
            this.down = res.down

            if (this.limitation === -1) {
                this.limitation = "报名人数无上限"
            }
           
            let year = new Date().getFullYear();
            //月份是从0月开始获取的，所以要+1;
            let month = new Date().getMonth() + 1;
            //日
            let day = new Date().getDate();
            //时
            let hour = new Date().getHours();
            //分
            let minute = new Date().getMinutes();
            //秒
            let second = new Date().getSeconds();
            let time = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second
            console.log(time)
            //比较两个日期大小。格式：2018-8-12 12:30
            const compareDate = (d1, d2) => {
                return ((new Date(d1.replace(/\-/g, "\/"))) > (new Date(d2.replace(/\-/g, "\/"))));
            }
            this.ddl = compareDate(this.entry_deadline, time)
            this.tijiao = [compareDate(time, this.begin_time) && compareDate(this.end_time, time)]
            console.log('tijiao', this.tijiao)

     

        });


        // 活动作品分数列表
        competitionworkpage({
            competition_id: this.$route.params.id,
            uid: token.user_id,
            schoolid: token.school_id,
            verify: verify
        }).then((res) => {
            console.log('worjsf', res.works)
            if (res.works !== null) {
                this.tableData = res.works
            } else {
                this.tableData = null
            }
            for (var i = 0; i < this.tableData.length; i++) {
                if (this.tableData[i].total_score === null) {
                    this.tableData[i].total_score = "暂无评分"
                }
            }
            if (this.tableData !== null) {

                this.tableData.sort(function (a, b) {
                    return b.total_score - a.total_score
                })
                for (let i = 0; i < this.tableData.length; i++) {

                    this.$set(this.tableData[i], "paiming", i + 1)
                }
            }
         
                if ( this.tableData.sort(function (a, b) {return b.total_score - a.total_score})===0) {
                    this.tableData.sort(function (a, b) {
                        return b.click_num - a.click_num
                    })
                    for (let i = 0; i < this.tableData.length; i++) {

                        this.$set(this.tableData[i], "paiming", i + 1)
                    }
                }
            


            console.log('this.tableData', this.tableData)

        });
        mypagecompetition({
            competition_id: this.$route.params.id,
            school_id: token.school_id,
            user_id: token.user_id,
            uid: token.user_id,
            schoolid: token.school_id,
            verify: verify
        }).then((res) => {
            // 需要改成优秀活动作品
            if (res.works !== null) {
                this.myworks = res.works
                localStorage.setItem("file_name", JSON.stringify(this.myworks[0].name + '.xml'));
            }


        });


   
  


    },
    methods: {
        handleClick(row) {
            console.log('row', row)
            this.$router.push({
                name: "goodcompetitiondetail",
                params: { id: row.id, competition_id: row.competition_id },
            });
        },
        tableRowClassName({ row, rowIndex }) {
            console.log('row', row)
            if (rowIndex === 0) {
                return 'no1';
            } else if (rowIndex === 1) {
                return 'no2';
            } else if (rowIndex === 2) {
                return 'no3';
            }
            return '';
        },
        tomy() {
            this.$router.push({
                name: "mycompetitiondetail",
                params: { id: this.myworks[0].id, competition_id: this.myworks[0].competition_id },
            });
        },
        toadd() {
            this.$router.push({
                name: "competitionaddwork",
                params: { id: null, competition_id: this.$route.params.id },
            });
        },
        bianji() {
            this.$router.push({
                name: "competitionaddwork",
                params: { id: this.myworks[0].id, competition_id: this.$route.params.id },
            });
        },

        submit() {
            let token = JSON.parse(localStorage.getItem("Authorization"));
            let verify = localStorage.getItem("verify")
            WorkChooseCompetition({
                student_id: token.user_id,
                competition_id: this.$route.params.id,
                uid: token.user_id,
                schoolid: token.school_id,
                verify: verify
            }).then(
                (res) => {
                    if (res.status === 1) {
                        this.$alert("" + res.msg, "状态提示", {
                            confirmButtonText: "确定",
                            showConfirmButton: false,
                        });
                        setTimeout(() => {
                            this.$msgbox.close();
                            window.location.reload()
                        }, 2000);

                    }else{
                        this.$alert("" + res.msg, "状态提示", {
                            confirmButtonText: "确定",
                            showConfirmButton: false,
                        });
                        setTimeout(() => {
                            this.$msgbox.close();
                            window.location.reload()
                        }, 2000);
                    }

                },
                function (error) { }
            );
        },

    },
    components: {
        CommonFooter,
    },
};
</script>
     
<style></style>
     
<style lang="css"  src='../../src/assets/.css/common.css'  scoped></style>
<style lang="css"  src='../../src/assets/.css/swiper-bundle.min.css'  scoped></style>
<style lang="css"  src='../../src/assets/.css/index.css'  scoped></style>
<style lang="css"  src='../../src/assets/.css/page.css'  scoped></style>
<style lang="css" scoped>
* {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
</style >
<style >
.el-table .no1 {
    background: rgb(253, 226, 176);

}

.el-table .no2 {
    background: rgb(250, 232, 196);
}

.el-table .no3 {
    background: oldlace;
}
</style>