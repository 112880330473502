const Mock = require('mockjs');
// 格式： Mock.mock( url, post/get , 返回的数据)；  下面的userInfo是指mock目录下的json目录下的userInfo.json文件
// Mock.mock('/user/userInfo', 'get', require('./json/userInfo'));
Mock.mock('/user/login', 'get', require('./json/login'));
Mock.mock('/user/loginReturn', 'post', require('./json/loginReturn'));
Mock.mock('/user/register', 'get', require('./json/register'));
Mock.mock('/user/registerReturn', 'post', require('./json/registerReturn'));
Mock.mock('/user/index', 'get', require('./json/index'));
Mock.mock('/user/findpwd', 'get', require('./json/findpwd'));
Mock.mock('/user/findpwdReturn', 'post', require('./json/findpwdReturn'));
