/**
 * api接口统一管理
 */
import { get, post } from './axios'
import axios from 'axios'

/**
 *
 * @param {string} params
 * 我们定义了一个topics方法
 * 这个方法有一个参数params
 * params是我们请求接口时携带的参数对象
 * 而后调用了我们封装的post方法
 * post方法的第一个参数是我们的接口地址
 * 第二个参数是topics的params参数
 * 即请求接口时携带的参数对象
 * 最后通过export导出topics。
 */


// var apiUrl = 'http://mixrecommender'
var apiConfig = params => get('admin/config.json', params)
var apiUrl = null
apiConfig().then(res => {
  apiUrl = res.url + "/index.php?r="
})

// async function initializeApiUrl() {
//   try {
//     const res = await apiConfig();
//     apiUrl = res.url + "/index.php?r=";
//   } catch (error) {
//     // 处理错误情况
//     console.error(error);
//   }
// }

// // 调用初始化函数
// initializeApiUrl();

export const loginpage = params => get(apiUrl + 'site/loginpage', params)
export const verifycode = params => get(apiUrl + 'site/verifycode', params)
export const login = params => post(apiUrl + 'site/login', params)
export const registerpage = params => get(apiUrl + 'site/registerpage', params)
export const register = params => post(apiUrl + 'site/register', params)
export const passwordpage = params => get(apiUrl + 'site/passwordpage', params)
export const password = params => post(apiUrl + 'site/password', params)
export const indexpage = params => post(apiUrl + 'site/indexpage', params)
export const getparam = params => post(apiUrl + 'adminsys/getparam', params)
export const getparamfile = params => post(apiUrl + 'adminsys/getparamfile', params)
export const setback = params => get(apiUrl + 'site/setback', params)




export const courseindexpage = params => post(apiUrl + 'course/indexpage', params)
export const coursechoose = params => post(apiUrl + 'course/choose', params)
export const detailspage = params => post(apiUrl + 'course/detailspage', params)
export const videopage = params => post(apiUrl + 'course/coursewarepage', params)
export const homeworkpage = params => post(apiUrl + 'assignment/homeworkpage', params)

export const homeworksubmit = params => post(apiUrl + 'file/writefile', params)

export const test = params => post(apiUrl + 'assignment/testpage', params)
export const questionnairepage = params => post(apiUrl + 'assignment/questionnairepage', params)
export const submit = params => post(apiUrl + 'assignment/testsubmit', params)
export const questionnairesubmit = params => post(apiUrl + 'assignment/questionnairesubmit', params)
//  export const discussion = params => get('/discussion.json', params) //还没有此功能
export const taskpage = params => post(apiUrl + 'assignment/indexpage', params)
export const fwbhomeworksubmit = params => post(apiUrl + 'assignment/homeworksubmit', params)

//work
export const excelentpage = params => post(apiUrl + 'works/excellentpage', params)
export const workspage = params => post(apiUrl + 'works/mypage', params)
export const newworkpage = params => post(apiUrl + 'works/newworkpage', params)
export const stepone = params => post(apiUrl + 'works/stepone', params)

export const workapply = params => post(apiUrl + 'works/apply', params)
export const myworkpage = params => post(apiUrl + 'works/detailspage', params)
export const editworkspage = params => get(apiUrl + 'works/editworkpage', params)
export const apply = params => post(apiUrl + 'works/apply', params)//申请成为优秀作品不用了
export const deletework = params => post(apiUrl + 'works/deletework', params)
export const WorkChooseCompetition = params => post(apiUrl + 'works/choosecompetition', params)
//user
export const info = params => post(apiUrl + 'personal/indexpage', params)
export const inforeturn = params => post(apiUrl + 'personal/edit', params) //post修改信息
export const editpassword = params => post(apiUrl + 'personal/password', params) //post修改密码
export const develop = params => post(apiUrl + 'personal/develop', params)
export const question = params => post(apiUrl + 'personal/question', params)

//competition
export const competitioncenter = params => post(apiUrl + 'adminwork/competitioncenter', params)
export const competitiondetail = params => post(apiUrl + 'adminwork/competitiondetail', params)
// admin
export const config = params => get('/admin/config.json', params)
export const data = params => get('/admin/data.json', params)
export const lessonInfo = params => get('/admin/lessonInfo.json', params)
export const userInfo = params => get('/admin/userInfo.json', params)
export const lessonTree = params => get('/admin/lessonTree.json', params)
export const lessonTreeTest = params => get('/admin/lessonTreeTest.json', params)
export const studentInfo_lesson = params => get('/admin/studentInfo_lesson.json', params)
export const homeworkInfo = params => get('/admin/homeworkInfo.json', params)
export const moduleType = params => get('/admin/moduleType.json')

//  export const adminUser = params => get(apiUrl + 'adminsite/user', params)
export const AdminSiteWebname = params => post(apiUrl + '/adminsite/webname', params)
export const AdminSiteAvatar = params => post(apiUrl + '/adminsite/avatar', params)

export const adminSiteToDo = params => post(apiUrl + '/adminsite/todo', params)
export const adminSiteCsToDo = params => post(apiUrl + '/adminsite/cstodo', params)
export const adminSiteTaskToDo = params => post(apiUrl + '/adminsite/tasktodo', params)
export const adminSiteTestToDo = params => post(apiUrl + '/adminsite/testtodo', params)
export const adminSiteCompetitionToDo = params => post(apiUrl + '/adminsite/competitiontodo', params)

export const adminIndex = params => post(apiUrl + 'adminsite/indexpage', params)
export const adminAxiosUpload = data => axios({
  method: 'post',
  timeout: 90000000000000000, //这个就是重点 延长时间
  url: apiUrl + 'file/uploadfile',
  data: data
})
export const adminMavonUpload = data => axios({
  method: 'post',
  timeout: 90000000000000000, //这个就是重点 延长时间
  url: apiUrl + 'file/mavonimg',
  data: data
})

export const attachmentpage = params => post(apiUrl + 'course/attachmentpage', params)

export const adminFileUploadZipFile = data => axios({
  method: 'post',
  timeout: 900000, //这个就是重点 延长时间
  url: apiUrl + 'file/uploadzipfile',
  data: data
})
// export const adminUpload = params => post(apiUrl + 'file/uploadfile', params)
export const adminCourse = params => post(apiUrl + 'admincourse/indexpage', params)
export const adminCourseDetail = params => post(apiUrl + 'admincourse/detailspage', params)
export const adminCourseDelete = params => post(apiUrl + 'admincourse/delete', params)
export const adminCourseShow = params => post(apiUrl + 'admincourse/show', params)
export const adminCoursePublish = params => post(apiUrl + 'admincourse/publish', params)
export const adminCourseAddCourse = params => post(apiUrl + 'admincourse/addcourse', params)
export const adminCourseAddPage = params => post(apiUrl + 'admincourse/addpage', params)
export const adminCourseAddOutline = params => post(apiUrl + 'admincourse/addoutline', params)
export const adminCourseEditOutline = params => post(apiUrl + 'admincourse/editoutline', params)
export const adminCourseDeleteOutline = params => post(apiUrl + 'admincourse/deleteoutline', params)
export const adminCourseEditPage = params => post(apiUrl + 'admincourse/editpage', params)
export const adminCourseEdit = params => post(apiUrl + 'admincourse/edit', params)
export const adminCourseCode = params => post(apiUrl + 'admincourse/coursecode', params)
export const adminCourseCheckState = params => post(apiUrl + 'admincourse/checkstate', params)
export const adminCourseAddStudents = data => axios({
  method: 'post',
  timeout: 900000, //这个就是重点 延长时间
  url: apiUrl + 'admincourse/addstudents',
  data: data
})
export const adminCourseDeleteStudent = params => post(apiUrl + 'admincourse/deletestudent', params)
export const adminCourseDeleteStudents = params => post(apiUrl + 'admincourse/deletestudents', params)
export const adminCourseStudentPage = params => post(apiUrl + 'admincourse/studentpage', params)
export const adminCourseAddCourseWarePage = params => post(apiUrl + 'admincourse/addcoursewarepage', params)
export const adminCourseAddCourseWare = params => post(apiUrl + 'admincourse/addcourseware', params)
export const adminCourseEditCourseWarePage = params => post(apiUrl + 'admincourse/editcoursewarepage', params)
export const adminCourseEditCourseWare = params => post(apiUrl + 'admincourse/editcourseware', params)
export const adminCourseAddSourcePage = params => post(apiUrl + 'admincourse/addsourcepage', params)
export const adminCourseAddSource = params => post(apiUrl + 'admincourse/addsource', params)
export const adminCourseEditAttachmentPage = params => post(apiUrl + 'admincourse/editattachmentpage', params)
export const adminCourseEditAttachment = params => post(apiUrl + 'admincourse/editattachment', params)
export const adminAssignmentDeleteHomework = params => post(apiUrl + 'adminassignment/deletehomework', params)
export const AdminAssignmentDeleteHomeworks = params => post(apiUrl + 'adminassignment/deletehomeworks', params)
export const adminAssignmentDeleteTest = params => post(apiUrl + 'adminassignment/deletetest', params)
export const AdminAssignmentDeleteTests = params => post(apiUrl + 'adminassignment/deletetests', params)
export const adminAssignmentDeleteQuestionnaire = params => post(apiUrl + 'adminassignment/deletequestionnaire', params)
export const AdminAssignmentDeleteQuestionnaires = params => post(apiUrl + 'adminassignment/deletequestionnaires', params)
export const adminAssignmentHomework = params => post(apiUrl + 'adminassignment/homework', params)
export const adminAssignmentAddHomeworkPage = params => post(apiUrl + 'adminassignment/addhomeworkpage', params)
export const adminAssignmentEditHomeworkPage = params => post(apiUrl + 'adminassignment/edithomeworkpage', params)
export const adminAssignmentEditHomework = params => post(apiUrl + 'adminassignment/edithomework', params)
export const adminAssignmentPublishHomework = params => post(apiUrl + 'adminassignment/publishhomework', params)
export const adminAssignmentAddTestPage = params => post(apiUrl + 'adminassignment/addtestpage', params)
export const adminAssignmentAddTest = params => post(apiUrl + 'adminassignment/addtest', params)
export const adminAssignmentAddQsPage = params => post(apiUrl + 'adminassignment/addqspage', params)
export const adminAssignmentAddQs = params => post(apiUrl + 'adminassignment/addqs', params)
export const adminAssignmentCourseModule = params => post(apiUrl + 'adminassignment/coursemodule', params)
export const adminAssignmentTestPage = params => post(apiUrl + 'adminassignment/testpage', params)
export const adminAssignmentEditTestPage = params => post(apiUrl + 'adminassignment/edittestpage', params)
export const adminAssignmentEditTest = params => post(apiUrl + 'adminassignment/edittest', params)
export const adminAssignmentDeleteTestItem = params => post(apiUrl + 'adminassignment/deletetestitem', params)
export const adminAssignmentEditQsPage = params => post(apiUrl + 'adminassignment/editqspage', params)
export const adminAssignmentEditQs = params => post(apiUrl + 'adminassignment/editqs', params)
export const adminAssignmentQs = params => post(apiUrl + 'adminassignment/qs', params)
export const adminAssignmentQnQs = params => post(apiUrl + 'adminassignment/qnqs', params)

export const adminAssignmentStudentHwPage = params => post(apiUrl + 'adminassignment/studenthwpage', params)
export const adminAssignmentHomeworkJudge = params => post(apiUrl + 'adminassignment/homeworkjudge', params)
export const adminAssignmentStudentTestPage = params => post(apiUrl + 'adminassignment/studenttestpage', params)
export const adminAssignmentStudentQnPage = params => post(apiUrl + 'adminassignment/studentqnpage', params)

export const adminTaskQuestionnaire = params => post(apiUrl + 'admintask/questionnaire', params)
export const adminTaskAddQuestionnairePage = params => post(apiUrl + 'admintask/addquestionnairepage', params)
export const adminTaskAddQuestionnaire = params => post(apiUrl + 'admintask/addquestionnaire', params)
export const adminTaskEditQuestionnairePage = params => post(apiUrl + 'admintask/editquestionnairepage', params)
export const adminTaskEditQuestionnaire = params => post(apiUrl + 'admintask/editquestionnaire', params)
export const adminTaskAddSubjectPage = params => post(apiUrl + 'admintask/addsubjectpage', params)
export const adminTaskAddSubject = params => post(apiUrl + 'admintask/addsubject', params)
export const adminTaskEditSubjectPage = params => post(apiUrl + 'admintask/editsubjectpage', params)
export const adminTaskEditSubject = params => post(apiUrl + 'admintask/editsubject', params)
export const AdminTaskHomeworklist = params => post(apiUrl + 'admintask/homeworklist', params)
export const AdminTaskTestlist = params => post(apiUrl + 'admintask/testlist', params)
export const adminTaskDeleteQnItem = params => post(apiUrl + 'admintask/deleteqnitem', params)

export const AdminTaskQuestionnairelist = params => post(apiUrl + 'admintask/questionnairelist', params)

export const AdminUserUserlist = params => post(apiUrl + 'adminuser/userlist', params)
export const AdminUserAddUserPage = params => post(apiUrl + 'adminuser/adduserpage', params)
export const AdminUserAddUser = params => post(apiUrl + 'adminuser/adduser', params)
export const AdminUserAddUsers = data => axios({
  method: 'post',
  timeout: 900000, //这个就是重点 延长时间
  url: apiUrl + 'adminuser/addusers',
  data: data
})
export const AdminUserEditUserPage = params => post(apiUrl + 'adminuser/edituserpage', params)
export const AdminUserModifyPassword = params => post(apiUrl + 'adminuser/modifypassword', params)
export const AdminUserModifyPasswordByAdmin = params => post(apiUrl + 'adminuser/modifypasswordbyadmin', params)
export const AdminUserEditUser = params => post(apiUrl + 'adminuser/edituser', params)
export const AdminUserEditUsers = data => axios({
  method: 'post',
  timeout: 900000, //这个就是重点 延长时间
  url: apiUrl + 'adminuser/editusers',
  data: data,
})
export const AdminUserDeleteUser = params => post(apiUrl + 'adminuser/deleteuser', params)
export const AdminUserSchoollist = params => post(apiUrl + 'adminuser/schoollist', params)
export const AdminUserAddSchool = params => post(apiUrl + 'adminuser/addschool', params)
export const AdminUserEditSchoolPage = params => post(apiUrl + 'adminuser/editschoolpage', params)
export const AdminUserEditSchool = params => post(apiUrl + 'adminuser/editschool', params)
export const AdminUserDeleteSchool = params => post(apiUrl + 'adminuser/deleteschool', params)
export const AdminUserClasslist = params => post(apiUrl + 'adminuser/classlist', params)
export const AdminUserAddClassPage = params => post(apiUrl + 'adminuser/addclasspage', params)
export const AdminUserAddClass = params => post(apiUrl + 'adminuser/addclass', params)
export const AdminUserEditClassPage = params => post(apiUrl + 'adminuser/editclasspage', params)
export const AdminUserEditClass = params => post(apiUrl + 'adminuser/editclass', params)
export const AdminUserDeleteClass = params => post(apiUrl + 'adminuser/deleteclass', params)
export const AdminUserDevelop = params => post(apiUrl + 'adminuser/develop', params)

export const adminWorkWorklist = params => post(apiUrl + 'adminwork/worklist', params)
export const adminWorkDeleteWork = params => post(apiUrl + 'adminwork/deletework', params)
export const adminWorkPublicWork = params => post(apiUrl + 'adminwork/publicwork', params)
export const AdminWorkCompetitionPage = params => post(apiUrl + 'adminwork/competitionpage', params)
export const AdminWorkAddCompetitionPage = params => post(apiUrl + 'adminwork/addcompetitionpage', params)
export const AdminWorkAddCompetition = params => post(apiUrl + 'adminwork/addcompetition', params)
export const AdminWorkEditCompetitionPage = params => post(apiUrl + 'adminwork/editcompetitionpage', params)
export const AdminWorkEditCompetition = params => post(apiUrl + 'adminwork/editcompetition', params)
export const AdminWorkDeleteCompetition = params => post(apiUrl + 'adminwork/deletecompetition', params)
export const AdminWorkCompetitionCenter = params => post(apiUrl + 'adminwork/competitioncenter', params)
export const AdminWorkCompetitionDetailInBack = params => post(apiUrl + 'adminwork/competitiondetailinback', params)
export const AdminWorkDeleteEnroll = params => post(apiUrl + 'adminwork/deleteenroll', params)
export const AdminWorkMyAudit = params => post(apiUrl + 'adminwork/myaudit', params)

export const adminSysIndexPage = params => post(apiUrl + 'adminsys/indexpage', params)
export const adminSysQuestionlist = params => post(apiUrl + 'adminsys/questionlist', params)

export const adminSysFilelist = params => post(apiUrl + 'adminsys/filelist', params)
export const adminSysDeleteFile = params => post(apiUrl + 'adminsys/deletefile', params)
export const adminSysAddquestion = params => post(apiUrl + 'adminsys/addquestion', params)
export const adminSysDeletequestion = params => post(apiUrl + 'adminsys/deletequestion', params)
export const adminSysAddSwiper = params => post(apiUrl + 'adminsys/addswiper', params)
export const adminSysDeleteswiper = params => post(apiUrl + 'adminsys/deleteswiper', params)
export const adminSysStarCourse = params => post(apiUrl + 'adminsys/starcourse', params)
export const adminSysEditQuestion = params => post(apiUrl + 'adminsys/editquestion', params)
export const adminSysSchoolCourse = params => post(apiUrl + 'adminsys/schoolcourse', params)
export const adminSysExcellentcourse = params => post(apiUrl + 'adminsys/excellentcourse', params)
export const adminSysDeleteexcellent = params => post(apiUrl + 'adminsys/deleteexcellent', params)
export const adminSysAllexcellentcourse = params => post(apiUrl + 'adminsys/allexcellentcourse', params)
export const adminSysSysParams = params => post(apiUrl + "adminsys/sysparams", params)
export const adminSysEditParam = params => post(apiUrl + "adminsys/editparam", params)
export const adminSysGetParamsFile = params => post(apiUrl + "adminsys/getparamfile", params)

export const adminAssignmentTestJudge = params => post(apiUrl + 'adminassignment/testjudge', params)


//competition
export const excellentcompetitionpage = params => post(apiUrl + 'works/excellentcompetitionpage', params)
export const mypagecompetition = params => post(apiUrl + 'works/mypagecompetition', params)
export const newcompetitionworkpage = params => post(apiUrl + 'works/newcompetitionworkpage', params)
export const steponecompetition = params => post(apiUrl + 'works/steponecompetition', params)
export const steptwo = params => post(apiUrl + 'works/steptwo', params)
export const steptwocompetition = params => post(apiUrl + 'works/steptwocompetition', params)
export const detailspagecompetition = params => post(apiUrl + 'works/detailspagecompetition', params)
export const competitionworkpage = params => post(apiUrl + 'works/competitionworkpage', params)
export const admincompetitionjudgepage = params => post(apiUrl + 'adminwork/competitionjudgepage', params)
export const admincompetitionjudge = params => post(apiUrl + 'adminwork/competitionjudge', params)

export const AdminSiteGetBehaviorData = params => post(apiUrl + '/adminsite/getbehaviordata', params)
export const AdminSiteBehaviorManagement = params => post(apiUrl + '/adminsite/behaviormanagement', params)
export const AdminKnowledgeTreeList = params => post(apiUrl + '/adminknowledge/treelist', params)
export const AdminKnowledgeAddTree = params => post(apiUrl + '/adminknowledge/addtree', params)
export const AdminKnowledgeEditTree = params => post(apiUrl + '/adminknowledge/edittree', params)
export const AdminKnowledgeDeleteTree = params => post(apiUrl + '/adminknowledge/deletetree', params)
export const adminKnowledgeOutlinePage = params => post(apiUrl + '/adminknowledge/outlinepage', params)
export const adminKnowledgeEditOutline = params => post(apiUrl + '/adminknowledge/editoutline', params)
export const adminCodeExerciseList = params => post(apiUrl + '/admincodeexercise/exerciselist', params)
export const adminCodeExerciseAddExercisePage = params => post(apiUrl + '/admincodeexercise/addexercisepage', params)
export const adminCodeExerciseTreeKnowledge = params => post(apiUrl + '/admincodeexercise/treeknowledge', params)
export const adminCodeExerciseAddExercise = params => post(apiUrl + '/admincodeexercise/addexercise', params)
export const adminCodeExerciseDeleteExercise = params => post(apiUrl + '/admincodeexercise/deleteexercise', params)
export const adminCodeExerciseEditCodeExercisePage = params => post(apiUrl + '/admincodeexercise/editcodeexercisepage', params)
export const adminCodeExerciseEditExercise = params => post(apiUrl + '/admincodeexercise/editexercise', params)
export const adminOjProblemList = params => post(apiUrl + '/adminojproblem/problemlist', params)
export const adminOjProblemDeleteProblem = params => post(apiUrl + '/adminojproblem/deleteproblem', params)
export const adminOjProblemAddProblemPage = params => post(apiUrl + '/adminojproblem/addproblempage', params)
export const adminOjProblemPredictDifficulty = data => axios({
  method: 'post',
  timeout: 900000, //这个就是重点 延长时间
  url: apiUrl + '/adminojproblem/predictdifficulty',
  data: data
})
export const adminOjProblemAddOjProblem = params => post(apiUrl + '/adminojproblem/addojproblem', params)
export const adminOjProblemEditProblemPage = params => post(apiUrl + '/adminojproblem/editproblempage', params)
export const adminOjProblemEditOjProblem = params => post(apiUrl + '/adminojproblem/editojproblem', params)
export const adminOjProblemAddProblems = data => axios({
  method: 'post',
  timeout: 90000000000000000, //这个就是重点 延长时间
  url: apiUrl + '/adminojproblem/addproblems',
  data: data
})
export const codeExercisePage = params => post(apiUrl + '/codeexercise/exercisepage', params)
export const problem = params => post(apiUrl + '/codeexercise/problem', params)
export const codeexerciseProblemList = params => post(apiUrl + '/codeexercise/problemlist', params)
export const codeexerciseNextProblem = params => post(apiUrl + '/codeexercise/nextproblem', params)
export const codeexerciseRandomProblem = params => post(apiUrl + '/codeexercise/randomproblem', params)
export const codeexerciseAddEvent = params => post(apiUrl + '/codeexercise/addevent', params)
export const adminCodeExerciseDetail = params => post(apiUrl + '/admincodeexercise/exercisedetail', params)
export const adminCodeExerciseStudentDetail = params => post(apiUrl + '/admincodeexercise/studentdetail', params)
export const adminCodeExerciseStudentExerciseDetail = params => post(apiUrl + '/admincodeexercise/studentexercisedetail', params)
export const adminCodeExerciseSubmitRecord = params => post(apiUrl + '/admincodeexercise/submitrecord', params)
export const adminCodeExerciseViewCode = params => post(apiUrl + '/admincodeexercise/viewcode', params)
export const codeExerciseSubmitInfo = params => post(apiUrl + '/codeexercise/submitinfo', params)