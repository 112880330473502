<template>
    <div >
       <!-- <div v-if="type2===null&&status===0">
     
    <CommonLayout></CommonLayout>
       </div>
       <div v-if="type2===1&&status===1">
    <CommonStudent></CommonStudent>
       </div>
       <div v-if="type2===2&&status===1">
    <CommonTeacher></CommonTeacher>
       </div> -->
       <div >
       <div class="bread ">
           <router-link :to="{name:'coursediscussion', params:{id:$route.params.module_id,kcid:this.$route.params.kcid}}" style="margin-left:-1060px; font-size: 20px;">返回</router-link>
           <span style="margin-left:;font-size: 20px;">分组聊天</span>
       </div>
       <el-row>
         <el-col :span="24">
          <div class="grid-content" style="border-style:solid none solid none;border-color:rgb(246, 247, 249)">
          <img class="img"   src="../../src/assets/img/talk.png" height="26" alt="">
         <span style="position:absolute;left:150px;top:10px;color:grey">CHAT</span>
         <span style="position:absolute;left:150px;top:30px;font-weight: bold;font-size:20px">第一组聊天室</span>
        </div></el-col>
       </el-row>
       <el-row>
         <el-col :span="19">
          <div  style="border-radius: 4px;min-height: 550px;margin-top:10px;margin-bottom:10px;border-style:solid solid solid none;border-color:rgb(246, 247, 249)">

          </div>
        </el-col>
         <el-col :span="5"><div  style="border-radius: 4px;min-height: 550px;margin-top:10px">
        <div style="margin:10px;padding:7px;border-style:solid none solid none;border-color:rgb(246, 247, 249);height:60px" >
          <div style="height:40px;position:absolute">
            <img style="width:40px;height:40px;position:relative;left:10px"  src="../../src/assets/img/avatar.png" height="26" alt="">
            <span style="position:relative;left:30px;bottom:10px;font-size:20px;font-weight: bold;color:rgb(67, 127, 191)">Terri Teacher</span>
          </div>
         
          
        </div>
        <div style="margin:10px;padding:7px;border-style:solid none solid none;border-color:rgb(246, 247, 249);height:60px" >
          <div style="height:40px;position:absolute">
            <img style="width:40px;height:40px;position:relative;left:10px"  src="../../src/assets/img/avatar.png" height="26" alt="">
            <span style="position:relative;left:30px;bottom:10px;font-size:20px;font-weight: bold;color:rgb(67, 127, 191)">Terri Teacher</span>
          </div>
         
          
        </div>
        <div style="margin:10px;padding:7px;border-style:solid none solid none;border-color:rgb(246, 247, 249);height:60px" >
          <div style="height:40px;position:absolute">
            <img style="width:40px;height:40px;position:relative;left:10px"  src="../../src/assets/img/avatar.png" height="26" alt="">
            <span style="position:relative;left:30px;bottom:10px;font-size:20px;font-weight: bold;color:rgb(67, 127, 191)">Terri Teacher</span>
          </div>
         
          
        </div>
        <div style="margin:10px;padding:7px;border-style:solid none solid none;border-color:rgb(246, 247, 249);height:60px" >
          <div style="height:40px;position:absolute">
            <img style="width:40px;height:40px;position:relative;left:10px"  src="../../src/assets/img/avatar.png" height="26" alt="">
            <span style="position:relative;left:30px;bottom:10px;font-size:20px;font-weight: bold;color:rgb(67, 127, 191)">Terri Teacher</span>
          </div>
         
          
        </div>
        <div style="margin:10px;padding:7px;border-style:solid none solid none;border-color:rgb(246, 247, 249);height:60px" >
          <div style="height:40px;position:absolute">
            <img style="width:40px;height:40px;position:relative;left:10px"  src="../../src/assets/img/avatar.png" height="26" alt="">
            <span style="position:relative;left:30px;bottom:10px;font-size:20px;font-weight: bold;color:rgb(67, 127, 191)">Terri Teacher</span>
          </div>
         
          
        </div>
        </div></el-col>
       </el-row>
   

   
   
   
   
   
   </div>
   
       <CommonFooter/>
    </div>
   </template>
   
   <script>
   
   import arrayToTree from "array-to-tree";
   
   
 
   import { pdfpage } from '@/http/api.js' // 导入接口
   
   import CommonLayout from '@/components/CommonLayout.vue'
   
   import CommonFooter from '@/components/CommonFooter.vue'
   import CommonStudent from '@/components/CommonStudent.vue'
   import CommonTeacher from '@/components/CommonTeacher.vue'
   export default {
       name:"discussiondetail",
       data(){
           return {
               status:"",
               type2:"",
               
               
               
               
   
        
   
   
           }
       },
   mounted() {
       
       
   
       console.log(this.$route.params.groupid)
       console.log(this.$route.params.module_id)
       
              
   
   
   
    
   
   
             
            
             
            
   
           
   
      
        
   
   
   

   },
   
       components: {     
       CommonLayout,
       CommonFooter,
       CommonStudent,
       CommonTeacher },
   
   }
   </script>
   
   <style>
     .el-row {
    margin-bottom: 20px;
  
  }
  .el-col {
    border-radius: 4px;
  }
  .img{
  width:40px;
  height:40px;
  margin-top:13px;
  position: absolute;
  left:100px
 

  }

  .grid-content {
    border-radius: 4px;
    min-height: 70px;
  }
  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
   </style>
   
   <style lang="css"  src='../../src/assets/.css/common.css'  scoped>
   
   </style>
   <style lang="css"  src='../../src/assets/.css/swiper-bundle.min.css'  scoped>
   
   </style>
   <style lang="css"  src='../../src/assets/.css/index.css'  scoped>
   
   </style>
   <style lang="css"  src='../../src/assets/.css/page.css'  scoped>
   
   </style>
   <style lang="css" scoped>
    *{
        font-family: Avenir, Helvetica, Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-align: center;
      color: #2c3e50;
    }
    </style>
   