<template>
  
</template>

<script>

const clientUid=''



export default {
    clientUid
}
</script>

<style>

</style>