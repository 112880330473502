<template>
  <div>
    <div style="position: absolute; min-width: 1250px">
      <div class="img_title flex_center">
        <img
          style="width: 100%; fit: cover"
          src="../../src/assets/img/course_center_bg.png"
          alt=""
        />
      </div>

      <div class="course_list" style="text-align: center">
        <div class="wrapper course_filter flex_between">
          <ul class="tab list_tab" style="text-align: left; margin-top: -8px">
            <li>
              <a
                href="javascript:;"
                @click="all"
                :class="n === 1 ? 'current' : ''"
                >{{ $t("message.all") }}</a
              >
            </li>
            <li>
              <a
                href="javascript:;"
                @click="selection"
                :class="n === 2 ? 'current' : ''"
                >{{ $t("message.selected") }}</a
              >
            </li>
            <li>
              <a
                href="javascript:;"
                @click="noselection"
                :class="n === 3 ? 'current' : ''"
                >{{ $t("message.unselected") }}</a
              >
            </li>
            <li>
              <a
                href="javascript:;"
                @click="underreview"
                :class="n === 4 ? 'current' : ''"
                >{{ $t("message.underreview") }}</a
              >
            </li>
          </ul>

          <el-select
            style="text-align: right; margin-top: -20px"
            v-model="value"
            :placeholder="$t('message.all')"
          >
            <el-option
              v-for="item in type"
              :key="item.value"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </div>

        <div class="clearfix" style="width: 100%; text-align: center">
          <div
            class="hc_item box"
            v-for="(item, index) in result"
            :key="index"
            v-if="
              [(currentPage - 1) * pageSize] <= index &&
              index < currentPage * pageSize
            "
            style="
              width: 19.5%;
              height: 210px;
              float: left;
              margin-left: 100px;
              margin-right: -60px;
              margin-bottom: 20px;
            "
          >
            <div class="cover_area">
              <router-link
                :to="{ name: 'coursedetail', params: { id: item.id } }"
              >
                <img
                  style="height: 150px"
                  :src="item.cover_url"
                  alt=""
                  class="object_fit cover"
                />
              </router-link>
            </div>
            <div class="item_course_info">
              <el-row style="text-align: right; bottom: 40px">
                <el-button
                  class="edit"
                  @click="choose(index)"
                  size="mini"
                  type="primary"
                  style="
                    height: 30px;
                    width: 80px;
                    font-weight: bold;
                    font-size: 15px;
                  "
                  v-if="item.status == 0"
                  >{{ $t("message.choose") }}</el-button
                >
                <el-button
                  class="edit"
                  disabled
                  size="mini"
                  type="primary"
                  style="
                    height: 30px;
                    width: 80px;
                    font-weight: bold;
                    font-size: 15px;
                  "
                  v-if="item.status == 2 || item.status == 3"
                  >{{ $t("message.underreview") }}</el-button
                >
                <router-link
                  :to="{ name: 'coursedetail', params: { id: item.id } }"
                >
                  <el-button
                    class="edit"
                    size="mini"
                    type="primary"
                    style="
                      height: 30px;
                      width: 90px;
                      font-weight: bold;
                      font-size: 15px;
                    "
                    v-if="item.status == 1"
                    >{{ $t("message.entercourse") }}</el-button
                  >
                </router-link>
              </el-row>
              <div class="border" style="margin-top: -40px">
                <div
                  v-if="item.is_public === 1 && item.is_star === 1"
                  class="hot_range"
                  style="padding-left: 10px"
                >
                  {{ $t("message.Featurec") }}
                </div>
                <div
                  v-if="item.is_star !== 1 && item.is_public === 1"
                  class="hot_range"
                  style="padding-left: 10px"
                >
                  {{ $t("message.public") }}
                </div>

                <div
                  class="flex_between"
                  style="margin: 10px; margin-top: 15px; margin-bottom: 20px"
                >
                  <el-popover
                    v-if="item.title.length > 10"
                    placement="top-start"
                    width="20"
                    trigger="hover"
                    style="margin-top: -10px"
                    :content="item.title"
                  >
                    <em
                      style="font-size: 16px; color: black; margin-top: -10px"
                      slot="reference"
                      >{{ item.title | ellipsis }}</em
                    >
                  </el-popover>

                  <em
                    v-if="item.title.length <= 10"
                    style="font-size: 16px; color: black; margin-top: -10px"
                    >{{ item.title }}</em
                  >

                  <em
                    v-if="item.title.length > 10"
                    class="tag"
                    style="margin-top: -20px"
                    >{{ item.cate_name }}</em
                  >
                  <em
                    v-if="item.title.length <= 10"
                    class="tag"
                    style="margin-top: -10px"
                    >{{ item.cate_name }}</em
                  >
                </div>
                <div
                  class="flex_between"
                  v-if="item.title.length > 10"
                  style="
                    padding-left: 10px;
                    padding-right: 10px;
                    margin-top: -15px;
                  "
                >
                  <em>{{ $t("message.teacher") }}：{{ item.teacher_name }}</em>
                  <em
                    >{{ $t("message.coursestudy") }}：{{
                      item.student_count
                    }}</em
                  >
                </div>
                <div
                  v-if="item.title.length <= 10"
                  class="flex_between"
                  style="
                    padding-left: 10px;
                    padding-right: 10px;
                    margin-top: -15px;
                  "
                >
                  <em>{{ $t("message.teacher") }}：{{ item.teacher_name }}</em>
                  <em
                    >{{ $t("message.coursestudy") }}：{{
                      item.student_count
                    }}</em
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="position: relative; top: 640px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[8, 16, 24, 32]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="result ? result.length : 0"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { index } from "@/http/api.js"; // 导入接口
import { courseindexpage } from "@/http/api.js"; // 导入接口
import { coursechoose } from "@/http/api.js"; // 导入接口
import CommonLayout from "@/components/CommonLayout.vue";

import CommonFooter from "@/components/CommonFooter.vue";
import CommonStudent from "@/components/CommonStudent.vue";
import CommonTeacher from "@/components/CommonTeacher.vue";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
export default {
  name: "coursecenter",
  data() {
    return {
      value: "",
      course: [],
      type: [],

      result: [],

      active: "",

      n: 0,
      currentPage: 1,
      pageSize: 8,
    };
  },
  filters: {
    ellipsis(value) {
      if (!value) return "";
      if (value.length > 10) {
        return value.slice(0, 10) + "...";
      }
      return value;
    },
  },
  created() {
    //已登录状态再进入登陆页面，直接跳转到首页。不允许登陆状态再次登陆
    // let token = JSON.parse(localStorage.getItem("Authorization"));
    // if (token != null) {
    //   this.$router.push("common");
    // }
    FingerprintJS.load().then((fp) => {
      fp.get().then((result) => {
        this.$global.clientUid = result.visitorId;
        //  console.log(this.$global.clientUid);//全局变量clientUid
      });
    });
  },
  watch: {
    value: {
      handler: function (newValue, oldValue) {
        console.log(newValue);
        if (newValue !== "全部") {
          if (this.result !== null) {
            this.result = this.course.filter(function (num) {
              return num.cate_name === newValue;
            });
          }
        }
        if (newValue === "全部") {
          this.result = this.course;
        }
      },
    },
  },
  methods: {
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
    },

    all() {
      this.n = 1;
      this.result = this.course;
    },
    selection() {
      this.active = "current";
      this.n = 2;
      if (this.result !== null) {
        this.result = this.course.filter(function (num) {
          return num.status === 1;
        });
      }
    },
    noselection() {
      this.active = "current";
      this.n = 3;
      if (this.result !== null) {
        this.result = this.course.filter(function (num) {
          return num.status === 0;
        });
      }
    },
    underreview() {
      this.active = "current";
      this.n = 4;
      if (this.result !== null) {
        this.result = this.course.filter(function (num) {
          return num.status === 2 || num.status === 3;
        });
      }
    },

    choose(num) {
      let token = JSON.parse(localStorage.getItem("Authorization"));
      let verify = localStorage.getItem("verify");
      console.log("course", this.result[num]);
      if (this.result[num].code_exist === 0) {
        coursechoose({
          course_id: this.result[num].id,
          student_code: null,
          user_id: token.user_id,
          uid: token.user_id,
          schoolid: token.school_id,
          verify: verify,
        }).then(
          (res) => {
            if (res.status === 1 || res.status === 3) {
              this.$alert("" + res.msg, "状态提示", {
                confirmButtonText: "确定",
                showClose: false,
              });
              this.result[num].status = res.status;
            } else {
            }
          },
          function (error) {}
        );
      } else {
        this.$prompt("请输入验证码", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }).then(({ value }) => {
          // this.result[num].code = value;
          console.log("code", value);
          coursechoose({
            course_id: this.result[num].id,
            student_code: value,
            user_id: token.user_id,
            uid: token.user_id,
            schoolid: token.school_id,
            verify: verify,
          }).then(
            (res) => {
              console.log("reschoose", res);
              if (
                res.status === 3 ||
                res.status === 2 ||
                res.status === 1 ||
                res.status === 0
              ) {
                this.$alert("" + res.msg, "状态提示", {
                  confirmButtonText: "确定",
                });
                this.result[num].status = res.status;
              } else {
                this.$alert("" + res.msg, "状态提示", {
                  confirmButtonText: "确定",
                });
              }
              //  console.log( this.course[num].status)
            },
            function (error) {}
          );
        });
      }

      // console.log(this.course[num].code)
    },
  },

  components: {
    CommonLayout,
    CommonFooter,
    CommonStudent,
    CommonTeacher,
  },
  mounted() {
    setTimeout(() => {
      let token = JSON.parse(localStorage.getItem("Authorization"));
      let verify = localStorage.getItem("verify");
      console.log(token.user_id);
      courseindexpage({
        user_id: token.user_id,
        school_id: token.school_id,
        uid: token.user_id,
        schoolid: token.school_id,
        verify: verify,
      }).then((res) => {
        console.log("shuju", res);
        this.course = res.course;
        this.type = res.type;
        this.result = this.course;
        if (this.type !== null) {
          this.type.unshift({
            name: "全部",
          });
        }
      });
    }, 300);
  },
};
</script>

<style scoped lang="css">
.fot {
  position: fixed;
  bottom: 10;
  width: 100%;
  line-height: var(--footer-height);
  color: #fff;
  margin-bottom: 5px;
}
</style>

<style lang="css" src="../../src/assets/.css/common.css" scoped></style>
<style lang="css" src="../../src/assets/.css/index.css" scoped></style>
<style lang="css" src="../../src/assets/.css/page.css" scoped></style>
<style lang="css" scoped>
* {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.edit,
.edit:focus {
  color: rgb(239, 88, 10);
  background-color: rgb(253, 246, 236);
  border-color: rgb(239, 88, 10);
}

.edit:hover {
  background: rgb(239, 88, 10);
  border-color: rgb(239, 88, 10);
  color: white;
}
</style>
