import { render, staticRenderFns } from "./competitiondetail.vue?vue&type=template&id=4b68f66a&scoped=true&"
import script from "./competitiondetail.vue?vue&type=script&lang=js&"
export * from "./competitiondetail.vue?vue&type=script&lang=js&"
import style1 from "../../src/assets/.css/common.css?vue&type=style&index=1&id=4b68f66a&prod&lang=css&scoped=true&"
import style2 from "../../src/assets/.css/swiper-bundle.min.css?vue&type=style&index=2&id=4b68f66a&prod&lang=css&scoped=true&"
import style3 from "../../src/assets/.css/index.css?vue&type=style&index=3&id=4b68f66a&prod&lang=css&scoped=true&"
import style4 from "../../src/assets/.css/page.css?vue&type=style&index=4&id=4b68f66a&prod&lang=css&scoped=true&"
import style5 from "./competitiondetail.vue?vue&type=style&index=5&id=4b68f66a&prod&lang=css&scoped=true&"
import style6 from "./competitiondetail.vue?vue&type=style&index=6&id=4b68f66a&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b68f66a",
  null
  
)

export default component.exports