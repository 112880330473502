export function redirectF(){
    let token = JSON.parse(localStorage.getItem("Authorization"));
    if (token && (token.type == 3 || token.type == 4 || token.type == 5)) {
        return '/data'
      }
      else {
        return '/common'
      }
    

  
}