<template>
  <div>
      <el-tree :data="allData" default-expand-all :expand-on-click-node="false" :draggable="true"
          :render-content="renderContent"></el-tree>
  </div>
</template>

<style lang="less">
.el-tree .el-tree-node__content {
  height: 45px;
}


.treeNode {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.edit {
  color: #fff;
  background-color: rgb(0, 149, 154);
  border-color: rgb(0, 149, 154);
}

.edit:hover,
.edit:focus {
  background: rgb(129, 188, 190);
  border-color: rgb(129, 188, 190);
  color: #fff;
}
</style>

<script>
import _ from "lodash";
import Queue from "@/common/queue.js";
export default {
    name: "DetailTree",
    props: {
        data: {
            type: Array,
            default: () => [],
        },
        course_id: null,
        status:null
    },
    data() {
        return {
            allData: [],
            course: [],
            n: null,
        };
    },
    watch: {
        data: {
            handler: function (n, o) {
                this.transformData();
            },
            deep: true,
        },
    },
    methods: {
        transformData() {

            //平面数组=>树形数据
            //需要克隆数据进行操作，不能在子组件中直接操作父组件的数据
            if (this.data.length == 0) {
                return;
            }
            let AllData = _.cloneDeep(this.data);
            // 以pid和fid为依据生成树
            let result2 = AllData.reduce((memo, current, index, data) => {
                if (current.operation) {
                    // 如果已经操作过该节点，则跳过
                    return memo;
                }
                this.pushOBinMemo(memo, current, data);
                return memo;
            }, []);

            this.allData = result2;
        },

        // 将前置节点及其前置节点及其。。。推入memo
        pushOBinMemo(memo, current, data) {
            // 如果前置节点还没进入memo，则递归
            if (current.fid != 0 && !this.readTreeArrAndFindNode(memo, current.fid)) {
                let item = this.readTreeArrAndFindNode(data, current.fid);
                this.pushOBinMemo(memo, item, data);
            }
            if (current.pid == 0) {
                memo.push(current);
            } else {
                let parent = this.readTreeArrAndFindNode(memo, current.pid);
                parent.children
                    ? parent.children.push(current)
                    : (parent.children = [current]);
            }
            current["operation"] = true;
        },

        readTreeArrAndFindNode(treeArr, id) {
            let length = treeArr.length;
            var result = null;
            var q = new Queue();
            for (let i = 0; i < length; i++) {
                q.enqueue(treeArr[i]);
            }
            // 广度遍历树（队列）
            while (!q.isEmpty()) {
                let item = q.front();
                if (item.id == id) {
                    result = item;
                    break;
                }
                if (item.children) {
                    for (let i of item.children) {
                        q.enqueue(i);
                    }
                }
                q.dequeue();
            }
            return result;
        },

        // 在data中找到双亲为pid的末尾节点，返回该节点id；若无满足条件的节点，返回null
        // data: 平面数组，pid：ParentID
        findLastBro(data, pid) {
            let id = null;
            let node = null;
            for (let item of data) {
                if (item.pid == pid) {
                    if (!node || node.fid < item.fid) {
                        node = item;
                        id = item.id;
                    }
                }
            }
            return id;
        },

    handleNodeClick (data) {
      if (data.type == "课件-pdf") {
        this.$router.push({
          name: "coursepdf",
          params: { id: data.id, kcid: this.course_id },
        });
      } else if (data.type == "课件-视频") {
        this.$router.push({
          name: "coursevideo",
          params: { id: data.id, kcid: this.course_id },
        });
      } else if (data.type == "问卷") {
        this.$router.push({
          name: "coursequestion",
          params: { id: data.id, kcid: this.course_id, back2: 2 },
        });
      } else if (data.type == "测试") {
        this.$router.push({
          name: "coursetest",
          params: { id: data.id, kcid: this.course_id, back2: 2 },
        });
      } else if (
        data.type == "编程任务-python3" ||
        data.type == "一般任务-文件" ||
        data.type == "一般任务-富文本" ||
        data.type == "编程任务-mixtoy" ||
        data.type == "自动测评任务" ||
        data.type == "编程任务-microbit" ||
        data.type == "编程任务-mithonCC" ||
        data.type == "编程任务-mixgoCE" ||
        data.type == "编程任务-mithon" ||
        data.type == "编程任务-mixgoAI" ||
        data.type == "编程任务-mixgoCar" ||
        data.type == "附件"
      ) {
        this.$router.push({
          name: "coursehomework",
          params: { id: data.id, kcid: this.course_id, type: data.type, back2: 2 },
        });
      } else if (
        data.type == "编程练习"
      ) {
        this.$router.push({
          name: "codeexercise",
          params: { id: data.id, kcid: this.course_id, type: data.type, back2: 2 },
        });
      }

    },

    renderContent (h, { node, data, store }) {
      return (
        <span class="treeNode">
          <span >{data.label}
            {(data.type == '编程任务-python3' || data.type == '自动测评任务' || data.type == '编程任务-microbit' || data.type == '编程任务-mithonCC' || data.type == '编程任务-mixgoCE' || data.type == '编程任务-mithon' || data.type == "编程任务-mixgoAI" ||
              data.type == "编程任务-mixgoCar") ? (
              <el-tag type="warning" style="text-align:left;margin-left:20px">编程任务</el-tag>
            ) : null}
            {data.type == '课件-pdf' ? (
              <el-tag type="warning" style="text-align:left;margin-left:20px">课件-pdf</el-tag>
            ) : null}
            {data.type == '附件' ? (
              <el-tag type="warning" style="text-align:left;margin-left:20px">附件</el-tag>
            ) : null}
            {data.type == '课件-视频' ? (
              <el-tag type="warning" style="text-align:left;margin-left:20px">课件-视频</el-tag>
            ) : null}
            {data.type == '一般任务-文件' ? (
              <el-tag type="warning" style="text-align:left;margin-left:20px">一般文件</el-tag>
            ) : null}
            {data.type == '一般任务-富文本' ? (
              <el-tag type="warning" style="text-align:left;margin-left:20px">富文本</el-tag>
            ) : null}
            {data.type == '测试' ? (
              <el-tag type="warning" style="text-align:left;margin-left:20px">测试</el-tag>
            ) : null}
            {data.type == '问卷' ? (
              <el-tag type="warning" style="text-align:left;margin-left:20px">问卷</el-tag>
            ) : null}
            {data.type == '编程练习' ? (
              <el-tag type="warning" style="text-align:left;margin-left:20px">编程练习</el-tag>
            ) : null}
          </span>
          {(data.type != "标题" && data.is_public == 1 && data.exist == 1 && this.status == 1)? (
            <el-button type='primary' class='edit' size="mini" on-click={(e) => this.handleNodeClick(data)} >进入</el-button>
          ) : (data.type != "标题" && this.status == 1 && (data.is_public == 0 || data.exist == 0)? (
            <i style="margin-left:5px;font-size:10px;color:rgb(239, 88, 10);font-weight:bold" v-if="item.school_id===0">暂未公开</i>
          ) : null)}
        </span>
      );
    },
  },
  data() {
      return {
          allData: [],
          course: [],
          n: null,
      };
  },
  watch: {
      data: {
          handler: function (n, o) {
              this.transformData();
          },
          deep: true,
      },
  },
  methods: {
      transformData() {

          //平面数组=>树形数据
          //需要克隆数据进行操作，不能在子组件中直接操作父组件的数据
          if (this.data.length == 0) {
              return;
          }
          let AllData = _.cloneDeep(this.data);
          // 以pid和fid为依据生成树
          let result2 = AllData.reduce((memo, current, index, data) => {
              if (current.operation) {
                  // 如果已经操作过该节点，则跳过
                  return memo;
              }
              this.pushOBinMemo(memo, current, data);
              return memo;
          }, []);

          this.allData = result2;
      },

      // 将前置节点及其前置节点及其。。。推入memo
      pushOBinMemo(memo, current, data) {
          // 如果前置节点还没进入memo，则递归
          if (current.fid != 0 && !this.readTreeArrAndFindNode(memo, current.fid)) {
              let item = this.readTreeArrAndFindNode(data, current.fid);
              this.pushOBinMemo(memo, item, data);
          }
          if (current.pid == 0) {
              memo.push(current);
          } else {
              let parent = this.readTreeArrAndFindNode(memo, current.pid);
              parent.children
                  ? parent.children.push(current)
                  : (parent.children = [current]);
          }
          current["operation"] = true;
      },

      readTreeArrAndFindNode(treeArr, id) {
          let length = treeArr.length;
          var result = null;
          var q = new Queue();
          for (let i = 0; i < length; i++) {
              q.enqueue(treeArr[i]);
          }
          // 广度遍历树（队列）
          while (!q.isEmpty()) {
              let item = q.front();
              if (item.id == id) {
                  result = item;
                  break;
              }
              if (item.children) {
                  for (let i of item.children) {
                      q.enqueue(i);
                  }
              }
              q.dequeue();
          }
          return result;
      },

      // 在data中找到双亲为pid的末尾节点，返回该节点id；若无满足条件的节点，返回null
      // data: 平面数组，pid：ParentID
      findLastBro(data, pid) {
          let id = null;
          let node = null;
          for (let item of data) {
              if (item.pid == pid) {
                  if (!node || node.fid < item.fid) {
                      node = item;
                      id = item.id;
                  }
              }
          }
          return id;
      },

  handleNodeClick (data) {
    if (data.type == "课件-pdf") {
      this.$router.push({
        name: "coursepdf",
        params: { id: data.id, kcid: this.course_id },
      });
    } else if (data.type == "课件-视频") {
      this.$router.push({
        name: "coursevideo",
        params: { id: data.id, kcid: this.course_id },
      });
    } else if (data.type == "问卷") {
      this.$router.push({
        name: "coursequestion",
        params: { id: data.id, kcid: this.course_id, back2: 2 },
      });
    } else if (data.type == "测试") {
      this.$router.push({
        name: "coursetest",
        params: { id: data.id, kcid: this.course_id, back2: 2 },
      });
    } else if (
      data.type == "编程任务-python3" ||
      data.type == "一般任务-文件" ||
      data.type == "一般任务-富文本" ||
      data.type == "编程任务-mixtoy" ||
      data.type == "自动测评任务" ||
      data.type == "编程任务-microbit" ||
      data.type == "编程任务-mithonCC" ||
      data.type == "编程任务-mixgoCE" ||
      data.type == "编程任务-mithon" ||
      data.type == "编程任务-mixgoAI" ||
      data.type == "编程任务-mixgoCar" ||
      data.type == "附件"
    ) {
      this.$router.push({
        name: "coursehomework",
        params: { id: data.id, kcid: this.course_id, type: data.type, back2: 2 },
      });
    } else if (
      data.type == "编程练习"
    ) {
      this.$router.push({
        name: "codeexercise",
        params: { id: data.id, kcid: this.course_id, type: data.type, back2: 2 },
      });
    }

  },

  renderContent (h, { node, data, store }) {
    return (
      <span class="treeNode">
        <span >{data.label}
          {(data.type == '编程任务-python3' || data.type == '自动测评任务' || data.type == '编程任务-microbit' || data.type == '编程任务-mithonCC' || data.type == '编程任务-mixgoCE' || data.type == '编程任务-mithon' || data.type == "编程任务-mixgoAI" ||
            data.type == "编程任务-mixgoCar") ? (
            <el-tag type="warning" style="text-align:left;margin-left:20px">编程任务</el-tag>
          ) : null}
          {data.type == '课件-pdf' ? (
            <el-tag type="warning" style="text-align:left;margin-left:20px">课件-pdf</el-tag>
          ) : null}
          {data.type == '附件' ? (
            <el-tag type="warning" style="text-align:left;margin-left:20px">附件</el-tag>
          ) : null}
          {data.type == '课件-视频' ? (
            <el-tag type="warning" style="text-align:left;margin-left:20px">课件-视频</el-tag>
          ) : null}
          {data.type == '一般任务-文件' ? (
            <el-tag type="warning" style="text-align:left;margin-left:20px">一般文件</el-tag>
          ) : null}
          {data.type == '一般任务-富文本' ? (
            <el-tag type="warning" style="text-align:left;margin-left:20px">富文本</el-tag>
          ) : null}
          {data.type == '测试' ? (
            <el-tag type="warning" style="text-align:left;margin-left:20px">测试</el-tag>
          ) : null}
          {data.type == '问卷' ? (
            <el-tag type="warning" style="text-align:left;margin-left:20px">问卷</el-tag>
          ) : null}
          {data.type == '编程练习' ? (
            <el-tag type="warning" style="text-align:left;margin-left:20px">编程练习</el-tag>
          ) : null}
        </span>
        {(data.type != "标题" && data.is_public == 1 && data.exist == 1 && this.status == 1)? (
          <el-button type='primary' class='edit' size="mini" on-click={(e) => this.handleNodeClick(data)} >进入</el-button>
        ) : (data.type != "标题" && this.status == 1 && (data.is_public == 0 || data.exist == 0)? (
          <i style="margin-left:5px;font-size:10px;color:rgb(239, 88, 10);font-weight:bold" >暂未公开</i>
        ) : null)}
      </span>
    );
  },
},
};
</script>

