<template>
  <div>
    <!-- <div v-if="type2===null&&status===0">
     
    <CommonLayout></CommonLayout>
       </div>
       <div v-if="type2===1&&status===1">
    <CommonStudent></CommonStudent>
       </div>
       <div v-if="type2===2&&status===1">
    <CommonTeacher></CommonTeacher>
       </div> -->
    <div >
      <div class="bread" >
        <router-link
          :to="{ name: 'coursedetail', params: { id: $route.params.kcid } }"
          style="margin-left: -1060px; font-size: 20px"
          >返回</router-link
        >
        <span style="margin-left: ; font-size: 20px">分组详情</span>
      </div>

      <div class="wrapper">
        <div class="box top_detail">
          <div class="detail_title flex">
            <img
              src="../../src/assets/img/green_test_large.png"
              height="26"
              alt=""
            />
            <span style="font-size: 20px">{{ topic }}</span>
          </div>

          <p style="text-align: left; font-size: 15px">
            讨论要求：{{ describe }}
          </p>
          <div class="detail_info_bar">
            <div class="info_item">
              <img src="../../src/assets/img/info_bar_time.png" alt="" />
              <span>发布时间：{{ create_time }}</span>
            </div>
            <div class="info_item">
              <img src="../../src/assets/img/info_bar_deadline.png" alt="" />
              <span>截止时间：{{ deadline }}</span>
            </div>
            <div class="info_item">
              <img src="../../src/assets/img/info_bar_list.png" alt="" />
              <span>组数：{{ groups.length }}组</span>
            </div>
          </div>
        </div>
      </div>

      <div class="wrapper box task_info margin_bottom_30">
        <div class="clearfix">
          <div
            class="hc_item box"
            v-for="(item, index) in groups"
            :key="index"
            style="
              width: 230px;
              height: 80px;
              float: left;
              margin-left: 47px;
              margin-right: 12px;
              margin-bottom: 20px;
              margin-top: 30px;
              background-color: rgb(30, 164, 168);
            "
          >
            <div class="item_course_info">
              <div class="info_item" @click="jump(index)">
                <img
                  style="width: 30px; height: 30px"
                  src="../../src/assets/img/chat.png"
                  alt=""
                />

                <a style="color: aliceblue">{{ item.name }}</a>
                <!-- <router-link style="color:aliceblue">{{item.name}}</router-link> -->
                <a style="color: aliceblue"
                  >({{ item.studens_count }}/{{ item.number }})</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <CommonFooter />
  </div>
</template>
   
   <script>

import { discussion } from "@/http/api.js"; // 导入接口

import CommonLayout from "@/components/CommonLayout.vue";

import CommonFooter from "@/components/CommonFooter.vue";
import CommonStudent from "@/components/CommonStudent.vue";
import CommonTeacher from "@/components/CommonTeacher.vue";
export default {
  name: "coursediscussion",
  data() {
    return {
      status: "",
      type2: "",
      id: "",
      kcid: "",

      topic: "",
      create_time: "",
      deadline: "",
      describe: "",
      groups: [],
    };
  },
  methods: {
    jump(index) {
      this.$router.push({
        name: "discussiondetail",
        params: {
          groupid: this.groups[index].id,
          module_id: this.$route.params.id,
          kcid: this.$route.params.kcid,
        },
      });
    },
  },
  mounted() {
    //    this.id=this.$route.params.id
    //    this.kcid=this.$route.params.kcid

    //    console.log(this.id)
    console.log(this.$route.params.kcid); //课程id
    console.log(this.$route.params.id); //模块id

    discussion({
      // id:this.$route.params.id
    }).then((res) => {
      this.topic = res.topic;
      this.create_time = res.create_time;
      this.deadline = res.deadline;
      this.describe = res.describe;
      this.groups = res.groups;
    });


  },

  components: {
    CommonLayout,
    CommonFooter,
    CommonStudent,
    CommonTeacher,
  },
};
</script>
   
   <style>
</style>
   
   <style lang="css"  src='../../src/assets/.css/common.css'  scoped>
</style>
   <style lang="css"  src='../../src/assets/.css/swiper-bundle.min.css'  scoped>
</style>
   <style lang="css"  src='../../src/assets/.css/index.css'  scoped>
</style>
   <style lang="css"  src='../../src/assets/.css/page.css'  scoped>
</style>
   <style lang="css" scoped>
* {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>